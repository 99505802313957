import { Box, Logo, Typography, useMedia } from '@partstech/ui';
import React, { useCallback } from 'react';
import {
  useRemoveBackorderFilter,
  useAddDefaultAvailabilityFilter,
  useChangeFilter,
  useSearchParams,
} from 'hooks/search';
import { ConfigureRetailPrice } from 'pages/SearchResults/ConfigureRetailPrice';
import { DiagramQuickLink } from '../DiagramQuickLink';
import { Filter } from './Filter';
import { styles } from './Filters.styles';
import type { Filter as FilterType } from 'types/search';

type Props = {
  filters: FilterType[];
  onSearch?: (e: React.MouseEvent) => void;
  onRedirect?: () => void;
};

export const Filters = ({ filters, onSearch = () => {}, onRedirect }: Props) => {
  const { isMobile } = useMedia();
  const { isTiresSearch } = useSearchParams();

  const { changeFilter } = useChangeFilter(filters);

  useRemoveBackorderFilter();
  useAddDefaultAvailabilityFilter();

  const handleChangeFilter = useCallback(
    (name: FilterType['name']) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;

      changeFilter(name, value, checked);
    },
    [changeFilter]
  );

  if (isMobile && filters.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pt={12}
        pb={11}
        width="100%"
        height="100%"
        textAlign="center"
        data-testid="filtersBlock"
      >
        <Box mx="auto" mb={3} css={styles.logo}>
          <Logo size="emblem" variant="dark" fullSize />
        </Box>

        <Typography variant="h2" color="dark">
          No options
        </Typography>
        <Typography variant="h2" color="dark">
          are available
        </Typography>
        <Box mt={8}>
          <Typography variant="caption" color="subtleText">
            try
          </Typography>
          <button css={styles.filtersEmptyButton} onClick={onSearch}>
            <Typography variant="overline" color="secondary">
              start new search
            </Typography>
          </button>
        </Box>
      </Box>
    );
  }

  if (filters.length === 0) {
    return null;
  }

  return (
    <Box width={{ sm: '100%', md: 'auto' }} background={{ sm: 'white', md: 'inherit' }} data-testid="filtersBlock">
      {isMobile && <ConfigureRetailPrice onRedirect={onRedirect} />}
      {isMobile && !isTiresSearch && <DiagramQuickLink />}

      {filters.map((filter) => (
        <Filter key={filter.name} onChange={handleChangeFilter(filter.name)} {...filter} />
      ))}
    </Box>
  );
};
