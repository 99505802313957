import type { PreferredBrandUnified } from 'types/preferredBrand';

export const getPreferredBrandsNamesMap = (
  partTypeIds: number[],
  brands: PreferredBrandUnified[]
): Record<number, string[]> =>
  partTypeIds.reduce((acc, partTypeId) => {
    const filteredBrands = brands.filter((brand) => String(brand.partTypeId) === String(partTypeId));
    const brandNames = filteredBrands.map((brand) => brand.name);
    return { ...acc, [partTypeId]: brandNames };
  }, {});
