import { BaseStatus } from 'components/Product/StoreAvailability/Statuses/BaseStatus';
import type { AvailabilityStatusProps } from 'components/Product/StoreAvailability/AvailabilityDropdown';

type Props = AvailabilityStatusProps;

export const BackorderStatus = ({ onClick, trailingIcon, variant }: Props) => (
  <BaseStatus
    color="monoDark"
    leadingIcon={variant === 'default' ? 'hourglass_top' : undefined}
    onClick={onClick}
    text="Backorder"
    trailingIcon={trailingIcon}
  />
);
