import { getCurrentScope } from '@sentry/browser';
import { useEffect } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

const configureSentry = (user: { accountId: string | null; username: string | null; email: string | null }) => {
  getCurrentScope().setUser({
    id: user.accountId ?? undefined,
    username: user.username ?? undefined,
    email: user.email ?? undefined,
  });
};

export const useSentry = () => {
  const { accountId, username, email, isSuccess } = useGetCurrentUser();

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    configureSentry({ accountId, username, email });
  }, [accountId, email, isSuccess, username]);
};
