import { forwardRef, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRouter } from 'app/contexts/RouterProvider/RouterContext';
import { isPathNeedBackground } from '../isPathNeedBackground';
import { useAppLocation } from '../useAppLocation';
import type { LocationState } from '../useAppLocation';
import type { RouterLinkPropsType } from '@partstech/ui';
import type { MouseEventHandler } from 'react';
import type { LinkProps } from 'react-router-dom';

type Props = LinkProps & Pick<RouterLinkPropsType, 'to' | 'css'>;

export const RouterLink = forwardRef<HTMLAnchorElement, Props>(({ onClick, to, state, ...props }, ref) => {
  const { isBlocked, setTryToNavigate } = useRouter();
  const location = useAppLocation();

  const enhancedState = useMemo<LocationState>(
    () => (isPathNeedBackground(location.pathname, to) ? { ...state, background: location } : state),
    [state, location, to]
  );

  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (isBlocked) {
        e.preventDefault();
        setTryToNavigate({ to, options: { state: enhancedState } });
      }

      if (onClick) {
        onClick(e);
      }
    },
    [isBlocked, onClick, setTryToNavigate, to, enhancedState]
  );

  return <Link ref={ref} to={to} state={enhancedState} {...props} onClick={handleClick} />;
});
