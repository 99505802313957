import { Icon } from '@partstech/ui';
import { useUserpilotWidget } from 'integrations/userpilot';
import { NavigationItem } from '../NavigationItem';

type Props = {
  onClick?: () => void;
};

export const UserpilotWidgetButton = ({ onClick }: Props) => {
  const { openWidget } = useUserpilotWidget({ onClick });

  return <NavigationItem title="Help" adornmentBefore={<Icon name="help" color="subtleText" />} onClick={openWidget} />;
};
