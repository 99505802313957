import { combineReducers } from '@reduxjs/toolkit';
import { product } from 'store/entities/product';
import { shipment } from 'store/entities/shipment';
import { shipmentPart } from 'store/entities/shipmentPart';
import { stockOrders } from 'store/entities/stockOrders';
import { store } from 'store/entities/store';
import { supplier } from 'store/entities/supplier';
import { supplierAccounts } from 'store/entities/supplierAccounts';
import { tireSize } from 'store/entities/tireSize';
import { vehicle } from 'store/entities/vehicle';

export const entities = combineReducers({
  product,
  shipment,
  shipmentPart,
  store,
  supplier,
  supplierAccounts,
  tireSize,
  vehicle,
  stockOrders,
});
