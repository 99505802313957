import { uniq, isNotNull } from '@partstech/ui/utils';
import { getActiveCartOrderById, getActiveCartOrders } from 'shared/api/rest/gen/shop';
import { getMissingStores } from 'store/entities/store';
import { getMissingSuppliers } from 'store/entities/supplier';
import { fetchVehiclesByIds, selectVehicleById } from 'store/entities/vehicle';
import { createAppAsyncThunk } from 'store/utils';
import type { Shipment } from 'shared/api/rest/gen/shop';

export type GetShipmentsArgs = { id?: number; saveError?: boolean; loadInBackground?: boolean } | undefined;

export const getShipments = createAppAsyncThunk<Shipment[], GetShipmentsArgs>(
  'entities/shipment/getAllShipmentAndParts',
  async (arg, { dispatch, getState }) => {
    const entities = arg?.id ? [(await getActiveCartOrderById(arg.id)).order] : (await getActiveCartOrders()).orders;

    if (entities.length > 0) {
      const state = getState();

      const supplierIds = entities.map((entity) => entity.supplierId).map(String);

      const storeIds = entities.map((entity) => entity.storeId);

      const vehicleIds = uniq(
        entities
          .reduce(
            (memo: (string | null)[], entity) => [
              ...memo,
              ...entity.parts.reduce(
                (memoParts: (string | null)[], part) => [
                  ...memoParts,
                  part.product.vehicleId ? `${part.product.vehicleId}` : null,
                ],
                []
              ),
            ],
            []
          )
          .filter(isNotNull)
          .filter((vehicleId) => selectVehicleById(state, vehicleId) === null)
      );

      dispatch(getMissingSuppliers(supplierIds));

      dispatch(getMissingStores(storeIds));

      if (vehicleIds.length > 0) {
        dispatch(fetchVehiclesByIds(vehicleIds.filter(isNotNull).map(Number)));
      }
    }

    return entities;
  }
);
