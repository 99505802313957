import { selectId } from '../product/selectId';
import { urlParamsToQuery } from '../product/urlParamsToQuery';
import type { ShipmentPart } from 'shared/api/rest/gen/shop';
import type { ShipmentType } from 'types/cart';
import type { Product } from 'types/product';
import type { ShipmentPartEntity } from 'types/shipment';

export const createPart = (
  id: number,
  product: Product,
  quantity: number,
  shipmentId: ShipmentType['id'],
  origin: ShipmentPart['origin']
): ShipmentPartEntity => {
  const urlParams = urlParamsToQuery(product);

  return {
    availableBundles: [],
    id,
    error: null,
    labor: {},
    urlParams: {
      ...urlParams,
      credentialId: urlParams.credentialId ?? null,
      linecard: urlParams.linecard ?? 0,
      part_term: urlParams.part_term ?? null,
      supplierId: urlParams.supplierId ?? null,
      vehicle: urlParams.vehicle ?? undefined,
    },
    quantity,
    rewardPoints: product.rewardPoints?.points ?? null,
    shipmentId,
    productId: selectId(product),
    tax: 0,
    totalDiscount: 0,
    partTypeName: null,
    origin,
    bundled: false,
    vehicleId: null,
  };
};
