import { useEffect, useMemo } from 'react';
import { getShortPlanName, useSubscription } from 'features/subscription';
import { FSSetUserVars } from '../FSSetUserVars';

type Props = {
  skip: boolean;
};

export const useSendSubscriptionPackage = ({ skip = false }: Props) => {
  const { subscription, isLoading: isSubscriptionLoading } = useSubscription();

  const subscriptionPackage = useMemo(() => {
    if (subscription?.plan) {
      return Array(getShortPlanName(subscription.plan.name).toLowerCase());
    }

    return [];
  }, [subscription?.plan]);

  useEffect(() => {
    if (!skip && !isSubscriptionLoading) {
      FSSetUserVars({ package: subscriptionPackage });
    }
  }, [isSubscriptionLoading, subscriptionPackage, skip]);
};
