import { css } from '@emotion/react';
import { Box, Button, Popover, Typography, useMedia } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { currency } from 'shared/lib/string';
import { LaborIcon } from '../LaborIcon';
import { LaborDetails } from './LaborDetails';
import { LaborDetailsMobile } from './LaborDetailsMobile';
import type { LaborLine } from '../../utils';
import type { PopoverRef, Theme } from '@partstech/ui';
import type { MouseEvent } from 'react';

const styles = {
  label: css`
    line-height: 14px;
  `,
  detailsButton: (theme: Theme) => css`
    width: ${theme.sizing(11)};
    color: ${theme.color.linkText};
    font-size: 12px;
    font-weight: 700;
  `,
};

type Props = {
  laborLines: LaborLine[];
  rate: number;
  hidePopover?: boolean;
};

export const LaborInfo = ({ laborLines, rate, hidePopover }: Props) => {
  const { isMobile } = useMedia();
  const { isOpen, open: openMobileDetails, close: closeMobileDetails } = useOpen();

  const popoverRef = useRef<PopoverRef>(null);

  const totalHours = useMemo(() => {
    const result = laborLines.reduce((acc, laborLine) => acc + laborLine.duration, 0);
    return Number(result.toFixed(3));
  }, [laborLines]);

  const totalPrice = useMemo(() => currency(totalHours * rate), [rate, totalHours]);

  const preventDefault = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleOpenDetails = useCallback(
    (e: MouseEvent) => {
      preventDefault(e);

      if (isMobile) {
        return openMobileDetails();
      }

      return popoverRef.current?.toggle();
    },
    [isMobile, openMobileDetails, preventDefault]
  );

  useEffect(() => {
    if (hidePopover) {
      popoverRef.current?.close();
    }
  }, [hidePopover]);

  if (laborLines.length === 0) {
    return null;
  }

  return (
    <Box display="flex" m={{ sm: 2, md: 0 }} mt={{ md: 3 }} onClick={handleOpenDetails} onMouseDown={preventDefault}>
      <LaborIcon size="small" />

      <Box display="flex" justifyContent="space-between" width="100%">
        <div>
          <Typography variant="overline" color="subtleText" component="p" css={styles.label}>
            {totalHours} HOURS
          </Typography>

          <Box onClick={preventDefault}>
            {isMobile ? (
              <Button variant="text" fill css={styles.detailsButton} onClick={handleOpenDetails}>
                DETAILS
              </Button>
            ) : (
              <Popover
                onClose={closeMobileDetails}
                withCloseButton
                preferPlacement="bottom"
                hideArrow
                padding={0}
                title="Labor and rate breakdown"
                triggerAction={isMobile ? 'none' : 'click'}
                targetElement={
                  <Button variant="text" fill css={styles.detailsButton} onClick={handleOpenDetails}>
                    DETAILS
                  </Button>
                }
                ref={popoverRef}
              >
                <LaborDetails laborLines={laborLines} totalHours={totalHours} totalPrice={totalPrice} rate={rate} />
              </Popover>
            )}
          </Box>
        </div>

        <Typography variant="overline" color="subtleText">
          {totalPrice}
        </Typography>
      </Box>

      {isMobile && isOpen && (
        <LaborDetailsMobile
          laborLines={laborLines}
          totalHours={totalHours}
          totalPrice={totalPrice}
          rate={rate}
          onClose={closeMobileDetails}
        />
      )}
    </Box>
  );
};
