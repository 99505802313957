import { Box, Tag, TextCut, Typography } from '@partstech/ui';
import { useCallback } from 'react';

type ValuePlaceholderOption<T> = {
  id: string;
  name: string;
  count?: string;
} & T;

export type ValuePlaceholderProps<T> = {
  onRemove?: (option: ValuePlaceholderOption<T>) => void;
  onClick?: () => void;
  placeholder?: string;
  value: ValuePlaceholderOption<T>[];
};

export const ValuePlaceholder = <T,>({ value, onRemove, onClick, placeholder = '' }: ValuePlaceholderProps<T>) => {
  const handleRemoveOption = useCallback(
    (option: ValuePlaceholderOption<T>) => (e: React.MouseEvent | React.KeyboardEvent) => {
      e.stopPropagation();
      onRemove && onRemove(option);
    },
    [onRemove]
  );

  return (
    <Box
      data-testid="valuePlaceholder"
      py={2}
      display="flex"
      height={value.length > 0 ? 'auto' : '38px'}
      width="100%"
      alignItems="baseline"
      justifyContent="space-between"
      onClick={onClick}
    >
      {!value.length && placeholder && <Typography>{placeholder}</Typography>}

      <Box display="flex" flexWrap="wrap" gap={1}>
        {Boolean(value.length) && (
          <>
            {value.map((option, index) => (
              <Box key={`${option.id}-${index}`}>
                <Tag
                  avatar={'count' in option ? { initials: option.count ?? '' } : undefined}
                  data-testid="tagItem"
                  onClose={onRemove && handleRemoveOption(option)}
                >
                  <Box maxWidth={34}>
                    <TextCut>{option.name}</TextCut>
                  </Box>
                </Tag>
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};
