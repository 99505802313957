import { useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetManagementSystems.generated';
import type { GetManagementSystemsQuery } from './GetManagementSystems.generated';
import type { ArrayItemType } from 'types/general';

export type ManagementSystems = NonNullable<GetManagementSystemsQuery['managementSystems']>;
export type EntityManagementSystem = ArrayItemType<ManagementSystems>;

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['ManagementSystems'],
  endpoints: {
    GetManagementSystems: {
      providesTags: () => ['ManagementSystems'],
    },
  },
});

const { useGetManagementSystemsQuery } = api;

const emptyManagementSystems: ManagementSystems = [];

export const useGetManagementSystems = () => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { data, ...rest } = useGetManagementSystemsQuery(undefined, { skip: !hasCurrentUser });

  const managementSystems = useMemo(() => data?.managementSystems ?? emptyManagementSystems, [data?.managementSystems]);

  return { data, managementSystems, ...rest };
};
