import { isString } from '@partstech/ui/utils';
import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  addItemInActiveCart,
  batchAddItemInActiveCart,
  buyActiveCart,
  deleteActiveCartItem,
  mitchell1BuyActiveCart,
  mitchell1SubmitActiveCart,
  submitActiveCart,
} from 'shared/api/rest/gen/shop';
import { getShipments } from 'store/actions/cart';
import { getMissingStores, getStoreById, selectStoreById } from 'store/entities/store';
import { logout } from 'store/features/user/account';
import { createAppAsyncThunk } from 'store/utils';
import { getSelectedStoreId, getAvailabilityBranch, getProductTitle } from 'utils';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AddToCartPayload } from 'app/CartProviderOld/types';
import type { ErrorLine, ResponseError } from 'shared/api';
import type {
  AddItemRequest,
  AddItemResponse,
  Mitchell1ShoppingCart,
  Shipment,
  SubmitActiveCartRequest,
} from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';
import type { SubmitOrderPayload, SubmitOrderResponse } from 'types/cart';
import type { FullShipmentPart } from 'types/shipment';

type PartStatus = {
  type: 'ready' | 'added' | 'removed';
  quantity?: number;
  searchResultPosition?: number;
};

type CartState = {
  isLoading: boolean;
  isSubmitting: boolean;
  isFirstLoadingCart: boolean;
  shipmentsToUpdate: number[];
  partsStatuses: {
    [id: number]: PartStatus;
  };
  mitchell1Cart: Mitchell1ShoppingCart | null;
};

export type CartErrorResponse = {
  additionalFieldErrors: Record<string, string>;
  errors: ErrorLine[];
  orders: Shipment[];
};

const initialState: CartState = {
  isLoading: true,
  isSubmitting: false,
  isFirstLoadingCart: true,
  shipmentsToUpdate: [],
  partsStatuses: {},
  mitchell1Cart: null,
};

export const submitOrder = createAsyncThunk<
  SubmitOrderResponse,
  SubmitOrderPayload,
  { rejectValue: ErrorLine | CartErrorResponse }
>('features/cart/submitOrder', async ({ shipments, labors, isCatalogSdk, isQuote }, { rejectWithValue }) => {
  const orderRequests: SubmitActiveCartRequest['orderRequests'] = shipments.map(
    ({ id, freightTerm }) => ({ orderId: id, shippingMethodCode: freightTerm }),
    {}
  );

  try {
    if (isQuote) {
      return isCatalogSdk
        ? await mitchell1SubmitActiveCart({ orderRequests, submittedLabors: labors })
        : await submitActiveCart({ orderRequests, submittedLabors: labors });
    }

    return isCatalogSdk
      ? await mitchell1BuyActiveCart({ orderRequests, submittedLabors: labors })
      : await buyActiveCart({ orderRequests, submittedLabors: labors });
  } catch (e) {
    return rejectWithValue(e.error ? e.error() : e);
  }
});

export const consumeAddToCart = createAppAsyncThunk<AddItemResponse, AddToCartPayload>(
  'features/cart/consumeAddToCart',
  async (partToAdd, { dispatch, getState, rejectWithValue }) => {
    const state = getState();

    const { quantity, searchedPartNumber, selectedStore, vin, product, origin, vehicleId, searchResultPosition } =
      partToAdd;

    const storeId = getSelectedStoreId(product, selectedStore);
    const availabilityBranch = getAvailabilityBranch(product, selectedStore);
    const hasStore = Boolean(selectStoreById(state, storeId));

    if (!hasStore && storeId) {
      dispatch(getStoreById(storeId));
    }

    const item: AddItemRequest = {
      quantity,
      storeId,
      lineCardId: product.lineCardId ?? 0,
      partNumberId: product.partNumberId,
      partNumber: product.partNumber,
      vehicleId: vehicleId ? Number(vehicleId) : undefined,
      partName: getProductTitle(product),
      credentialsId: product.credentialId ?? 0,
      partTermId: product.partTypeId,
      searchedPartNumber,
      searchResultPosition: origin === 'SEARCH' ? searchResultPosition : undefined,
      vin,
      availabilityBranch,
      origin,
    };

    try {
      const response = await addItemInActiveCart(item);

      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const consumeAddToCartMultiple = createAppAsyncThunk<
  AddItemResponse[],
  AddToCartPayload[],
  {
    rejectValue: AddItemResponse[] | ResponseError;
  }
>('features/cart/consumeAddToCartMultiple', async (parts, { dispatch, rejectWithValue }) => {
  const items: AddItemRequest[] = parts.map((partToAdd) => {
    const { quantity, searchedPartNumber, selectedStore, vin, product, origin, vehicleId } = partToAdd;

    const storeId = getSelectedStoreId(product, selectedStore);
    const availabilityBranch = getAvailabilityBranch(product, selectedStore);

    dispatch(getMissingStores([storeId]));

    return {
      quantity,
      storeId,
      lineCardId: product.lineCardId ?? 0,
      partNumberId: product.partNumberId,
      partNumber: product.partNumber,
      vehicleId: vehicleId ? Number(vehicleId) : undefined,
      partName: getProductTitle(product),
      credentialsId: product.credentialId ?? 0,
      partTermId: product.partTypeId,
      searchedPartNumber,
      vin,
      availabilityBranch,
      origin,
    };
  });

  try {
    const responses = await batchAddItemInActiveCart({ items });
    return responses;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const consumeRemoveFromCart = createAppAsyncThunk<void, FullShipmentPart, { rejectValue: number }>(
  'features/cart/removePartById',
  async (part, { rejectWithValue }) => {
    try {
      await deleteActiveCartItem(part.id);
    } catch (e) {
      rejectWithValue(part.shipmentId);
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearShipmentsToUpdate: (state) => {
      state.shipmentsToUpdate = [];
    },
    setPartStatus: (state, action: PayloadAction<{ partId: number; status: PartStatus }>) => {
      state.partsStatuses[action.payload.partId] = action.payload.status;
    },
    setMitchell1Cart: (state, action: PayloadAction<Mitchell1ShoppingCart>) => {
      state.mitchell1Cart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShipments.pending, (state, action) => {
      if (!action.meta.arg?.id && !action.meta.arg?.loadInBackground) {
        state.isLoading = true;
        state.partsStatuses = {};
      }
    });
    builder.addCase(getShipments.fulfilled, (state, action) => {
      action.payload.forEach((shipment) => {
        shipment.parts.forEach((part) => {
          state.partsStatuses[part.id] = { type: 'ready' };
        });
      });
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(submitOrder.pending, (state) => {
      state.isLoading = true;
      state.isSubmitting = true;
    });
    builder.addCase(consumeAddToCart.fulfilled, (state, action) => {
      const { error, orderId, orderItemId } = action.payload;
      const { quantity, searchResultPosition } = action.meta.arg;

      if (orderId && orderItemId && !error) {
        state.partsStatuses[orderItemId] = { type: 'added', quantity, searchResultPosition };

        if (!state.shipmentsToUpdate.some((id) => id === orderId)) {
          state.shipmentsToUpdate = [...state.shipmentsToUpdate, orderId];
        }
      }
    });
    builder.addCase(consumeAddToCartMultiple.fulfilled, (state, action) => {
      action.payload.forEach(({ status, orderId, orderItemId }, index) => {
        if (status === 200 && orderId && orderItemId) {
          const { quantity, searchResultPosition } = action.meta.arg[index] || {};
          state.partsStatuses[orderItemId] = { type: 'added', quantity, searchResultPosition };

          if (!state.shipmentsToUpdate.some((id) => id === orderId)) {
            state.shipmentsToUpdate = [...state.shipmentsToUpdate, orderId];
          }
        }
      });
    });
    builder.addCase(consumeRemoveFromCart.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const { [id]: removed, ...rest } = state.partsStatuses;
      state.partsStatuses = rest;
    });
    builder.addCase(submitOrder.fulfilled, (state, action) => {
      if (('redirectUrl' in action.payload && isString(action.payload.redirectUrl)) || action.meta.arg.isCatalogSdk) {
        return;
      }

      state.partsStatuses = {};
      state.isFirstLoadingCart = false;
      state.isLoading = false;
      state.isSubmitting = false;
    });
    builder.addMatcher(isAnyOf(getShipments.fulfilled, getShipments.rejected, submitOrder.rejected), (state) => {
      state.isFirstLoadingCart = false;
      state.isLoading = false;
    });
    builder.addMatcher(isAnyOf(consumeRemoveFromCart.fulfilled, consumeRemoveFromCart.rejected), (state, action) => {
      if (!state.shipmentsToUpdate.some((id) => id === action.meta.arg.shipmentId)) {
        state.shipmentsToUpdate = [...state.shipmentsToUpdate, action.meta.arg.shipmentId];
      }
    });
  },
});

export const cart = cartSlice.reducer;

export const { clearShipmentsToUpdate, setPartStatus, setMitchell1Cart } = cartSlice.actions;

const selectState = (state: RootState) => state.features.cart;

export const selectMitchell1Cart = createSelector(selectState, (state) => state.mitchell1Cart);
export const selectShipmentsToUpdates = createSelector(selectState, (state) => state.shipmentsToUpdate);
export const selectPartStatuses = createSelector(selectState, (state) => state.partsStatuses);
export const selectIsFirstLoadingCart = createSelector(selectState, (state) => state.isFirstLoadingCart);

export const selectIsCartBarLoading = createSelector(
  selectState,
  (state) => state.isFirstLoadingCart || state.isLoading
);

export const selectIsCartSubmitting = createSelector(selectState, (state) => state.isSubmitting);

export const selectPartStatusById = createSelector(
  [selectPartStatuses, (_: unknown, id: number) => id],
  (partStatuses, id) => partStatuses[id]
);
