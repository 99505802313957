import { css } from '@emotion/react';
import { Box, Card, ImageFallback, Typography } from '@partstech/ui';
import { integerCurrency } from 'shared/lib/string';
import { ProductAlias, type PaidFeatureAlias } from '../../../../constants';
import { PeriodSwitcher } from '../../../PeriodSwitcher';
import { PlanForCompareStatus } from '../../PlanForCompare/PlanForCompareStatus';
import { FeaturesTable } from './FeaturesTable';
import type { Plan } from '../../../../types';
import type { CardProps, Theme } from '@partstech/ui';

const styles = {
  wrapper: (theme: Theme) => css`
    .ui-status {
      top: ${theme.sizing(2)};
      left: 0;
      border-radius: ${theme.sizing(0, 0, 2, 0)};
    }
  `,
  tagline: (theme: Theme) => css`
    border-top-left-radius: ${theme.sizing(2)};
    border-top-right-radius: ${theme.sizing(2)};
  `,
};

type Props = CardProps & {
  highlightFeature: PaidFeatureAlias | null;
  isPlanIncluded: boolean;
  plan: Plan;
  periodToggle: () => void;
};

export const SelectedPlanCard = ({ highlightFeature, isPlanIncluded, plan, periodToggle, ...props }: Props) => {
  const { alias, color, description, features, isYearly, logo, name, priceMonthly, priceYearly } = plan;

  const isFree = alias === ProductAlias.Free;

  return (
    <Card css={styles.wrapper} {...props}>
      <PlanForCompareStatus alias={alias} isIncluded={isPlanIncluded} />

      <Box background={color} height={2} css={styles.tagline} />

      <Box mt={8} mx={4} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h3">{name}</Typography>

        <Box mt={2} flexBasis={8}>
          <Typography variant="caption">{description}</Typography>
        </Box>

        <Box mt={3} mb={1}>
          <ImageFallback height={140} src={logo} alt={`${alias}Logo`} />
        </Box>

        {!isPlanIncluded && (
          <>
            <Typography variant="h3">{integerCurrency(isYearly ? priceYearly / 12 : priceMonthly)} /month</Typography>

            <Box my={2}>
              <Typography variant="caption" lineThrough={!isFree && isYearly}>
                {isFree ? 'Free forever' : `${integerCurrency(priceMonthly)} /month`}
              </Typography>
            </Box>

            {!isFree && <PeriodSwitcher mb={2} isChecked={isYearly} onChange={periodToggle} />}

            <Typography variant="overline" component="p">
              {isFree ? 'NEVER BILLED' : `BILLED ${isYearly ? 'YEARLY' : 'MONTHLY'}`}
            </Typography>
          </>
        )}

        <Box mt={5}>
          <FeaturesTable features={features} highlightFeature={highlightFeature} planName={name} />
        </Box>
      </Box>
    </Card>
  );
};
