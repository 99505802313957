import { useModalHistory } from '@partstech/ui';
import { useCallback } from 'react';
import type { PaymentCardModalData, PaymentCardModalPaths } from '../router';
import type { PaymentCard } from 'entities/paymentCard';

export const useEditCard = () => {
  const { data, state, setData, reset, onSubmit, onClose } = useModalHistory<
    PaymentCardModalPaths,
    'editCard',
    PaymentCardModalData
  >();

  const { card, paymentMethod } = data;

  const submitForm = useCallback(
    (updatedCard: PaymentCard, isUpdatedPaymentMethod: boolean) => {
      if (!paymentMethod) {
        return;
      }

      if (isUpdatedPaymentMethod) {
        setData({ card, paymentMethod: card });

        reset('paymentCardUpdated', { cardForRemoving: paymentMethod });

        return;
      }

      onSubmit({ card: updatedCard, paymentMethod });

      onClose();
    },
    [card, onClose, onSubmit, paymentMethod, reset, setData]
  );

  return {
    card,
    paymentMethod,
    showCardOptions: state?.showCardOptions ?? false,
    submitForm,
    onClose,
  };
};
