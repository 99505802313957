import { getCustomAttributesForSection } from './getCustomAttributesForSection';
import type { AttributeUsageSection } from 'constant';
import type { Product, Variation } from 'types/product';

// TODO: Move to model???
export const getAttributesByProductAndVariation = (
  entity: Product | null,
  variationId: Variation['variationId'] | null = null,
  section: AttributeUsageSection = 'search'
) => {
  const selectedVariation = entity?.variations?.find((variation) => variation.variationId === variationId) ?? null;

  return getCustomAttributesForSection(
    [...(selectedVariation?.attributes ?? []), ...(entity?.attributes ?? [])],
    section
  );
};
