import { makeContext } from '@partstech/ui/utils';
import type { CreateShopAndUserWithSmsConnectInput } from 'features/smsConnections';
import type { Dispatch, SetStateAction } from 'react';
import type { SmsRegistrationData } from 'shared/api';

type SmsConnectionsContext = {
  currentSms: string;
  shopAndUserData: CreateShopAndUserWithSmsConnectInput;
  setShopAndUserData: Dispatch<SetStateAction<CreateShopAndUserWithSmsConnectInput>>;
  videoUrl: string;

  smsRegistrationData: SmsRegistrationData | null;
  isLoadingSmsRegistrationData: boolean;
  isErrorSmsRegistrationData: boolean;
  refetchSmsRegistrationData: () => Promise<void>;

  isShowBackButton: boolean;
  showBackButton: () => void;
  hideBackButton: () => void;
};

export const [useSmsConnectionsContext, SmsConnectionsContextProvider] = makeContext<SmsConnectionsContext>();
