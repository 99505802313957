import { TireQualityAttribute } from 'components/Product/Attributes/Attribute/TireQualityAttribute';
import { TireSizeAttribute } from 'components/Product/Attributes/Attribute/TireSizeAttribute';
import { Certification } from 'components/Product/Certification';
import { Specification } from 'components/Product/Specification';
import type { ProductAttribute } from 'types/product';

type Props = {
  attribute: ProductAttribute | null;
};

export const Attribute = ({ attribute }: Props) => {
  if (!attribute) {
    return null;
  }

  switch (attribute.name) {
    case 'TiresSize':
      return <TireSizeAttribute attribute={attribute} />;

    case 'TiresQuality':
      return <TireQualityAttribute attribute={attribute} />;

    case 'WinterCertification':
      return <Certification attribute={attribute} />;

    default:
      return <Specification label={attribute.label} value={attribute.value} />;
  }
};
