import { Routes } from 'constant';
import { StockOrderLayout } from './StockOrderLayout';
import type { RouteObject } from 'react-router-dom';

const stockOrderNewPage = {
  path: 'new',
  lazy: () => import('pages/StockOrders/StockOrderNew').then((module) => ({ Component: module.StockOrderNew })),
};

const stockOrderTemplatesPage = {
  index: true,
  lazy: () =>
    import('pages/StockOrders/StockOrderTemplates').then((module) => ({ Component: module.StockOrderTemplates })),
};

const stockOrderTemplatePage = {
  path: ':templateId',
  lazy: () =>
    import('pages/StockOrders/StockOrderTemplate').then((module) => ({ Component: module.StockOrderTemplate })),
};

export const stockOrdersPages: RouteObject = {
  path: Routes.STOCK_ORDER,
  element: <StockOrderLayout />,
  children: [
    stockOrderNewPage,
    {
      path: 'templates',
      children: [stockOrderTemplatesPage, stockOrderTemplatePage],
    },
  ],
};
