import { Country } from 'shared/api';
import { getSelectedStoreId } from '../product/getSelectedStoreId';
import type { ShipmentType } from 'types/cart';
import type { AvailabilityLine, Product } from 'types/product';

export const createShipment = (
  product: Product,
  selectedStore: AvailabilityLine | null,
  shipmentId: ShipmentType['id'],
  PONumber: string | null = null
): ShipmentType => ({
  name: product.supplier?.name || '',
  credentialsId: product.credentialId,
  id: shipmentId,
  freightTerm: '',
  freightTerms: [],
  isPaid: false,
  paymentValidation: {
    PONumberValidation: [{ max: 20, message: null, min: null, regex: null }],
    noteValidation: [{ max: 250, message: null, min: null, regex: null }],
  },
  supplierId: product.supplierId ?? 0,
  storeId: getSelectedStoreId(product, selectedStore),
  paymentWay: 'wholesale' as ShipmentType['paymentWay'],
  rewardPoints: null,
  billingAddress: {
    address1: '',
    address2: '',
    country: Country.Us,
    city: '',
    state: '',
    zipCode: '',
  },
  shippingAddress: {
    address1: '',
    address2: '',
    country: Country.Us,
    city: '',
    state: '',
    zipCode: '',
  },
  PONumber,
  customNotes: null,
  errors: [],
  isLoading: true,
  discountTotal: 0,
  optionsPreferences: {
    shippingMethodCode: null,
  },
  taxWithoutShipping: 0,
});
