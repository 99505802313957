import { useCallback, useMemo } from 'react';
import { ADDONS, PLAN_COMPLETE, PLAN_FREE } from '../constants';
import type { ProductAlias } from '../constants';
import type { AddOn, FeatureEX, Plan } from '../types';

type Args = {
  featuresEX: FeatureEX[];
  isRestrictedEX: boolean;
  subscriptionAddOns: AddOn[];
  subscriptionPlan: Plan | undefined;
};

export const usePlanAndAddOns = ({ featuresEX, isRestrictedEX, subscriptionAddOns, subscriptionPlan }: Args) => {
  const hasFeaturesEX = featuresEX.length > 0;

  const isFeatureEX = useCallback(
    (alias: ProductAlias) => featuresEX.some((feature) => feature.alias === alias),
    [featuresEX]
  );

  const addOnsEX = useMemo(
    () =>
      ADDONS.reduce<AddOn[]>((accumulator, ADDON) => {
        if (isFeatureEX(ADDON.alias)) {
          accumulator.push({ ...ADDON, isActive: true });
        }

        return accumulator;
      }, []),
    [isFeatureEX]
  );

  const addOns = useMemo(() => {
    if (isRestrictedEX) {
      return [...addOnsEX, ...subscriptionAddOns];
    }

    return subscriptionAddOns;
  }, [addOnsEX, isRestrictedEX, subscriptionAddOns]);

  const plan = useMemo(() => {
    if (isRestrictedEX && hasFeaturesEX) {
      return { ...PLAN_COMPLETE, isActive: true };
    }

    return subscriptionPlan ?? { ...PLAN_FREE, isActive: true };
  }, [hasFeaturesEX, isRestrictedEX, subscriptionPlan]);

  return {
    addOns,
    plan,
  };
};
