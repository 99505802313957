import { css } from '@emotion/react';
import { Box, Typography } from '@partstech/ui';
import { currency } from 'shared/lib/string';
import { LaborIcon } from '../LaborIcon';

const styles = {
  description: css`
    display: block;
    line-height: 16px;
  `,
};

type Props = {
  laborRate: number;
  totalHours: number;
  quantity: number;
};

export const LaborTotals = ({ totalHours, laborRate, quantity }: Props) => (
  <Box px={3} py={4} display="flex" justifyContent="space-between" alignItems="center" gap={4}>
    <Box display="flex">
      <LaborIcon size="small" />

      <div>
        <Typography variant="caption" color="subtleText" css={styles.description}>
          Services: {quantity}
        </Typography>
        <Typography variant="caption" color="subtleText" css={styles.description}>
          {totalHours.toFixed(2)} HRS x {currency(laborRate)}
        </Typography>
      </div>
    </Box>

    <Typography variant="subtitle1">{currency(laborRate * totalHours)}</Typography>
  </Box>
);
