import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { TireSize } from 'models';
import { searchGetTiresSize } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';
import type { TireSize as TireSizeType } from 'types/tireSize';

export const getTireSizes = createAsyncThunk('entities/tireSize/getTireSizes', searchGetTiresSize);

type TireSizeState = TireSizeType[];

const initialState: TireSizeState = [];

const tireSizeSlice = createSlice({
  name: 'tireSize',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTireSizes.fulfilled, (_: unknown, action) => action.payload);
  },
});

export const tireSize = tireSizeSlice.reducer;

const selectState = (state: RootState) => state.entities.tireSize;

export const selectTireSizes = createSelector([selectState], (tireSizes) =>
  tireSizes.map((size) => new TireSize(size))
);
