import { isEmpty } from '@partstech/ui/utils';
import { createOption } from './createOption';
import { getAttributeValuesFromProduct } from './getAttributeValuesFromProduct';
import { groupFilterOptions } from './groupFilterOptions';
import type { Product } from 'models';
import type { RawAttribute } from 'shared/api/rest/gen/shop';
import type { FilterOption } from 'types/search';

export const makeAttributeFilterOptions = (
  name: RawAttribute['name'],
  products: Product[],
  checkedOptions: FilterOption[]
) => {
  const options = products
    .map((product) =>
      getAttributeValuesFromProduct(product, name)
        .filter((value) => !isEmpty(value))
        .map((value: string) => createOption(value, value, 1))
    )
    .flat();

  return groupFilterOptions([...options, ...checkedOptions]);
};
