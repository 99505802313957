import { Box } from '@partstech/ui';
import { usePrevious } from '@partstech/ui/hooks';
import { CreateShopPages } from 'utils/url';
import { useCreateShopContext } from '../../CreateShopContext/CreateShopContext';
import { Description } from './Description';
import { HexNutsAndHexagons } from './HexNutsAndHexagons';
import type { variants } from './HexNutsAndHexagons/variants';

const urlPathNumberMap: Record<CreateShopPages | 'select-shop-with-google', keyof typeof variants> = {
  [CreateShopPages.Type]: 1,
  'select-shop-with-google': 2,
  [CreateShopPages.ShopDetails]: 3,
  [CreateShopPages.ManagementSystem]: 4,
  [CreateShopPages.PartsSuppliers]: 5,
  [CreateShopPages.TireSuppliers]: 6,
  [CreateShopPages.Role]: 7,
  [CreateShopPages.HearAboutUs]: 8,
  [CreateShopPages.PhoneNumber]: 9,
};

export const StepDescription = () => {
  const { activeStep, selectedShop } = useCreateShopContext();

  const id = !selectedShop && activeStep.id === CreateShopPages.ShopDetails ? 'select-shop-with-google' : activeStep.id;

  const previousId = usePrevious(id);

  const previousStepIndex = urlPathNumberMap[previousId ?? id];
  const currentStepIndex = urlPathNumberMap[id];

  return (
    <Box maxWidth={67}>
      <Box position="relative">
        <HexNutsAndHexagons currentIndex={previousStepIndex} nextIndex={currentStepIndex} />

        <Box
          position="absolute"
          top="46%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
          maxWidth={57}
          width="100%"
        >
          <Description id={id} />
        </Box>
      </Box>
    </Box>
  );
};
