import { TireQualityTranslation } from 'components/Part/CustomAttributes/AttributeTooltip/TireQualityTranslation';
import { AttributeTooltip } from 'components/Product/Attributes/Attribute/AttributeTooltip';
import { Specification } from 'components/Product/Specification';
import type { ProductAttribute } from 'types/product';

type Props = {
  attribute: ProductAttribute;
};

export const TireQualityAttribute = ({ attribute }: Props) => (
  <Specification
    label={attribute.label}
    value={attribute.value}
    adornmentAfter={<AttributeTooltip tooltip={<TireQualityTranslation attributes={[attribute]} />} />}
  />
);
