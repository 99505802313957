import { useCallback, useMemo } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { parseQuery } from 'shared/lib/query';
import { getSmsConnectionsUrl } from 'utils/url';
import { useSmsConnectionsPathParams } from './useSmsConnectionsPathParams';
import type { AppNavigateOptions } from 'app/AppRouter/useAppNavigate';
import type { SmsConnectionsQueryParams, SmsConnectionsPages } from 'utils/url';

export const useSmsConnectionsRouter = () => {
  const navigate = useAppNavigate();

  const location = useAppLocation();

  const pathParams = useSmsConnectionsPathParams();

  const searchParams = useMemo(
    () =>
      parseQuery<SmsConnectionsQueryParams>(location.search, { ignoreQueryPrefix: true, decoder: decodeURIComponent }),
    [location.search]
  );

  const generateSmsConnectionsUrl = useCallback(
    // TODO: Use object as a parameter
    (page: SmsConnectionsPages | '' = '', search?: SmsConnectionsQueryParams) =>
      getSmsConnectionsUrl({ ...pathParams, page, search }),
    [pathParams]
  );

  const navigateToSmsConnectionsPage = useCallback(
    // TODO: Use object as a parameter
    (page: SmsConnectionsPages | '' = '', search?: SmsConnectionsQueryParams, options?: AppNavigateOptions) => {
      navigate(generateSmsConnectionsUrl(page, search), options);
    },
    [generateSmsConnectionsUrl, navigate]
  );

  return {
    pathParams,
    searchParams,
    generateSmsConnectionsUrl,
    navigateToSmsConnectionsPage,
  };
};
