import { MaskTextField } from '@partstech/ui';
import { useExpireCardField } from './useExpireCardField';
import type { TextFieldProps } from '@partstech/ui';
import type { RegisterOptions } from '@partstech/ui/forms';

type Props = Omit<TextFieldProps, 'error' | 'prefix'> & {
  rules?: RegisterOptions;
};

export const FormExpireCardField = ({ name = '', label, rules, ...props }: Props) => {
  const { field, error, onChange } = useExpireCardField({ name, rules });

  return (
    <MaskTextField
      {...props}
      name={name}
      label={label}
      mask="99 / 99"
      maskPlaceholder="-"
      error={error}
      value={field.value}
      onChange={onChange}
      onBlur={onChange}
      ref={field.ref}
    />
  );
};
