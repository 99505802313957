import { css } from '@emotion/react';
import { Box, Button, ButtonGroup, Tag } from '@partstech/ui';
import { useQuestionOptions, type Option } from './useQuestionOptions';
import type { BoxProps } from '@partstech/ui';

const styles = {
  tag: css`
    width: 100%;
    span {
      flex: 1;
      text-align: center;
    }
  `,
};

type Props = BoxProps & {
  defaultOption?: Option;
  options: Option[];
  optionMinWidth?: BoxProps['width'];
  onSelect?: (option: Option) => void;
  onSkip?: () => void;
};

export const QuestionOptions = ({ defaultOption, options, optionMinWidth, onSelect, onSkip, ...props }: Props) => {
  const { selectedOption, selectOption } = useQuestionOptions({ defaultOption, onSelect });

  return (
    <>
      <Box mt={6} display="flex" flexWrap="wrap" {...props}>
        {options.map((option) => (
          <Box key={option} minWidth={optionMinWidth}>
            <Tag css={styles.tag} checked={option === selectedOption} onChange={selectOption(option)} controlled>
              {option}
            </Tag>
          </Box>
        ))}
      </Box>

      {onSkip && (
        <ButtonGroup mt={7} justifyContent={{ sm: 'center', md: 'flex-start' }}>
          <Button variant="text" onClick={onSkip}>
            Skip
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};
