const mainHost = '//www.partstech.com';
const mySiteHost = '//partstech.my.site.com';

const getMainUrl = (path: string) => [mainHost, path].join('');
const getMySiteUrl = (path: string) => [mySiteHost, path].join('');

export const URL = {
  ANALYTICS: getMainUrl('/software/analytics'),
  CONTACT: getMainUrl('/contact-us'),
  DIAGRAMS: getMainUrl('/software/diagrams'),
  EXTRA_TERMS_OF_SERVICE: 'https://www.extra-awards.com/termsandconditions',
  HOME: getMainUrl('/'),
  MANAGING_USERS: getMySiteUrl('/help/s/article/Managing-Users-How-to-Add-Remove-Edit-Additional-Users-on-PartsTech'),
  PARTS_TIRE_SEARCH: getMainUrl('/software/parts-tire-search'),
  POPULAR_JOBS: getMainUrl('/software/jobs'),
  PREFERRED_BRANDS: getMainUrl('/software/preferred-brands'),
  PRIVACY_POLICY: getMainUrl('/privacy-policy/'),
  QUOTING: getMainUrl('/software/estimator-software'),
  SUPPLIERS_USERS: getMainUrl('/software/suppliers-users'),
  SUPPLIER_SYSTEM_STATUS: getMySiteUrl('/help/s/article/System-Status'),
  SUPPLIER_STATUS_MESSAGES: getMySiteUrl(
    '/help/s/article/What-does-Pending-Approval-Mean-Supplier-Status-Messages-Explained'
  ),
  TERMS_OF_SERVICE: getMainUrl('/terms-of-service/'),
  TIRE_COMPARISON: getMainUrl('/software/tire-comparison'),
  TIRE_SUPPLIERS: getMySiteUrl('/help/s/article/Tire-Suppliers-on-PartsTech'),
  USER_PERMISSIONS: getMainUrl('/m/user-permissions'),
};
