import { selectIdFromQuery } from '../product/selectIdFromQuery';
import type { Product } from 'models';
import type { QuoteSlot, RawSlot, SlotLabel } from 'types/quote';

const slotLabels: SlotLabel[] = ['GOOD', 'BETTER', 'BEST'];

export const convertQuoteSlots = (
  rawSlots: RawSlot[],
  productsBySlot: (Product | null)[],
  fetchedIds: string[],
  invalidIds: string[]
): QuoteSlot[] =>
  rawSlots.map((slot, index) => {
    const product = slot.product ?? (productsBySlot[index] || null);

    const productId = slot.urlParams ? selectIdFromQuery(slot.urlParams) : '';
    const label = slot.label ? slot.label : (slotLabels[index] ?? 'GOOD');
    const selectedSlot: QuoteSlot = {
      ...slot,
      label,
      product,
      isFetched: fetchedIds.includes(productId),
      isInvalid: invalidIds.includes(productId),
    };
    return selectedSlot;
  });
