import type { FilterOption } from 'types/search';

export const createOption = (
  value: FilterOption['value'],
  name: FilterOption['name'],
  count?: FilterOption['count']
): FilterOption => ({
  name,
  value,
  count,
});
