import { css } from '@emotion/react';
import { Box, Typography } from '@partstech/ui';
import { memo, useCallback } from 'react';
import { useBundlesModal } from 'components/Modal';
import type { BoxProps, Theme } from '@partstech/ui';
import type { MouseEvent } from 'react';
import type { Bundle } from 'types/product';

const styles = {
  bundle: (isBundled: boolean) => (theme: Theme) => css`
    padding: 0;
    cursor: pointer;
    text-decoration: ${isBundled ? 'none' : 'underline'};
    color: ${theme.color.positive};
  `,
};

type Props = {
  isBundled: boolean;
  bundles: Bundle[];
} & BoxProps;

export const BundleDeals = memo(({ isBundled, bundles, ...props }: Props) => {
  const { openBundlesModal } = useBundlesModal(bundles);

  const handleBundleModalClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      openBundlesModal();
    },
    [openBundlesModal]
  );

  return (
    <Box {...props}>
      {isBundled && (
        <Typography variant="overline" color="positive" textTransform="uppercase" css={styles.bundle(isBundled)}>
          Bundle deal
        </Typography>
      )}

      {!isBundled && bundles.length > 0 && (
        <button type="button" css={styles.bundle(isBundled)} onClick={handleBundleModalClick}>
          <Typography variant="overline" color="positive" textTransform="uppercase">
            Bundle eligible
          </Typography>
        </button>
      )}
    </Box>
  );
});
