import { useEffect, useState } from 'react';
import { Country } from 'shared/api';
import type { CreateShopAndUserWithSmsConnectInput } from 'features/smsConnections';

const initialValues: CreateShopAndUserWithSmsConnectInput = {
  address: {
    address1: '',
    address2: null,
    city: '',
    country: Country.Us,
    state: '',
    zipCode: '',
  },
  email: '',
  lastName: '',
  password: '',
  shopName: '',
  shopPhoneNumber: '',
  shopType: null,
  website: null,
  firstName: '',
};

type Args = {
  defaultValues?: CreateShopAndUserWithSmsConnectInput;
};

export const useShopAndUserData = ({ defaultValues = initialValues }: Args) => {
  const [shopAndUserData, setShopAndUserData] = useState<CreateShopAndUserWithSmsConnectInput>(initialValues);

  useEffect(() => {
    if (!defaultValues) {
      return;
    }

    setShopAndUserData(defaultValues);
  }, [defaultValues]);

  return [shopAndUserData, setShopAndUserData] as const;
};
