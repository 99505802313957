import { makeContext } from '@partstech/ui/utils';
import type { Dispatch, SetStateAction } from 'react';
import type { NavigateOptions, To } from 'react-router-dom';

export type RouterContext = {
  isBlocked: boolean;
  block: () => void;
  unBlock: () => void;
  setTryToNavigate: Dispatch<
    SetStateAction<{
      to: To | number;
      options?: NavigateOptions | undefined;
    } | null>
  >;
};

export const [useRouter, RouterContextProvider] = makeContext<RouterContext>();
