import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useSelectedSmsFromQuery } from 'hooks/shopManagementSystems';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { OnboardStepType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import { CreateShopPages, getSignUpUrlNew } from 'utils/url';
import { useAppLocation } from '../useAppLocation';

export const useSignUpQuestionsRedirect = () => {
  const {
    flags: { signUpQuestions: isSignUpQuestions },
  } = useLaunchDarkly();

  const { pathname } = useAppLocation();
  const { selectedSms } = useSelectedSmsFromQuery();
  const { hasCurrentUser, shop, managementSystem } = useGetCurrentUser();
  const { isOnboardCompleted, isSuccess, isStepCompleted } = useOnboardSteps();

  const stepsConfig = useMemo(
    () => [
      { step: CreateShopPages.ShopDetails, isAvailable: Boolean(shop) },
      { step: CreateShopPages.ManagementSystem, isAvailable: Boolean(managementSystem) },
      { step: CreateShopPages.PartsSuppliers, isAvailable: Boolean(isStepCompleted(OnboardStepType.Parts)) },
      { step: CreateShopPages.TireSuppliers, isAvailable: Boolean(isStepCompleted(OnboardStepType.Tires)) },
      { step: CreateShopPages.Role, isAvailable: Boolean(isStepCompleted(OnboardStepType.PrimaryRole)) },
      { step: CreateShopPages.HearAboutUs, isAvailable: Boolean(isStepCompleted(OnboardStepType.Referral)) },
      { step: CreateShopPages.PhoneNumber, isAvailable: Boolean(isStepCompleted(OnboardStepType.Phone)) },
    ],
    [isStepCompleted, shop, managementSystem]
  );

  const stepCompletionStatus = useMemo(
    () =>
      stepsConfig.reduce<Record<string, boolean>>(
        (acc, object) => ({ ...acc, [getSignUpUrlNew(object.step)]: object.isAvailable }),
        {
          [getSignUpUrlNew(CreateShopPages.Type)]: true,
          [getSignUpUrlNew(CreateShopPages.ShopDetails)]: true,
        }
      ),
    [stepsConfig]
  );

  const redirectUrl = useMemo(() => {
    const neededStep = stepsConfig.find((step) => !step.isAvailable)?.step;

    if (!neededStep) {
      return null;
    }

    return getSignUpUrlNew(neededStep, { selectedSms });
  }, [selectedSms, stepsConfig]);

  const pathRedirect = redirectUrl?.split('?')[0] ?? '';

  const shouldSkipRedirect = Boolean(
    useMatch(pathRedirect) ||
      !isSignUpQuestions ||
      shop?.newOnboard === false ||
      !isSuccess ||
      !hasCurrentUser ||
      isOnboardCompleted
  );

  if (shouldSkipRedirect) {
    return null;
  }

  return stepCompletionStatus[pathname] ? null : redirectUrl;
};
