import { useCallback, useEffect, useState } from 'react';
import {
  Diagram,
  PartNumber,
  PartType,
  TireSize,
  PartTypeAlias,
  TireConfiguration,
  LegacyJob,
  LegacyJobAlias,
} from 'models';
import { trimSpecialCharacters } from 'shared/lib/string';
import { SearchTypes } from 'types/search';
import { KeywordSearch } from '../../models';
import type { SearchEntryPointInterface } from '../../models';
import type { Job } from 'models';

type PartSuggestion = PartType | LegacyJob | PartNumber | Job | Diagram;
type TireSuggestion = TireSize | PartNumber;

const findMatchByNameOrAlias = (partType: PartType, search: string) => {
  const hasExactName = partType.name.toLowerCase() === search.toLowerCase();
  const hasAlias = partType.aliases?.some((alias) => alias.toLowerCase() === search.toLowerCase());

  return hasExactName || hasAlias;
};

const findMatchedSuggestionByString = (suggestions: PartSuggestion[], search: string) =>
  suggestions.find((suggestion) => {
    if (suggestion instanceof PartNumber) {
      return undefined;
    }

    if (suggestion instanceof PartType) {
      return findMatchByNameOrAlias(suggestion, search);
    }

    if (suggestion instanceof LegacyJob) {
      return (
        suggestion.partTypes.some((partType) => findMatchByNameOrAlias(partType, search)) ||
        suggestion.aliases?.some((alias) => alias.toLowerCase() === search.toLowerCase()) ||
        suggestion.name === search
      );
    }

    if (suggestion instanceof Diagram) {
      return suggestion.name === search;
    }

    return undefined;
  });

const findPartTypeMatch = (suggestions: PartSuggestion[], search: string) =>
  findMatchedSuggestionByString(suggestions, search) ?? new KeywordSearch(search);

const findPartsEntryPoint = (
  suggestions: PartSuggestion[] | TireSuggestion[],
  delayedValue: string,
  isPartNumber: boolean
) => {
  if (isPartNumber) {
    return new PartNumber({ partNumber: delayedValue, id: delayedValue });
  }

  const options = suggestions.filter((suggestion): suggestion is PartSuggestion => !(suggestion instanceof TireSize));
  const entryPoint = findPartTypeMatch(options, delayedValue);

  if (
    entryPoint instanceof PartType &&
    entryPoint.aliases?.some((alias) => alias.toLowerCase() === delayedValue.toLowerCase())
  ) {
    return new PartTypeAlias(entryPoint, delayedValue);
  }

  if (entryPoint instanceof LegacyJob) {
    if (entryPoint.aliases?.some((alias) => alias.toLowerCase() === delayedValue.toLowerCase())) {
      return new LegacyJobAlias(entryPoint, delayedValue);
    }

    const partType = entryPoint.partTypes?.find((item) => findMatchByNameOrAlias(item, delayedValue));

    if (partType) {
      return new PartTypeAlias(partType, delayedValue);
    }
  }

  return entryPoint;
};

const findTiresEntryPoint = (suggestions: PartSuggestion[] | TireSuggestion[], delayedValue: string) => {
  const foundTireSizes = suggestions
    .filter((suggestion): suggestion is TireSize => suggestion instanceof TireSize)
    .filter((tireSize) => tireSize.isMatch(delayedValue));

  const foundTireSize = foundTireSizes.length > 0 ? foundTireSizes[0] : null;

  const foundPartNumber = suggestions
    .filter((suggestion) => suggestion instanceof PartNumber)
    .find((suggestion) => suggestion.partNumber === delayedValue);

  return (
    foundTireSize ??
    TireConfiguration.createFromString(delayedValue) ??
    foundPartNumber ??
    new PartNumber({ partNumber: delayedValue, id: delayedValue, searchType: SearchTypes.TIRES })
  );
};

type Props = {
  isPartNumber: boolean;
  onSubmit: (value: SearchEntryPointInterface) => void;
  isLoading: boolean;
  searchType: SearchTypes;
  suggestions: PartSuggestion[] | TireSuggestion[];
};

/**
 * Hooks waits until `isLoading` is true, searches string provided to `setDelayedValue` to `onSubmit` when a suggestion matches the search.
 */
export const useSubmitOnSuggestionMatch = ({ onSubmit, isPartNumber, searchType, isLoading, suggestions }: Props) => {
  const [delayedValue, setDelayedValue] = useState<string | null>(null);

  const changeDelayedValue = useCallback((value: string) => {
    setDelayedValue(trimSpecialCharacters(value));
  }, []);

  useEffect(() => {
    if (isLoading || delayedValue === null) {
      return;
    }

    setDelayedValue(null);

    const entryPoint =
      searchType === SearchTypes.PARTS
        ? findPartsEntryPoint(suggestions, delayedValue, isPartNumber)
        : findTiresEntryPoint(suggestions, delayedValue);

    onSubmit(entryPoint);
  }, [delayedValue, isPartNumber, onSubmit, searchType, isLoading, suggestions]);

  return { setDelayedValue: changeDelayedValue };
};
