import { useSnackbar, useMedia } from '@partstech/ui';
import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { Routes } from 'constant';
import { useSearchSequenceStorage } from 'hooks/searchSequence';

export const useAddedToCartSnackbar = () => {
  const navigate = useAppNavigate();
  const { addSnackbar } = useSnackbar();
  const { isMobile, isWide } = useMedia();

  const { totalTask } = useSearchSequenceStorage();

  const handleOpenCart = useCallback(() => {
    navigate(Routes.CART);
  }, [navigate]);

  const showAddedToCartSnackbar = useCallback(() => {
    if (isMobile || !isWide) {
      addSnackbar(() => ({
        label: 'Item was added to cart',
        button: 'View',
        position: { horizontal: 'middle', vertical: 'bottom', offsetY: totalTask > 0 && isMobile ? 18 : 4 },
        buttonColor: 'primaryLight',
        dense: true,
        onClick: handleOpenCart,
        fullWidth: true,
        maxCount: 1,
      }));
    }
  }, [addSnackbar, handleOpenCart, isMobile, isWide, totalTask]);

  return {
    showAddedToCartSnackbar,
  };
};
