import type { MemberPermissions } from 'shared/api';
import type { PermissionGroup } from 'types/permissions';

export const defaultPermissionValues: MemberPermissions = {
  allOrders: false,
  boschRewards: false,
  brandPreferences: false,
  partsReport: false,
  payments: false,
  reportsDashboardAccess: true,
  retailPricing: false,
  shipping: false,
  shopProfile: false,
  spendReport: false,
  subscription: false,
  suppliers: false,
  tireReport: false,
  viewWholesaleCosts: false,
};

export const defaultPermissionGroups: PermissionGroup[] = [
  {
    name: 'shop',
    label: 'Shop permissions',
    description: 'Permissions related to general shop settings.',
    permissions: [
      {
        name: 'shopProfile',
        label: 'Shop details',
        description: 'Allows user to edit shop profile.',
        isAvailable: () => true,
      },
      {
        name: 'shipping',
        label: 'Shipping',
        description: 'Allows user to view and edit shipping addresses and pickup radius.',
        isAvailable: () => true,
      },
      {
        name: 'suppliers',
        label: 'Suppliers',
        description: 'Allows user to edit and prioritize suppliers.',
        isAvailable: () => true,
      },
      {
        name: 'payments',
        label: 'Payments',
        description: 'Allows user to view and edit payment options.',
        isAvailable: () => true,
      },
      {
        name: 'retailPricing',
        label: 'Edit retail pricing',
        description: 'Allows user to access and edit retail pricing settings.',
        isAvailable: (map) => map.retailPricingAllowed || map.previewMode,
      },
      {
        name: 'viewWholesaleCosts',
        label: 'View wholesale costs',
        description: 'Allows user to see wholesale cost, List, and MAP in results and cart.',
        // TODO: Return "true" after completing the task PD-6989
        isAvailable: () => false,
      },
      {
        name: 'brandPreferences',
        label: 'Brand preferences',
        description: 'Allows user edit brand preferences.',
        isAvailable: () => true,
      },
      {
        name: 'boschRewards',
        label: 'Rewards',
        description: 'Allows user to see rewards page.',
        isAvailable: () => true,
      },
      {
        name: 'subscription',
        label: 'Subscriptions',
        description: 'Allows user to change subscription plan.',
        isAvailable: () => true,
      },
    ],
    isAvailable: () => true,
  },
  {
    name: 'orders',
    label: 'Orders permissions',
    description: 'Permissions related to orders and stock ordering.',
    permissions: [
      {
        name: 'allOrders',
        label: 'Orders',
        description: 'Allows user to view all orders (not just their own)',
        isAvailable: () => true,
      },
    ],
    isAvailable: () => true,
  },
  {
    name: 'reporting',
    label: 'Analytics permissions',
    description: 'Allow full access.',
    permissions: [
      {
        name: 'reportsDashboardAccess',
        label: 'Analytics dashboard access',
        description: 'Allows user to view the analytics dashboard.',
        isAvailable: () => true,
      },
      {
        name: 'spendReport',
        label: 'Spend by supplier',
        description: 'Allows user to view all analytics spend data (not just their own)',
        isAvailable: (map) => map.reportsAccessAllowed && (map.spendReportAllowed || map.previewMode),
      },
      {
        name: 'partsReport',
        label: 'Spend by part type',
        description: 'Allows user to view all analytics part data (not just their own)',
        isAvailable: (map) => map.reportsAccessAllowed && (map.advancedReportsAllowed || map.previewMode),
      },
      {
        name: 'tireReport',
        label: 'Tire sales by size',
        description: 'Allows user to view all analytics tire data (not just their own)',
        isAvailable: (map) => map.reportsAccessAllowed && (map.advancedReportsAllowed || map.previewMode),
      },
    ],
    isAvailable: (map) =>
      map.reportsAccessAllowed || map.spendReportAllowed || map.advancedReportsAllowed || map.previewMode,
  },
];
