import { useCallback } from 'react';
import { Routes } from 'constant';
import { usePreferredBrands } from 'hooks/preferredBrands';
import { FullStoryEventNames, useTracking } from 'integrations/fullstory/tracking';
import { PreferredBrandsMode } from 'shared/api';
import { getProductUrl } from 'utils/url';
import type { AddToCartPayload } from '../types';

export const usePartAddedTracking = () => {
  const { sendEvent } = useTracking();

  const { preferredBrands } = usePreferredBrands({
    type: 'Part',
    mode: PreferredBrandsMode.Automatic,
  });

  const trackEvent = useCallback(
    ({ product, searchResultPosition, quantity }: AddToCartPayload, error?: string) => {
      const payload = {
        added_from: window.location.pathname === Routes.SEARCH_RESULTS ? 'search results' : 'product page',
        added_dealers: Boolean(product.supplier?.isDealer),
        brand: product.brand?.name,
        partType: product.partTypeName,
        name: product.title,
        position: searchResultPosition,
        price: product.price,
        product_id: product.getPartNumberId(),
        quantity,
        sku: product.partNumber?.partNumber,
        supplier: product.supplier?.name,
        url: getProductUrl({
          partNumberId: product.getPartNumberId(),
          supplierId: Number(product.supplier?.id),
          lineCardId: product.lineCardId,
          credentialId: product.credentialId ? Number(product.credentialId) : null,
        }),
        preferred_item: preferredBrands.some(
          (brand) => brand.partTypeId === product.getPartNumberId() && brand.name === product.brand?.name
        ),
        staggeredTires: product.isStaggeredTire,
        dealCompleted: product.isBundleAvailable,
        sponsoredProductAddedToCart: product.sponsorType === 'SPONSORED',
      };

      if (error) {
        sendEvent(FullStoryEventNames.PRODUCT_ADDING_ERROR, { ...payload, error });
      } else {
        sendEvent(FullStoryEventNames.PRODUCT_ADDED, payload);
      }
    },
    [preferredBrands, sendEvent]
  );

  return {
    trackEvent,
  };
};
