import { useMemo } from 'react';
import { createCartFromActiveCartData } from 'factories';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { api as generatedApi } from './GetActiveCart.generated';
import type { GetActiveCartQuery } from './GetActiveCart.generated';

export type ActiveCartRawData = NonNullable<GetActiveCartQuery['activeCart']>;

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetActiveCart: {
      providesTags: ['ActiveCart'],
      serializeQueryArgs: () => ({}),
    },
  },
});

const { useGetActiveCartQuery } = api;

export const useGetActiveCart = () => {
  const { hasCurrentUser } = useGetCurrentUser();
  const {
    flags: { newCart },
  } = useLaunchDarkly();
  const { accounts } = useGetSupplierAccounts();
  const {
    shop: { laborAllowed },
  } = usePermissions();

  const { data, isLoading, isFetching, refetch } = useGetActiveCartQuery(
    { withLabor: laborAllowed },
    { skip: !hasCurrentUser || !newCart }
  );

  const cart = useMemo(
    () => (data?.activeCart ? createCartFromActiveCartData(data?.activeCart, accounts) : null),
    [data?.activeCart, accounts]
  );

  return {
    isLoading: isLoading || !newCart,
    isFetching,
    cart,
    refetch,
  };
};
