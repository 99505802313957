import { useDebouncedCallback } from '@partstech/ui/hooks';
import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { initialShop } from 'constant';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useAppDispatch, useAppSelector } from 'store';
import {
  addProductToQuote,
  removeIndex,
  removeProductFromQuote,
  reorderQuoteSlots,
  resetQuote,
  selectQuoteQuantity,
  selectQuoteSlots,
  selectRawSlots,
  setQuoteQuantity,
} from 'store/features/quote';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { removeNonDigits, selectIdFromQuery, convertQuoteSlots } from 'utils';
import { useComparedQuoteProducts } from '../useComparedQuoteProducts';
import { useClearComparedQuote } from './useClearComparedQuote';
import type { Product } from 'models';
import type { UseCreateQuoteResult } from 'types/quote';

export const useComparedQuoteCreation = ({
  shouldFetchProducts,
}: {
  shouldFetchProducts: boolean;
}): UseCreateQuoteResult => {
  const dispatch = useAppDispatch();
  const {
    flags: { tireQuoteGql },
  } = useLaunchDarkly();

  const { clear } = useClearComparedQuote();

  const { shop, email, showRetailPrice } = useGetCurrentUser();

  const quantity = useAppSelector(selectQuoteQuantity);
  const rawSlots = useAppSelector(selectRawSlots, shallowEqual);

  const hasEmptySlots = useMemo(() => rawSlots.some((slot) => !slot.urlParams), [rawSlots]);

  const slotsUrlParams = useMemo(() => rawSlots.map((slot) => slot.urlParams).filter(isNotNull), [rawSlots]);

  const productIds = useMemo(() => slotsUrlParams.map(selectIdFromQuery), [slotsUrlParams]);

  const { isLoading, isSuccess, failedProductIds, fetchedProductIds, isError, products } =
    useComparedQuoteProducts(shouldFetchProducts);

  const slotsGraphQL = convertQuoteSlots(rawSlots, products, fetchedProductIds, failedProductIds);
  const slotsRest = useAppSelector((state) => selectQuoteSlots(state, showRetailPrice), shallowEqual);

  const slots = tireQuoteGql ? slotsGraphQL : slotsRest;

  const isSingleSlot = useMemo(() => slots.filter((slot) => slot.urlParams).length === 1, [slots]);

  const setQuantity = useDebouncedCallback((value: number) => {
    dispatch(setQuoteQuantity(value));
  }, 300);

  const addProduct = useCallback(
    (product: Product) => {
      dispatch(addProductToQuote(product));
      if (product.isStaggeredTire) {
        setQuantity(quantity === 4 ? 2 : quantity);
      }
    },
    [dispatch, quantity, setQuantity]
  );

  const removeProduct = useCallback(
    (product: Product) => {
      dispatch(removeProductFromQuote(product.getId()));
    },
    [dispatch]
  );

  const removeProductByIndex = useCallback(
    (index: number) => {
      dispatch(removeIndex(index));
    },
    [dispatch]
  );

  const swapSlots = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      dispatch(reorderQuoteSlots({ oldIndex, newIndex }));
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(resetQuote());
  }, [dispatch]);

  const checkIsProductAdded = useCallback((product: Product) => productIds.includes(product.getId()), [productIds]);

  const toggleProduct = useCallback(
    (product: Product) => {
      if (checkIsProductAdded(product)) {
        return removeProduct(product);
      }

      return addProduct(product);
    },
    [addProduct, checkIsProductAdded, removeProduct]
  );

  const isToggleDisabled = useCallback(
    (product: Product) => !checkIsProductAdded(product) && !hasEmptySlots,
    [checkIsProductAdded, hasEmptySlots]
  );

  const productErrorIds = useMemo(
    () => slots.filter((slot) => slot.isInvalid).map((errorSlot) => removeNonDigits(errorSlot.urlParams!.partnumberid)),
    [slots]
  );

  return {
    addProduct,
    checkIsProductAdded,
    clear,
    hasEmptySlots,
    isError,
    isLoading,
    isSingleSlot,
    isSuccess,
    isToggleDisabled,
    productErrorIds,
    quantity,
    removeProduct,
    removeProductByIndex,
    reset,
    setQuantity,
    shop: shop
      ? {
          email: email || null,
          logo: shop.logo || null,
          name: shop.name || '',
          phone: shop.phone || null,
        }
      : initialShop,
    slots,
    swapSlots,
    toggleProduct,
  };
};
