import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { ErrorFallback } from './ErrorFallback';
import { useErrorBoundary } from './useErrorBoundary';
import type { FunctionComponent, ReactNode } from 'react';
import type { ResponseError } from 'shared/api';

type ErrorBoundaryProps = {
  children: ReactNode;
  showButton?: boolean;
};

export const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = ({ children, showButton = false }) => {
  const { askRefresh, setError } = useErrorBoundary();

  const handleError = useCallback(
    (err: unknown) => {
      setError(err as Error & ResponseError);
    },
    [setError]
  );

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorFallback askRefresh={askRefresh} showButton={showButton} />}
      onError={handleError}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
