import { historyModalRouterBuilder } from '@partstech/ui';
import { AddCard } from './AddCard';
import { ChangePayment } from './ChangePayment';
import { DeleteCard } from './DeleteCard';
import { EditCard } from './EditCard';
import { PaymentUpdated } from './PaymentUpdated';
import type { PaymentCard } from 'entities/paymentCard';
import type { ReactNode } from 'react';

export type PaymentCardModalData = { card: PaymentCard | null; paymentMethod: PaymentCard | null };

export type PaymentCardModalPaths = {
  addCard: { showCardOptions?: boolean };
  editCard: { showCardOptions?: boolean; showAddNewCardButton?: boolean };
  deleteCard: { card: PaymentCard; children: ReactNode };
  changePaymentMethod: { showCardOptions?: boolean; showAddNewCardButton?: boolean };
  paymentCardUpdated: { cardForRemoving: PaymentCard };
};

export const router = historyModalRouterBuilder<PaymentCardModalPaths, PaymentCardModalData>()
  .addRoute({
    path: 'addCard',
    element: <AddCard />,
    title: 'Add credit card',
  })
  .addRoute({
    path: 'editCard',
    element: <EditCard />,
    title: 'Edit credit card',
    createActions: ({ push, data, setData, state }) => [
      {
        title: 'Delete',
        icon: 'delete',
        buttonColor: 'negative',
        onClick: () => {
          const card = data?.card;

          if (!card) {
            return;
          }

          if (!card.isDefault) {
            push('deleteCard', { card, children: 'Are you sure you want to delete this card?' });

            return;
          }

          setData?.({ card, paymentMethod: data.paymentMethod });

          push('changePaymentMethod', state);
        },
      },
    ],
  })
  .addRoute({
    path: 'deleteCard',
    element: <DeleteCard />,
    title: 'Delete credit card',
  })
  .addRoute({
    path: 'changePaymentMethod',
    element: <ChangePayment />,
    title: 'Change payment method',
  })
  .addRoute({
    path: 'paymentCardUpdated',
    element: <PaymentUpdated />,
    title: 'Payment method updated',
  })
  .build();
