/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSubCategoryListQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['ID']['input'];
}>;

export type GetSubCategoryListQuery = {
  __typename?: 'Query';
  category?: {
    __typename?: 'PartCategory';
    subCategories?: Array<{
      __typename?: 'PartSubCategory';
      id: string;
      name: Types.Scalars['String']['input'];
      partTypes?: Array<{
        __typename?: 'PartType';
        id: string;
        name: Types.Scalars['String']['input'];
        application: Types.PartTypeApplication;
      }> | null;
    }> | null;
  } | null;
};

export const GetSubCategoryListDocument = `
    query GetSubCategoryList($categoryId: ID!) {
  category(categoryID: $categoryId) {
    subCategories {
      id
      name
      partTypes {
        id
        name
        application
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubCategoryList: build.query<GetSubCategoryListQuery, GetSubCategoryListQueryVariables>({
      query: (variables) => ({ document: GetSubCategoryListDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSubCategoryListQuery, useLazyGetSubCategoryListQuery } = injectedRtkApi;
