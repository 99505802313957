import { Brand } from '../models/Brand';
import { PartNumber } from '../models/PartNumber';
import { Product } from '../models/Product';
import { createQuoteFromData } from './createQuoteFromData';
import type { Store, Supplier } from 'models';
import type { Bundle, ProductEntity } from 'types/product';

type Arguments = {
  productData: ProductEntity & {
    bundles?: Bundle[];
    sponsoredPurchasesCount?: number | null;
    shippingCharge?: boolean;
  };
  supplier?: Supplier | null;
  store?: Store | null;
  showRetailPrice?: boolean;
  isPreferred?: boolean;
  accountNickname?: string | null;
};

export const createProductFromData = ({
  productData: product,
  supplier = null,
  store = null,
  showRetailPrice = false,
  isPreferred = false,
  accountNickname = null,
}: Arguments) => {
  const model = new Product({
    attributes: product.attributes,
    bundles: product.bundles ?? [],
    coreCharge: product.coreCharge ?? null,
    credentialId: product.credentialId ? String(product.credentialId) : null,
    descriptions: product.descriptions,
    fetCharge: product.fetCharge ?? null,
    hasVehicleFitment: product.hasVehicleFitment,
    id: product.id,
    images: product.images,
    interchangePart: product.interchangePart,
    lineCardId: product.lineCardId,
    listPrice: product.listPrice ?? null,
    mapPrice: product.mapPrice ?? null,
    notes: product.notes,
    notesOem: product.notesOem,
    partTypeId: product.partTypeId ? String(product.partTypeId) : null,
    partTypeName: product.partTypeName,
    price: product.price ?? null,
    quantityStep: product.quantityStep ?? null,
    rebates: product.rebates,
    retailPrice: product.shopRetailPrice ?? null,
    rewardPoints: product.rewardPoints,
    sponsoredPurchasesCount: product.sponsoredPurchasesCount ?? null,
    sponsorType: product.sponsorType ?? null,
    stockMessage: product.stockMessage,
    shippingCharge: product.shippingCharge ?? false,
    sortHash: product.sortHash,
    title: product.title,
    variations: product.variations,
    vehicleId: product.vehicleId,
  });

  if (supplier) {
    model.setSupplier(supplier);
  }

  if (store) {
    model.setStore(store);
  }

  model.setShowRetailPrice(showRetailPrice);

  model.setPartNumber(new PartNumber({ partNumber: product.partNumber, id: product.partNumberId }));

  model.setBrand(
    new Brand({
      id: product.brandId ? String(product.brandId) : null,
      isPreferred,
      lineCode: product.brandLineCode,
      logo: product.brandLogo,
      name: product.brandName,
    })
  );

  if (product.quote) {
    model.setQuote(
      createQuoteFromData(
        product.quote,
        product.stockMessage,
        supplier?.isDealer,
        store?.id,
        accountNickname,
        supplier?.id
      )
    );
  }

  return model;
};
