import { useMounted } from '@partstech/ui/hooks';
import { useEffect } from 'react';
import { useWelcomeBackModal } from 'components/Modal';
import { useSkipSignUpWelcomeBack } from 'hooks/sessionStorage';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';

export const useSignUpWelcomeBack = () => {
  const isMounted = useMounted();

  const { shop } = useGetCurrentUser();
  const { isOnboardCompleted } = useOnboardSteps();

  const { isSkipped, skip } = useSkipSignUpWelcomeBack();
  const { open } = useWelcomeBackModal(skip);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    skip();
  }, [isMounted, skip]);

  useEffect(() => {
    if (isMounted || isSkipped || isOnboardCompleted || !shop) {
      return;
    }

    open();
  }, [isMounted, isOnboardCompleted, isSkipped, shop, open]);
};
