import { useMemo } from 'react';
import { OnboardStepType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useOnboardSteps';
import { CreateShopPages } from 'utils/url';
import { useCreateShopContext } from '../CreateShopContext/CreateShopContext';

type StepConditions = Partial<Record<CreateShopPages, boolean>>;

export const useLayout = () => {
  const { isStepCompleted } = useOnboardSteps();
  const { managementSystem } = useGetCurrentUser();

  const { activeStep, isDuplicatedShopStep, nextStep, shopType, navigateToNextStep } = useCreateShopContext();

  const { shop } = useGetCurrentUser();

  const nextStepName = nextStep?.name.toLowerCase() ?? '';

  const stepConditions: StepConditions = useMemo(
    () => ({
      [CreateShopPages.Type]: Boolean(shopType) || isStepCompleted(OnboardStepType.ShopType),
      [CreateShopPages.ShopDetails]: Boolean(shop),
      [CreateShopPages.ManagementSystem]: Boolean(managementSystem),
      [CreateShopPages.PartsSuppliers]: isStepCompleted(OnboardStepType.Parts),
      [CreateShopPages.TireSuppliers]: isStepCompleted(OnboardStepType.Tires),
      [CreateShopPages.Role]: isStepCompleted(OnboardStepType.PrimaryRole),
      [CreateShopPages.HearAboutUs]: isStepCompleted(OnboardStepType.Referral),
    }),
    [managementSystem, shop, shopType, isStepCompleted]
  );

  const showNextButton = useMemo(() => {
    if (activeStep?.id && activeStep.id in stepConditions) {
      return stepConditions[activeStep.id];
    }

    return Boolean(nextStepName);
  }, [activeStep?.id, nextStepName, stepConditions]);

  return { isDuplicatedShopStep, nextStepName, navigateToNextStep, showNextButton };
};
