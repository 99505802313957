import { groupBy, entries } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { PartType } from 'models';
import { SuggestedPartTypeOption } from '../models';
import type { SearchEntryPointInterface } from '../models';

type GroupName =
  | 'FITTED'
  | 'UNIVERSAL'
  | 'jobs'
  | 'diagrams'
  | 'part numbers'
  | 'part types'
  | 'did you mean?'
  | 'unknown';

const sortOrder: GroupName[] = [
  'did you mean?',
  'part types',
  'part numbers',
  'jobs',
  'FITTED',
  'UNIVERSAL',
  'diagrams',
];

const groupByOptionType = (
  options: SearchEntryPointInterface[],
  shouldSplitPartTypes: boolean
): Record<string | number, SearchEntryPointInterface[]> =>
  groupBy(options, (option) => {
    if (!shouldSplitPartTypes && option instanceof PartType && !(option instanceof SuggestedPartTypeOption)) {
      return 'part types';
    }

    return option.getColumnTitle ? option.getColumnTitle() : '';
  });

const sortOptionGroups = (groups: Record<GroupName, SearchEntryPointInterface[]>) =>
  entries(groups)
    .sort(([nameA], [nameB]) => sortOrder.indexOf(nameA) - sortOrder.indexOf(nameB))
    .reduce(
      (result, [name, groupOptions]) => ({
        ...result,
        [name]: groupOptions,
      }),
      {} as Record<GroupName, SearchEntryPointInterface[]>
    );

export const useGroupedOptions = (options: SearchEntryPointInterface[], shouldSplitPartTypes: boolean) => {
  const optionsByGroups = useMemo(
    () => sortOptionGroups(groupByOptionType(options, shouldSplitPartTypes)),
    [options, shouldSplitPartTypes]
  );

  const getStartOptionIndexOfGroup = useCallback(
    (groupIndex: number) =>
      Object.values(optionsByGroups).reduce(
        (result, list, currentIndex) => (groupIndex < currentIndex ? result + list.length : result),
        0
      ),
    [optionsByGroups]
  );

  return { optionsByGroups, getStartOptionIndexOfGroup };
};
