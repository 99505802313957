import { FullStory } from '@fullstory/browser';
import { useCallback } from 'react';
import { isDevEnvironment } from 'app/config';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { FullStoryEventNames } from './constants';
import type { FullStoryEventPayload } from './types';

export const useTracking = () => {
  const { shop, accountId } = useGetCurrentUser();

  const sendEvent = useCallback(
    (eventName: FullStoryEventNames, payload: FullStoryEventPayload = {}) => {
      if (isDevEnvironment()) {
        return;
      }

      const extendedPayload = {
        ...payload,
        shopID: shop?.id,
        userID: accountId || undefined,
      };

      if (payload.products) {
        FullStory.event(eventName, {
          ...extendedPayload,
          products: payload.products.map((product) => ({
            ...product,
            shopID: shop?.id,
            userID: accountId || undefined,
          })),
        });
      } else {
        FullStory.event(eventName, extendedPayload);
      }
    },
    [accountId, shop?.id]
  );

  return { sendEvent };
};
