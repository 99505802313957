import { CartTotals, PartType } from 'models';
import type { CartOrder } from 'models';
import type { Shipment } from 'shared/api/rest/gen/shop';
import type { FullShipment } from 'types/cart';

const getShippingCharge = (order: Shipment | FullShipment) =>
  order?.freightTerms?.find((option) => option.service.shippingMethodCode === order.freightTerm)?.service
    .shippingCharge || 0;

export const createCartTotalsFromCartOrders = (orders: CartOrder[], laborRate?: number, laborHours?: number) => {
  const parts = orders.map((order) => order.items).flat();

  return new CartTotals({
    partCount: parts.reduce((acc, part) => acc + part.quantity, 0),
    partsSum: parts.reduce((acc, { quantity, price }) => acc + quantity * price, 0),
    partsSumRetail: parts.reduce((acc, { quantity, retailPrice }) => acc + quantity * retailPrice, 0),
    discountSum: orders.reduce((acc, order) => acc + (order.totals?.discountSum || 0), 0),
    fetSum: parts.reduce((acc, { fetCharge, quantity }) => acc + quantity * fetCharge, 0),
    coreChargeSum: parts.reduce((acc, { coreCharge, quantity }) => acc + quantity * coreCharge, 0),
    shippingLines: orders
      .map((order) => ({ name: order.title, price: order.totals?.shippingCharge || 0 }))
      .filter((line) => line.price > 0),
    taxesLines: orders
      .map((order) => ({ name: order.title || '', price: order.totals?.taxesCharge || 0 }))
      .filter((line) => line.price > 0),
    hasDiscount: parts.some((part) => part.bundled),
    hasParts: parts.some((part) => !part.isTire),
    hasTires: parts.some((part) => part.isTire),
    laborRate,
    laborHours,
  });
};

export const createCartTotalsFromShipments = (orders: Shipment[] | FullShipment[], laborHours = 0, laborRate = 0) => {
  const parts = orders.map((order) => order.parts).flat();

  return new CartTotals({
    partCount: parts.reduce((acc, part) => acc + part.quantity, 0),
    partsSum: parts.reduce((acc, { quantity, product }) => acc + quantity * (product?.price || 0), 0),
    partsSumRetail: parts.reduce((acc, { quantity, product }) => acc + quantity * (product?.shopRetailPrice || 0), 0),
    discountSum: orders.reduce((acc, order) => acc + (order.discountTotal || 0), 0),
    fetSum: parts.reduce((acc, { product, quantity }) => acc + quantity * (product?.fetCharge ?? 0), 0),
    coreChargeSum: parts.reduce((acc, { product, quantity }) => acc + quantity * (product?.coreCharge ?? 0), 0),
    shippingLines: orders
      .map((order) => ({ name: order.name || '', price: getShippingCharge(order) }))
      .filter((line) => line.price > 0),
    taxesLines: orders
      .map((order) => ({ name: order.name || '', price: order.taxWithoutShipping }))
      .filter((line) => line.price > 0),
    laborHours,
    laborRate,
    hasDiscount: parts.some((part) => part.bundled),
    hasParts: parts.some((part) => String(part.product?.partTypeId) !== PartType.TIRE_ID),
    hasTires: parts.some((part) => String(part.product?.partTypeId) === PartType.TIRE_ID),
  });
};
