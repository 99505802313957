import { wrapCreateBrowserRouter } from '@sentry/react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from 'app/AppRouter';
import { LaunchDarklyProvider } from 'integrations/launchDarkly';
import { store } from 'store';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const App = () => (
  <Provider store={store}>
    <LaunchDarklyProvider>
      <RouterProvider router={sentryCreateBrowserRouter(routes)} />
    </LaunchDarklyProvider>
  </Provider>
);
