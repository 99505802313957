import { Box, Button, Card, Icon, IconButton, useSnackbar, Result } from '@partstech/ui';
import { useCallback } from 'react';

type Props = {
  onClick: () => void;
};

export const UpdateAppBanner = ({ onClick }: Props) => {
  const { snackbars, removeSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    const id = snackbars.find((snackbar) => snackbar.label === 'update app banner')?.id;

    id && removeSnackbar(id);
  }, [removeSnackbar, snackbars]);

  return (
    <Card elevation={16} background="white" position="relative">
      <Result
        title="We’ve made some updates."
        message="Refresh the page to see the latest content."
        variant="refresh"
        extraButtons={[<Button onClick={onClick}>Refresh</Button>]}
      />

      <Box position="absolute" top={0} right={0}>
        <IconButton size="dense" noHover onClick={handleClose}>
          <Icon name="close" size="medium" />
        </IconButton>
      </Box>
    </Card>
  );
};
