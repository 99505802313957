import { css } from '@emotion/react';
import { Box, useMedia } from '@partstech/ui';
import { CartBarLoader } from 'components/CartBar/CartBarLoader';
import { DashboardSkeleton } from './DashboardSkeleton';
import { HeaderSkeleton } from './HeaderSkeleton';
import { SideBarSkeleton } from './SideBarSkeleton';
import type { Theme } from '@partstech/ui';

const styles = {
  cartBar: (theme: Theme) => css`
    position: fixed;
    right: 0;
    top: calc(${theme.sizing(19)} - 2px);
    bottom: 0;
    background: ${theme.color.white};
    border-left: 1px solid ${theme.color.mono17};
    width: 279px;
  `,
};

export const LayoutSkeleton = () => {
  const { isMobile, isTablet } = useMedia();

  if (isMobile) {
    return <HeaderSkeleton />;
  }

  return (
    <Box>
      <Box pl={14}>
        <HeaderSkeleton />
      </Box>

      <SideBarSkeleton />
      <DashboardSkeleton />

      {!isTablet && (
        <Box css={styles.cartBar}>
          <CartBarLoader isVisible />
        </Box>
      )}
    </Box>
  );
};
