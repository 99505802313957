import { usePrevious } from '@partstech/ui/hooks';
import { memo } from 'react';
import { AuthorizedProvider } from 'app/contexts/AuthorizedProvider';
import { ErrorFallback } from 'app/ErrorBoundary/ErrorFallback';
import { useIsInvitationPage } from 'hooks/invitations';
import { useIdentifyFullstory } from 'integrations/fullstory/useIdentifyFullstory';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useSentry } from 'integrations/useSentry';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import { LayoutSkeleton } from './LayoutSkeleton';
import { useModalCloser } from './useModalCloser';
import { useRedirectToSupplierPortal } from './useRedirectToSupplierPortal';
import type { PropsWithChildren } from 'react';

export const AppProvider = memo(({ children }: PropsWithChildren) => {
  const {
    flags: { signUpQuestions },
  } = useLaunchDarkly();

  const { isSuccess, hasCurrentUser, shop, isError, isSupplier } = useGetCurrentUser();

  const { isSignupCompleted } = useGetOnBoard();
  const { isOnboardCompleted } = useOnboardSteps();

  const previousHasCurrentUser = usePrevious(hasCurrentUser);

  const isIdentifiedFullstory = useIdentifyFullstory();

  useSentry();

  const isInvitationPage = useIsInvitationPage();

  useModalCloser({ skip: hasCurrentUser === previousHasCurrentUser });

  useRedirectToSupplierPortal();

  const isShopCreated = signUpQuestions && (shop?.newOnboard ?? true) ? isOnboardCompleted : isSignupCompleted;

  if (isError) {
    return <ErrorFallback askRefresh={false} showButton />;
  }

  if (!isSuccess) {
    return <LayoutSkeleton />;
  }

  if (isSupplier) {
    return null;
  }

  if ((!isSupplier && !isShopCreated && hasCurrentUser) || isInvitationPage) {
    return children;
  }

  return <AuthorizedProvider isIdentifiedFullstory={isIdentifiedFullstory}>{children}</AuthorizedProvider>;
});
