import { useCallback, useMemo } from 'react';
import { ManagementSystemStatus, OnboardStatus, OnboardType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

type Args = {
  skip?: boolean;
};

export const useGetOnBoard = ({ skip = false }: Args = {}) => {
  const { activeMember, isPrimary, shop, managementSystem, ...props } = useGetCurrentUser({ skip });

  const onboard = useMemo(() => shop?.onboard, [shop?.onboard]);
  const skipOnboard = shop?.skipOnboard ?? false;

  const isCompletedStep = useCallback(
    (type: OnboardType) =>
      Boolean(shop?.onboard?.some((step) => step.type === type && step.status === OnboardStatus.Completed)),
    [shop?.onboard]
  );

  const hasSignUpDetailsStep = onboard?.some((onboardItem) => onboardItem.type === OnboardType.Suppliers);

  const isSignupCompleted = useMemo(
    () => shop && (!isPrimary || skipOnboard || hasSignUpDetailsStep),
    [isPrimary, hasSignUpDetailsStep, shop, skipOnboard]
  );

  const hasActiveOnboarding = useMemo(() => {
    if (skipOnboard || !isPrimary) {
      return false;
    }

    const hasSkippedSteps = onboard?.some((item) => item.status === OnboardStatus.Skipped);

    const isIntegrationCompleteSMS = managementSystem?.status === ManagementSystemStatus.IntegrationComplete;

    const isCompleted = onboard
      ?.filter((item) => [OnboardType.Suppliers, isIntegrationCompleteSMS && OnboardType.Sms].includes(item.type))
      .every((item) => item.status === OnboardStatus.Completed);

    return !hasSkippedSteps && !isCompleted;
  }, [skipOnboard, isPrimary, onboard, managementSystem?.status]);

  return {
    isPrimary,
    hasSignUpDetailsStep,
    isSignupCompleted,
    shop,
    managementSystem,
    hasActiveOnboarding,
    isCompletedStep,
    onboard,
    skipOnboard,
    ...props,
  };
};
