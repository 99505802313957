import { Box, Button } from '@partstech/ui';
import { useCallback, type PropsWithChildren } from 'react';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { SmsConnectionsContext } from './SmsConnectionsContext';
import { useSmsConnectionsContext } from './SmsConnectionsContext/SmsConnectionsContext';

const SmsConnectionsWrapper = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();

  const { isShowBackButton } = useSmsConnectionsContext();

  const handleBackButtonClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {isShowBackButton && (
        <Box position="absolute" top={{ sm: 1, md: 2 }} left={{ sm: 1, md: 6 }}>
          <Button leadingIcon="chevron_left" variant="text" onClick={handleBackButtonClick} noHover>
            Back
          </Button>
        </Box>
      )}

      <Outlet>{children}</Outlet>
    </>
  );
};

export const SmsConnectionsLayout = ({ children }: PropsWithChildren) => (
  <Box minHeight="100vh" minWidth="100vw" background="white">
    <Box
      display="flex"
      justifyContent="center"
      px={{ sm: 4, md: 6 }}
      py={{ sm: 5, md: 6 }}
      width="100%"
      height="100%"
      minHeight="100vh"
      maxWidth={192}
      mx="auto"
    >
      <SmsConnectionsContext>
        <SmsConnectionsWrapper>{children}</SmsConnectionsWrapper>
      </SmsConnectionsContext>
    </Box>
  </Box>
);
