import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useState } from 'react';

export const useImageNavigation = (imageCount: number, defaultIndex?: number) => {
  const { isOpen: isVisible, open: show, close: hide } = useOpen();

  const [current, setCurrent] = useState(defaultIndex ?? 0);

  const handleNextClick = useCallback(() => {
    setCurrent(current === imageCount - 1 ? 0 : current + 1);
  }, [imageCount, current, setCurrent]);

  const handlePrevClick = useCallback(() => {
    setCurrent(current === 0 ? imageCount - 1 : current - 1);
  }, [imageCount, current, setCurrent]);

  const handleCurrentClick = useCallback((currentIndex: number) => {
    setCurrent(currentIndex);
  }, []);

  return {
    current,
    isVisible,
    show,
    hide,
    next: handleNextClick,
    prev: handlePrevClick,
    currentClick: handleCurrentClick,
  };
};
