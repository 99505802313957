import { css } from '@emotion/react';
import { Box, Card, Typography, Scrollbar } from '@partstech/ui';
import { entries, groupBy } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { AvailabilityMenuItem } from './AvailabilityMenuItem';
import type { Theme } from '@emotion/react';
import type { AvailabilityLine } from 'models';

type Props = {
  allowSelect: boolean;
  onCloseClick: () => void;
  onItemClick: (store: AvailabilityLine) => void;
  store: AvailabilityLine | undefined;
  stores: AvailabilityLine[];
  message?: string;
};

const styles = {
  scrollbar: (theme: Theme) => css`
    max-height: ${theme.sizing(100)};

    > div:last-of-type {
      padding-bottom: 0;
    }
  `,
  lines: css`
    > div:last-of-type {
      padding-bottom: 0;
    }
  `,
};

export const AvailabilityMenu = ({ allowSelect, onCloseClick, onItemClick, store, stores, message }: Props) => {
  const groupedStores = useMemo(() => groupBy(stores, (item) => item.group ?? ''), [stores]);

  const handleItemClick = useCallback(
    (selectedStore: AvailabilityLine) => {
      onItemClick(selectedStore);
      onCloseClick();
    },
    [onCloseClick, onItemClick]
  );

  return (
    <Card p={4} elevation={6} gap={1} maxWidth={150} width="100%">
      <Scrollbar css={styles.scrollbar}>
        {entries(groupedStores).map(([groupName, group]) => (
          <Box pb={3} key={groupName}>
            {groupName && (
              <Typography variant="overline" color="subtleText" textTransform="uppercase">
                {groupName}
              </Typography>
            )}

            {group.map((item, index) => (
              <AvailabilityMenuItem
                allowSelect={allowSelect}
                isSelected={item.isEqual(store)}
                key={`${item.id}${index}`}
                onClick={handleItemClick}
                store={item}
              />
            ))}
          </Box>
        ))}

        {message && (
          <Box maxWidth={85}>
            <Typography variant="bodyText2">{message}</Typography>
          </Box>
        )}
      </Scrollbar>
    </Card>
  );
};
