import { Button, Checkbox, Box } from '@partstech/ui';
import { HookForm, FormPhoneField, FormTextField } from '@partstech/ui/forms';
import { AddressForm } from 'entities/address';
import { GoogleMapsProvider } from 'integrations/google';
import { of, required } from 'shared/lib/form';
import { useCreateShopForm } from './useCreateShopForm';
import type { ShopFormData } from '../../../types';
import type { Address } from 'shared/api';

type Props = {
  defaultValues?: ShopFormData;
  submitButtonText: string;
  onSubmit: (
    shop: ShopFormData,
    params: Partial<{ hasDuplicates: boolean; hasChangedShopInfo: boolean }>
  ) => Promise<void>;
  hasShop?: boolean;
  isLoading?: boolean;
};

export const CreateShopForm = ({ submitButtonText, isLoading, ...props }: Props) => {
  const { form, submitForm, isSubmitting, isDisabled, isShowShippingForm, changeShippingAddressCheckbox } =
    useCreateShopForm(props);

  return (
    <HookForm form={form} onSubmit={submitForm}>
      <Box my={2}>
        <FormTextField name="name" label="Shop name" hideRequiredMark rules={{ required }} />
      </Box>

      <FormPhoneField name="phone" label="Shop phone number" hideRequiredMark rules={{ required }} />

      <Box my={2}>
        <FormTextField name="website" label="Website" />
      </Box>

      <GoogleMapsProvider>
        <AddressForm path={of<ShopFormData, 'billingAddress'>('billingAddress')} />
      </GoogleMapsProvider>

      <Box textAlign="left" mt={4}>
        <Checkbox data-testid="checkbox" checked={isShowShippingForm} onChange={changeShippingAddressCheckbox}>
          Shipping address is the same as billing address
        </Checkbox>
      </Box>

      {!isShowShippingForm && (
        <Box mt={2} mb={4}>
          <GoogleMapsProvider>
            <AddressForm path={of<{ shippingAddress: Address }, 'shippingAddress'>('shippingAddress')} />
          </GoogleMapsProvider>
        </Box>
      )}

      <Box mt={4}>
        <Button
          type="submit"
          fullWidth
          isLoading={isSubmitting || isLoading}
          disabled={isDisabled || isLoading}
          data-testid="submitCreateShop"
        >
          {submitButtonText}
        </Button>
      </Box>
    </HookForm>
  );
};
