import { useDeepCompareEffect } from '@partstech/ui/hooks';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchProduct, selectProductModelsByIds } from 'store/entities/product';
import {
  selectIsProductsFetchFailed,
  selectIsProductsFetched,
  selectIsProductsFetching,
} from 'store/features/productPage';
import { useToggleRetailPrice } from '../retailPricing/useToggleRetailPrice';
import type { ProductQueryParams } from 'types/product';

export const useProducts = (urlParamsList: ProductQueryParams[], productIds: string[], skip: boolean = false) => {
  const dispatch = useAppDispatch();

  // TODO: Move outside
  const { isShown: showRetailPrice } = useToggleRetailPrice();

  const isLoading = useAppSelector(selectIsProductsFetching);
  const isProductsFetched = useAppSelector((state) => selectIsProductsFetched(state, productIds));
  const isProductsFetchFailed = useAppSelector((state) => selectIsProductsFetchFailed(state, productIds));
  const products = useAppSelector(
    (state) => selectProductModelsByIds(state, productIds, showRetailPrice),
    shallowEqual
  );

  useDeepCompareEffect(() => {
    if (!isProductsFetched && !isLoading && !skip) {
      urlParamsList.forEach((urlParams) => dispatch(fetchProduct(urlParams)));
    }
  }, [dispatch, isLoading, isProductsFetched, urlParamsList, skip]);

  return {
    isLoading,
    isSuccess: isProductsFetched && !isProductsFetchFailed,
    isFailed: isProductsFetchFailed,
    products,
    productIds,
  };
};
