import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { useSendShopData } from './useSendShopData';
import { useSendSubscriptionPackage } from './useSendSubscriptionPackage';
import { useSendSupplierAccountStats } from './useSendSupplierAccountStats';

type Args = {
  isIdentified: boolean;
};

export const useFullstory = ({ isIdentified }: Args) => {
  const { isSignupCompleted } = useGetOnBoard();

  const skip = !isIdentified || !isSignupCompleted;

  useSendShopData({ skip });
  useSendSubscriptionPackage({ skip });
  useSendSupplierAccountStats({ skip });
};
