import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { initialShop } from 'constant';
import { createProductFromData } from 'factories';
import { FullStoryEventNames, useTracking } from 'integrations/fullstory/tracking';
import { getTireQuote } from 'shared/api/rest/gen/shop';
import { useAppDispatch } from 'store';
import { urlParamsToQuery } from 'utils';
import type { GetTireQuoteResponse } from 'shared/api/rest/gen/shop';
import type { ReadonlyQuote } from 'store/features/quote';
import type { ArrayItemType } from 'types/general';
import type { UseViewQuoteResult } from 'types/quote';

type QuoteTire = ArrayItemType<GetTireQuoteResponse['tires']> & { customPrice?: number | null };

type GetTireQuoteResponseExtended = Omit<GetTireQuoteResponse, 'tires'> & {
  tires: QuoteTire[];
};

const createQuoteFromQuery = (response: GetTireQuoteResponseExtended, quoteId: string) => {
  const quote: ReadonlyQuote = {
    id: quoteId,
    quantity: response.quantity,
    packages: [],
    slots: response.tires.map((item) => ({
      product: createProductFromData({ productData: item.part, supplier: null, store: null, showRetailPrice: true }),
      calculation: item.calculations,
      label: item.label,
      isFetched: true,
      isInvalid: false,
      urlParams: urlParamsToQuery(item.part),
      note: item.note,
      customPrice: item.customPrice,
    })),
    error: null,
    isSuccess: false,
    isLoading: false,
    isShareModalOpen: false,
  };

  return {
    shop: response.shop,
    isExecuted: response.isExecuted,
    quote,
  };
};

export const useViewComparedQuote = ({ skip }: { skip: boolean }): UseViewQuoteResult => {
  const { sendEvent } = useTracking();
  const [shop, setShop] = useState<GetTireQuoteResponse['shop']>(initialShop);
  const [isExecuted, setIsExecuted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [quote, setQuote] = useState<ReadonlyQuote>();

  const dispatch = useAppDispatch();

  const { quoteId = '' } = useParams<{ quoteId: string }>();

  useEffect(() => {
    if (!skip && !isLoading) {
      getTireQuote(quoteId)
        .then((response) => {
          const {
            quote: responseQuote,
            shop: responseShop,
            isExecuted: responseIsExecuted,
          } = createQuoteFromQuery(response, quoteId);

          if (responseQuote.slots.length > 0) {
            setShop(responseShop);
            setQuote(responseQuote);
            setIsExecuted(responseIsExecuted);
            setIsLoading(false);
            setIsSuccess(true);
          } else {
            setShop(responseShop);
            setIsError(true);
            setIsLoading(false);
            setIsSuccess(true);
            sendEvent(FullStoryEventNames.CUSTOMER_FAILED_QUOTE, { error: 'tireUnavailable' });
          }
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
          setIsSuccess(true);
          sendEvent(FullStoryEventNames.CUSTOMER_FAILED_QUOTE, { error: 'quoteInvalid' });
        });
    }
  }, [skip, quoteId, dispatch, isLoading, sendEvent]);

  return {
    slots: quote?.slots ?? [],
    quantity: quote?.quantity ?? 4,
    shop,
    isExecuted,
    isSuccess,
    isError,
    isLoading,
  };
};
