/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CartLaborItemFragmentDoc } from '../../../../../shared/api/graphql/fragment/CartLaborItem.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateLaborItemInCartMutationVariables = Types.Exact<{
  hours: Types.Scalars['BigDecimal']['input'];
  id: Types.Scalars['ID']['input'];
}>;

export type UpdateLaborItemInCartMutation = {
  __typename?: 'Mutation';
  updateActiveCartLaborItem?:
    | { __typename?: 'UpdateActiveCartLaborItemErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | {
        __typename?: 'UpdateActiveCartLaborItemSuccessPayload';
        cartLaborItem: {
          __typename?: 'CartLaborItem';
          id: string;
          hours: Types.Scalars['Float']['input'];
          defaultHours: Types.Scalars['Float']['input'];
          vin?: Types.Scalars['String']['input'] | null;
          orderItemId?: string | null;
          name: Types.Scalars['String']['input'];
          notes?: Types.Scalars['String']['input'] | null;
          skill: Types.Scalars['String']['input'];
          identifiers?:
            | { __typename?: 'Mitchell1Identifiers' }
            | { __typename?: 'MotorIdentifiers'; id: string }
            | null;
          vehicle?: { __typename?: 'Vehicle'; id: string } | null;
        };
      }
    | null;
};

export const UpdateLaborItemInCartDocument = `
    mutation UpdateLaborItemInCart($hours: BigDecimal!, $id: ID!) {
  updateActiveCartLaborItem(input: {hours: $hours, id: $id}) {
    ... on UpdateActiveCartLaborItemErrorPayload {
      errorMessage
    }
    ... on UpdateActiveCartLaborItemSuccessPayload {
      cartLaborItem {
        ...CartLaborItem
      }
    }
  }
}
    ${CartLaborItemFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateLaborItemInCart: build.mutation<UpdateLaborItemInCartMutation, UpdateLaborItemInCartMutationVariables>({
      query: (variables) => ({ document: UpdateLaborItemInCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateLaborItemInCartMutation } = injectedRtkApi;
