import { combineReducers } from '@reduxjs/toolkit';
import { cart } from 'store/features/cart';
import { productPage } from 'store/features/productPage';
import { punchout } from 'store/features/punchout';
import { quote } from 'store/features/quote';
import { search } from 'store/features/search';
import { supplierSearch } from 'store/features/supplierSearch';
import { account } from 'store/features/user/account';
import { paymentMethod } from '../../features/paymentsMethod/model/paymentMethodSlice';
import { trackingStats } from './trackingStats';

export const features = combineReducers({
  cart,
  trackingStats,
  paymentMethod,
  productPage,
  search,
  supplierSearch,
  punchout,
  quote,
  user: combineReducers({ account }),
});
