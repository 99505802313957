import { css } from '@emotion/react';
import { Banner, Box, Breadcrumbs, Card, Link, Scrollbar } from '@partstech/ui';
import { useCallback } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { useDynamicAttributeConfigurator } from './useDynamicAttributeConfigurator';
import type { CardProps } from '@partstech/ui';
import type { UniversalPartType } from 'models';

const styles = {
  scrollbar: (isSelectVehicleShow: boolean) => css`
    width: 100%;
    height: calc(100% - ${isSelectVehicleShow ? 98 : 58}px);
  `,
};

export type DynamicAttributeConfiguratorProps = {
  partType: UniversalPartType;
  isSelectVehicleShow: boolean;
  onSelectVehicleClick?: () => void;
  onChange: (partType: UniversalPartType) => void;
  onReset?: () => void;
} & Omit<CardProps, 'onChange' | 'onReset'>;

export const DynamicAttributeConfigurator = ({
  partType,
  isSelectVehicleShow,
  onSelectVehicleClick,
  onChange,
  onReset,
  ...cardProps
}: DynamicAttributeConfiguratorProps) => {
  const { breadcrumbs, attribute, selectValue, isLastAttribute, selectedAttributes } = useDynamicAttributeConfigurator({
    partType,
    onReset,
  });

  const handleChangeAttribute = useCallback(
    (name: string) => (value: string) => {
      selectValue(name, value);

      if (isLastAttribute) {
        onChange(partType.setSelectedAttributes({ ...selectedAttributes, [name]: [value] }));
      }
    },
    [isLastAttribute, onChange, partType, selectValue, selectedAttributes]
  );

  const handleSelectVehicleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      onSelectVehicleClick?.();
    },
    [onSelectVehicleClick]
  );

  return (
    <Card elevation={null} height="100%" {...cardProps}>
      <Box display="flex">
        <Card display="flex" elevation={null} background="mono2" mb={2}>
          <Breadcrumbs px={4} py={2} breadcrumbs={breadcrumbs} />
        </Card>
      </Box>

      {isSelectVehicleShow && (
        <Banner icon="info" variant="info" radius={6} borderWidth={0} dense mb={2} display="inline-flex">
          Want to see vehicle specific options?{' '}
          <Link variant="caption" color="linkText" underline="hover" to="#" onClick={handleSelectVehicleClick}>
            Click here to enter a vehicle
          </Link>
        </Banner>
      )}

      <Scrollbar css={styles.scrollbar(isSelectVehicleShow)}>
        <OptionsByColumns
          maxColumns={2}
          options={attribute?.values ?? []}
          onChange={handleChangeAttribute(attribute?.name ?? '')}
        />
      </Scrollbar>
    </Card>
  );
};
