import { useMemo } from 'react';
import { useComparedQuoteCreation } from './useComparedQuoteCreation';
import { useComparedQuoteView } from './useComparedQuoteView';
import type { QuoteMode } from 'types/quote';

type Props = {
  mode?: QuoteMode;
  skip?: boolean;
  shouldFetchProducts?: boolean;
};

export const useCompareQuote = (
  { skip = false, shouldFetchProducts = false, mode = 'create' }: Props = {
    skip: false,
    shouldFetchProducts: false,
    mode: 'create',
  }
) => {
  const createQuote = useComparedQuoteCreation({ shouldFetchProducts });
  const viewQuote = useComparedQuoteView({ skip: mode !== 'view' || skip });

  const slots = useMemo(() => {
    if (mode === 'view') {
      return viewQuote.slots;
    }
    return createQuote.slots;
  }, [createQuote.slots, mode, viewQuote.slots]);

  const quote = useMemo(() => {
    if (mode === 'view') {
      return { ...createQuote, ...viewQuote };
    }
    return { ...viewQuote, ...createQuote };
  }, [createQuote, mode, viewQuote]);

  return {
    ...quote,
    slots,
  };
};
