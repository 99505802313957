/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CreateAdditionalDetailsMutationVariables = Types.Exact<{
  input: Types.CreateAdditionalDetailsInput;
}>;

export type CreateAdditionalDetailsMutation = {
  __typename?: 'Mutation';
  createAdditionalDetails?: { __typename: 'CreateAdditionalDetailsPayload' } | null;
};

export const CreateAdditionalDetailsDocument = `
    mutation CreateAdditionalDetails($input: CreateAdditionalDetailsInput!) {
  createAdditionalDetails(input: $input) {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateAdditionalDetails: build.mutation<CreateAdditionalDetailsMutation, CreateAdditionalDetailsMutationVariables>({
      query: (variables) => ({ document: CreateAdditionalDetailsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateAdditionalDetailsMutation } = injectedRtkApi;
