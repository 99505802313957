import { get } from '@partstech/ui/utils';
import { useMemo, useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { getDynamicAttributesWithRangesForSection, isDynamicAttributeFilterName, isRangedAttributeValue } from 'utils';
import { useGetSearchUrl } from './useGetSearchUrl';
import { useNonAppAttributes } from './useNonAppAttributes';
import { useSearchParams } from './useSearchParams';
import type { DynamicAttributeName, Filter } from 'types/search';

export const useChangeFilter = (filters: Filter[]) => {
  const navigate = useAppNavigate();
  const { searchParams } = useSearchParams();
  const { getSearchUrl } = useGetSearchUrl();

  const { attributes } = useNonAppAttributes();

  const attributesByRange = useMemo(() => getDynamicAttributesWithRangesForSection(attributes, 'search'), [attributes]);

  const changeFilter = useCallback(
    (name: Filter['name'], value: string, checked: boolean) => {
      const checkedValues = get(searchParams, name) ?? [];
      const changedFilter = filters.find((filter) => filter.name === name);
      const selectedOption = changedFilter?.options.find((option) => option.value === value);
      const selectedOptionValues = changedFilter?.options
        .filter((option) => selectedOption?.name === option.name)
        .map((option) => option.value) ?? [value];

      const isDynamicAttribute = isDynamicAttributeFilterName(name);
      const dynamicAttributeName: DynamicAttributeName = isDynamicAttribute ? (name.split('.').pop() ?? '') : '';
      const rangedAttributeValue = attributesByRange[dynamicAttributeName] ?? [];

      const values =
        isDynamicAttribute && isRangedAttributeValue(rangedAttributeValue)
          ? rangedAttributeValue.filter((options) => options.includes(selectedOption?.value ?? '')).flat()
          : selectedOptionValues;

      const newCheckedValues = checked
        ? [...checkedValues, ...values]
        : checkedValues.filter((checkedValue) => !values.includes(checkedValue));

      if (isDynamicAttribute) {
        searchParams.attributes = { ...searchParams.attributes, [dynamicAttributeName]: newCheckedValues };
      } else {
        searchParams[name] = newCheckedValues;
      }

      navigate(
        getSearchUrl({ ...searchParams, fit: isDynamicAttribute ? undefined : searchParams.fit, page: undefined })
      );
    },
    [searchParams, filters, attributesByRange, navigate, getSearchUrl]
  );

  return { changeFilter };
};
