import type { ApplicationGroup, LaborProcedureItemExtended, Mitchell1Labor, OperationProcedure } from 'types/shipment';

const getProcedureTotalHours = (items: LaborProcedureItemExtended[]) =>
  items.reduce((totalItems, item) => totalItems + (item.checked ? item.customDuration || item.standardTime : 0), 0);

const getApplicationGroupsTotalHours = (groups: ApplicationGroup[]) =>
  groups.reduce((total, group) => {
    const groupItemsHours = getProcedureTotalHours(group.items);

    const additionalProceduresHours = group.additionalProcedures.reduce(
      (acc, item) => acc + getProcedureTotalHours(item.items),
      0
    );

    const combinationProceduresHours = group.combinationProcedures.reduce(
      (acc, item) => acc + getProcedureTotalHours(item.items),
      0
    );

    return total + groupItemsHours + additionalProceduresHours + combinationProceduresHours;
  }, 0);

const getOperationTotalHours = (items: OperationProcedure[]) =>
  items.reduce(
    (total, item) => total + (item.procedure ? getApplicationGroupsTotalHours(item.procedure.applicationGroups) : 0),
    0
  );

export const getTotalMitchell1LaborHours = (items: Mitchell1Labor[]) => {
  const result = items.reduce(
    (total, item) =>
      total +
      (item?.mitchell1VehicleId ? getOperationTotalHours(item.operationProcedures[item.mitchell1VehicleId] || []) : 0),
    0
  );
  return result ? Number(result.toFixed(3)) : 0;
};
