import { isNumber } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRouter } from 'app/contexts/RouterProvider/RouterContext';
import { generateLocation } from 'utils/url/generateLocation';
import { isPathNeedBackground } from './isPathNeedBackground';
import { useAppLocation } from './useAppLocation';
import type { LocationState } from './useAppLocation';
import type { NavigateFunction, NavigateOptions, To } from 'react-router-dom';

export type AppNavigateOptions = Omit<NavigateOptions, 'state'> & { state?: LocationState };

interface useAppNavigateReturnType extends NavigateFunction {
  (to: To | number, options?: AppNavigateOptions): void;
}

export const useAppNavigate = (): useAppNavigateReturnType => {
  const { isBlocked, setTryToNavigate } = useRouter();
  const nativeNavigate = useNavigate();
  const location = useAppLocation();

  const navigate = useCallback(
    (to: To | number, options?: AppNavigateOptions) => {
      const needBackground = !isNumber(to) && isPathNeedBackground(location.pathname, to);
      const enhancedOptions = needBackground
        ? {
            ...options,
            state: {
              ...options?.state,
              background: generateLocation(location.pathname, location.state, location.search, location.hash),
            },
          }
        : options;

      if (isBlocked) {
        return setTryToNavigate({ to, options: enhancedOptions });
      }

      return isNumber(to) ? nativeNavigate(to) : nativeNavigate(to, enhancedOptions);
    },
    [isBlocked, nativeNavigate, setTryToNavigate, location.pathname, location.state, location.search, location.hash]
  );

  return navigate;
};
