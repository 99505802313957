import { Box } from '@partstech/ui';
import { useCallback } from 'react';
import { SupplierAccountsRequiredView } from 'components/SupplierAccountsRequiredView';
import { RecentSearchesList } from 'features/recentSearches';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { UniversalPartType, type Vehicle } from 'models';
import { SearchTypes } from 'types/search';
import { useDefaultOptions } from '../../hooks/useDefaultOptions';
import { SearchTabs } from '../../types';
import { OptionListGroups } from '../OptionListGroups';
import { Categories } from './Categories';
import { Diagrams } from './Diagrams';
import { FluidsAndChemicals } from './FluidsAndChemicals';
import { Jobs } from './Jobs';
import { PartsSearchTabs } from './PartsSearchTabs';
import { ServiceGuides } from './ServiceGuides';
import { Tires } from './Tires';
import type { RecentSearchInterface } from 'features/recentSearches';
import type { SearchEntryPointInterface } from 'features/searchForm';

export type SearchEntryPointsProps = {
  isKeyboardDisabled?: boolean;
  onChange?: (value: SearchEntryPointInterface) => void;
  onRecentSearchSelect?: (value: RecentSearchInterface) => void;
  onSelect?: (value: SearchEntryPointInterface) => void;
  onSelectVehicleClick?: () => void;
  onTabChange: (tab: SearchTabs) => void;
  tab: SearchTabs;
  vehicle?: Vehicle | null;
  searchEntry?: SearchEntryPointInterface | null;
};

export const SearchEntryPoints = ({
  onChange,
  onRecentSearchSelect,
  onSelect,
  onSelectVehicleClick,
  onTabChange,
  isKeyboardDisabled = false,
  tab,
  vehicle,
  searchEntry,
}: SearchEntryPointsProps) => {
  const { categories, diagrams, jobs, partTypes, tiresOptions, fluidsAndChemicals } = useDefaultOptions(vehicle);
  const { hasAccounts: hasPartsAccounts } = useSupplierAccounts({ type: 'parts' });
  const { hasAccounts: hasTiresAccounts } = useSupplierAccounts({ type: 'tires' });

  const hasVehicle = Boolean(vehicle?.id);

  const handleChange = useCallback(
    (option: SearchEntryPointInterface) => {
      onChange && onChange(option);
    },
    [onChange]
  );

  const handleSelect = useCallback(
    (option: SearchEntryPointInterface) => {
      onSelect && onSelect(option);
    },
    [onSelect]
  );

  return (
    <Box data-testid="searchEntryPoints" display="flex" flexDirection="column" height="100%">
      <Box mt={{ sm: -1, md: -4 }} mx={0}>
        <PartsSearchTabs tab={tab} onChange={onTabChange} />
      </Box>

      {tab === SearchTabs.POPULAR && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <Box width="100%" height="100%">
            <OptionListGroups
              options={partTypes}
              onChange={handleChange}
              onSelect={handleSelect}
              className="ps-popular"
              shouldSplitPartTypes
              isKeyboardDisabled={isKeyboardDisabled}
            />
          </Box>
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.TIRES && (
        <SupplierAccountsRequiredView isAllowed={hasTiresAccounts} type={SearchTypes.TIRES}>
          <Tires
            hasVehicle={hasVehicle}
            onSelectVehicleClick={onSelectVehicleClick}
            options={tiresOptions}
            onChange={handleChange}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.FLUIDS_CHEMICALS && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <FluidsAndChemicals
            hasVehicle={hasVehicle}
            onSelectVehicleClick={onSelectVehicleClick}
            options={fluidsAndChemicals}
            defaultPartType={searchEntry instanceof UniversalPartType ? searchEntry : undefined}
            onChange={handleChange}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.DIAGRAMS && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <Diagrams
            hasVehicle={hasVehicle}
            options={diagrams}
            onChange={handleChange}
            onSelect={handleSelect}
            onSelectVehicleClick={onSelectVehicleClick}
            isKeyboardDisabled={isKeyboardDisabled}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.JOBS && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <Jobs
            hasVehicle={hasVehicle}
            options={jobs}
            onChange={handleChange}
            onSelect={handleSelect}
            onSelectVehicleClick={onSelectVehicleClick}
            isKeyboardDisabled={isKeyboardDisabled}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.CATEGORIES && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <Categories
            categories={categories}
            onChange={handleChange}
            onSelect={handleSelect}
            isKeyboardDisabled={isKeyboardDisabled}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.SERVICE_GUIDES && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <ServiceGuides
            vehicleId={vehicle?.id}
            vehicleYear={vehicle?.year}
            vin={vehicle?.vin}
            onSelectVehicleClick={onSelectVehicleClick}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.RECENT_SEARCHES && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <RecentSearchesList onSelect={onRecentSearchSelect} />
        </SupplierAccountsRequiredView>
      )}
    </Box>
  );
};
