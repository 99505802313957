import { combineReducers } from '@reduxjs/toolkit';
import { outTheDoorPrices } from 'store/features/search/outTheDoorPrices';
import { productStatuses } from './productStatuses';
import { settings } from './settings';

export const search = combineReducers({
  outTheDoorPrices,
  productStatuses,
  settings,
});
