import { entries, keys } from '@partstech/ui/utils';
import { PartTypeApplication } from 'shared/api';
import { PartType } from './PartType';
import type { Vehicle } from './Vehicle';

type SelectedAttribute = Record<string, string[]>;

export class UniversalPartType extends PartType {
  selectedAttributes: SelectedAttribute = {};

  isAllAttributesFilled() {
    return (this.attributes ?? []).length === keys(this.selectedAttributes).length;
  }

  setSelectedAttributes(attributes: SelectedAttribute) {
    this.selectedAttributes = attributes;

    return this;
  }

  shouldPickAttributes(hasVehicle: boolean): boolean {
    if (hasVehicle) {
      return false;
    }

    return !this.isAllAttributesFilled();
  }

  getSelectedAttributes(hasVehicle: Boolean) {
    if (hasVehicle) {
      return this.selectedAttributes;
    }

    return entries(this.selectedAttributes).reduce<SelectedAttribute>(
      (acc, [name, values]) => ({
        ...acc,
        [name]: [...values, 'Not Specified'],
      }),
      {}
    );
  }

  toQuery(vehicle: Vehicle | null, isFittedAvailable: boolean = false) {
    return {
      part_text_id: this.id,
      part_text: this.name,
      attributes: !isFittedAvailable ? this.getSelectedAttributes(Boolean(vehicle)) : undefined,
      fit: this.application === PartTypeApplication.UniversalFitted && vehicle && isFittedAvailable ? ['1'] : undefined,
    };
  }
}
