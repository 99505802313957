import { Loader, MenuAction, Menu, Icon, Dropdown } from '@partstech/ui';
import { useGetPaymentCards } from '../../../../api/paymentCards';
import { SelectedCreditCard } from '../../../SelectedCreditCard';
import { CreditCardItem } from './CreditCardItem';
import type { PaymentCard } from 'entities/paymentCard';

type Props = {
  defaultCard: PaymentCard | null;
  onAddNewCard: () => void;
  onEditCard: (card: PaymentCard) => void;
  onSelectCard: (card: PaymentCard) => void;
  showAddNewCardButton?: boolean;
};

export const PaymentCardDropdown = ({
  defaultCard,
  showAddNewCardButton,
  onAddNewCard,
  onEditCard,
  onSelectCard,
}: Props) => {
  const { cards, isLoading } = useGetPaymentCards();

  if (isLoading) {
    return <Loader size="medium" isCentered />;
  }

  if (!defaultCard) {
    return null;
  }

  return (
    <Dropdown
      usePortal
      stretchContent
      renderButton={({ isOpened, toggleHandler }) => (
        <SelectedCreditCard
          actionIcon={isOpened ? 'expand_less' : 'expand_more'}
          card={defaultCard}
          isHovered
          onCardClick={toggleHandler}
        />
      )}
    >
      {({ closeHandler }) => (
        <Menu hasAction fullWidth>
          {cards.map((card) => (
            <CreditCardItem
              key={card.id}
              card={card}
              isSelectedCard={card.id === defaultCard.id}
              onCloseDropdown={closeHandler}
              onEditCard={onEditCard}
              onSelectCard={onSelectCard}
            />
          ))}

          {showAddNewCardButton && (
            <MenuAction
              adornmentBefore={<Icon name="add" />}
              justifyContent="center"
              color="primary"
              hasDivider
              typographyStyle="subtitle2"
              onClick={onAddNewCard}
            >
              Add new card
            </MenuAction>
          )}
        </Menu>
      )}
    </Dropdown>
  );
};
