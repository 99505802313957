import React, { useCallback } from 'react';
import { Option } from './Option';
import type { OptionsProps } from './types';

export const Options = ({
  name,
  nameAsValue = false,
  options,
  disabledOptions,
  checkedOptions,
  adornmentAfter,
  onChange,
}: OptionsProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (nameAsValue) {
        const selectedOption = options.find((option) => option.value === e.target.value);
        onChange({ ...e, target: { ...e.target, value: selectedOption?.name ?? e.target.value } });

        return;
      }

      onChange(e);
    },
    [nameAsValue, onChange, options]
  );

  return (
    <>
      {options.map((option) => (
        <Option
          key={option.name}
          name={name}
          option={option}
          checked={checkedOptions.includes(option.value)}
          disabled={disabledOptions?.includes(option.value)}
          adornmentAfter={adornmentAfter}
          onChange={handleChange}
        />
      ))}
    </>
  );
};
