import { useSnackbar } from '@partstech/ui';
import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useState } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { Routes } from 'constant';
import { createCartOrderFromActiveCart } from 'factories';
import { getSubmittedLabor } from 'features/labor';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { useBuyCart } from '../api/mutations/buyCart';
import { useSumbitCart } from '../api/mutations/submitCart';
import { getMitchell1Cart } from '../utils';
import type { CartOrder, LaborApplication } from 'models';
import type { Mitchell1ShoppingCart } from 'shared/api/rest/gen/shop';

type Props = {
  orders?: CartOrder[];
  laborApplications?: LaborApplication[];
  refetchCart: () => void;
};

export const useBuyOrSumbitCart = ({ orders, laborApplications = [], refetchCart }: Props) => {
  const navigate = useAppNavigate();
  const { addSnackbar } = useSnackbar();
  const { accounts } = useSupplierAccounts();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paidOrders, setPaidOrders] = useState<CartOrder[]>([]);
  const [mitchell1Payload, setMitchell1Payload] = useState<Mitchell1ShoppingCart | null>(null);

  const [buyCart] = useBuyCart();
  const [submitCart] = useSumbitCart();

  const buyOrSubmit = useCallback(
    async (action: 'buy' | 'submit') => {
      if (!orders) {
        return;
      }

      setIsSubmitting(true);

      const submittedLabor = getSubmittedLabor(laborApplications);
      const laborItemIds = laborApplications.map((item) => item.customId).filter(isNotNull);

      const res = await (action === 'buy' ? buyCart : submitCart)(orders, laborItemIds, submittedLabor);

      if (res.error) {
        addSnackbar({ label: res.error });
        refetchCart();
        setIsSubmitting(false);
        return;
      }

      if (res.redirectUrl) {
        window.location.href = res.redirectUrl;
        return;
      }

      if (res.mitchell1Payload) {
        setMitchell1Payload(getMitchell1Cart(res.mitchell1Payload));
        navigate(Routes.CART_TRANSFER);
        return;
      }

      if (res.orders) {
        setPaidOrders(res.orders.map((order) => createCartOrderFromActiveCart(order, [], accounts)));
      }

      navigate(Routes.CART);
      refetchCart();
      setIsSubmitting(false);
    },
    [orders, laborApplications, buyCart, submitCart, navigate, refetchCart, addSnackbar, accounts]
  );

  const buy = useCallback(() => {
    buyOrSubmit('buy');
  }, [buyOrSubmit]);

  const submit = useCallback(() => {
    buyOrSubmit('submit');
  }, [buyOrSubmit]);

  const clearPaidOrders = useCallback(() => {
    setPaidOrders([]);
  }, []);

  return {
    isSubmitting,
    paidOrders,
    mitchell1Payload,
    buy,
    submit,
    clearPaidOrders,
  };
};
