import { useMemo } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { parseQuery } from 'shared/lib/query';
import type { SignUpQueryParams } from 'utils/url';

export const useSignupQueryParams = () => {
  const { search } = useAppLocation();

  return useMemo(
    () => parseQuery<SignUpQueryParams>(search, { ignoreQueryPrefix: true, decoder: decodeURIComponent }),
    [search]
  );
};
