import { ProductAlias } from '../constants';
import type { AddOn } from '../types';

type GroupedAddOns = {
  tireAddOn: AddOn | undefined;
  commonAddOns: AddOn[];
};

const initialValue: GroupedAddOns = {
  tireAddOn: undefined,
  commonAddOns: [],
};

export const getGroupedAddOns = (addOns: AddOn[]) =>
  addOns.reduce<GroupedAddOns>((accumulator, addOn) => {
    if (addOn.alias === ProductAlias.Tires) {
      return { ...accumulator, tireAddOn: addOn };
    }

    return { ...accumulator, commonAddOns: [...accumulator.commonAddOns, addOn] };
  }, initialValue);
