import { useCallback } from 'react';
import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { setPreferredDeliveryMethod } from 'store/entities/shipment';
import { api as generatedApi } from './UpdatePreferredShippingMethod.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdatePreferredShippingMethod: {
      invalidatesTags: ['Shipment'],
    },
  },
});

const { useUpdatePreferredShippingMethodMutation } = api;

export const useUpdatePreferredShippingMethod = () => {
  const dispatch = useAppDispatch();
  const {
    flags: { newCart },
  } = useLaunchDarkly();
  const [mutate, status] = useUpdatePreferredShippingMethodMutation();

  const updatePreferredShippingMethod = useCallback(
    async (shippingMethodCode: string, supplierId: string) => {
      if (newCart) {
        const updatedCart = dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            draft?.activeCart?.orders?.forEach((order) => {
              if (order.account?.supplier?.id === supplierId) {
                Object.assign(order, { optionsPreferences: { shippingMethodCode } });
              }
            });
          })
        );

        const { data } = await mutate({ shippingMethodCode, supplierId });

        if (!data?.updateCartSupplierPreferredShippingMethod?.optionsPreferences) {
          updatedCart.undo();
        }
      } else {
        dispatch(setPreferredDeliveryMethod({ supplierId: Number(supplierId), mothodCode: shippingMethodCode }));
        mutate({ shippingMethodCode, supplierId });
      }
    },
    [dispatch, mutate, newCart]
  );

  useMutationStatus({ status });

  return [updatePreferredShippingMethod, status] as const;
};
