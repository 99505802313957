import { api } from './GetSubscription.generated';

const { useGetSubscriptionQuery } = api;

export const useGetSubscription = (skip?: boolean) => {
  const { data, ...rest } = useGetSubscriptionQuery(undefined, { skip });

  const subscription = data?.currentUser?.activeMember?.shop?.shopSubscription;

  return {
    ...rest,
    subscription,
  };
};

export const subscriptionApi = api;
