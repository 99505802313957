import { memo, useMemo } from 'react';
import { ProductWarnings } from 'components/ProductWarnings';
import type { OrderItemProductWarning } from 'shared/api';
import type { Warning } from 'types/entityTyped';

type Props = {
  warnings: OrderItemProductWarning[];
};

export const Warnings = memo(({ warnings }: Props) => {
  const convertedWarnings = useMemo<Warning[]>(
    () =>
      warnings.map((warning) => {
        if ('attachment' in warning) {
          return {
            Value: '',
            File: warning.attachment,
            FileIsImage: false,
            Type: '',
          };
        }

        if ('path' in warning) {
          return {
            Value: '',
            File: warning.path,
            FileIsImage: true,
            Type: '',
          };
        }

        return {
          Value: warning.text,
          File: null,
          FileIsImage: false,
          Type: '',
        };
      }),
    [warnings]
  );

  if (convertedWarnings.length === 0) {
    return null;
  }

  return <ProductWarnings warnings={convertedWarnings} color="secondary" variant="bodyText1" />;
});
