import { ProductAlias } from '../constants';
import type { FeatureEX } from '../types';
import type { ShopPermissions } from 'models';

export const getFeaturesEX = ({ laborAllowed, schedulesAllowed, specsAllowed }: ShopPermissions): FeatureEX[] => [
  {
    alias: ProductAlias.Labor,
    isEnabled: laborAllowed,
    name: 'Labor',
    description: 'Super charge your quoting workflow with Labor',
  },
  {
    alias: ProductAlias.Maintenance,
    isEnabled: schedulesAllowed,
    name: 'Maintenance Schedules',
    description: 'Keep your customers vehicle running smooth',
  },
  {
    alias: ProductAlias.VehicleSpecs,
    isEnabled: specsAllowed,
    name: 'Vehicle Specs',
    description: 'Make the right decisions with vehicle & fluid specs',
  },
];
