import { useToggle } from '@partstech/ui/hooks';
import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useSmsConnectionsRouter } from 'hooks/smsConnections';
import { SmsConnectionsPages } from 'utils/url';

export const useBackButton = () => {
  const { isToggle: isShowBackButton, turnOn: showBackButton, turnOff: hideBackButton } = useToggle(false);

  const { generateSmsConnectionsUrl } = useSmsConnectionsRouter();

  const isSignUpPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.SignUp)));
  const isLogInPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.LogIn)));

  useEffect(() => {
    if (isSignUpPage || isLogInPage) {
      return;
    }

    hideBackButton();
  }, [hideBackButton, isSignUpPage, isLogInPage]);

  return { isShowBackButton, showBackButton, hideBackButton };
};
