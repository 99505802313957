import { Box, Button, ButtonGroup } from '@partstech/ui';
import { HookForm, FormCheckBoxField } from '@partstech/ui/forms';
import { StripeLoader, CardForm } from 'integrations/stripe';
import { useAddPaymentCardForm } from './useAddPaymentCardForm';
import type { PaymentCard } from 'entities/paymentCard';

type Props = {
  paymentMethod: PaymentCard | null;
  showCardOptions?: boolean;
  onCancel?: () => void;
  onPaymentChanged?: (card: PaymentCard) => void;
  onSubmit?: (card: PaymentCard) => void;
};

const AddPaymentCard = ({ paymentMethod, showCardOptions, onCancel, ...props }: Props) => {
  const { form, isDisabled, isLoading, submitForm } = useAddPaymentCardForm(props);

  return (
    <HookForm form={form} onSubmit={submitForm}>
      <CardForm />

      {showCardOptions && (
        <Box mt={6}>
          <FormCheckBoxField name="isMovingSubscriptions">Use card for subscription</FormCheckBoxField>
        </Box>
      )}

      <ButtonGroup mt={6} flexDirection="column">
        <Button fullWidth type="submit" isLoading={isLoading} disabled={isDisabled}>
          Add card
        </Button>

        <Button fullWidth variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </HookForm>
  );
};

export const AddPaymentCardForm = (props: Props) => (
  <StripeLoader>
    <AddPaymentCard {...props} />
  </StripeLoader>
);
