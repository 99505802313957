import { useMemo } from 'react';
import { createProductFromData } from 'factories';
import { useFailedProductTracking } from 'hooks/product';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { useGetProductQuery } from 'store/queries/product/GetProduct.generated';
import { convertProduct } from 'utils';
import type { GetProductQueryVariables } from 'store/queries/product/GetProduct.generated';

export const useProduct = (props: GetProductQueryVariables, skip: boolean = false) => {
  const { accounts } = useSupplierAccounts();
  const { currentData, refetch, isFetching, isError, isSuccess } = useGetProductQuery(props, { skip });
  const account = useMemo(() => accounts.find((item) => item.id === props.accountId), [accounts, props.accountId]);

  const product = useMemo(() => {
    const data = currentData?.product
      ? convertProduct(currentData.product, account, props?.vehicleId ?? undefined)
      : null;

    if (!data) {
      return null;
    }

    return {
      ...data,
      supplier: account?.supplier ?? null,
      store: account?.store ?? null,
    };
  }, [account, currentData?.product, props?.vehicleId]);

  const productModel = useMemo(
    () =>
      product
        ? createProductFromData({
            productData: product,
            supplier: account?.supplier ?? null,
            store: account?.store ?? null,
            accountNickname: account?.nickname,
          })
        : null,
    [product, account?.supplier, account?.store, account?.nickname]
  );

  useFailedProductTracking(isError, {
    partnumberid: props?.partNumberId ? props?.partNumberId : '',
    credentialId: props?.accountId ? Number(props.accountId) : undefined,
    linecard: props?.lineCardId ? Number(props.lineCardId) : undefined,
    part_term: props?.partTypeId ? Number(props.partTypeId) : undefined,
    vehicle: props?.vehicleId ? Number(props.vehicleId) : undefined,
    vin: props?.vin,
  });

  return useMemo(
    () => ({
      product,
      productModel,
      isLoading: isFetching,
      isSuccess,
      isFailed: isError,
      refetch,
    }),
    [isError, isFetching, isSuccess, product, productModel, refetch]
  );
};
