import { css } from '@emotion/react';
import { Box, ComboBox, Icon, Scrollbar, TextField, Typography, WithClickAway } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { forwardRef, useCallback, useRef } from 'react';
import { useInputValue } from 'shared/lib/form';
import { useGetCategoriesWithPartTypesQuery } from '../../api';
import { MIN_SEARCH_LENGTH } from '../../constants';
import { useCategoryOptions } from '../../hooks';
import { PartTaxonomySelector } from '../PartTaxonomySelector';
import { ValuePlaceholder } from '../ValuePlaceholder';
import type { Theme } from '@partstech/ui';
import type { PartSubCategory } from 'models';
import type { ChangeEvent } from 'react';

type TaxonomySelectBoxProps = {
  defaultValue: PartSubCategory[];
  onChange: (subCategories: PartSubCategory[]) => void;
  disabledPartTypeIds?: string[];
};

const styles = {
  field: css`
    .text-field-container,
    .text-field-input-container {
      padding-right: 0;
    }
  `,

  contentScroll: css`
    position: relative;
  `,
  dropdownContainer: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  searchInput: (theme: Theme) => css`
    margin-top: 0;
    border-color: transparent;
    border-radius: 0;
    border-bottom-color: ${theme.color.mono17};
  `,
};

export const TaxonomySelectBox = forwardRef<HTMLInputElement, TaxonomySelectBoxProps>(
  ({ defaultValue, disabledPartTypeIds, onChange }: TaxonomySelectBoxProps, ref) => {
    const { value: searchValue, debouncedValue, clearValue, changeValue } = useInputValue('');
    const {
      categories,
      selectOption,
      removeOption,
      selectedSubCategories,
      subCategories,
      partTypes,
      openOption,
      openedSubCategory,
      openedCategory,
      goBack,
      reset,
      isLoaded,
      isFetching,
      findCategoryNameBySubCategory,
    } = useCategoryOptions({
      useQuery: useGetCategoriesWithPartTypesQuery,
      searchValue: searchValue === '' ? '' : debouncedValue,
      defaultValue,
      disabledPartTypeIds,
      onChange,
    });

    const { open, close, isOpen } = useOpen();

    const scrollbar = useRef<HTMLDivElement>(null);

    const handleSearchInputChange = useCallback(
      (input: ChangeEvent<HTMLInputElement>) => {
        changeValue(input);

        if (openedSubCategory || openedCategory) {
          reset();
        }
      },
      [changeValue, reset, openedCategory, openedSubCategory]
    );

    const handleClearClick = useCallback(() => {
      if (!searchValue) {
        close();
      }

      scrollbar.current?.scroll({ top: 0 });
      reset();
    }, [close, reset, searchValue]);

    const handleClose = useCallback(() => {
      clearValue();

      close();
      reset();
    }, [clearValue, reset, close]);

    const isAllOptionsEmpty =
      isLoaded && categories.length === 0 && subCategories.length === 0 && partTypes.length === 0;

    const selectorTitle = openedSubCategory?.name ?? openedCategory?.name;

    return (
      <ComboBox
        label="Select part types"
        labelAlwaysUp={isOpen || selectedSubCategories.length > 0}
        open={isOpen}
        onOpen={open}
        onClose={handleClose}
        css={styles.field}
        fieldContent={<ValuePlaceholder<PartSubCategory> value={selectedSubCategories} onRemove={removeOption} />}
        expandedContent={
          <WithClickAway onClickAway={handleClose} css={styles.dropdownContainer}>
            <TextField
              adornmentBefore={<Icon name="search" size="medium" />}
              value={searchValue}
              onChange={handleSearchInputChange}
              name="taxonomyFilter"
              onClear={handleClearClick}
              css={styles.searchInput}
              isClearable
              ref={ref}
            />
            <Scrollbar ref={scrollbar} data-testid="partTaxonomySelector" css={styles.contentScroll}>
              {isAllOptionsEmpty && (
                <Box position="relative" width="100%" textAlign="center" p={2}>
                  <Typography color="subtleText">Your search yielded no results</Typography>
                </Box>
              )}

              {isFetching && (
                <Box position="relative" width="100%" textAlign="center" p={2}>
                  <Typography color="subtleText">Fetching results...</Typography>
                </Box>
              )}

              {!isAllOptionsEmpty && !isFetching && (
                <PartTaxonomySelector
                  disabledPartTypeIds={disabledPartTypeIds}
                  title={selectorTitle}
                  categories={categories}
                  subCategories={subCategories}
                  showSectionTitles={Boolean(searchValue) && searchValue.length >= MIN_SEARCH_LENGTH}
                  partTypes={partTypes}
                  onOpen={openOption}
                  onGoBack={goBack}
                  value={selectedSubCategories}
                  onChange={selectOption}
                  highlightedText={searchValue.length >= MIN_SEARCH_LENGTH ? searchValue : ''}
                  renderTag={findCategoryNameBySubCategory}
                />
              )}
            </Scrollbar>
          </WithClickAway>
        }
      />
    );
  }
);
