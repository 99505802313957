/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { JobFragmentDoc } from '../../../../shared/api/graphql/fragment/Job.generated';
import { DiagramFragmentDoc } from '../../diagrams/DiagramFragment.generated';
import { TiresFragmentDoc } from '../TiresFragment.generated';
import { VehicleFragmentDoc } from '../VehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetVehicleByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetVehicleByIdQuery = {
  __typename?: 'Query';
  vehicle?: {
    __typename?: 'Vehicle';
    id: string;
    type: Types.VehicleType;
    regions?: Array<Types.VehicleRegion> | null;
    year: number;
    jobs?: Array<{
      __typename?: 'Job';
      id: string;
      name: Types.Scalars['String']['input'];
      isFree: Types.Scalars['Boolean']['input'];
      partTypes: Array<{
        __typename?: 'JobPartType';
        label?: Types.Scalars['String']['input'] | null;
        required: Types.Scalars['Boolean']['input'];
        partType: {
          __typename?: 'PartType';
          id: string;
          name: Types.Scalars['String']['input'];
          application: Types.PartTypeApplication;
        };
        attributes: Array<{
          __typename?: 'PartTypeAttribute';
          name: Types.Scalars['String']['input'];
          values?: Array<Types.Scalars['String']['input']> | null;
          required: Types.Scalars['Boolean']['input'];
        }>;
      }>;
    }> | null;
    diagrams?: Array<{
      __typename?: 'Diagram';
      id: string;
      url?: Types.Scalars['String']['input'] | null;
      name: Types.Scalars['String']['input'];
      brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
      category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
    make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
    model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
    subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
    engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
    tires?: Array<{
      __typename?: 'VehicleTire';
      id: string;
      name: Types.Scalars['String']['input'];
      size: {
        __typename?: 'TireSize';
        width: Types.Scalars['Float']['input'];
        height: Types.Scalars['Float']['input'];
        rim: Types.Scalars['Float']['input'];
        speedRating?: Types.Scalars['String']['input'] | null;
        loadIndex?: number | null;
      };
      rearSize?: {
        __typename?: 'TireSize';
        width: Types.Scalars['Float']['input'];
        height: Types.Scalars['Float']['input'];
        rim: Types.Scalars['Float']['input'];
        speedRating?: Types.Scalars['String']['input'] | null;
        loadIndex?: number | null;
      } | null;
    }> | null;
  } | null;
};

export const GetVehicleByIdDocument = `
    query GetVehicleById($id: ID!) {
  vehicle(vehicleID: $id) {
    ...Vehicle
    ...Tires
    jobs {
      ...Job
    }
    diagrams {
      ...Diagram
    }
  }
}
    ${VehicleFragmentDoc}
${TiresFragmentDoc}
${JobFragmentDoc}
${DiagramFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVehicleById: build.query<GetVehicleByIdQuery, GetVehicleByIdQueryVariables>({
      query: (variables) => ({ document: GetVehicleByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVehicleByIdQuery, useLazyGetVehicleByIdQuery } = injectedRtkApi;
