import { useForm } from '@partstech/ui/forms';
import { useEffect } from 'react';
import { Country } from 'shared/api';
import { denormalizeFormData } from 'shared/lib/form';
import type { CreateShopAndUserWithSmsConnectInput } from '../../api/createShopAndUserWithSmsConnect';

const initialValues: CreateShopAndUserWithSmsConnectInput = {
  address: {
    address1: '',
    address2: null,
    city: '',
    country: Country.Us,
    state: '',
    zipCode: '',
  },
  email: '',
  lastName: '',
  password: '',
  shopName: '',
  shopPhoneNumber: '',
  shopType: null,
  website: null,
  firstName: '',
};

export type SignUpFormArgs = {
  defaultValues?: CreateShopAndUserWithSmsConnectInput;
  onSubmit: (payload: CreateShopAndUserWithSmsConnectInput) => void | Promise<void>;
};

export const useSignUpForm = ({ defaultValues = initialValues, onSubmit }: SignUpFormArgs) => {
  const form = useForm<CreateShopAndUserWithSmsConnectInput>({ defaultValues: denormalizeFormData(defaultValues) });

  const {
    reset,
    formState: { isValid, isSubmitting },
  } = form;

  const isLoading = isSubmitting;

  const isDisabled = !isValid || isLoading;

  useEffect(() => {
    reset(denormalizeFormData(defaultValues));
  }, [defaultValues, reset]);

  return { form, submitForm: onSubmit, isLoading, isDisabled };
};
