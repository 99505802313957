import { useCallback } from 'react';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { getShipments } from 'store/actions/cart';
import { api as savedCartApi } from '../../queries/GetSavedCart.generated';
import { api as generatedApi } from './DeleteCartOrder.generated';
import type { DeleteCartOrderInput, Exact } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteCartOrder: {
      invalidatesTags: (data) => {
        const res = data?.deleteCartOrder;
        if (res && 'cart' in res && res.cart?.active) {
          return ['SavedCart', 'ActiveCart'];
        }
        return ['SavedCart'];
      },
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          savedCartApi.util.updateQueryData('GetSavedCart', { id: params.input.cartId }, (draft) => {
            if (draft.cart) {
              return {
                cart: {
                  ...draft.cart,
                  orders: draft.cart.orders?.filter((order) => order.id !== params.input.orderId),
                },
              };
            }
            return draft;
          })
        );
      },
    },
  },
});

const { useDeleteCartOrderMutation } = api;

export const useDeleteCartOrder = () => {
  const dispatch = useAppDispatch();
  const {
    flags: { newCart },
  } = useLaunchDarkly();

  const [deleteCartOrder, status] = useDeleteCartOrderMutation();

  useMutationStatus({ status });

  const triger = useCallback(
    async (args: Exact<{ input: DeleteCartOrderInput }> & { isActive: boolean }) => {
      await deleteCartOrder({ input: args.input });

      if (!newCart && args.isActive) {
        dispatch(getShipments({ id: Number(args.input.cartId) }));
      }
    },
    [deleteCartOrder, dispatch, newCart]
  );

  return [triger, status] as const;
};
