import { Box, Button } from '@partstech/ui';
import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import type { BoxProps, IconProps } from '@partstech/ui';

type Props = {
  title: string;
  icon?: IconProps['name'];
  url?: string | null;
} & BoxProps;

export const GoBackButton = ({ title, url, icon = 'arrow_back', ...boxProps }: Props) => {
  const navigate = useAppNavigate();

  const handleArrowBackClick = useCallback(() => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  }, [navigate, url]);

  return (
    <Box {...boxProps}>
      <Button variant="text" leadingIcon={icon} onClick={handleArrowBackClick} data-testid="goBackButton">
        {title}
      </Button>
    </Box>
  );
};
