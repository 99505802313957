import { MAX_END_PRICE_MATRIX } from 'constant';
import type { RetailPriceMatrix } from 'types/matrix';

export const isEmptyMatrix = (matrix: RetailPriceMatrix) => {
  if (matrix.items.length === 1) {
    const firstItem = matrix.items[0];

    return (
      firstItem?.startPrice === 0 &&
      firstItem.endPrice === MAX_END_PRICE_MATRIX &&
      firstItem.price === 1 &&
      !firstItem.fixed
    );
  }

  return matrix.items.length === 0;
};
