import { css } from '@emotion/react';
import { Box, Button, Icon, IconButton, TextField, useMedia } from '@partstech/ui';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { SearchInputSize } from '../../../types';
import { useInputFocusEvents } from './useInputFocusEvents';
import type { TextFieldProps, Theme } from '@partstech/ui';

const styles = {
  root: (theme: Theme) => css`
    position: relative;
    height: ${theme.sizing(10)};
    width: 100%;

    ${theme.breakpoints.up('md')} {
      height: ${theme.sizing(14)};
    }
  `,
  textField:
    ({ isAbsolute, size }: { isAbsolute: boolean; size: SearchInputSize }) =>
    (theme: Theme) => css`
      box-sizing: border-box;
      height: ${theme.sizing(10)};

      ${theme.breakpoints.up('md')} {
        height: ${theme.sizing(14)};
      }

      ${theme.breakpoints.down('lg')} {
        input {
          padding: ${theme.sizing(0, 2)};
        }
      }

      margin: 0;
      border-color: transparent;
      border-radius: ${theme.sizing(2)};
      background-color: ${theme.color.mono2};
      display: flex;
      align-items: center;
      max-height: 100%;

      ${size === SearchInputSize.DENSE ? `padding: ${theme.sizing(0, 0, 0, 0)};` : `padding: ${theme.sizing(0, 2)};`}

      ${isAbsolute &&
      `
        @media (min-width: ${theme.breakpoints.md}px) and (max-width: ${theme.breakpoints.xl - 1}px) {
          position: absolute;
          left: 0;
          right: -276px;
          z-index: 2;
          top:0;
        }
      `}

      :focus-within {
        border-color: ${theme.color.primary};
      }

      & input:focus:not(:placeholder-shown) {
        font-weight: bold;
      }

      .text-field-input-container {
        align-items: center;
      }
    `,
  adornmentAfter: (theme: Theme) => css`
    display: flex;
    align-items: center;
    padding: ${theme.sizing(0, 1)};

    button.ui-button {
      height: auto;
      border-radius: ${theme.sizing(1)};
      box-sizing: border-box;

      &.close-button,
      &.tune-button {
        height: ${theme.sizing(6)};
        width: ${theme.sizing(6)};
        margin-right: ${theme.sizing(0)};

        ${theme.breakpoints.up('md')} {
          height: ${theme.sizing(8)};
          width: ${theme.sizing(8)};
        }
      }

      &.tune-button {
        border: 1px solid ${theme.color.mono17};
      }

      &.search-button {
        max-height: ${theme.sizing(12.5)};
        min-width: ${theme.sizing(25)};
        height: ${theme.sizing(10)};
        margin: 0 7px 0 0;
        font-size: 16px;
        padding: ${theme.sizing(0, 6)};
      }
    }
  `,
};

export type SearchInputProps = Omit<TextFieldProps, 'onSubmit' | 'onChange' | 'size'> & {
  onChange?: (value: string) => void;
  onClear?: () => void;
  onSubmit?: (value: string) => void;
  size?: SearchInputSize;
  value?: string;
  extendsOnFocus?: boolean;
};

type SearchInputRef = {
  blur: () => void;
  focus: () => void;
};

export const SearchInput = forwardRef<SearchInputRef, SearchInputProps>(
  (
    {
      adornmentAfter,
      adornmentBefore,
      value,
      onBlur,
      onClear,
      onChange,
      onFocus,
      onSubmit,
      placeholder,
      extendsOnFocus,
      size = SearchInputSize.DEFAULT,
      ...props
    },
    ref
  ) => {
    const input = useRef<HTMLInputElement | null>(null);
    const { isMobile } = useMedia();

    const { isFocused, handleFocus, handleBlur } = useInputFocusEvents({ onFocus, onBlur });
    useImperativeHandle(
      ref,
      () => ({
        getBoundingClientRect: () => input.current?.getBoundingClientRect(),
        blur: () => {
          input.current?.blur();
          handleBlur({} as React.FocusEvent<HTMLInputElement>);
        },
        focus: () => input.current?.focus(),
      }),
      [handleBlur]
    );

    const submit = useCallback(() => {
      if (!value || value.length === 0) {
        input.current?.focus();
        return;
      }

      onSubmit && onSubmit(value);
    }, [onSubmit, value]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          submit();
        }
      },
      [submit]
    );

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e.target.value);
      },
      [onChange]
    );

    return (
      <Box css={styles.root}>
        <TextField
          ref={input}
          name="search"
          adornmentBefore={adornmentBefore}
          adornmentAfter={
            <Box css={styles.adornmentAfter} gap={4}>
              {value && (size === SearchInputSize.DEFAULT || isFocused) && (
                <IconButton variant="light" className="close-button" onClick={onClear}>
                  <Icon name="close" />
                </IconButton>
              )}

              {!isMobile || (isMobile && !isFocused) ? adornmentAfter : null}

              {size === SearchInputSize.DEFAULT && (
                <Button className="search-button" onClick={submit}>
                  Search
                </Button>
              )}
            </Box>
          }
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={value}
          css={styles.textField({ size, isAbsolute: Boolean(isFocused && extendsOnFocus) })}
          autoComplete="off"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
      </Box>
    );
  }
);
