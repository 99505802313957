import { useMemo } from 'react';
import { createPartTypesFromQuery } from 'factories';
import { useGetPartTypesWithSubCategory } from 'store/queries/partTypes/useGetPartTypesWithSubCategory';

type Props = {
  search?: string;
  ids?: string[];
  categoryID?: string;
  alias?: string;
};

type Params = {
  skip?: boolean;
};

export const usePartTypesWithSubCategory = (
  { ids, search, categoryID, alias }: Props,
  { skip = false }: Params = {}
) => {
  const { partTypes: partTypesData, ...props } = useGetPartTypesWithSubCategory({ ids, search, categoryID }, { skip });

  const partTypes = useMemo(() => createPartTypesFromQuery(partTypesData, alias), [alias, partTypesData]);

  return {
    partTypes,
    ...props,
  };
};
