import { createPartTypesFromQuery, createSubCategoryFromQuery } from 'factories';
import type { PartCategory, PartSubCategory } from 'models';

export const getAllSubCategoriesFromCategories = (categories: PartCategory[]) =>
  categories.reduce<PartSubCategory[]>(
    (result, category) => [
      ...result,
      ...(category.subCategories?.map((subCategory) => {
        const partTypes = createPartTypesFromQuery(subCategory.partTypes).map((partType) =>
          partType.setSubCategoryId(subCategory.id)
        );

        return createSubCategoryFromQuery(subCategory, category.id, partTypes);
      }) ?? []),
    ],
    []
  );
