import { useCallback } from 'react';

type Args = {
  onClick?: () => void;
};

export const useUserpilotWidget = ({ onClick }: Args) => {
  const openWidget = useCallback(() => {
    window.userpilot && window.userpilot.trigger && window.userpilot.trigger('Sr3G26T');

    onClick?.();
  }, [onClick]);

  return { openWidget };
};
