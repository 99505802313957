import { useCallback, useMemo } from 'react';
import { PartSubCategory } from 'models';
import { useSubCategory } from 'store/queries/categories/useSubCategory';
import { checkPartTypeDisabled } from '../../../../utils';
import { Option } from '.';
import type { OptionProps } from '.';

type Props = Omit<OptionProps<PartSubCategory>, 'secondaryText' | 'isSelected'> & {
  disabledPartTypeIds: string[];
  renderSecondaryText: (option: PartSubCategory) => string;
  renderTag: (option: PartSubCategory) => string;
  isSelected: (options: PartSubCategory) => boolean;
};

export const SubCategoryOption = ({
  onSelect,
  disabledPartTypeIds,
  option: subCategoryOption,
  renderSecondaryText,
  renderTag,
  isSelected,
  ...props
}: Props) => {
  const { subCategory } = useSubCategory({
    categoryId: subCategoryOption.categoryId,
    subCategoryId: subCategoryOption.id,
  });

  const option = useMemo(() => {
    subCategory?.partTypes.forEach((partType) =>
      partType.setIsDisabled(checkPartTypeDisabled(partType, disabledPartTypeIds))
    );
    return subCategory?.setIsDisabled() || null;
  }, [disabledPartTypeIds, subCategory]);

  const handleSelect = useCallback(
    (optionToSelect: PartSubCategory) => {
      if (onSelect) {
        onSelect(
          new PartSubCategory(
            optionToSelect.id,
            optionToSelect.name,
            optionToSelect.categoryId,
            optionToSelect.partTypes.filter((partType) => !partType.isDisabled)
          )
        );
      }
    },
    [onSelect]
  );

  if (!option) {
    return null;
  }

  return (
    <Option
      option={option}
      secondaryText={renderSecondaryText(option)}
      tag={renderTag(option)}
      onSelect={handleSelect}
      isSelected={isSelected(option)}
      {...props}
    />
  );
};
