import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { Routes } from 'constant';
import { useGetManagementSystems } from 'features/shop/api/managementSystems';
import { parseQuery } from 'shared/lib/query';
import type { EntityManagementSystem } from 'features/shop/api/managementSystems';
import type { SignUpQueryParams } from 'utils/url';

type UseSelectedSmsFromQuery = {
  selectedSms?: string;
  shopManagementSystem?: EntityManagementSystem;
  isFetching?: boolean;
};

export const useSelectedSmsFromQuery = (): UseSelectedSmsFromQuery => {
  const { search } = useAppLocation();

  const isSignupPage = useMatch(Routes.SIGN_UP);
  const isGoogleSignup = useMatch(Routes.SIGN_UP_GOOGLE);

  const query = useMemo(
    () => (isGoogleSignup || isSignupPage ? (parseQuery(search) as SignUpQueryParams) : {}),
    [isGoogleSignup, isSignupPage, search]
  );

  const locationState = query?.state ? JSON.parse(query.state) : null;
  const selectedSmsFromState = locationState?.selectedSms;

  const { managementSystems, isFetching } = useGetManagementSystems();

  const shopManagementSystem = useMemo(
    () => managementSystems.find((sms) => sms.name === query?.selectedSms),
    [managementSystems, query?.selectedSms]
  );

  return useMemo(
    () => ({ selectedSms: query?.selectedSms ?? selectedSmsFromState, shopManagementSystem, isFetching }),
    [isFetching, query?.selectedSms, selectedSmsFromState, shopManagementSystem]
  );
};
