import { css } from '@emotion/react';
import { Typography, Box, Container, Logo } from '@partstech/ui';
import { Outlet } from 'app/AppRouter/Outlet';
import { useSignupQueryParams } from 'hooks/signup';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { useCreateShopContext } from '../CreateShopContext/CreateShopContext';
import { CreateShopStepper } from './CreateShopStepper';
import type { Theme } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const styles = {
  header: (theme: Theme) => css`
    background: ${`linear-gradient(${theme.color.secondary}, ${theme.color.secondaryLight})`};
  `,
};

const Layout = ({ children }: PropsWithChildren) => {
  const { firstName } = useGetOnBoard();

  const { isHiddenSignUpStepper, isHiddenSignUpHeader } = useCreateShopContext();

  const { token } = useSignupQueryParams();

  return (
    <Box position="relative" data-testid="createShop">
      {!isHiddenSignUpHeader && (
        <Box display="flex" justifyContent="center" width="100%" height={10} py={2} css={styles.header}>
          <Logo variant="white" />
        </Box>
      )}

      {token ? (
        <Outlet>{children}</Outlet>
      ) : (
        <Container size="small" disableGutters>
          {isHiddenSignUpStepper ? (
            <Box mt={10} mb={1} textAlign="center">
              <Typography>Welcome {firstName}!</Typography>
            </Box>
          ) : (
            <CreateShopStepper />
          )}

          <Outlet>{children}</Outlet>
        </Container>
      )}
    </Box>
  );
};

export const CreateShopLayoutOld = ({ children }: PropsWithChildren) => <Layout>{children}</Layout>;
