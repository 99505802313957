import { PartTypeApplication } from 'shared/api';
import { PartCategory } from '../../models/PartCategory';
import { PartType } from '../../models/PartType';
import { PartTypeAlias } from '../../models/PartTypeAlias';
import { UniversalPartType } from '../../models/UniversalPartType';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

const createPartType = (data: FullPartTypeFragment, alias?: string) => {
  if (data.application !== PartTypeApplication.Fitted) {
    return new UniversalPartType(data);
  }

  return alias ? new PartTypeAlias(data, alias) : new PartType(data);
};

export const createPartTypeFromQuery = (data: FullPartTypeFragment, alias?: string) => {
  const partType = createPartType(data, alias);

  if (data.category) {
    const { id, name } = data.category;

    partType.setCategory(new PartCategory(id, name, []));
  }

  if (data.subCategory) {
    partType.setSubCategoryId(data.subCategory.id);
  }

  return partType;
};
