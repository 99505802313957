import { useMemo } from 'react';
import { useGetSmsRegistrationData } from 'features/smsConnections';
import { useSmsConnectionsRouter } from 'hooks/smsConnections';
import { Country } from 'shared/api';
import { SmsConnectionTypes } from 'utils/url';
import { SmsConnectionsContextProvider } from './SmsConnectionsContext';
import { useBackButton } from './useBackButton';
import { useShopAndUserData } from './useShopAndUserData';
import { useVideoUrl } from './useVideoUrl';
import type { PropsWithChildren } from 'react';

const smsNames: Record<SmsConnectionTypes, string> = {
  [SmsConnectionTypes.ShopMonkey]: 'Shopmonkey',
};

export const SmsConnectionsContext = ({ children }: PropsWithChildren) => {
  const {
    pathParams: { hash, sms },
  } = useSmsConnectionsRouter();

  const {
    smsRegistrationData,
    isLoading: isLoadingSmsRegistrationData,
    isError: isErrorSmsRegistrationData,
    refetch: refetchSmsRegistrationData,
  } = useGetSmsRegistrationData({ hash });

  const defaultValues = useMemo(() => {
    if (!smsRegistrationData) {
      return undefined;
    }

    return {
      email: smsRegistrationData.email ?? '',
      firstName: smsRegistrationData.firstName ?? '',
      lastName: smsRegistrationData.lastName ?? '',
      password: '',
      shopName: smsRegistrationData.shopName ?? '',
      shopPhoneNumber: smsRegistrationData.shopPhoneNumber ?? '',
      shopType: smsRegistrationData.shopType ?? '',
      address: {
        address1: smsRegistrationData.address1 ?? '',
        address2: smsRegistrationData.address2,
        city: smsRegistrationData.city ?? '',
        country: smsRegistrationData.country ?? Country.Us,
        state: smsRegistrationData.state ?? '',
        zipCode: smsRegistrationData.zipCode ?? '',
      },
      website: smsRegistrationData.website ?? '',
    };
  }, [smsRegistrationData]);

  const [shopAndUserData, setShopAndUserData] = useShopAndUserData({ defaultValues });
  const videoUrl = useVideoUrl({ defaultUrl: smsRegistrationData?.video });
  const { hideBackButton, isShowBackButton, showBackButton } = useBackButton();

  const value = useMemo(
    () => ({
      currentSms: smsNames[sms],
      shopAndUserData,
      setShopAndUserData,
      videoUrl,

      smsRegistrationData,
      isLoadingSmsRegistrationData,
      isErrorSmsRegistrationData,
      refetchSmsRegistrationData: async () => {
        await refetchSmsRegistrationData();
      },

      isShowBackButton,
      showBackButton,
      hideBackButton,
    }),
    [
      sms,
      shopAndUserData,
      setShopAndUserData,
      videoUrl,
      smsRegistrationData,
      isLoadingSmsRegistrationData,
      isErrorSmsRegistrationData,
      isShowBackButton,
      showBackButton,
      hideBackButton,
      refetchSmsRegistrationData,
    ]
  );

  return <SmsConnectionsContextProvider value={value}>{children}</SmsConnectionsContextProvider>;
};
