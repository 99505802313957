import { isEmpty } from '@partstech/ui/utils';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { memo, useMemo } from 'react';
import { launchDarklyKey } from 'app/config';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { LaunchDarklyContextProvider } from './LaunchDarklyContext';
import { useGetLaunchDarklyFlags } from './useGetLaunchDarklyFlags';
import { useLaunchDarklySessionClose } from './useLaunchDarklySessionClose';
import { useSendLaunchDarklyEvent } from './useSendLaunchDarklyEvent';
import type { PropsWithChildren } from 'react';

const LD_CLIENT_ID = '6559f1f194a34a13154f0184';

const clientSideID = launchDarklyKey === 'ld-key' ? LD_CLIENT_ID : launchDarklyKey;

const LDContext = memo(({ children }: PropsWithChildren) => {
  const flags = useGetLaunchDarklyFlags();
  const sendEvent = useSendLaunchDarklyEvent();

  const launchDarklyContext = useMemo(
    () => ({
      flags,
      sendEvent,
      isLoaded: !isEmpty(flags),
    }),
    [flags, sendEvent]
  );

  useLaunchDarklySessionClose();

  return <LaunchDarklyContextProvider value={launchDarklyContext}>{children}</LaunchDarklyContextProvider>;
});

export const LaunchDarklyProvider = memo(({ children }: PropsWithChildren) => {
  const { username, shop, isSuccess } = useGetCurrentUser();

  const context = useMemo(() => {
    if (!isSuccess) {
      return undefined;
    }

    return isEmpty(shop?.id)
      ? {
          kind: 'user',
          anonymous: true,
          key: 'anon',
        }
      : {
          kind: 'multi',
          user: {
            kind: 'user',
            key: username,
          },
          shop: {
            kind: 'shop',
            key: shop?.id ?? '',
            name: shop?.name ?? '',
            demo: shop?.attributes?.demo,
            suppliers: shop?.attributes?.suppliers,
            shippingZipCode: shop?.attributes?.defaultShippingZipCode ?? null,
            totalPaidOrders: shop?.attributes?.totalPaidOrders,
            usersCount: shop?.attributes?.usersCount,
          },
        };
  }, [
    isSuccess,
    shop?.id,
    shop?.name,
    shop?.attributes?.demo,
    shop?.attributes?.suppliers,
    shop?.attributes?.defaultShippingZipCode,
    shop?.attributes?.totalPaidOrders,
    shop?.attributes?.usersCount,
    username,
  ]);

  const options = useMemo(() => ({ sendEventsOnlyForVariation: true }), []);

  return (
    <LDProvider
      key={shop?.id ?? 'key'}
      clientSideID={clientSideID}
      context={context}
      options={options}
      deferInitialization
    >
      <LDContext>{children}</LDContext>
    </LDProvider>
  );
});
