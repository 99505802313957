/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetCategoriesWithPartTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCategoriesWithPartTypesQuery = {
  __typename?: 'Query';
  categories?: Array<{
    __typename?: 'PartCategory';
    id: string;
    name: Types.Scalars['String']['input'];
    subCategories?: Array<{
      __typename?: 'PartSubCategory';
      id: string;
      name: Types.Scalars['String']['input'];
      partTypes?: Array<{
        __typename?: 'PartType';
        id: string;
        name: Types.Scalars['String']['input'];
        application: Types.PartTypeApplication;
      }> | null;
    }> | null;
  }> | null;
};

export const GetCategoriesWithPartTypesDocument = `
    query GetCategoriesWithPartTypes {
  categories {
    id
    name
    subCategories {
      id
      name
      partTypes {
        id
        name
        application
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCategoriesWithPartTypes: build.query<
      GetCategoriesWithPartTypesQuery,
      GetCategoriesWithPartTypesQueryVariables | void
    >({
      query: (variables) => ({ document: GetCategoriesWithPartTypesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCategoriesWithPartTypesQuery, useLazyGetCategoriesWithPartTypesQuery } = injectedRtkApi;
