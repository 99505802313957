/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type CreateLaborRateMutationVariables = Types.Exact<{
  input: Types.CreateLaborRateInput;
}>;

export type CreateLaborRateMutation = {
  __typename?: 'Mutation';
  createLaborRate?: {
    __typename?: 'CreateLaborRatePayload';
    laborRate?: {
      __typename?: 'LaborRate';
      id: string;
      isDefault: Types.Scalars['Boolean']['input'];
      name: Types.Scalars['String']['input'];
      rate: Types.Scalars['BigDecimal']['input'];
    } | null;
  } | null;
};

export const CreateLaborRateDocument = `
    mutation CreateLaborRate($input: CreateLaborRateInput!) {
  createLaborRate(input: $input) {
    laborRate {
      id
      isDefault
      name
      rate
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateLaborRate: build.mutation<CreateLaborRateMutation, CreateLaborRateMutationVariables>({
      query: (variables) => ({ document: CreateLaborRateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateLaborRateMutation } = injectedRtkApi;
