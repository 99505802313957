import type { AvailabilityLine } from 'models';
import type { StockMessage } from 'types/product';

export class Quote {
  lines: AvailabilityLine[];

  status: 'IN_STOCK' | 'SPECIAL_ORDER' | 'BACK_ORDER' | 'OUT_OF_STOCK';

  action: 'ADD_TO_CART' | 'CHECK_AVAILABILITY' | 'CALL_AVAILABILITY' | 'CALL_SUPPLIER' | 'HIDDEN';

  stockMessage?: StockMessage[] | null = null;

  constructor({ action, status, lines, stockMessage }: Pick<Quote, 'action' | 'status' | 'lines' | 'stockMessage'>) {
    this.action = action;
    this.status = status;
    this.lines = lines;
    this.stockMessage = stockMessage;
  }

  getAvailableQuantity() {
    return this.lines.reduce((sum, line) => sum + line.qty.value, 0);
  }

  hasLines() {
    return this.lines.length > 0;
  }

  getMainLine() {
    return this.lines.find((line) => line.isMain());
  }

  getNetworkLine() {
    return this.lines.find((line) => line.isNetwork());
  }

  hasLineInNetwork() {
    return this.lines.some((line) => line.isNetwork());
  }

  isMainEmptyAndNetworkInStock() {
    return (
      (this.hasLines() && !this.getMainLine()?.isMainInStock() && this.getNetworkLine()?.isNetworkInStock()) || false
    );
  }

  isAvailableOrder() {
    return false;
  }

  isBackOrder() {
    return this.status === 'BACK_ORDER';
  }

  isInStock() {
    return this.status === 'IN_STOCK';
  }

  isInNetwork() {
    return this.isInStock() && this.isMainEmptyAndNetworkInStock();
  }

  isOutOfStock() {
    return this.status === 'OUT_OF_STOCK';
  }

  isSpecialOrder() {
    return this.status === 'SPECIAL_ORDER';
  }

  isAddToCart() {
    return this.action === 'ADD_TO_CART';
  }

  isCallAvailability() {
    return this.action === 'CALL_AVAILABILITY';
  }

  isCallSupplier() {
    return this.action === 'CALL_SUPPLIER';
  }
}
