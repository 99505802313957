import { usePrevious } from '@partstech/ui/hooks';
import { useEffect, useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { applicationRedirects } from 'app/AppRouter/applicationRedirects';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { Routes } from 'constant';
import { generateUrlFromLocation, getSignUpUrl, getSubscriptionUrl } from 'utils/url';
import { useLastUrl } from '../localStorage/useLastUrl';

const excludedRoutes = [
  Routes.LOGIN,
  Routes.LOGIN_BY_INVITATION,
  Routes.RESET_PASSWORD_REQUEST,
  Routes.CART,
  Routes.EDIT_LABOR,
  Routes.LOGIN_GOOGLE,
  Routes.SIGN_UP_GOOGLE,
  Routes.SIGN_UP,
  Routes.SIGN_UP_INVITATION,
  Routes.SERVICE_GUIDES,
  Routes.SAVED_QUOTE,
  Routes.SHOP,
  Routes.DIAGRAM,
  Routes.SMS_CONNECTIONS,
  getSignUpUrl('invalid-token'),
];

const excludedSearchParams = [getSubscriptionUrl()];

const isRouteExcluded = (pathname: string = '') => excludedRoutes.some((path) => matchPath({ path }, pathname));
const isSearchParamIncluded = (pathname: string) => !excludedSearchParams.some((path) => matchPath({ path }, pathname));

export const useSaveLastUrl = () => {
  const location = useAppLocation();
  const prevLocation = usePrevious(location);

  const [_, setLastUrl] = useLastUrl();

  const currentUrl = generateUrlFromLocation(location);

  const prevUrl = useMemo(() => {
    if (!prevLocation) {
      return null;
    }

    return generateUrlFromLocation({
      ...prevLocation,
      search: isSearchParamIncluded(prevLocation.pathname) ? prevLocation.search : '',
    });
  }, [prevLocation]);

  useEffect(() => {
    if (!prevUrl || prevUrl === currentUrl || isRouteExcluded(prevLocation?.pathname)) {
      return;
    }

    const lastUrlRedirect = applicationRedirects.find(({ from }) => from === prevUrl);

    setLastUrl(lastUrlRedirect?.to ?? prevUrl);
  }, [currentUrl, prevLocation?.pathname, prevUrl, setLastUrl]);
};
