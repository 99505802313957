import { css } from '@emotion/react';
import { Box, Link, Tooltip, Typography, typographyStyles } from '@partstech/ui';
import { memo, useCallback } from 'react';
import warningImage from 'images/icons/warning.svg';
import type { Theme, LinkProps } from '@partstech/ui';
import type { MouseEvent } from 'react';
import type { Warning as WarningType } from 'types/entityTyped';

const styles = {
  textNew: (theme: Theme) => css`
    ${typographyStyles.caption('inherit')(theme)};
  `,

  image: (theme: Theme) => css`
    max-width: 100%;
    width: 100%;

    ${theme.breakpoints.down('md')} {
      max-width: ${theme.sizing(80)};
    }
  `,
};

type Props = {
  warning: WarningType;
  color: LinkProps['color'];
  variant: LinkProps['variant'];
};

export const Warning = memo(({ warning: { Value, File, FileIsImage }, color, variant }: Props) => {
  const isDocument = Boolean(!FileIsImage && !Value && File);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      !isDocument && e.preventDefault();
    },
    [isDocument]
  );

  return (
    <Tooltip
      tooltip={
        <Box
          width="100%"
          maxWidth={155}
          display="flex"
          alignItems="center"
          alignContent="center"
          py={2}
          data-testid="warningTooltip"
        >
          {!FileIsImage && Value && (
            <>
              <Box mr={5} ml={2}>
                <img css={styles.image} src={warningImage} alt="" data-testid="warningImage" />
              </Box>

              <div>
                <Typography color="white" variant="caption" bold>
                  WARNING: Proposition 65 Information:{' '}
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: Value }} css={styles.textNew} />
              </div>
            </>
          )}

          {FileIsImage && !Value && <img css={styles.image} src={File || ''} alt="" data-testid="warningImage" />}

          {FileIsImage && Value && (
            <div>
              <div>
                <Typography color="white" variant="caption" bold>
                  WARNING: Proposition 65 Information{' '}
                </Typography>

                <Box mb={2}>
                  <Typography variant="caption">With text and image.</Typography>
                </Box>
              </div>

              <img css={styles.image} src={File || ''} alt="" data-testid="warningImage" />
            </div>
          )}

          {isDocument && 'Click to see document'}
        </Box>
      }
      autoWidth
    >
      <Link
        to={(isDocument && File) || '#'}
        target={(isDocument && '_blank') || '_self'}
        variant={variant}
        color={color}
        onClick={handleClick}
        isExternal
        data-testid="warningDocumentLink"
      >
        WARNING: Proposition 65 Information
      </Link>
    </Tooltip>
  );
});
