import { Table, TableCol, TableRow, Typography } from '@partstech/ui';
import { getShortPlanName } from '../../../../../utils';
import { FeaturesEX } from './FeaturesEX';
import { FeatureTableRow } from './FeatureTableRow';
import { styles } from './FeatureTableRow/FeatureTableRow.styles';
import type { PaidFeatureAlias } from '../../../../../constants';
import type { PlanFeature } from '../../../../../types';

type Props = {
  features: PlanFeature[];
  highlightFeature: PaidFeatureAlias | null;
  planName: string;
};

export const FeaturesTable = ({ features, highlightFeature, planName }: Props) => (
  <Table variant="compact">
    <TableRow css={[styles.tableRow(), styles.headerRow]}>
      <TableCol>
        <Typography variant="subtitle2">PartsTech</Typography>
      </TableCol>

      <TableCol>
        <Typography variant="subtitle2">{getShortPlanName(planName).toUpperCase()}</Typography>
      </TableCol>
    </TableRow>

    {features.map((feature, index) => (
      <FeatureTableRow key={index} feature={feature} isHighlighted={feature.alias === highlightFeature} />
    ))}

    <FeaturesEX />
  </Table>
);
