/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCategoriesQuery = {
  __typename?: 'Query';
  categories?: Array<{
    __typename?: 'PartCategory';
    id: string;
    name: Types.Scalars['String']['input'];
    subCategories?: Array<{
      __typename?: 'PartSubCategory';
      id: string;
      name: Types.Scalars['String']['input'];
    }> | null;
  }> | null;
};

export const GetCategoriesDocument = `
    query GetCategories {
  categories {
    id
    name
    subCategories {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCategories: build.query<GetCategoriesQuery, GetCategoriesQueryVariables | void>({
      query: (variables) => ({ document: GetCategoriesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCategoriesQuery, useLazyGetCategoriesQuery } = injectedRtkApi;
