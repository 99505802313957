import { Checkbox } from '@partstech/ui';
import React from 'react';
import type { FilterOption } from 'types/search';

type Props = {
  disabled?: boolean;
  name: string;
  option: FilterOption;
  checked: boolean;
  adornmentAfter?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Option = ({ disabled, name, option, checked, adornmentAfter, onChange }: Props) => (
  <Checkbox
    data-testid="checkbox"
    checked={checked}
    id={String(option.value)}
    key={option.value}
    name={name}
    title={option.name}
    onChange={onChange}
    value={option.value}
    disabled={disabled}
  >
    {option.name} {typeof option.count === 'number' && `(${option.count})`}
    {adornmentAfter}
  </Checkbox>
);
