import { Box, Scrollbar } from '@partstech/ui';
import { useCallback, useState } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { DynamicAttributeConfigurator } from 'features/dynamicAttribute';
import type { UniversalPartType } from 'models';

type Props = {
  defaultPartType?: UniversalPartType;
  hasVehicle: boolean;
  onSelectVehicleClick?: () => void;
  options: UniversalPartType[];
  onChange: (searchEntry: UniversalPartType) => void;
};

export const FluidsAndChemicals = ({ defaultPartType, hasVehicle, onSelectVehicleClick, options, onChange }: Props) => {
  const [selectedPartType, setSelectedPartType] = useState<UniversalPartType | null>(defaultPartType ?? null);

  const handleSelectPartType = useCallback(
    (partType: UniversalPartType) => {
      if (partType.shouldPickAttributes(hasVehicle)) {
        setSelectedPartType(partType);

        return;
      }

      onChange(partType);
    },
    [hasVehicle, onChange]
  );

  const handleReset = useCallback(() => {
    setSelectedPartType(null);
  }, []);

  const handleSelectVehicleClick = useCallback(() => {
    onSelectVehicleClick?.();
  }, [onSelectVehicleClick]);

  return (
    <Box width="100%">
      {!selectedPartType && (
        <Scrollbar>
          <OptionsByColumns options={options} onChange={handleSelectPartType} />
        </Scrollbar>
      )}

      {selectedPartType && (
        <DynamicAttributeConfigurator
          partType={selectedPartType}
          onSelectVehicleClick={handleSelectVehicleClick}
          isSelectVehicleShow={!hasVehicle}
          onChange={onChange}
          onReset={handleReset}
        />
      )}
    </Box>
  );
};
