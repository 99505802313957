import { isEmpty, keys, uniq } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { getSearchUrl } from 'utils/url';
import { useNonAppAttributes } from '../search/useNonAppAttributes';
import { useSearchParams } from '../search/useSearchParams';
import type { Product } from 'models';

export const usePrefillSearchAttributes = () => {
  const {
    flags: { fluidsChemicalsTab },
  } = useLaunchDarkly();

  const navigate = useAppNavigate();
  const { searchParams, valuableParams, selectedSupplierId } = useSearchParams();

  const { attributes } = useNonAppAttributes();

  const prefillSearchAttributes = useCallback(
    (products: Product[]) => {
      if (!isEmpty(valuableParams.attributes) && valuableParams.vehicle) {
        return;
      }

      const attrs: Record<string, string[]> = {};

      products
        .filter((product) => product.hasVehicleFitment)
        .forEach(({ attributes: partAttributes }) => {
          keys(attributes).forEach((name) => {
            const partAttribute = partAttributes.find((attribute) => attribute.name === name);

            attrs[name] = uniq([...(attrs[name] ?? []), ...(partAttribute ? partAttribute.value : ['Not Specified'])]);
          });
        });

      if (!isEmpty(attrs)) {
        navigate(
          getSearchUrl({
            ...valuableParams,
            attributes: attrs,
            selected_distributor: selectedSupplierId ?? undefined,
            fit: fluidsChemicalsTab ? undefined : ['1'],
            dealers: searchParams.dealers,
          })
        );
      }
    },
    [attributes, fluidsChemicalsTab, navigate, searchParams.dealers, selectedSupplierId, valuableParams]
  );

  return {
    prefillSearchAttributes,
  };
};
