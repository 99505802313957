import { isEmpty } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { PagePermission, Routes } from 'constant';
import { useIsInvitationPage } from 'hooks/invitations';
import { useLastUrl } from 'hooks/localStorage';
import { useGetMyShopRedirect } from 'hooks/myShop';
import { useSelectedSmsFromQuery } from 'hooks/shopManagementSystems';
import { useSignUpComeBack, useSignupQueryParams } from 'hooks/signup';
import { useIsSmsConnectionsPage } from 'hooks/smsConnections';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { useOnboardSteps } from 'store/queries/currentUser/useGetOnBoardSteps';
import { CreateShopPages, getDashboardUrl, getLoginUrl, getSignUpUrl, getSignUpUrlNew } from 'utils/url';
import { useCreateShopRedirect } from './useCreateShopRedirect';
import { useSignUpQuestionsRedirect } from './useSignUpQuestionsRedirect';
import { useSmsConnectionsRedirect } from './useSmsConnectionsRedirect';

export const useGetRedirect = (permission: PagePermission) => {
  const [lastUrl] = useLastUrl();

  const isCreateShopPage = useMatch(getSignUpUrl('create-shop'));
  const isGoogleSignUpPage = useMatch(Routes.SIGN_UP_GOOGLE);
  const isInvitationPage = useIsInvitationPage();
  const isSmsConnectionsPage = useIsSmsConnectionsPage();

  const myShopRedirectUrl = useGetMyShopRedirect();

  const createShopRedirect = useCreateShopRedirect();
  const smsConnectionsRedirect = useSmsConnectionsRedirect();
  const signUpQuestionsRedirect = useSignUpQuestionsRedirect();

  const { hasCurrentUser, shop, isSuccess, isSignupCompleted } = useGetOnBoard();
  const { isOnboardCompleted } = useOnboardSteps();

  const { selectedSms } = useSelectedSmsFromQuery();

  const { pathname, search } = useAppLocation();
  const query = useSignupQueryParams();

  const {
    flags: { signUpQuestions: isSignUpQuestions },
    isLoaded,
  } = useLaunchDarkly();

  const isAuthorizedPage = permission === PagePermission.AUTHORIZED;
  const isUnauthorizedPage = permission === PagePermission.UNAUTHORIZED;
  const isAuthorizedNoShopPage = permission === PagePermission.AUTHORIZED_NO_SHOP;

  const shouldSkipRedirect = !isSuccess || isInvitationPage;

  const IsSignupTokenConfirmationPage = isCreateShopPage && query.token;

  const isNewOnboard = isSignUpQuestions && (shop?.newOnboard ?? true);

  useSignUpComeBack({ skip: !isLoaded || isNewOnboard || shouldSkipRedirect || !isAuthorizedPage });

  const shouldRedirectToOldOnboarding = useMemo(
    () =>
      isNewOnboard ? false : Object.values(CreateShopPages).some((element) => getSignUpUrlNew(element) === pathname),
    [isNewOnboard, pathname]
  );

  if (shouldSkipRedirect) {
    return null;
  }

  if (isSmsConnectionsPage) {
    if (smsConnectionsRedirect && smsConnectionsRedirect !== pathname) {
      return smsConnectionsRedirect;
    }

    return null;
  }

  if (!query?.code && isGoogleSignUpPage) {
    return getSignUpUrl();
  }

  if (!hasCurrentUser && !isUnauthorizedPage) {
    if (IsSignupTokenConfirmationPage) {
      return null;
    }

    return getLoginUrl();
  }

  if (hasCurrentUser && isUnauthorizedPage) {
    if (isNewOnboard && signUpQuestionsRedirect) {
      return signUpQuestionsRedirect;
    }

    if (isEmpty(shop) && !isNewOnboard) {
      return getSignUpUrl('create-shop', { selectedSms });
    }

    if (lastUrl && lastUrl !== `${pathname}${search}`) {
      return lastUrl;
    }

    return getDashboardUrl();
  }

  if (isAuthorizedNoShopPage) {
    if (isNewOnboard) {
      if (signUpQuestionsRedirect) {
        return signUpQuestionsRedirect;
      }

      if (isOnboardCompleted) {
        return getDashboardUrl();
      }
    }

    if (!isNewOnboard) {
      if (shouldRedirectToOldOnboarding && createShopRedirect) {
        return createShopRedirect;
      }

      if (isSignupCompleted) {
        return getDashboardUrl();
      }
    }

    if (hasCurrentUser && IsSignupTokenConfirmationPage) {
      if (!isEmpty(shop)) {
        return getDashboardUrl();
      }

      return getSignUpUrl('create-shop', { selectedSms });
    }
  }

  if (isAuthorizedPage) {
    if (isNewOnboard && signUpQuestionsRedirect) {
      return signUpQuestionsRedirect;
    }

    if (!isSignupCompleted && !isNewOnboard && createShopRedirect) {
      return createShopRedirect;
    }
  }

  if (myShopRedirectUrl) {
    return myShopRedirectUrl;
  }

  return null;
};
