import { entries, groupBy } from '@partstech/ui/utils';
import { DisplayAction, OrderType, ProductInterchangeType } from 'shared/api';
import type { SupplierAccount, Vehicle } from 'models';
import type { ProductAttribute, ProductAvailabilityLine, ProductRebate } from 'shared/api';
import type { RawAttribute } from 'shared/api/rest/gen/shop';
import type { FullProductFragment } from 'store/queries/product/FullProductFragment.generated';
import type { BaseProductFragment } from 'store/queries/searchResults/BaseProductFragment.generated';
import type { AvailabilityLine, Product as ProductRest, Quote } from 'types/product';

type Fragment = FullProductFragment | BaseProductFragment;

const createAvailabilityLine = (line: ProductAvailabilityLine): AvailabilityLine => ({
  address: line.address ?? null,
  group: line.group ?? null,
  id: line.id ?? null,
  type: line.type,
  name: line.name,
  qty: {
    type: 'EQ',
    value: line.quantity,
  },
});

const createQuoteStatus = (
  product: Fragment,
  hasAvailabilityInStock: boolean,
  displayAction?: DisplayAction
): Quote['status'] => {
  switch (true) {
    case (product.orderType === OrderType.Regular && hasAvailabilityInStock) ||
      (product.orderType === OrderType.Backorder && hasAvailabilityInStock) ||
      (product.orderType === OrderType.None && hasAvailabilityInStock && displayAction === DisplayAction.No):
      return 'IN_STOCK';

    case product.orderType === OrderType.Backorder:
      return 'BACK_ORDER';

    case product.orderType === OrderType.SpecialOrder:
      return 'SPECIAL_ORDER';

    default:
      return 'OUT_OF_STOCK';
  }
};

const createQuoteAction = (
  status: Quote['status'],
  orderType: Fragment['orderType'],
  displayAction?: DisplayAction,
  allowStoreSelect?: boolean,
  hasAvailabilityInStock?: boolean
): Quote['action'] => {
  const isCheckAvailability = () => {
    if (!allowStoreSelect) {
      return false;
    }

    if (!hasAvailabilityInStock) {
      return false;
    }

    return orderType === OrderType.CheckAvailability;
  };

  const getActions = (): {
    addToCartAction: Quote['action'];
    outOfStockAction: Quote['action'];
  } => {
    if (isCheckAvailability()) {
      return {
        addToCartAction: 'CHECK_AVAILABILITY',
        outOfStockAction: 'CALL_AVAILABILITY',
      };
    }

    if (displayAction === DisplayAction.Yes) {
      return {
        addToCartAction: 'ADD_TO_CART',
        outOfStockAction: 'CALL_AVAILABILITY',
      };
    }

    if (displayAction === DisplayAction.No || displayAction === DisplayAction.PriceOnly) {
      return {
        addToCartAction: 'CALL_SUPPLIER',
        outOfStockAction: 'CALL_SUPPLIER',
      };
    }

    return {
      addToCartAction: 'HIDDEN',
      outOfStockAction: 'HIDDEN',
    };
  };

  const { addToCartAction, outOfStockAction } = getActions();

  switch (true) {
    case status === 'IN_STOCK':
      return addToCartAction;

    case status === 'BACK_ORDER':
      return addToCartAction;

    case status === 'SPECIAL_ORDER':
      return 'HIDDEN';

    case status === 'OUT_OF_STOCK':
      return outOfStockAction;

    default:
      return 'HIDDEN';
  }
};

const createQuote = (product: Fragment, account: SupplierAccount | undefined): Quote | null => {
  if (!account) {
    return null;
  }

  const hasAvailabilityInStock = product.availability.some((item) => item.quantity > 0);

  const status = createQuoteStatus(product, hasAvailabilityInStock, account?.supplier?.displayAction ?? undefined);

  return {
    action: createQuoteAction(
      status,
      product.orderType,
      account?.supplier?.displayAction ?? undefined,
      account?.supplier?.allowStoreSelect,
      hasAvailabilityInStock
    ),
    availability: {
      type: 'AVAILABILITY_DETAILED',
      lines: product.availability.map(createAvailabilityLine),
      total: null,
    },
    status,
  };
};

const transformImages = (images: { full: string; medium: string; preview: string }[]) =>
  images.map((image) => ({
    filename: '',
    full: image.full,
    isAttachment: false,
    isVideo: false,
    medium: image.medium,
    name: null,
    preview: image.preview,
  }));

const createImages = (product: Fragment) => {
  const [firstImage, ...restImages] = product.images;
  const restImages360Groups =
    'image360Groups' in product ? product.image360Groups.map((group) => group.images).map(transformImages) : [];
  const otherImages = transformImages(restImages);

  return {
    image: firstImage?.medium ?? '',
    manufacturer: product.brand?.logo ?? null,
    other: otherImages,
    image360Groups: restImages360Groups,
    partImage: firstImage
      ? {
          full: firstImage?.full ?? '',
          isAttachment: false,
          isVideo: false,
          medium: firstImage?.medium ?? '',
          preview: firstImage?.preview ?? '',
        }
      : null,
    supplier: '',
  };
};

const createInterchangePart = (product: Fragment) => {
  const getType = (type: ProductInterchangeType | undefined): 'INTCH' | 'SUPERSEDE' | 'ALTERNATE' => {
    switch (type) {
      case ProductInterchangeType.Alternate:
        return 'ALTERNATE';

      case ProductInterchangeType.Interchange:
        return 'INTCH';

      case ProductInterchangeType.Supersede:
        return 'SUPERSEDE';

      default:
        return 'INTCH';
    }
  };

  if (!product.interchangeFor) {
    return null;
  }

  return {
    type: getType(product.interchangeFor?.type),
    partNumber: product.interchangeFor?.partNumber ?? '',
    brand: product.interchangeFor?.brandName ?? '',
  };
};

const createAttributes = (attributes: ProductAttribute[]) =>
  attributes.map((attribute) => ({
    name: attribute.name as RawAttribute['name'],
    value: attribute.values,
  }));

const createRebates = (rebates: ProductRebate[]): ProductRest['rebates'] =>
  rebates.map((rebate) => ({
    couponLink: rebate.couponLink ?? null,
    description: rebate.description,
    legalLink: rebate.legalLink ?? null,
    price: rebate.price ?? 0,
    text: rebate.title,
    title: rebate.title,
    validDate: rebate.validDate ?? null,
  }));

const createDigitalAssets = (product: Fragment) => {
  if ('attachments' in product && product.attachments) {
    return entries(groupBy(product.attachments, 'group'))
      .map(([name, attachments]) => ({
        name,
        files: attachments.map((attachment) => ({
          filename: attachment.filename,
          full: attachment.url,
        })),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return [];
};

const createPartNumbers = (product: Fragment) =>
  'interchanges' in product && product.interchanges && product.interchanges.length > 0
    ? product.interchanges.map((interchange) => ({
        company: interchange.brandName ?? '',
        has_data: true,
        interchange: interchange.type === ProductInterchangeType.Interchange,
        value: interchange.partNumber,
      }))
    : [];

const createPackages = (product: Fragment) =>
  'freightPackage' in product
    ? {
        merchandising: product.freightPackage?.merchandising ?? null,
        shipping: product.freightPackage?.shipping ?? null,
        weight: product.freightPackage?.weight ?? null,
      }
    : null;

const createProductAttributes = (product: Fragment) =>
  'productAttributes' in product && product?.productAttributes && product.productAttributes.length > 0
    ? product.productAttributes.reduce<Record<string, string[]>>(
        (acc, attribute) => ({ ...acc, [attribute.name]: attribute.values }),
        {}
      )
    : {};

const createExtendedInformation = (product: Fragment) =>
  'extendedInformation' in product && product?.extendedInformation && product.extendedInformation.length > 0
    ? product.extendedInformation.reduce<Record<string, string>>(
        (acc, information) => ({ ...acc, [information.name]: information.value }),
        {}
      )
    : {};

const createWarnings = (product: Fragment) =>
  'warnings' in product
    ? product.warnings.map((warning) => ({
        File: 'url' in warning ? warning.url : null,
        FileIsImage: 'url' in warning,
        Type: '',
        Value: 'text' in warning ? warning.text : '',
      }))
    : [];

const getDiagramsData = (product: Fragment) => {
  if ('diagrams' in product) {
    const firstDiagram = product.diagrams && product.diagrams[0];

    return {
      diagramCategoryId: firstDiagram?.category?.id,
      diagramId: firstDiagram?.id,
    };
  }

  return {};
};

export const convertProduct = (
  product: Fragment,
  account: SupplierAccount | undefined,
  vehicleId: Vehicle['id'] | undefined
): ProductRest & {
  bundles?: Fragment['bundles'];
  sponsoredPurchasesCount: Fragment['sponsoredPurchasesCount'];
  shippingCharge: Fragment['shippingCharge'];
} => ({
  assets: {
    diagrams: [],
    digital: createDigitalAssets(product),
  },
  attributes: createAttributes(product.attributes),
  brandId: product.brand?.id ? Number(product.brand.id) : null,
  brandLineCode: product.brand?.lineCode ?? null,
  brandName: product.brand?.name ?? null,
  brandLogo: product.brand?.logo ?? null,
  bundleAvailable: product.bundles && product.bundles.length > 0,
  bundles: product.bundles,
  coreCharge: product.coreCharge ?? null,
  credentialId: account?.id ? Number(account?.id) : null,
  descriptions: product.descriptions.map((description) => ({ ...description, value: description.values })),
  extendedInformation: createExtendedInformation(product),
  fetCharge: product.fetCharge ?? null,
  hasVehicleFitment: product.hasFitment,
  id: product.id,
  images: createImages(product),
  interchangePart: createInterchangePart(product),
  lineCardId: product.lineCard?.id ? Number(product.lineCard.id) : null,
  listPrice: product.listPrice ?? null,
  links: 'links' in product ? product.links : [],
  mapPrice: product.mapPrice ?? null,
  marketingDescriptions: 'marketingDescriptions' in product ? product.marketingDescriptions : [],
  notes: product.notes.map((note) => note.group),
  notesOem: product.notesOem,
  partNumber: product.partNumber,
  partNumberId: product.partNumberId,
  partTypeId: product.partType ? Number(product.partType.id) : null,
  partTypeName: product.partType?.name ?? null,
  price: product.price ?? null,
  productAttributes: createProductAttributes(product),
  partNumbers: createPartNumbers(product),
  packages: createPackages(product),
  quantityStep: product.quantityStep ?? null,
  quote: createQuote(product, account),
  rebates: createRebates(product.rebates),
  rewardPoints: product.rewardPoints
    ? {
        isPromotion: product.rewardPoints.isPromotion,
        points: product.rewardPoints.points,
        bonus: 0,
        defaultPoints: 0,
        multipliers: 0,
        program: '',
      }
    : null,
  sale: product.sale ?? null,
  shopRetailPrice: product.customerPrice ?? null,
  sortHash: product.sortHash ?? '',
  sponsorType: 'sponsorType' in product ? product.sponsorType : 'NONE',
  sponsoredPurchasesCount: 'sponsoredPurchasesCount' in product ? product.sponsoredPurchasesCount : null,
  stockMessage:
    product.deliveryNotes.length > 0
      ? product.deliveryNotes.reduce<Record<string, string>>((acc, note) => ({ ...acc, [note.label]: note.text }), {})
      : null,
  shippingCharge: product.shippingCharge,
  specificMarketingDescriptions:
    'specificMarketingDescriptions' in product
      ? product.specificMarketingDescriptions.map((description) => ({ ...description, value: description.values }))
      : [],
  storeId: account?.store?.id ? Number(account?.store?.id) : null,
  title: product.title,
  variations: product.variations.map((variation) => ({
    ...variation,
    attributes: createAttributes(variation.attributes),
    notes: variation.notes.map((note) => note.group),
    partTitle: '',
    variationId: variation.variationID,
  })),
  vehicleId: vehicleId ? Number(vehicleId) : null,
  warnings: createWarnings(product),
  ...getDiagramsData(product),

  // Unnecessary fields
  diagrams: [],
  companyCopyright: '',
  marketingSections: [],
  store: null,
  supplier: null,
  debugMessages: [],
  specialOrder: false,
  supplierId: null,
  variation: null,
  outTheDoorPrices: [],
});
