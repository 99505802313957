import { ProtectedRoute } from 'app/AppRouter/ProtectedRoute';
import { SmsConnectionsLayout } from 'components/Layout/SmsConnectionsLayout';
import { PagePermission } from 'constant';
import { SmsConnectionsPages } from 'utils/url';
import type { RouteObject } from 'react-router-dom';

const connectionsPage = {
  path: '',
  lazy: () => import('./Connect').then((module) => ({ Component: module.Connect })),
};

const howToUsePage = {
  path: SmsConnectionsPages.HowToUse,
  lazy: () => import('./HowToUse').then((module) => ({ Component: module.HowToUse })),
};

const logInPage = {
  path: SmsConnectionsPages.LogIn,
  lazy: () => import('./LogIn').then((module) => ({ Component: module.LogIn })),
};

const signUpPage = {
  path: SmsConnectionsPages.SignUp,
  lazy: () => import('./SignUp').then((module) => ({ Component: module.SignUp })),
};

const successPage = {
  path: SmsConnectionsPages.Success,
  lazy: () => import('./Success').then((module) => ({ Component: module.Success })),
};

const errorPage = {
  path: SmsConnectionsPages.Error,
  lazy: () => import('./Error').then((module) => ({ Component: module.Error })),
};

const forgotPasswordPage = {
  path: SmsConnectionsPages.ForgotPassword,
  lazy: () => import('./ForgotPassword').then((module) => ({ Component: module.ForgotPassword })),
};

export const smsConnectionsPages: RouteObject = {
  path: '/sms-connections/:sms',
  element: <SmsConnectionsLayout />,
  children: [
    {
      path: 'shop-monkey?/:hash?/*',
      children: [
        {
          element: <ProtectedRoute permission={PagePermission.UNAUTHORIZED} />,
          children: [logInPage, signUpPage, forgotPasswordPage],
        },

        {
          element: <ProtectedRoute permission={PagePermission.AUTHORIZED} />,
          children: [connectionsPage, howToUsePage],
        },

        {
          element: <ProtectedRoute permission={PagePermission.PUBLIC} />,
          children: [successPage, errorPage],
        },
      ],
    },
  ],
};
