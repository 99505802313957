import { useCallback, useMemo } from 'react';
import { ADDONS } from '../constants';
import { getGroupedAddOns } from '../utils';
import { useSubscriptionEX } from './useSubscriptionEX';
import { useSubscriptionProducts } from './useSubscriptionProducts';
import type { ProductAlias } from '../constants';
import type { AddOn } from '../types';

export const useAddOns = (isYearly: boolean) => {
  const { featuresEX, isRestrictedEX, isStatic } = useSubscriptionEX();
  const { getProductByAlias, isLoading, subscription } = useSubscriptionProducts(isYearly);

  const addOns: AddOn[] = useMemo(
    () =>
      ADDONS.map((ADDON) => {
        const { alias } = ADDON;
        const product = getProductByAlias(alias);

        return {
          ...ADDON,
          ...product,
          isActive: isRestrictedEX
            ? featuresEX.some((featureEX) => featureEX.alias === alias)
            : Boolean(product?.isActive),
        };
      }),
    [featuresEX, getProductByAlias, isRestrictedEX]
  );

  const { commonAddOns, tireAddOn } = useMemo(() => getGroupedAddOns(addOns), [addOns]);

  const filteredCommonAddOns = useMemo(
    () => commonAddOns.filter((addOn) => !isStatic || addOn.isActive),
    [commonAddOns, isStatic]
  );

  const getAddOnByAlias = useCallback(
    (alias?: ProductAlias) => addOns.find((addOn) => addOn.alias === alias),
    [addOns]
  );

  return {
    commonAddOns: filteredCommonAddOns,
    currentPeriodEnd: subscription?.currentPeriodEnd,
    isLoading,
    tireAddOn,
    getAddOnByAlias,
  };
};
