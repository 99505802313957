import { Box, Button, ButtonGroup, Link, ModalView, Typography } from '@partstech/ui';
import { getSubscriptionUrl } from 'utils/url';
import { PLAN_FREE } from '../../constants';
import { usePlans } from '../../hooks/usePlans';
import { LoseFeaturesCard } from '../LoseFeaturesCard';
import type { ProductAlias } from '../../constants';
import type { ModalProps } from '@partstech/ui';

export const DowngradeReminderModal = ({ onClose }: ModalProps) => {
  const { subscriptionPlan, getPlanByAlias } = usePlans();

  const planAfterDowngrading = getPlanByAlias(
    (subscriptionPlan?.downgradeProductAlias ?? PLAN_FREE.alias) as ProductAlias
  );

  return (
    <ModalView title="Subscription is changing" onClose={onClose}>
      <>
        <Typography variant="subtitle1" component="span">
          Your {subscriptionPlan?.name} subscription is ending within the next 72 hours
        </Typography>{' '}
        <Typography component="span">
          and your {planAfterDowngrading?.name} subscription will begin at the same time.
        </Typography>
      </>

      <Box my={6}>
        <LoseFeaturesCard
          background="mono2"
          borderWidth={0}
          planAliasAfterDowngrading={planAfterDowngrading?.alias}
          title="By downgrading, you’re losing these great benefits:"
        />
      </Box>

      <Typography>
        To keep your subscription, please reactivate {subscriptionPlan?.name} from My subscription.
      </Typography>

      <ButtonGroup mt={6} display="flex" flexDirection="column">
        <Button as={<Link to={getSubscriptionUrl()} />} onClick={onClose}>
          View my subscription
        </Button>

        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </ButtonGroup>
    </ModalView>
  );
};
