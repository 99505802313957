import { useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
import { useCreateManagementSystem } from 'hooks/shopManagementSystems';
import { useFormStatus } from 'shared/lib/form';
import { useIntegrationStatusModal } from './useIntegrationStatusModal';

type FromData = { name: string };

type Args = {
  onSubmit: () => void;
};

export const useCreateSystemForm = ({ onSubmit }: Args) => {
  const [createManagementSystem, status] = useCreateManagementSystem();

  const form = useForm<FromData>({ defaultValues: { name: '' } });
  const { formState } = form;
  const { dirtyFields } = formState;

  const { open } = useIntegrationStatusModal({ onSubmit });

  const submitForm = useCallback(
    async (values: FromData) => {
      await createManagementSystem(values);
    },
    [createManagementSystem]
  );

  useFormStatus({ form, status, onSuccess: open });

  return { form, submitForm, isDisabled: !dirtyFields.name, isLoading: status.isLoading };
};
