import type { Variation as VariationType } from 'types/product';

type Variation = {
  variation: string | null;
  variations: VariationType[];
};

export const getVariationFromProduct = (entity: Variation | null = null, variationId?: string | null) =>
  entity
    ? entity.variations.find((variation) => variation.variationId === (entity.variation ?? variationId)) ||
      (entity.variations[0] ?? null)
    : null;
