import { useCallback, useState } from 'react';
import type { ShopMode } from 'features/shop';
import type { AdditionalDetail } from 'shared/api';

type Args = {
  onSelect: (modes: AdditionalDetail[]) => void;
};

export const useSelectShopModes = ({ onSelect }: Args) => {
  const [selectedShopMode, setSelectedShopMode] = useState<ShopMode | null>(null);

  const selectShopMode = useCallback(
    (shopMode: ShopMode) => () => {
      setSelectedShopMode(shopMode);

      onSelect(shopMode.modes);
    },
    [onSelect]
  );

  return { selectedShopMode, selectShopMode };
};
