import { isString, isNotNull } from '@partstech/ui/utils';
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  deleteActiveCartOrder,
  updateActiveCartOrderAdditionalFields,
  updateActiveCartOrderPaymentNotes,
  updateActiveCartOrderShippingMethod,
} from 'shared/api/rest/gen/shop';
import { getShipments } from 'store/actions/cart';
import { consumeAddToCart, consumeAddToCartMultiple, selectPartStatuses, submitOrder } from 'store/features/cart';
import { logout } from 'store/features/user/account';
import { selectSupplierAccountFromRootStoreById } from 'store/queries/currentUser/supplierAccounts/selectSupplierAccountFromRootStoreById';
import { selectRootState } from 'store/utils';
import { createShipment } from 'utils';
import { selectProductById } from './product';
import { selectStoreById } from './store';
import { selectSupplierById } from './supplier';
import type { EntityId, PayloadAction } from '@reduxjs/toolkit';
import type { ResponseError } from 'shared/api';
import type { UpdateActiveCartOrderAdditionalFieldsRequest } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';
import type { FullShipment, ShipmentType } from 'types/cart';

export const updateShippingMethod = createAsyncThunk(
  'entities/shipment/updateShippingMethod',
  async ({ id, shippingMethodCode }: { id: number; shippingMethodCode: string }, { rejectWithValue }) => {
    try {
      return await updateActiveCartOrderShippingMethod(id, { shippingMethodCode });
    } catch (e) {
      return rejectWithValue(e.error().message);
    }
  }
);

type UpdatePaymentNotesArgs = {
  id: number;
  PONumber: ShipmentType['PONumber'];
  customNotes: ShipmentType['customNotes'];
};

export const updatePaymentNotes = createAsyncThunk<void, UpdatePaymentNotesArgs>(
  'entities/shipment/updatePaymentNotes',
  async ({ id, PONumber, customNotes }, { rejectWithValue }) => {
    try {
      return await updateActiveCartOrderPaymentNotes(id, { customNotes, PONumber });
    } catch (e) {
      return rejectWithValue(e.error().message);
    }
  }
);

export const removeShipmentAndPartsById = createAsyncThunk(
  'entities/shipment/removeShipmentAndPartsById',
  ({ id }: { id: number }) => deleteActiveCartOrder(id)
);

type UpdateAdditionalFields = {
  id: ShipmentType['id'];
  additionalFields: UpdateActiveCartOrderAdditionalFieldsRequest['additionalFields'];
};

export const updateAdditionalFields = createAsyncThunk<void, UpdateAdditionalFields, { rejectValue: ResponseError }>(
  'entities/shipment/updateAdditionalFields',
  async (payload, { rejectWithValue }) => {
    const { id, additionalFields } = payload;
    try {
      return updateActiveCartOrderAdditionalFields(id, { additionalFields });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const selectShipmentState = (state: RootState) => state.entities.shipment;

const shipmentsAdapter = createEntityAdapter<ShipmentType, EntityId>({ selectId: (entity) => entity.id });

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState: shipmentsAdapter.getInitialState(),
  reducers: {
    markShipmentPaid: (state, action: PayloadAction<ShipmentType['id'][]>) => {
      shipmentsAdapter.updateMany(
        state,
        action.payload.map((id) => ({
          id,
          changes: {
            isPaid: true,
          },
        }))
      );
    },
    clearCart: (state) => {
      shipmentsAdapter.removeMany(state, state.ids);
    },
    removePaidShipmentsAndParts: (state, action: PayloadAction<ShipmentType['id'][]>) => {
      shipmentsAdapter.removeMany(state, action.payload);
    },
    removeShipmentById: (state, action: PayloadAction<ShipmentType['id']>) => {
      shipmentsAdapter.removeOne(state, action.payload);
    },
    changePONumbers: (state, action: PayloadAction<{ ids: number[]; value: string | null }>) => {
      shipmentsAdapter.updateMany(
        state,
        action.payload.ids.map((id) => ({
          id,
          changes: { PONumber: action.payload.value, shouldSavePaymentNote: true },
        }))
      );
    },
    changeCustomNotes: (state, action: PayloadAction<{ id: number; value: string | null }>) => {
      shipmentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { customNotes: action.payload.value, shouldSavePaymentNote: true },
      });
    },
    setPreferredDeliveryMethod: (state, action: PayloadAction<{ supplierId: number; mothodCode: string }>) => {
      const ids = state.ids.filter((id) => state.entities[id]?.supplierId === action.payload.supplierId);

      shipmentsAdapter.updateMany(
        state,
        ids.map((id) => ({
          id,
          changes: { optionsPreferences: { shippingMethodCode: action.payload.mothodCode } },
        }))
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShipments.fulfilled, (state, action) => {
      const entities: ShipmentType[] = action.payload.map((shipment) => {
        const { parts, customerNumber, invoice, isTaxFree, paymentWayOriginal, ...entity } = shipment;

        const hasFreightTerm = entity.freightTerms.find(
          (freightTerm) => freightTerm.service.shippingMethodCode === entity.freightTerm
        );

        return {
          ...entity,
          isLoading: false,
          freightTerm: hasFreightTerm ? entity.freightTerm : entity.freightTerms[0]?.service?.shippingMethodCode || '',
          isShipmentTemporal: false,
        };
      });

      if (!action.meta.arg?.id) {
        shipmentsAdapter.removeAll(state);
      }

      shipmentsAdapter.upsertMany(state, entities);
    });
    builder.addCase(getShipments.rejected, (state, action) => {
      if (action.meta.arg?.id && action.error.message === 'Not found') {
        shipmentsAdapter.removeOne(state, action.meta.arg.id);
      }
    });
    builder.addCase(updateShippingMethod.fulfilled, (state, action) => {
      shipmentsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: { freightTerm: action.meta.arg.shippingMethodCode },
      });
    });
    builder.addCase(removeShipmentAndPartsById.fulfilled, (state, action) => {
      shipmentsAdapter.removeOne(state, action.meta.arg.id);
    });

    builder.addCase(updateAdditionalFields.fulfilled, (state, action) => {
      const shipment = state.entities[action.meta.arg.id];
      const additionalFields = shipment?.additionalFields;
      const updatedAdditionalFields = additionalFields?.map((field, index) => ({
        ...field,
        default: action.meta.arg.additionalFields[index]?.value ?? null,
      }));

      shipmentsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: { additionalFields: updatedAdditionalFields },
      });
    });

    builder.addCase(submitOrder.fulfilled, (state, action) => {
      if (('redirectUrl' in action.payload && isString(action.payload.redirectUrl)) || action.meta.arg.isCatalogSdk) {
        return;
      }

      shipmentsAdapter.updateMany(
        state,
        action.meta.arg.shipments.map(({ id }) => ({ id, changes: { isPaid: true } }))
      );
    });

    builder.addCase(consumeAddToCart.fulfilled, (state, action) => {
      const { orderId } = action.payload;
      if (orderId && !state.entities[orderId]) {
        const PONumber = state.ids[0] ? state.entities[state.ids[0]]?.PONumber : null;
        const { product, selectedStore } = action.meta.arg;
        shipmentsAdapter.upsertOne(state, createShipment(product, selectedStore, orderId, PONumber));
      }
    });

    builder.addCase(consumeAddToCartMultiple.fulfilled, (state, action) => {
      action.payload.forEach(({ orderId, status }, index) => {
        if (status === 200 && orderId && !state.entities[orderId]) {
          const shipmentProduct = action.meta.arg[index];
          if (!shipmentProduct) {
            return;
          }

          const PONumber = state.ids[0] ? state.entities[state.ids[0]]?.PONumber : null;
          const { product, selectedStore } = shipmentProduct;

          shipmentsAdapter.upsertOne(state, createShipment(product, selectedStore, orderId, PONumber));
        }
      });
    });

    builder.addCase(logout.fulfilled, (state) => {
      shipmentsAdapter.removeAll(state);
    });

    builder.addCase(updatePaymentNotes.pending, (state, action) => {
      shipmentsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: { isPaymentNoteLoading: true, shouldSavePaymentNote: false },
      });
    });
    builder.addCase(updatePaymentNotes.fulfilled, (state, action) => {
      shipmentsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: { isPaymentNoteLoading: false, paymentNoteError: null },
      });
    });
    builder.addCase(updatePaymentNotes.rejected, (state, action) => {
      shipmentsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: {
          isPaymentNoteLoading: false,
          paymentNoteError: isString(action.payload) ? action.payload : 'An unexpected error occurred.',
        },
      });
    });

    builder.addMatcher(
      isAnyOf(getShipments.pending, updateShippingMethod.pending, removeShipmentAndPartsById.pending),
      (state, action) => {
        if (action.meta.arg?.id) {
          shipmentsAdapter.updateOne(state, {
            id: action.meta.arg.id,
            changes: { isLoading: true },
          });
        }
      }
    );
    builder.addMatcher(
      isAnyOf(updateShippingMethod.fulfilled, updateShippingMethod.rejected, removeShipmentAndPartsById.rejected),
      (state, action) => {
        if (action.meta.arg?.id) {
          shipmentsAdapter.updateOne(state, {
            id: action.meta.arg.id,
            changes: { isLoading: false },
          });
        }
      }
    );
  },
});

export const shipment = shipmentsSlice.reducer;

export const {
  clearCart,
  removePaidShipmentsAndParts,
  removeShipmentById,
  changePONumbers,
  changeCustomNotes,
  setPreferredDeliveryMethod,
} = shipmentsSlice.actions;

const { selectById, selectIds } = shipmentsAdapter.getSelectors(selectShipmentState);

export const selectShipmentById = createSelector(
  [(state: RootState, id: EntityId) => selectById(state, id), selectRootState, (_: unknown, id: EntityId) => id],
  (entity, state, id): FullShipment | null => {
    if (!entity) {
      return null;
    }

    return {
      ...entity,
      parts: Object.values(state.entities.shipmentPart.entities)
        .filter((part) => part?.shipmentId === id)
        .filter(isNotNull)
        .map((part) => ({ ...part, product: selectProductById(state, part.productId) })),
      supplierAccount: entity.credentialsId
        ? selectSupplierAccountFromRootStoreById(state, String(entity.credentialsId))
        : null,
      supplier: selectSupplierById(state, String(entity.supplierId)),
      store: selectStoreById(state, entity.storeId),
    };
  }
);

export const selectAllShipments = createSelector([selectIds, selectRootState], (ids, state) =>
  ids.map((id) => selectShipmentById(state, id)).filter(isNotNull)
);

export const selectAdditionalFieldsById = createSelector(
  selectShipmentById,
  (entity) => entity?.additionalFields ?? []
);

const selectUnpaidShipments = createSelector([selectAllShipments], (entities) =>
  entities.filter(({ isPaid }) => !isPaid)
);

export const selectActualShipments = createSelector(
  [(state) => selectPartStatuses(state), selectUnpaidShipments],
  (partStatuses, entities) =>
    entities.filter((entity) => !entity.parts.every((part) => partStatuses[part.id]?.type === 'removed'))
);

export const selectAreShipmentsLoading = createSelector([selectActualShipments], (entities) =>
  entities.some((entity) => entity.isLoading)
);

export const selectActualShipmentsIds = createSelector(selectActualShipments, (entities) =>
  entities.map((entity) => entity.id)
);

const selectPaidShipments = createSelector([selectAllShipments], (entities) =>
  entities.filter((entity) => entity.isPaid)
);

export const selectPaidShipmentIds = createSelector(selectPaidShipments, (entities) =>
  entities.map((entity) => entity.id)
);

export const selectHasPaidShipments = createSelector(selectPaidShipments, (entities) => entities.length > 0);

export const selectCheckoutMessage = createSelector(
  [selectShipmentById, selectAllShipments, selectRootState],
  (entity, entities, state) => {
    if (entity) {
      const supplier = selectSupplierById(state, String(entity.supplierId));

      return supplier?.checkoutMessage ?? null;
    }

    const messages = entities
      .map((shipmentEntity) => {
        const supplier = selectSupplierById(state, String(shipmentEntity.supplierId));
        return supplier?.checkoutMessage;
      })
      .filter((message) => Boolean(message));

    if (messages.length > 0) {
      return 'One or more suppliers have alerts that require your attention.';
    }

    return null;
  }
);

export const selectDeliveryOption = createSelector([selectShipmentById], (entity) =>
  entity?.freightTerms?.find((option) => option.service.shippingMethodCode === entity.freightTerm)
);

export const selectShipmentsWithPoNumber = createSelector([selectActualShipments], (entities) =>
  entities.filter((entity) => entity.supplier?.allowCustomPurchaseOrderNumbers)
);

export const selectHasPoNumber = createSelector([selectShipmentsWithPoNumber], (entities) => entities.length > 0);

export const selectShipmentWithPoNumberIds = createSelector([selectShipmentsWithPoNumber], (entities) =>
  entities.map((entity) => entity.id)
);

export const selectIsMultiplePoNumbers = createSelector([selectShipmentsWithPoNumber], (entities) =>
  entities.some((entity) => entity.PONumber !== entities[0]?.PONumber)
);

export const selectGlobalPoNumber = createSelector(
  [selectIsMultiplePoNumbers, selectShipmentsWithPoNumber],
  (isMultiple, entities) => (isMultiple ? 'Multiple POs' : entities[0]?.PONumber || '')
);

export const selectIsPaymentNotesLoading = createSelector([selectActualShipments], (entities) =>
  entities.some((entity) => entity.shouldSavePaymentNote || entity.isPaymentNoteLoading)
);
