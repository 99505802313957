import { Box, Link, Typography } from '@partstech/ui';
import { memo } from 'react';
import { Price } from 'components/Price';
import { StoreAvailability } from 'components/Product/StoreAvailability';
import { RewardPoints } from 'components/RewardPoints';
import { VehicleName } from 'components/VehicleString/VehicleName';
import { Attributes } from './Attributes';
import { DeliveryNotes } from './DeliveryNotes';
import { Notes } from './Notes';
import { Warnings } from './Warnings';
import type { CartOrderItem } from 'models';

type Props = {
  orderItem: CartOrderItem;
  showRetailPrice: boolean;
  onTitleClick?: () => void;
};

export const PartInfo = memo(({ orderItem, showRetailPrice, onTitleClick }: Props) => (
  <Box display="flex" flexDirection="column" gap={2}>
    <Link
      data-testid="part-title"
      to={orderItem.error ? '' : orderItem.productUrl}
      color="primaryDark"
      underline="hover"
      variant="bodyText1"
      onClick={onTitleClick}
    >
      {orderItem.title}
    </Link>

    <RewardPoints points={orderItem.rewardPoint} />

    <Typography color="monoDark" variant="subtitle1" bold>
      {orderItem.partNumber}
    </Typography>

    <Warnings warnings={orderItem.warnings} />

    <StoreAvailability quote={orderItem.quote} store={orderItem.quote?.lines[0]} allowSelect={false} usePortal />

    <Price
      price={orderItem.price}
      coreCharge={orderItem.coreCharge}
      retailPrice={orderItem.retailPrice}
      listPrice={orderItem.listPrice}
      mapPrice={orderItem.mapPrice}
      showOnlyRetailPrice={showRetailPrice}
      isTire={orderItem.isTire}
      priceAlign="left"
    />

    {orderItem.vehicle && <VehicleName vehicle={orderItem.vehicle} multiline hasFlag={false} />}

    <Attributes attributes={orderItem.attributes} />

    <DeliveryNotes notes={orderItem.deliveryNotes} />

    <Notes notes={orderItem.notes} />
  </Box>
));
