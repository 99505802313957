import { uniq, isNotNull } from '@partstech/ui/utils';
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { searchGetVehicle } from 'shared/api/rest/gen/shop';
import { selectRootState } from 'store/utils';
import type { EntityId } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { Vehicle } from 'types/state';

const vehiclesAdapter = createEntityAdapter<Vehicle, EntityId>({ selectId: (entity) => entity.id });

type State = {
  isLoading: boolean;
};

const selectState = (state: RootState) => state.entities.vehicle;

const { selectById } = vehiclesAdapter.getSelectors<RootState>(selectState);

export const selectVehicleById = createSelector(
  (state: RootState, id: string | null) => selectById(state, id ?? ''),
  (entity) => entity ?? null
);

export const selectVehiclesByIds = createSelector([selectRootState, (_: unknown, ids: string[]) => ids], (state, ids) =>
  uniq(ids.map((id) => selectVehicleById(state, id)).filter(isNotNull))
);

export const fetchVehiclesByIds = createAsyncThunk('entities/vehicle/fetchByIds', (ids: Vehicle['id'][]) =>
  searchGetVehicle({ ids })
);

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: vehiclesAdapter.getInitialState<State>({
    isLoading: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehiclesByIds.fulfilled, vehiclesAdapter.upsertMany)
      .addCase(fetchVehiclesByIds.pending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(fetchVehiclesByIds.fulfilled, fetchVehiclesByIds.rejected), (state) => {
        state.isLoading = false;
      });
  },
});

export const vehicle = vehiclesSlice.reducer;

export const selectIsVehiclesLoading = createSelector([selectState], (state) => state.isLoading);
