import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSettings } from 'hooks/app/useSettings';
import type { Stripe } from '@stripe/stripe-js';
import type { PropsWithChildren } from 'react';

export const StripeLoader = ({ children }: PropsWithChildren) => {
  // TODO: Remove this dependency on useSettings (DEV-3374)
  const { stripeKey } = useSettings();

  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    if (!stripeKey) {
      return;
    }

    const getStripe = async () => {
      if (stripe) {
        return;
      }

      const result = await loadStripe(stripeKey);

      if (!result) {
        return;
      }

      setStripe(result);
    };

    getStripe();
  }, [stripe, stripeKey]);

  return (
    <Elements
      stripe={stripe}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro',
          },
        ],
      }}
    >
      {children}
    </Elements>
  );
};
