import { Button } from '@partstech/ui';
import { useCallback } from 'react';
import { useCreateManagementSystem } from 'hooks/shopManagementSystems';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

type Props = {
  onSubmit: () => void;
};

export const ContinueWithoutSystemButton = ({ onSubmit }: Props) => {
  const { shop } = useGetCurrentUser();

  const {
    flags: { signUpQuestions: isSignUpQuestions },
  } = useLaunchDarkly();

  const [createManagementSystem, status] = useCreateManagementSystem({ onSuccess: onSubmit });

  const handleCreateShopManagementSystem = useCallback(() => {
    createManagementSystem({ noSms: true });
  }, [createManagementSystem]);

  return (
    <Button
      trailingIcon="arrow_forward"
      variant="text"
      isLoading={status.isLoading}
      onClick={handleCreateShopManagementSystem}
    >
      Continue without a {isSignUpQuestions && shop?.newOnboard ? 'management system' : 'software'}
    </Button>
  );
};
