import { css } from '@emotion/react';
import { Box, Button, Card, ImageFallback, QuantitySelector, Typography, useMedia } from '@partstech/ui';
import { useDebouncedCallback } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { Price } from 'components/Price';
import { StoreAvailability } from 'components/Product/StoreAvailability';
import { ErrorMessage } from './ErrorMessage';
import type { Theme } from '@partstech/ui';
import type { CartOrderItem as CartOrderItemModel } from 'models';

const styles = {
  container: (theme: Theme) => css`
    position: relative;

    .deleteIcon {
      position: absolute;
      top: ${theme.sizing(3)};
      right: ${theme.sizing(1)};

      ${theme.breakpoints.up('md')} {
        display: none;
      }
    }

    ${theme.breakpoints.up('md')} {
      &:hover {
        background-color: ${theme.color.lightBlue};
        .deleteIcon {
          display: block;
        }
      }
    }
  `,
  image: css`
    object-fit: contain;
  `,
};

type Props = {
  orderItem: CartOrderItemModel;
  showOnlyRetailPrice?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onChangeQuantity?: (orderItemId: string, quantity: number) => void;
  onRemove?: (orderItemId: string) => void;
};

export const CartOrderItem = ({
  orderItem,
  showOnlyRetailPrice = false,
  disabled,
  isLoading,
  onChangeQuantity,
  onRemove,
}: Props) => {
  const { id, image, partNumber, tireSize, error, price, quantity, retailPrice, coreCharge, fetCharge, title, quote } =
    orderItem;

  const { isMobile } = useMedia();

  const showAvailability = quote && quote.status !== 'OUT_OF_STOCK' && !disabled;

  const handleRemovePart = useCallback(() => {
    if (onRemove) {
      onRemove(id);
    }
  }, [onRemove, id]);

  const handleChangeQuantity = useDebouncedCallback((newQuantity: number) => {
    if (onChangeQuantity) {
      onChangeQuantity(id, newQuantity);
    }
  }, 500);

  return (
    <Box
      display="flex"
      px={4}
      py={3}
      gap={{ sm: 2, md: 4 }}
      flexDirection={{ sm: 'column', md: 'row' }}
      justifyContent="space-between"
      data-testid="orderItem"
      css={styles.container}
    >
      <Box display="flex" gap={{ sm: 2, md: 4 }}>
        {image && (
          <Card p={1} height={20} flexShrink={0}>
            <ImageFallback src={image} height={72} width={64} css={styles.image} />
          </Card>
        )}

        <Box pr={{ sm: 5, md: 0 }} maxWidth={{ md: 56, lg: 86 }}>
          <Box mb={2}>
            <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'}>{title}</Typography>

            <Typography variant="caption">
              <Typography component="span" variant="caption" color="subtleText">
                #:{' '}
              </Typography>
              {partNumber}
            </Typography>

            {tireSize && (
              <Typography variant="caption" component="p">
                Size:{' '}
                <Typography component="span" variant="overline" color="subtleText">
                  {tireSize}
                </Typography>
              </Typography>
            )}
          </Box>

          {!isMobile && showAvailability && (
            <StoreAvailability
              allowSelect={false}
              quote={quote}
              store={quote.lines[0]}
              usePortal
              disableAutoPlacement={false}
            />
          )}

          {!isMobile && <ErrorMessage error={error} mt={2} />}
        </Box>
      </Box>

      {isMobile && showAvailability && (
        <Box mb={2}>
          <StoreAvailability
            allowSelect={false}
            quote={quote}
            store={quote.lines[0]}
            usePortal
            disableAutoPlacement={false}
          />
          <ErrorMessage mt={2} error={error} />
        </Box>
      )}

      {Boolean(price) && (
        <Box display="flex" gap={{ sm: 2, md: 4 }} alignSelf={{ sm: 'flex-end', md: 'flex-start' }} flexShrink={0}>
          <QuantitySelector
            defaultValue={quantity}
            disabled={disabled || isLoading || !onChangeQuantity}
            onChange={handleChangeQuantity}
            disableAutoPlacement={false}
          />

          <Box minWidth={26} flexShrink={0} pr={{ md: 7 }}>
            <Price
              price={price}
              retailPrice={retailPrice}
              coreCharge={coreCharge}
              fetCharge={fetCharge}
              showOnlyRetailPrice={showOnlyRetailPrice}
              size="small"
              fetPricePosition="right"
            />
          </Box>
        </Box>
      )}

      {onRemove && !disabled && (
        <Button
          data-testid="deleteOrderItem"
          className="deleteIcon"
          leadingIcon="delete"
          iconColor="negative"
          variant="light"
          size="dense"
          disabled={isLoading}
          onClick={handleRemovePart}
        />
      )}
    </Box>
  );
};
