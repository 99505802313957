import { usePrevious } from '@partstech/ui/hooks';
import { isNotNull, entries } from '@partstech/ui/utils';
import { useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { Routes } from 'constant';
import { useCategoryNamesMapByPartTypeIds } from 'features/taxonomy';
import { usePreferredBrands } from 'hooks/preferredBrands';
import { FullStoryEventNames, useTracking } from 'integrations/fullstory/tracking';
import { PreferredBrandsMode } from 'shared/api';
import { useAppSelector } from 'store';
import { selectShipmentPartTypeIds, selectShipmentPartsMap } from 'store/entities/shipmentPart';
import { selectPartStatuses } from 'store/features/cart';
import { selectProductsWithError } from 'store/features/search/productStatuses';
import { getPreferredBrandsNamesMap, isStaggeredTire } from 'utils';
import { getProductUrl } from 'utils/url';
import type { Product } from 'types/product';

type PartInfo = {
  product: Product;
  searchResultPosition: number;
  quantity: number;
  isPreferredItem: boolean;
  category?: string;
  bundled?: boolean;
};

const getPayload = ({ searchResultPosition, product, quantity, isPreferredItem, category, bundled }: PartInfo) => ({
  added_from: window.location.pathname === Routes.SEARCH_RESULTS ? 'search results' : 'product page',
  added_dealers: Boolean(product.supplier?.isDealer),
  brand: product.brandName,
  category,
  partType: product.partTypeName,
  name: product.title,
  position: searchResultPosition,
  price: product.price,
  product_id: product.partNumberId,
  quantity,
  sku: product.partNumber,
  supplier: product.supplier?.name,
  url: getProductUrl(product),
  preferred_item: isPreferredItem,
  staggeredTires: isStaggeredTire(product),
  dealCompleted: bundled || false,
  sponsoredProductAddedToCart: product.sponsorType === 'SPONSORED',
});

export const usePartAddedTracking = () => {
  const partStatuses = useAppSelector(selectPartStatuses, shallowEqual);
  const productsWithError = useAppSelector(selectProductsWithError, shallowEqual);
  const partsMap = useAppSelector(selectShipmentPartsMap, shallowEqual);
  const shipmentPartTypeIds = useAppSelector(selectShipmentPartTypeIds, shallowEqual);

  const { sendEvent } = useTracking();

  const partTypeIds = useMemo(() => {
    const productsWithErrorPartTypeIds = productsWithError.map(({ product }) => product?.partTypeId);

    return [...shipmentPartTypeIds, ...productsWithErrorPartTypeIds].filter(isNotNull);
  }, [productsWithError, shipmentPartTypeIds]);

  const { preferredBrands: automaticPreferredPartsBrandsNew } = usePreferredBrands({
    type: 'Part',
    mode: PreferredBrandsMode.Automatic,
  });

  const preferredBrandsNamesMap = useMemo(
    () => getPreferredBrandsNamesMap(partTypeIds, automaticPreferredPartsBrandsNew),
    [partTypeIds, automaticPreferredPartsBrandsNew]
  );

  const categoriesMap = useCategoryNamesMapByPartTypeIds({ partTypeIds });

  const prevPartStatuses = usePrevious(partStatuses);
  const prevProductsWithError = usePrevious(productsWithError);

  useEffect(() => {
    entries(partStatuses).forEach(([id, status]) => {
      if (status.type === 'added' && prevPartStatuses?.[id]?.type !== 'added') {
        const part = partsMap[id];
        const product = part?.product;
        const partTypeId = product?.partTypeId;

        if (!partTypeId) {
          return;
        }

        const payload = getPayload({
          product,
          searchResultPosition: status.searchResultPosition || 1,
          quantity: status.quantity || 1,
          isPreferredItem: preferredBrandsNamesMap[partTypeId]?.includes(product.brandName || '') || false,
          category: categoriesMap[partTypeId],
          bundled: part?.bundled,
        });

        sendEvent(FullStoryEventNames.PRODUCT_ADDED, payload);
      }
    });
  }, [sendEvent, categoriesMap, partStatuses, partsMap, preferredBrandsNamesMap, prevPartStatuses]);

  useEffect(() => {
    productsWithError.forEach(({ product, status }) => {
      if (
        prevProductsWithError &&
        !prevProductsWithError.some(({ product: prevProduct }) => prevProduct?.id === product?.id)
      ) {
        const partTypeId = product?.partTypeId;
        const { quantity, searchResultPosition, error } = status;

        if (!partTypeId || !error) {
          return;
        }

        const payload = getPayload({
          product,
          searchResultPosition: searchResultPosition || 1,
          quantity: quantity || 1,
          isPreferredItem: preferredBrandsNamesMap[partTypeId]?.includes(product.brandName || '') || false,
          category: categoriesMap[partTypeId],
        });

        sendEvent(FullStoryEventNames.PRODUCT_ADDING_ERROR, { ...payload, error });
      }
    });
  }, [sendEvent, categoriesMap, preferredBrandsNamesMap, prevProductsWithError, productsWithError]);
};
