import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { ComparePlansModal } from '../components/ComparePlansModal';
import type { PaidFeatureAlias } from '../constants';

export const useComparePlansModal = (highlightFeature?: PaidFeatureAlias) => {
  const { opened: isComparePlansModalOpened, open } = useCreateModal(
    ComparePlansModal,
    { size: 'fullScreen', highlightFeature },
    { customId: 'comparePlansModal' }
  );

  const openComparePlansModal = useCallback(() => {
    open();
  }, [open]);

  return {
    isComparePlansModalOpened,
    openComparePlansModal,
  };
};
