import { TireSizeTranslation } from 'components/Part/CustomAttributes/AttributeTooltip/TireSizeTranslation';
import { AttributeTooltip } from 'components/Product/Attributes/Attribute/AttributeTooltip';
import { Specification } from 'components/Product/Specification';
import type { ProductAttribute } from 'types/product';

const emptyAttributes: ProductAttribute[] = [];

type Props = {
  attribute: ProductAttribute;
};

export const TireSizeAttribute = ({ attribute }: Props) => (
  <Specification
    label={attribute.label}
    value={attribute.value}
    adornmentAfter={
      <AttributeTooltip tooltip={<TireSizeTranslation attributes={attribute.children || emptyAttributes} />} />
    }
  />
);
