import { isNotNull } from '@partstech/ui/utils';
import { useEffect, useMemo } from 'react';
import { useGetAddresses } from 'entities/address';
import { getShortPlanName, useSubscription } from 'features/subscription';
import { useAfterMarketSupplierAccounts, useSupplierAccounts } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useUserpilot = () => {
  const { flags } = useLaunchDarkly();
  const availableFlags = useMemo(
    () =>
      Object.entries(flags)
        .filter(([, value]) => value)
        .map(([key]) => key)
        .join('-'),
    [flags]
  );

  const {
    activeMember,
    accountId,
    email,
    fullName,
    registrationDate,
    managementSystem,
    shopName,
    hasCurrentUser,
    isSuccess,
    shop,
  } = useGetCurrentUser();

  const { defaultAddress } = useGetAddresses({ skip: !activeMember });

  const { accounts, isFetching: isFetchingSupplierAccounts } = useSupplierAccounts();
  const { afterMarketAccounts } = useAfterMarketSupplierAccounts();

  const { subscription, isLoading: isSubscriptionLoading } = useSubscription();

  const suppliersNames = useMemo(
    () =>
      accounts
        .map((account) => account.supplier?.name)
        .filter(isNotNull)
        .join('-'),
    [accounts]
  );

  const userpilotEl = document.getElementById('userpilot');
  const canIdentifyUserpilot =
    hasCurrentUser && isSuccess && !isFetchingSupplierAccounts && !isSubscriptionLoading && !userpilotEl;

  useEffect(() => {
    if (!canIdentifyUserpilot) {
      return;
    }

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.id = 'userpilot';
    script.src = 'https://js.userpilot.io/sdk/latest.js';
    script.defer = true;
    script.onload = () => {
      window.userpilot?.identify(accountId ?? '', {
        email,
        ldFlags: availableFlags,
        fullName,
        package: getShortPlanName(subscription?.plan?.name ?? '').toLowerCase(),
        partSupplierCount: afterMarketAccounts.length,
        created_at: registrationDate,
        shopName,
        shopShippingZipCode: shop?.attributes?.defaultShippingZipCode ?? null,
        shopState: defaultAddress?.state ?? null,
        shopManagementSystemName: managementSystem?.name ?? null,
        subscriptionStartedAt: subscription?.startedAt ?? null,
        suppliers: suppliersNames,
        numberOrders: shop?.orderingStatistic?.totalPaidOrders ?? 0,
        id: accountId,
        userIdEven: Number(accountId) % 2 === 0,
      });
    };

    document.body.appendChild(script);
  }, [
    accountId,
    afterMarketAccounts.length,
    availableFlags,
    canIdentifyUserpilot,
    defaultAddress?.state,
    email,
    fullName,
    managementSystem?.name,
    registrationDate,
    shop?.attributes?.defaultShippingZipCode,
    shop?.orderingStatistic?.totalPaidOrders,
    shopName,
    subscription?.plan?.name,
    subscription?.startedAt,
    suppliersNames,
  ]);
};
