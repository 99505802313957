import { Status } from '@partstech/ui';
import { ProductAlias } from '../../../../constants';

type Props = {
  alias: ProductAlias;
  isIncluded?: boolean;
};

export const PlanForCompareStatus = ({ alias, isIncluded = false }: Props) => {
  if (isIncluded) {
    return (
      <Status builtIn="top-left" color="positive">
        included
      </Status>
    );
  }

  if (alias === ProductAlias.Complete) {
    return (
      <Status builtIn="top-left" color="positive" leadingIcon="star">
        Best value
      </Status>
    );
  }

  if (alias === ProductAlias.Plus) {
    return (
      <Status builtIn="top-left" color="primary" leadingIcon="star">
        most popular
      </Status>
    );
  }

  return null;
};
