import { useCreateModal, usePrevious } from '@partstech/ui/hooks';
import { isNumber } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { Routes } from 'constant';
import { useSaveLastUrl } from 'hooks/app';
import { LeavePagePromptModal } from './LeavePagePromptModal';
import { RouterContextProvider } from './RouterContext';
import type { RouterContext } from './RouterContext';
import type { PropsWithChildren } from 'react';
import type { NavigateOptions, To } from 'react-router-dom';

const excludedScrollToRoutes = [Routes.SEARCH_RESULTS, Routes.STOCK_ORDER_RESULTS];

export const RouterProvider = ({ children }: PropsWithChildren) => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const prevLocation = usePrevious(location);

  const { pathname, search } = location;

  const [isBlocked, setIsBlocked] = useState(false);
  const [tryToNavigate, setTryToNavigate] = useState<{ to: To | number; option?: NavigateOptions } | null>(null);

  const onLeavePage = useCallback(() => {
    setIsBlocked(false);

    if (tryToNavigate) {
      isNumber(tryToNavigate.to) ? navigate(tryToNavigate.to) : navigate(tryToNavigate.to, tryToNavigate.option);
    }
  }, [navigate, tryToNavigate]);

  const { open, close, opened } = useCreateModal(LeavePagePromptModal, {});

  const onKeepPage = useCallback(() => {
    setTryToNavigate(null);
    close();
  }, [close]);

  useEffect(() => {
    if (
      prevLocation?.state?.background?.pathname === pathname ||
      excludedScrollToRoutes.some((path) => Boolean(matchPath({ path }, pathname)))
    ) {
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname, prevLocation?.state?.background?.pathname]);

  // TODO: Moved to useUserPilot
  useEffect(() => {
    window.userpilot && window.userpilot.reload && window.userpilot.reload();
  }, [pathname, search]);

  const contextValue: RouterContext = useMemo(
    () => ({
      isBlocked,
      block: () => setIsBlocked(true),
      unBlock: () => setIsBlocked(false),
      setTryToNavigate,
    }),
    [isBlocked]
  );

  useEffect(() => {
    if (tryToNavigate && !opened) {
      open({ onLeavePage, onKeepPage, onClose: onKeepPage, unclosable: true, size: 'small' });
    }
  }, [onKeepPage, onLeavePage, open, opened, tryToNavigate]);

  useSaveLastUrl();

  return <RouterContextProvider value={contextValue}>{children}</RouterContextProvider>;
};
