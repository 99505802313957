import { api as generatedApi } from './UpdateMatrix.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateMatrix: {
      invalidatesTags: ['Matrix', 'ActiveCart'],
    },
  },
});

export const { useUpdateMatrixMutation } = api;
