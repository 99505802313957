import 'react-app-polyfill/stable';
import { FullStory, init } from '@fullstory/browser';
import * as squatch from '@saasquatch/squatch-js';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { App } from 'app/App';
import { isDevEnvironment, environment, release } from 'app/config';
import { checkModuleError } from 'app/ErrorBoundary/checkModuleError';
import { getFullStorySessionId } from 'integrations/fullstory';
import { sentryFullstory } from 'integrations/fullstory/sentry';
import { config as squatchConfig } from 'integrations/saaSquatch';
import { client } from 'shared/api';

init({
  orgId: 'SC78R',
  devMode: isDevEnvironment(),
  recordOnlyThisIFrame: true,
});

client.interceptors.request.use((request) => {
  // eslint-disable-next-line no-param-reassign
  request.headers['X-FSID'] = getFullStorySessionId();
  return request;
});

Sentry.init({
  allowUrls: [/.*partstech\.com/],
  dsn: 'https://fae64f2aab434d769809c82bdd5380dd@o442774.ingest.sentry.io/5416820',
  debug: false,
  enabled: !isDevEnvironment(),
  release,
  environment,
  integrations: [sentryFullstory('partstech', { client: FullStory })],
  tracesSampleRate: 0,
  normalizeDepth: 10,
  autoSessionTracking: false,
  beforeSend: (event, hint) => {
    const isModuleError = checkModuleError(hint.originalException as Error);
    if (isModuleError) {
      return null;
    }

    return event;
  },
  ignoreErrors: [
    'ChunkLoadError',
    'Failed to fetch dynamically imported module:',
    "Can't execute code from a freed script",
    's.defineSlot is not a function',
    'ResizeObserver loop limit exceeded',
    /^calljs\d+ is not defined$/i,
    'UET is not defined',
  ],
});

squatch.init(squatchConfig);

window.userpilotSettings = { token: '53nu41g1' };

const root = createRoot(document.getElementById('app')!);
root.render(<App />);
