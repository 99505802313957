import { useCallback } from 'react';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { useMutationStatus } from 'shared/api';
import { GOOGLE_ONE_TAP_CONTAINER_ID } from '../../constant';
import { useCreateUserByGoogleOneTapMutation } from '../../store/createUserByGoogleOneTap/CreateUserByGoogleOneTap.generated';
import type { IGoogleEndPointResponse } from 'react-google-one-tap-login/dist/types/types';
import type { UserAccountStatus } from 'shared/api';

type Args = {
  clientId: string;
  onSuccess: (accountStatus?: UserAccountStatus) => Promise<void>;
};

export const useGoogleOneTap = ({ clientId, onSuccess }: Args) => {
  const [createUser, { data, originalArgs, ...status }] = useCreateUserByGoogleOneTapMutation();

  const handleCreateUser = useCallback(
    async (response: IGoogleEndPointResponse) => {
      const firstName = response.given_name || response.email.split('@')[0] || '';

      const input = { email: response.email, firstName, lastName: response.family_name || null };

      const createUserResponse = await createUser({ input });

      if ('error' in createUserResponse) {
        return;
      }

      await onSuccess(createUserResponse.data?.createUser?.accountStatus ?? undefined);
    },
    [createUser, onSuccess]
  );

  useMutationStatus({ status });

  useGoogleOneTapLogin({
    onSuccess: handleCreateUser,
    googleAccountConfigs: {
      client_id: clientId,
      prompt_parent_id: GOOGLE_ONE_TAP_CONTAINER_ID,
      cancel_on_tap_outside: false,
      context: 'signup',
    },
  });
};
