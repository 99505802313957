import { css } from '@emotion/react';
import { Box, Card, Status, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { PLAN_FREE, ProductAlias } from '../../../constants';
import { PeriodSwitcher } from '../../PeriodSwitcher';
import { PlanForCompare } from '../PlanForCompare';
import { FeatureRow } from './FeatureRow';
import { FeaturesEX } from './FeaturesEX';
import { PlanFeatureValue } from './PlanFeatureValue';
import type { PaidFeatureAlias } from '../../../constants';
import type { Plan } from '../../../types';
import type { Theme } from '@partstech/ui';

const styles = {
  wrapper: (theme: Theme) => css`
    .feature-column {
      border-right: 1px solid ${theme.color.mono17};
      padding-left: ${theme.sizing(18)};
      width: ${theme.sizing(100)};
    }

    .plan-column {
      border-right: 1px solid ${theme.color.mono17};
      width: ${theme.sizing(48)};

      &:last-child {
        border-right: none;

        .background {
          border-top-right-radius: ${theme.sizing(2)};
        }
      }
    }
  `,
};

type Props = {
  highlightFeature: PaidFeatureAlias | null;
  isLoading: boolean;
  isRestrictedEX: boolean;
  isYearly: boolean;
  plans: Plan[];
  onControlButtonClick: (alias: ProductAlias) => void;
  periodToggle: () => void;
};

export const DesktopView = ({
  highlightFeature,
  isLoading,
  isRestrictedEX,
  isYearly,
  plans,
  onControlButtonClick,
  periodToggle,
}: Props) => {
  const hasPlanComplete = useMemo(() => plans.some((plan) => plan.alias === ProductAlias.Complete), [plans]);

  return (
    <Card mt={10} mb={8} mx="auto" css={styles.wrapper} width={isRestrictedEX ? 148 : 243}>
      {isRestrictedEX && (
        <Status builtIn="top-left" color="positive">
          included
        </Status>
      )}

      <Box display="flex">
        <Box className="feature-column" pt={27} pb={2} width="100%">
          <Typography variant="h2">{isRestrictedEX ? 'Subscription plan' : 'Your subscription plans'}</Typography>

          <Box mt={7} pr={5}>
            {isRestrictedEX && hasPlanComplete && (
              <Typography variant="bodyText2">
                Includes PartsTech Complete plan and add-ons purchased by SMS.
              </Typography>
            )}

            {!isRestrictedEX && <PeriodSwitcher isChecked={isYearly} onChange={periodToggle} />}
          </Box>

          <Box mt={30} textAlign="center">
            <Typography variant="subtitle2">Key Features</Typography>
          </Box>
        </Box>

        {plans.map((plan) => (
          <PlanForCompare
            key={plan.alias}
            isLoading={isLoading}
            plan={plan}
            onControlButtonClick={onControlButtonClick}
          />
        ))}
      </Box>

      {PLAN_FREE.features.map((feature) => (
        <FeatureRow
          key={feature.name}
          isHighlighted={feature.alias === highlightFeature}
          link={feature.link}
          name={feature.name}
        >
          {plans.map((plan) => (
            <PlanFeatureValue key={plan.alias} featureRow={feature.name} plan={plan} />
          ))}
        </FeatureRow>
      ))}

      <FeaturesEX />
    </Card>
  );
};
