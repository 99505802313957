import { Button, ModalButtons, ModalView, Typography } from '@partstech/ui';
import { useCallback } from 'react';

type Props = {
  onKeepPage?: () => void;
  onLeavePage?: () => void;
  onClose: () => void;
};

export const LeavePagePromptModal = ({ onClose, onLeavePage = () => {}, onKeepPage = () => {} }: Props) => {
  const onLeaveClick = useCallback(() => {
    onLeavePage();
    onClose();
  }, [onClose, onLeavePage]);

  return (
    <ModalView title="Unsaved changes" onClose={onClose}>
      <Typography>
        You have unsaved changes made to this page which will be lost if you navigate away. Are you sure you want to
        leave?
      </Typography>
      <ModalButtons>
        <Button onClick={onKeepPage}>Stay on this page</Button>
        <Button onClick={onLeaveClick} variant="text">
          Leave without saving
        </Button>
      </ModalButtons>
    </ModalView>
  );
};
