import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { getDashboardUrl } from 'utils/url';

export const usePhoneNumber = () => {
  const navigate = useAppNavigate();

  const { shop } = useGetCurrentUser();

  const navigateToDashboard = useCallback(() => {
    navigate(getDashboardUrl());
  }, [navigate]);

  return {
    shopPhoneNumber: shop?.phone ?? '',
    navigateToDashboard,
  };
};
