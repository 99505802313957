import { createSelector } from '@reduxjs/toolkit';
import { CATALOG_GROUP } from 'constant';
import { selectRootState } from 'store/utils';
import { createProductResult } from 'utils';
import { useLazyGetCatalogProductsQuery, api as generatedApi } from './GetCatalogProducts.generated';
import { getSearchInputWithoutAttributes } from './getSearchInputWithoutAttributes';
import type { BaseProductFragment } from './BaseProductFragment.generated';
import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { CatalogSearchInput } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Product'],
  endpoints: {
    GetCatalogProducts: {
      providesTags: () => [{ type: 'Product' }],
      serializeQueryArgs: ({ queryArgs }) => {
        if (!queryArgs.searchInput) {
          return '';
        }

        return `Product(${JSON.stringify(getSearchInputWithoutAttributes(queryArgs.searchInput))})`;
      },
    },
  },
});

export const useGetLazyCatalogProducts = () => {
  const [trigger] = useLazyGetCatalogProductsQuery();

  return {
    getCatalogProducts: trigger,
  };
};

export const selectCatalogSearchResults = createSelector(
  [selectRootState, (_, params: { searchInput: CatalogSearchInput } | null) => params],
  (rootState, params): Record<string, { status: QueryStatus; products: BaseProductFragment[]; errors?: string[] }> => {
    if (!params) {
      return {};
    }

    const { searchInput } = params;

    const result = api.endpoints.GetCatalogProducts.select({ searchInput })(rootState);

    return {
      [CATALOG_GROUP]: createProductResult({
        status: result.status,
        products: result.data?.catalogProducts ?? [],
        errors: result.error?.message ? [result.error.message] : undefined,
      }),
    };
  }
);
