import { css } from '@emotion/react';
import { Box, Scrollbar, Typography } from '@partstech/ui';
import { keys } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { OptionList } from 'components/OptionList';
import { LegacyJob } from 'models';
import { useColumnKeyboardSelection } from 'shared/lib/keyboard';
import { useGroupedOptions } from '../../hooks/useGroupedOptions';
import { isNotCategory } from '../../utils';
import type { Theme } from '@partstech/ui';
import type { SearchEntryPointInterface } from 'features/searchForm';

const styles = {
  content: (theme: Theme) => css`
    display: flex;
    width: 100%;

    ${theme.breakpoints.up('md')} {
      height: -webkit-fill-available;
    }
  `,
};

type Props = {
  className?: string;
  /**
   * Callback to be called when an option is selected to be used
   */
  onChange: (option: SearchEntryPointInterface) => void;
  /**
   * Callback to be called when an option is hovered
   * @param option
   */
  onSelect: (option: SearchEntryPointInterface) => void;
  onSelectVehicleClick?: () => void;
  options: SearchEntryPointInterface[];
  shouldSplitPartTypes?: boolean;
  isKeyboardDisabled?: boolean;
  highlightText?: string;
};

const adornmentAfter = (option: SearchEntryPointInterface) =>
  option instanceof LegacyJob && option.partTypes.length > 1 ? (
    <Typography variant="bodyText2" color="mono56" component="span">
      {option.partTypes.map((partType) => partType.name).join(', ')}
    </Typography>
  ) : undefined;

/**
 * TODO extract to a independent component
 */
export const OptionListGroups = ({
  onChange,
  onSelect,
  options,
  shouldSplitPartTypes = false,
  isKeyboardDisabled,
  highlightText,
  ...props
}: Props) => {
  const { optionsByGroups } = useGroupedOptions(options, shouldSplitPartTypes);

  const names = useMemo(() => keys(optionsByGroups), [optionsByGroups]);
  const columns = useMemo(() => Object.values(optionsByGroups), [optionsByGroups]);

  const { isActiveByColumnIndex } = useColumnKeyboardSelection({
    columns,
    onEnterKeyDown: onChange,
    onArrowKeyDown: onSelect,
    isKeyboardDisabled,
  });

  const showColumns = useMemo(
    () => shouldSplitPartTypes || columns.length >= 2,
    [columns.length, shouldSplitPartTypes]
  );

  return (
    <Scrollbar css={styles.content} data-testid="content" {...props}>
      <Box
        display="flex"
        width="100%"
        flexDirection={showColumns ? 'row' : 'column'}
        flexWrap="wrap"
        gap={{ sm: 4, md: 0 }}
      >
        {columns.map((columnOptions, index) => (
          <OptionList
            adornmentAfter={adornmentAfter}
            highlightText={highlightText}
            isActive={isActiveByColumnIndex(index)}
            key={names[index]}
            onChange={onChange}
            onSelect={onSelect}
            options={columnOptions.filter(isNotCategory)}
            title={names[index]}
            width={{ sm: '100%', md: `${100 / columns.length}%` }}
          />
        ))}
      </Box>
    </Scrollbar>
  );
};
