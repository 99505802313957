import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './UpdateLaborItemInCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateLaborItemInCart: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            const { activeCart } = draft;

            if (!activeCart) {
              return;
            }

            if (data.updateActiveCartLaborItem && 'cartLaborItem' in data.updateActiveCartLaborItem) {
              const { cartLaborItem } = data.updateActiveCartLaborItem;
              const { orderItemId } = cartLaborItem;

              if (!orderItemId) {
                activeCart.laborItems?.forEach((laborItem) => {
                  if (laborItem.id === cartLaborItem.id) {
                    Object.assign(laborItem, { hours: cartLaborItem.hours });
                  }
                });
              } else {
                activeCart.orders?.forEach((order) => {
                  order.items?.forEach((orderItem) => {
                    orderItem.laborItems?.some((laborItem) => {
                      if (laborItem.id === cartLaborItem.id) {
                        Object.assign(laborItem, { hours: cartLaborItem.hours });
                        return true;
                      }
                      return false;
                    });
                  });
                });
              }
            }
          })
        );
      },
    },
  },
});

const { useUpdateLaborItemInCartMutation } = api;

export const useUpdateLaborItemInCart = () => {
  const [mutate, status] = useUpdateLaborItemInCartMutation();

  useMutationStatus({ status });

  return [mutate, status] as const;
};
