import { css } from '@emotion/react';
import { Button, Dropdown, Menu, MenuItem, Typography } from '@partstech/ui';
import { useCallback, useRef } from 'react';
import { SearchTypes } from 'types/search';
import type { DropdownRef } from '@partstech/ui';

const styles = {
  button: css`
    padding: 0;
  `,
  label: css`
    text-transform: capitalize;
  `,
  menuItem: css`
    width: 120px;
    text-transform: capitalize;
  `,
};

type Props = {
  value: SearchTypes;
  onChange?: (searchType: SearchTypes) => void;
  onOpen?: () => void;
};

export const SearchTypeSelector = ({ value, onChange, onOpen }: Props) => {
  const dropdown = useRef<DropdownRef | null>(null);

  const handleToggleClick = useCallback(() => {
    if (!dropdown.current?.isOpened && onOpen) {
      onOpen();
    }

    dropdown.current?.toggle();
  }, [onOpen]);

  const handleMenuItemClick = useCallback(
    (searchType: SearchTypes) => () => {
      if (value === searchType) {
        return;
      }

      onChange && onChange(searchType);
      dropdown.current?.close();
    },
    [onChange, value]
  );

  return (
    <Dropdown
      ref={dropdown}
      renderButton={({ isOpened }) => (
        <Button
          size="dense"
          variant="light"
          onClick={handleToggleClick}
          trailingIcon={isOpened ? 'expand_less' : 'expand_more'}
          data-testid="searchTypeSelector"
          css={styles.button}
        >
          <Typography variant="subtitle1" component="span" css={styles.label}>
            {value}
          </Typography>
        </Button>
      )}
    >
      {() => (
        <Menu fullWidth>
          {Object.values(SearchTypes).map((searchType) => (
            <MenuItem
              key={searchType}
              css={styles.menuItem}
              onClick={handleMenuItemClick(searchType)}
              active={value === searchType}
            >
              {searchType}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Dropdown>
  );
};
