import { api as generatedApi } from './GetSmsRegistrationData.generated';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['SmsRegistrationData'],
  endpoints: {
    GetSmsRegistrationData: {
      providesTags: () => ['SmsRegistrationData'],
    },
  },
});

const { useGetSmsRegistrationDataQuery } = api;

type Args = {
  hash: string;
};

export const useGetSmsRegistrationData = ({ hash }: Args) => {
  const { currentData, ...rest } = useGetSmsRegistrationDataQuery({ hash }, { skip: !hash });

  const smsRegistrationData = currentData?.smsRegistrationData ?? null;

  return { smsRegistrationData, ...rest };
};
