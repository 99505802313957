import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FullStoryEventNames, useTracking } from 'integrations/fullstory/tracking';
import { useAppDispatch } from 'store';
import { useGetQuote } from './useGetQuote';
import type { UseViewQuoteResult } from 'types/quote';

export const useViewQuote = ({ skip }: { skip: boolean }): UseViewQuoteResult => {
  const { sendEvent } = useTracking();

  const dispatch = useAppDispatch();

  const { quoteId = '' } = useParams<{ quoteId: string }>();

  const { tireQuote, isLoading, isError, isSuccess } = useGetQuote(quoteId, skip);

  useEffect(() => {
    if (!skip && !isLoading) {
      if (tireQuote?.quote.slots.length === 0) {
        sendEvent(FullStoryEventNames.CUSTOMER_FAILED_QUOTE, { error: 'tireUnavailable' });
      }

      if (isError) {
        sendEvent(FullStoryEventNames.CUSTOMER_FAILED_QUOTE, { error: 'quoteInvalid' });
      }
    }
  }, [skip, quoteId, dispatch, isLoading, sendEvent, tireQuote?.quote.slots.length, isError]);

  return {
    slots: tireQuote?.quote.slots ?? [],
    quantity: tireQuote?.quote?.quantity ?? 4,
    shop: {
      email: tireQuote?.shop?.email ?? null,
      logo: tireQuote?.shop?.logo ?? null,
      name: tireQuote?.shop?.name ?? '',
      phone: tireQuote?.shop?.phone ?? null,
    },
    isExecuted: tireQuote?.isExecuted ?? false,
    isSuccess,
    isError,
    isLoading,
  };
};
