import { isEmpty } from '@partstech/ui/utils';
import { useSelectedSmsFromQuery } from 'hooks/shopManagementSystems';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { getSignUpUrl } from 'utils/url';

export const useCreateShopRedirect = () => {
  const { shop, managementSystem, hasSignUpDetailsStep, onboard } = useGetOnBoard();

  const { selectedSms } = useSelectedSmsFromQuery();

  const isManagementSystemStep = !managementSystem && !onboard?.length;
  const isDetailsStep = !hasSignUpDetailsStep;

  if (isEmpty(shop)) {
    return getSignUpUrl('create-shop', { selectedSms });
  }

  if (isManagementSystemStep) {
    return getSignUpUrl('select-sms');
  }

  if (isDetailsStep) {
    return getSignUpUrl('details');
  }

  return null;
};
