import { Box, GraphicButton } from '@partstech/ui';
import partsIcon from 'images/icons/signUp/parts.svg';
import partsTiresIcon from 'images/icons/signUp/partsTires.svg';
import tiresIcon from 'images/icons/signUp/tires.svg';
import { AdditionalDetail } from 'shared/api';
import { useSelectShopModes } from './useSelectShopModes';
import type { ShopMode } from '../../../types';

const shopTypeModes: ShopMode[] = [
  { id: 1, modes: [AdditionalDetail.Parts], label: 'Parts only', logo: partsIcon },
  { id: 2, modes: [AdditionalDetail.Tires], label: 'Tires only', logo: tiresIcon },
  { id: 3, modes: [AdditionalDetail.Parts, AdditionalDetail.Tires], label: 'Parts + Tires', logo: partsTiresIcon },
];

type Props = {
  onSelect: (modes: AdditionalDetail[]) => void;
};

export const SelectShopModes = ({ onSelect }: Props) => {
  const { selectedShopMode, selectShopMode } = useSelectShopModes({ onSelect });

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={4}
      mt={6}
      mx="auto"
      mb={{ sm: 6, md: 10 }}
      flexDirection="row"
      flexWrap={{ sm: 'wrap', md: 'nowrap' }}
    >
      {shopTypeModes.map((mode) => (
        <GraphicButton
          isActive={selectedShopMode?.id === mode.id}
          onClick={selectShopMode(mode)}
          imageSrc={mode.logo}
          cardWidth={39}
          cardHeight={39}
          imageHeight={25}
          key={mode.id}
          caption={mode.label}
        />
      ))}
    </Box>
  );
};
