import type { File } from 'types/entityTyped';
import type { Product } from 'types/product';

export const extractImages = (part: Product): File[] => {
  if (!part.images?.other || !part.images.partImage) {
    return [];
  }

  return part.images.other
    .reduce((images, image) => [...images, image], [part.images.partImage])
    .filter(({ isAttachment, isVideo }) => !isVideo && !isAttachment);
};
