import { createSubCategoryFromQuery } from 'factories';
import type { PartSubCategory, PartType } from 'models';

export const getSubCategoriesWithPartTypes = (subCategories: PartSubCategory[], partTypes: PartType[]) =>
  subCategories.reduce<PartSubCategory[]>((prev, subCategory) => {
    const partTypesBySubCategory = partTypes.filter((partType) => partType.subCategoryId === subCategory.id);

    if (partTypesBySubCategory.length && !prev.some((item) => item.id === subCategory.id)) {
      return [...prev, createSubCategoryFromQuery(subCategory, subCategory.categoryId, partTypesBySubCategory)];
    }

    return prev;
  }, []);
