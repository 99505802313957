import { usePrevious } from '@partstech/ui/hooks';
import { useMemo } from 'react';
import { NavigationType, useMatch, useNavigationType } from 'react-router-dom';
import { useGetSmsRegistrationData } from 'features/smsConnections';
import { useSmsConnectionsRouter } from 'hooks/smsConnections';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { SmsConnectionTypes, SmsConnectionsPages } from 'utils/url';

export const useSmsConnectionsRedirect = () => {
  const navigationType = useNavigationType();

  const {
    pathParams: { sms, hash },
    generateSmsConnectionsUrl,
  } = useSmsConnectionsRouter();

  const { smsRegistrationData, isSuccess, isError } = useGetSmsRegistrationData({ hash });

  const isNeedFirstRedirect = usePrevious(!isSuccess);

  const isAlreadyRegistered = smsRegistrationData?.alreadyRegistered;

  const isSignUpPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.SignUp)));
  const isLogInPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.LogIn)));
  const isConnectPage = Boolean(useMatch(generateSmsConnectionsUrl('')));
  const isErrorPage = Boolean(useMatch(generateSmsConnectionsUrl(SmsConnectionsPages.Error)));

  const { activeMember, isSuccess: isSuccessCurrentUser } = useGetCurrentUser();

  const isSmsValid = useMemo(
    () => Boolean(Object.values(SmsConnectionTypes).find((currentSms) => currentSms === sms)),
    [sms]
  );

  const authPage = isAlreadyRegistered ? SmsConnectionsPages.LogIn : SmsConnectionsPages.SignUp;

  if (!hash || isError || !isSmsValid) {
    if (isErrorPage) {
      return null;
    }

    return generateSmsConnectionsUrl(SmsConnectionsPages.Error);
  }

  if (!isSuccess || !isSuccessCurrentUser) {
    return null;
  }

  if (!activeMember && isConnectPage) {
    return generateSmsConnectionsUrl(authPage);
  }

  if (activeMember && (isSignUpPage || isLogInPage)) {
    return generateSmsConnectionsUrl();
  }

  if (isNeedFirstRedirect && navigationType === NavigationType.Pop) {
    if (activeMember) {
      return generateSmsConnectionsUrl();
    }

    return generateSmsConnectionsUrl(authPage);
  }

  return null;
};
