import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import type { LDFlagSet } from 'types/launchdarkly';

export const useGetLaunchDarklyFlags = () => {
  const {
    clearSearchBarAfterChangingVehicle,
    fluidsChemicalsTab,
    mitchell1TransferRetry,
    newCart,
    optionsPreferences,
    ordersPageUpdates,
    reCaptcha,
    signUpQuestions,
    tireQuoteGql,
    shippingCheckbox,
    vehicleSpecificJobs,
    xlPartsTphEtas,
    stockOrderSearchForm,
    xlPartsTphEtasUi,
    saveLaborInCart,
  } = useFlags();

  const [flags, setFlags] = useState<LDFlagSet>({});

  useEffect(() => {
    setFlags({
      clearSearchBarAfterChangingVehicle,
      fluidsChemicalsTab,
      mitchell1TransferRetry,
      newCart,
      optionsPreferences,
      ordersPageUpdates,
      reCaptcha,
      signUpQuestions,
      tireQuoteGql,
      shippingCheckbox,
      vehicleSpecificJobs,
      xlPartsTphEtas,
      stockOrderSearchForm,
      xlPartsTphEtasUi,
      saveLaborInCart,
    });
  }, [
    clearSearchBarAfterChangingVehicle,
    fluidsChemicalsTab,
    mitchell1TransferRetry,
    newCart,
    optionsPreferences,
    ordersPageUpdates,
    reCaptcha,
    shippingCheckbox,
    signUpQuestions,
    stockOrderSearchForm,
    tireQuoteGql,
    vehicleSpecificJobs,
    xlPartsTphEtas,
    xlPartsTphEtasUi,
    saveLaborInCart,
  ]);

  return flags;
};
