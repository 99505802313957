import { useLoadScript } from '@react-google-maps/api';
import { useMemo } from 'react';
import { GoogleMapsContextProvider } from './GoogleMapsContext';
import type { LoadScriptProps } from '@react-google-maps/api';
import type { PropsWithChildren } from 'react';

const libraries: LoadScriptProps['libraries'] = ['places'];

export const GoogleMapsProvider = ({ children }: PropsWithChildren) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyC_ESy9UR1txPWJZkE46a5RYdrzHj5khtw',
    libraries,
    language: 'en',
    version: '3.52',
  });

  const value = useMemo(() => ({ isLoaded, loadError }), [isLoaded, loadError]);

  return <GoogleMapsContextProvider value={value}>{children}</GoogleMapsContextProvider>;
};
