import { Icon, Typography, Divider, MenuItem, useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import React, { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { NicknameModal } from 'components/SupplierSelector/SupplierTabs/NicknameModal';
import { AddressString, AddressStringFormat } from 'entities/address';
import { useSearchParams } from 'hooks/search';
import { useSupplierAccounts, useChangeSupplierAccountPriorities } from 'hooks/supplierAccounts';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { getMyShopUrl } from 'utils/url';
import type { SupplierAccount } from 'models';

type Props = {
  supplierAccount: SupplierAccount;
  onClose: () => void;
};

export const SupplierInformationContent = ({ supplierAccount, onClose }: Props) => {
  const navigate = useAppNavigate();
  const { isMobile } = useMedia();
  const { isTiresSearch } = useSearchParams();

  const {
    member: { suppliersAllowed },
  } = usePermissions();

  const { store, supplier, nickname } = supplierAccount;

  const { open: opeNicknameModal } = useCreateModal(
    NicknameModal,
    {
      size: 'medium',
      supplierAccount,
    },
    { customId: 'nicknameModal' }
  );

  const { accountIds } = useSupplierAccounts({ type: isTiresSearch ? 'tires' : 'parts' });
  const [changeSupplierAccountPriorities] = useChangeSupplierAccountPriorities();

  const handleChangeSuppliersClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      navigate(getMyShopUrl('suppliers', { tab: isTiresSearch ? 'tires' : 'parts' }));
    },
    [navigate, isTiresSearch]
  );

  const handleChangeFirstCallSupplierClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      const supplierAccountsPriority = [
        supplierAccount.id,
        ...accountIds.filter((item) => item !== supplierAccount.id),
      ];

      changeSupplierAccountPriorities(supplierAccountsPriority);

      onClose();
    },
    [supplierAccount.id, accountIds, changeSupplierAccountPriorities, onClose]
  );

  const handleNicknameModalOpen = useCallback(() => {
    opeNicknameModal();

    onClose();
  }, [onClose, opeNicknameModal]);

  return (
    <>
      <Typography variant="subtitle1">{supplierAccount.name}</Typography>
      {!supplier?.isLocationHidden && store?.address && (
        <AddressString
          address={store.address}
          format={AddressStringFormat.ONELINE}
          variant="bodyText2"
          color="subtleText"
        />
      )}

      <>
        <Divider my={isMobile ? 6 : 3} type="dashed" color="mono6" />
        <MenuItem
          size="dense"
          adornmentBefore={<Icon name="edit" color="subtleText" />}
          onClick={handleChangeSuppliersClick}
        >
          Manage suppliers
        </MenuItem>
        {!supplierAccount.isFirstCall && (
          <MenuItem
            size="dense"
            adornmentBefore={<Icon name="star" color="subtleText" />}
            disabled={!suppliersAllowed}
            onClick={handleChangeFirstCallSupplierClick}
          >
            Make it first call
          </MenuItem>
        )}
        {supplierAccount?.isApproved && (
          <MenuItem
            size="dense"
            adornmentBefore={<Icon name="domain" color="subtleText" />}
            onClick={handleNicknameModalOpen}
            disabled={!suppliersAllowed}
          >
            {nickname ? 'Edit nickname' : 'Add nickname'}
          </MenuItem>
        )}
      </>
    </>
  );
};
