import { Routes } from 'constant';
import { generateUrl } from './generateUrl';

export type SignUpFormVariant = 'detailed' | 'default';

export type SmsConnectionsQueryParams = Partial<{
  signUpFormVariant: SignUpFormVariant;
}>;

export enum SmsConnectionTypes {
  ShopMonkey = 'shop-monkey',
}

export enum SmsConnectionsPages {
  ForgotPassword = 'forgot-password',
  HowToUse = 'how-to-use',
  LogIn = 'log-in',
  SignUp = 'sign-up',
  Error = 'error',
  Success = 'success',
}

export const getSmsConnectionsUrl = ({
  sms = SmsConnectionTypes.ShopMonkey,
  page = '',
  hash = '',
  search,
}: {
  sms: SmsConnectionTypes;
  page?: SmsConnectionsPages | '';
  hash: string;
  search?: SmsConnectionsQueryParams;
}) => generateUrl(Routes.SMS_CONNECTIONS, { sms, hash, page }, search);
