import { useCallback } from 'react';
import { useCreateShopManagementSystemMutation } from 'features/shop/api/createShopManagementSystem';
import { FullStoryEventNames, useTracking } from 'integrations/fullstory/tracking';
import { useMutationStatus, type MutationStatusArgs, type CreateShopManagementSystemRelationInput } from 'shared/api';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';

const NO_SMS = 'NO SMS';

const matchesCurrentSystem = (
  { managementSystemId, noSms }: CreateShopManagementSystemRelationInput,
  currentSystem: { id: string | null; name: string } | null
): boolean => {
  if (!currentSystem) {
    return false;
  }

  return (noSms && currentSystem.name === NO_SMS) || managementSystemId === currentSystem.id;
};

type Args = Omit<MutationStatusArgs, 'status'>;

export const useCreateManagementSystem = ({ onSuccess, ...args }: Args = {}) => {
  const { sendEvent } = useTracking();
  const { managementSystem } = useGetOnBoard();
  const [createManagementSystem, status] = useCreateShopManagementSystemMutation();

  const handleCreateManagementSystem = useCallback(
    async (input: CreateShopManagementSystemRelationInput) => {
      const isMatch = matchesCurrentSystem(input, managementSystem);

      if (isMatch) {
        onSuccess?.();
        return;
      }

      await createManagementSystem({ input });
    },
    [createManagementSystem, managementSystem, onSuccess]
  );

  const handleSuccess = useCallback(() => {
    sendEvent(FullStoryEventNames.SELECT_SMS);
    onSuccess?.();
  }, [onSuccess, sendEvent]);

  useMutationStatus({ ...args, status, onSuccess: handleSuccess });

  return [handleCreateManagementSystem, status] as const;
};
