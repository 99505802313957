import { useCallback, useState } from 'react';

export const useToggleCategory = <T,>() => {
  const [openedCategory, setOpenedCategory] = useState<T | null>(null);

  const resetCategory = useCallback(() => {
    setOpenedCategory(null);
  }, []);

  return { openedCategory, resetCategory, setOpenedCategory };
};
