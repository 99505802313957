import { DefaultFilter } from '../DefaultFilter';
import type { FilterProps } from '../DefaultFilter/types';

export const AvailabilityFilter = ({ options, ...restProps }: FilterProps) => {
  if (options.length <= 1) {
    return null;
  }

  return <DefaultFilter {...restProps} options={options} />;
};
