import { FullStory } from '@fullstory/browser';
import { keys } from '@partstech/ui/utils';
import { isDevEnvironment } from 'app/config';
import { pickDefinedValues } from 'shared/lib/object';

export type FSUserVars = {
  shopName?: string[];
  accountType?: 'DIFM' | 'supplier';
  displayName?: string;
  email?: string;
  package?: string[];
  featureFlag?: string[];
  userName?: string;
  usersCount?: number;
  partSupplierCount?: number;
  oemSupplierCount?: number;
  tireSuppliersCount?: number;
  shopManagementSystemName?: string;
  punchoutSessionId?: string | null;
  smsValidatedDate?: Date | null;
  isDemo?: boolean;
  numberOrders?: number;
  retailSetupDate?: Date;
  partMatrixCount?: number;
  tireMatrixCount?: number;
  dealersSupplierCount?: number;
  tireCustomBrands?: number;
  tirePackageCount?: number;
  partCustomBrands?: number;
  preferredTireBrandType?: string;
  preferredPartBrandType?: string;
  ldflags?: string[];
};

export const FSSetUserVars = (userVars: FSUserVars) => {
  if (isDevEnvironment()) {
    return;
  }

  const definedUserVars = pickDefinedValues(userVars);

  if (keys(definedUserVars).length === 0) {
    return;
  }

  FullStory.setUserVars(definedUserVars);
};
