import { useLocation } from 'react-router-dom';
import type { PaidFeatureAlias } from 'features/subscription';
import type { Location } from 'react-router-dom';
import type { UserAccountStatus } from 'shared/api';

export type LocationState = Partial<{
  background: Location;
  from: string;
  highlightFeature: PaidFeatureAlias;
  shipmentErrorId: number;
  signUpAccountStatus: UserAccountStatus;
  prevSearch: string;
}>;

export const useAppLocation = () => useLocation() as Omit<Location, 'state'> & { state: LocationState };
