import { css } from '@emotion/react';
import { Button, Box, Stepper, useMedia } from '@partstech/ui';
import { useCreateShopStepper } from './useCreateShopStepper';
import type { Theme } from '@partstech/ui';

const styles = {
  backButton: (theme: Theme) => css`
    ${theme.breakpoints.down('md')} {
      .ui-button {
        width: ${theme.sizing(8)};
        height: ${theme.sizing(8)};
      }
    }

    .ui-button:active {
      .icon {
        color: ${theme.color.white};
      }
    }
  `,
};

export const CreateShopStepper = () => {
  const { isMobile } = useMedia();

  const { steps, stepNames, currentStep, previousStep, changeStep, navigateToPreviousStep, showPreviousButton } =
    useCreateShopStepper();

  return (
    <>
      {showPreviousButton && (
        <Box position="absolute" top={{ sm: 1, md: 12 }} left={{ sm: 1, md: 2 }} css={styles.backButton}>
          <Button
            variant={isMobile ? 'light' : 'text'}
            leadingIcon={isMobile ? 'arrow_back' : 'chevron_left'}
            iconColor={isMobile ? 'white' : 'primary'}
            onClick={navigateToPreviousStep}
            data-testid="prevStepButton"
          >
            {!isMobile && `Back to ${steps[previousStep]?.name ?? ''}`}
          </Button>
        </Box>
      )}

      <Box width={70} mt={{ sm: 4, md: 10 }} mx={{ sm: 6, md: 'auto' }} mb={{ sm: 6, md: 10 }}>
        <Stepper canReturn currentStep={currentStep + 1} steps={stepNames} onChange={changeStep} />
      </Box>
    </>
  );
};
