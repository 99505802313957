import { useCallback, useEffect, useState } from 'react';

export type Option = string;

type Args = {
  defaultOption?: Option;
  onSelect?: (option: Option) => void;
};

export const useQuestionOptions = ({ defaultOption, onSelect }: Args) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(defaultOption ?? null);

  const selectOption = useCallback(
    (option: Option) => () => {
      setSelectedOption(option);

      onSelect?.(option);
    },
    [onSelect]
  );

  useEffect(() => {
    if (!defaultOption) {
      return;
    }

    setSelectedOption(defaultOption);
  }, [defaultOption]);

  return { selectedOption, selectOption };
};
