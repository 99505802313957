import { isEmpty } from '@partstech/ui/utils';
import { useEffect } from 'react';
import { useWelcomeBackModal } from 'components/Modal/useWelcomeBackModal';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { useIsHiddenSignUpStepper } from '../sessionStorage/useIsHiddenSignUpStepper';

type Args = {
  skip?: boolean;
};

export const useSignUpComeBack = ({ skip }: Args) => {
  const { setIsHiddenSignUpStepper } = useIsHiddenSignUpStepper();

  const { shop, managementSystem, hasSignUpDetailsStep, onboard, skipOnboard } = useGetOnBoard();

  const isManagementSystemStep = !managementSystem && !onboard?.length;
  const isDetailsStep = !hasSignUpDetailsStep;

  const { open } = useWelcomeBackModal();

  useEffect(() => {
    if (skip || isEmpty(shop) || skipOnboard) {
      return;
    }

    if (isManagementSystemStep) {
      open();
      return;
    }

    if (managementSystem && isDetailsStep) {
      setIsHiddenSignUpStepper(true);
    }
  }, [
    isDetailsStep,
    isManagementSystemStep,
    managementSystem,
    open,
    setIsHiddenSignUpStepper,
    shop,
    skip,
    skipOnboard,
  ]);
};
