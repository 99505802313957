import { css } from '@emotion/react';
import { Box, Button, Typography, useMedia } from '@partstech/ui';
import { plural } from '@partstech/ui/utils';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store';
import { selectPartById } from 'store/entities/shipmentPart';
import type { Theme } from '@partstech/ui';

const styles = {
  root: (theme: Theme) => css`
    background: rgba(0, 0, 0, 0.8);
    border-radius: ${theme.sizing(1)};

    ${theme.breakpoints.down('md')} {
      border-radius: 0;
    }
  `,
  button: (theme: Theme) => css`
    color: ${theme.color.white};
  `,
};

type Props = {
  partId: number;
  onClick?: () => void;
};

export const RemoveFromCartSnackbar = ({ partId, onClick }: Props) => {
  const { isMobile } = useMedia();
  const part = useAppSelector((state) => selectPartById(state, partId), shallowEqual);

  if (!part) {
    return null;
  }

  return (
    <Box
      color="white"
      width={isMobile ? '100vw' : 120}
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      css={styles.root}
      data-testid="snackbar"
    >
      <Typography color="white">
        {`You removed ${part.quantity} ${plural('item', part.quantity)} (${part.product?.partNumber}) from your cart`}
      </Typography>
      <Button variant="light" onClick={onClick} css={styles.button}>
        Undo
      </Button>
    </Box>
  );
};
