import { Box, Switcher, Typography } from '@partstech/ui';
import { DiscountNotice } from '../DiscountNotice';
import type { BoxProps } from '@partstech/ui';
import type { ReactNode } from 'react';

type Props = BoxProps & {
  adornmentAfter?: ReactNode;
  adornmentBefore?: ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
};

export const PeriodSwitcher = ({
  adornmentAfter,
  adornmentBefore,
  isChecked,
  isDisabled,
  onChange,
  ...props
}: Props) => (
  <Box display="flex" alignItems="baseline" gap={1} {...props}>
    {adornmentBefore}

    <Switcher alignItems="center" name="period" checked={isChecked} disabled={isDisabled} onChange={onChange}>
      <Typography variant="caption">Monthly</Typography>
    </Switcher>

    <Typography variant="caption">Yearly</Typography>

    <DiscountNotice component="span" isYearly={isChecked} />

    {adornmentAfter}
  </Box>
);
