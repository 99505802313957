import { useEffect } from 'react';
import { COUNTRIES } from 'shared/constant';
import { useGoogleMaps } from '../components/GoogleMapsProvider/GoogleMapsContext';
import { extractAddressFromGoogleResult } from '../utils/extractAddressFromGoogleResult';
import type { Address } from 'shared/api';

const googlePlaceAutocompleteOptions = {
  componentRestrictions: {
    country: COUNTRIES.map((country) => country.value.toLowerCase()),
  },
};

export const useGooglePlacesAutocomplete = (
  inputRef: HTMLInputElement | null,
  onSelect: (address: Address | null) => void
) => {
  const { isLoaded } = useGoogleMaps();

  useEffect(() => {
    if (!isLoaded || !inputRef) {
      return () => {};
    }

    const autocomplete = new google.maps.places.Autocomplete(inputRef, googlePlaceAutocompleteOptions);

    autocomplete.setFields(['address_component']);

    const autocompleteListener = autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const address = extractAddressFromGoogleResult(place.address_components);

      onSelect(address);
    });

    return () => {
      google.maps.event.removeListener(autocompleteListener);

      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, [isLoaded, inputRef, onSelect]);
};
