import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store';
import { selectActualShipments } from 'store/entities/shipment';
import type { CartError, CartErrors, FullShipment } from 'types/cart';

const hasFreightTerms = (shipment: FullShipment) =>
  shipment.freightTerm !== null && Boolean(shipment.freightTerms?.length > 0);

const isHotshotNoArea = (shippingMethodCode: string | null) => shippingMethodCode === 'hotshot_noarea';

const hasOnlyHotshotNoArea = (shipment: FullShipment) =>
  shipment.freightTerms.length === 1 && isHotshotNoArea(shipment.freightTerms[0]?.service.shippingMethodCode ?? null);

const getShipmentClientErrors = (shipment: FullShipment): CartError[] => {
  const clientErrors: CartError[] = [];

  const isValidFreightTerm =
    (hasFreightTerms(shipment) || shipment.isLoading) &&
    !isHotshotNoArea(shipment.freightTerm) &&
    !hasOnlyHotshotNoArea(shipment);

  if (!isValidFreightTerm) {
    clientErrors.push({
      shipmentId: shipment.id,
      message: `${shipment.supplier?.name} has a delivery error.`,
    });
  }

  shipment.additionalFields?.forEach((field) => {
    if (field.required && !field.default) {
      clientErrors.push({
        shipmentId: shipment.id,
        message: `Field ${field.label} is required.`,
      });
    }
  });

  return clientErrors;
};

const getShipmentApiErrors = (shipment: FullShipment): CartError[] =>
  Object.values(shipment.errors).map((error) => ({
    shipmentId: shipment.id,
    message: error,
  }));

export const useCartErrors = (): CartErrors => {
  const shipments = useAppSelector(selectActualShipments, shallowEqual);

  const clientErrors = useMemo(
    () => shipments.reduce<CartError[]>((acc, shipment) => [...acc, ...getShipmentClientErrors(shipment)], []),
    [shipments]
  );

  const apiErrors = useMemo(
    () => shipments.reduce<CartError[]>((acc, shipment) => [...acc, ...getShipmentApiErrors(shipment)], []),
    [shipments]
  );

  const errors = useMemo(() => [...clientErrors, ...apiErrors], [apiErrors, clientErrors]);

  const hasError = useMemo(() => errors.length > 0, [errors]);

  return { clientErrors, apiErrors, errors, hasError };
};
