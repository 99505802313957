import { Box, Button, Card } from '@partstech/ui';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { ContinueWithoutSystemButton } from './ContinueWithoutSystemButton';
import { CreateSystemForm } from './CreateSystemForm';

type Props = {
  onSubmit: () => void;
  onSearchClick?: () => void;
};

export const CreateSystem = ({ onSubmit, onSearchClick }: Props) => {
  const { shop } = useGetCurrentUser();

  const {
    flags: { signUpQuestions: isSignUpQuestions },
  } = useLaunchDarkly();

  return (
    <Box textAlign="center" data-testid="createSystem">
      <Button variant="text" leadingIcon="autorenew" onClick={onSearchClick}>
        Try searching again
      </Button>

      <Box display="flex" justifyContent="center" my={6}>
        <Card p={6} maxWidth={isSignUpQuestions && shop?.newOnboard ? 128 : 88}>
          <CreateSystemForm onSubmit={onSubmit} />
        </Card>
      </Box>

      <ContinueWithoutSystemButton onSubmit={onSubmit} />
    </Box>
  );
};
