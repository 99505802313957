import { OrderItemProductAvailabilityLineType } from 'shared/api';
import type { CartOrderItemFragment } from '../api/queries';
import type { Product } from 'models';

export const createOrderItemData = (id: string, quantity: number, product: Product): CartOrderItemFragment => ({
  id,
  image: product.images.image,
  partName: product.title,
  partNumber: product.partNumber?.partNumber || '',
  partNumberId: product.getPartNumberId(),
  lineCardId: product.lineCardId,
  quantity,
  partType: {
    id: String(product.partTypeId) || '',
    name: product.partTypeName || '',
  },
  builtItem: {
    bundled: false,
    discount: 0,
    errors: [],
    availableBundles: [],
    product: {
      price: product.price || 0,
      customerPrice: product.retailPrice,
      coreCharge: product.coreCharge,
      fetCharge: product.fetCharge,
      mapPrice: product.mapPrice,
      listPrice: product.listPrice,
      partNumberDisplay: product.partNumber?.partNumber || '',
      availabilityLines:
        product.quote?.lines.map((line) => ({
          name: line.name,
          quantity: line.qty.value,
          type:
            line.type === 'MAIN'
              ? OrderItemProductAvailabilityLineType.Main
              : OrderItemProductAvailabilityLineType.Network,
        })) || [],
      attributes: [],
      notes: [],
      deliveryNotes: [],
      warnings: [],
    },
  },
});
