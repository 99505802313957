/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../../../shared/api/graphql/graphql';

export type SubscriptionFragment = {
  __typename?: 'ShopSubscription';
  amount: Types.Scalars['BigDecimal']['input'];
  currentPeriodEnd: Types.Scalars['DateTime']['input'];
  period: Types.SubscriptionPlanPeriod;
  startedAt?: Types.Scalars['DateTime']['input'] | null;
  plans?: Array<{
    __typename?: 'SubscriptionSubscriptionPlan';
    downgradeProductAlias?: Types.SubscriptionProductAlias | null;
    status: Types.SubscriptionSubscriptionPlanStatus;
    account?: { __typename?: 'Account'; id: string } | null;
    plan?: {
      __typename?: 'SubscriptionPlan';
      amount: Types.Scalars['BigDecimal']['input'];
      id: string;
      product?: {
        __typename?: 'SubscriptionProduct';
        alias: Types.SubscriptionProductAlias;
        isPlan: Types.Scalars['Boolean']['input'];
        unsubscribeReasons?: Array<Types.Scalars['String']['input']> | null;
      } | null;
    } | null;
  }> | null;
};

export const SubscriptionFragmentDoc = `
    fragment Subscription on ShopSubscription {
  amount
  currentPeriodEnd
  period
  plans {
    account {
      id
    }
    downgradeProductAlias
    plan {
      amount
      id
      product {
        alias
        isPlan
        unsubscribeReasons
      }
    }
    status
  }
  startedAt
}
    `;
