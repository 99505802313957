import { makeContext } from '@partstech/ui/utils';
import type { Highlight, AddToCartPayload } from 'app/CartProviderOld/types';
import type { LaborRate, ResponseError } from 'shared/api';
import type { AddItemResponse } from 'shared/api/rest/gen/shop';
import type { GetShipmentsArgs } from 'store/actions/cart';
import type { CartErrors } from 'types/cart';
import type { FullShipmentPart } from 'types/shipment';

export type CartContextValue = {
  updateShipments: (arg?: GetShipmentsArgs) => void;
  addToCart: (item: AddToCartPayload, onAddSuccess?: (response?: AddItemResponse | ResponseError) => void) => void;
  addToCartMultiple: (
    items: AddToCartPayload[],
    onFinally?: (responses?: AddItemResponse[] | ResponseError) => void
  ) => void;
  removeFromCart: (item: FullShipmentPart) => void;
  checkIsPartAdding: (id?: string) => boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  addHighlight: (highlight: Highlight[]) => void;
  getHighlight: (partId: number) => Highlight['type'] | undefined;
  laborRate: LaborRate | null;
  setLaborRate: (laborRate: LaborRate | null) => void;
  isScrolling: boolean;
  onScroll: () => void;
  cartErrors: CartErrors;
};

export const [useCartOld, CartContextProvider] = makeContext<CartContextValue>();
