import { entries } from '@partstech/ui/utils';
import { useCallback, useMemo, useState } from 'react';
import type { UniversalPartType } from 'models';

type Attributes = Record<string, string[]>;

type Props = {
  partType: UniversalPartType;
  onReset?: () => void;
};

export const useDynamicAttributeConfigurator = ({ partType, onReset }: Props) => {
  const [selectedAttributes, setSelectedAttributes] = useState<Attributes>(
    partType.attributes?.reduce<Attributes>((acc, attr) => ({ ...acc, [attr.name]: [] }), {}) ?? {}
  );

  const attribute = useMemo(() => {
    const [unSelectedAttributeName] = entries(selectedAttributes).find(([_, value]) => value.length === 0) ?? [];

    return partType.attributes?.find((attr) => attr.name === unSelectedAttributeName);
  }, [partType.attributes, selectedAttributes]);

  const isAttributeSelected = useCallback(
    (attributeName: string) => (selectedAttributes[attributeName]?.length ?? 0) > 0,
    [selectedAttributes]
  );

  const getSelectedValue = useCallback(
    (attributeName: string) => selectedAttributes[attributeName] ?? [],
    [selectedAttributes]
  );

  const getBreadcrumbTitle = useCallback(
    (attributeName: string) =>
      isAttributeSelected(attributeName)
        ? `${getSelectedValue(attributeName)} selected`
        : `Select a ${attributeName.toLowerCase()}`,
    [getSelectedValue, isAttributeSelected]
  );

  const clearSelectedAttributes = useCallback(
    (attributeName: string) => {
      setSelectedAttributes((prevAttributes) => {
        const attributeIndex = partType.attributes?.findIndex((attr) => attr.name === attributeName);

        if (attributeIndex === undefined || attributeIndex === -1) {
          return prevAttributes;
        }

        return Object.fromEntries(
          Object.entries(prevAttributes).map(([key, value]) => {
            const currentIndex = partType.attributes?.findIndex((attr) => attr.name === key);

            return [key, currentIndex !== undefined && currentIndex >= attributeIndex ? [] : value];
          })
        );
      });
    },
    [partType.attributes]
  );

  const breadcrumbs = useMemo(() => {
    const optionBreadcrumb = onReset
      ? {
          title: partType.name,
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            onReset();
          },
        }
      : null;

    const attributeBreadcrumbs =
      partType.attributes?.map((attr) => ({
        title: getBreadcrumbTitle(attr.name),
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          clearSelectedAttributes(attr.name);
        },
      })) ?? [];

    if (optionBreadcrumb) {
      return [optionBreadcrumb, ...attributeBreadcrumbs];
    }

    return attributeBreadcrumbs;
  }, [clearSelectedAttributes, getBreadcrumbTitle, onReset, partType.attributes, partType.name]);

  const isLastAttribute = useMemo(
    () => partType.attributes?.filter(({ name }) => !isAttributeSelected(name)).length === 1,
    [isAttributeSelected, partType.attributes]
  );

  const handleSelectValue = useCallback(
    (name: string, value: string) => {
      setSelectedAttributes({ ...selectedAttributes, [name]: [value] });
    },
    [selectedAttributes]
  );

  return {
    attribute,
    selectedAttributes,
    breadcrumbs,
    selectValue: handleSelectValue,
    isLastAttribute,
  };
};
