import { Link, TextCut, useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { VehicleDetailsModal } from './VehicleDetailsModal';
import type { Vehicle } from 'models';
import type { SyntheticEvent } from 'react';

type Props = {
  vehicle: Vehicle;
  /**
   * @deprecated delete this prop after applying new design
   */
  isNewDesign?: boolean;
};

export const VinButton = ({ vehicle, isNewDesign }: Props) => {
  const { isMobile, isTablet, isDesktop } = useMedia();

  const { open } = useCreateModal(VehicleDetailsModal, { vehicle, size: 'medium' }, { customId: 'vinDetails' });

  const handleOpen = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      open();
    },
    [open]
  );

  if (isNewDesign) {
    return (
      <Link to="#" onClick={handleOpen} data-testid="vinButton" variant="caption" color="linkText" underline="hover">
        <TextCut shouldShowTooltip={false}>VIN: {vehicle.vin}</TextCut>
      </Link>
    );
  }

  return (
    <Link
      to="#"
      onClick={handleOpen}
      data-testid="vinButton"
      variant={isMobile || isTablet || isDesktop ? 'bodyText2' : 'caption'}
      color={isMobile ? 'linkText' : 'primary'}
      underline={isMobile ? 'hover' : 'default'}
    >
      {isMobile ? 'See details' : vehicle.vin}
    </Link>
  );
};
