import { useCallback } from 'react';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { getShipments } from 'store/actions/cart';
import { clearCart } from 'store/entities/shipment';
import { api as generatedApi } from './CreateCart.generated';
import type { CreateCartInput, Exact } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateCart: {
      invalidatesTags: ['ActiveCart'],
    },
  },
});

const { useCreateCartMutation } = api;

export const useCreateCart = () => {
  const dispatch = useAppDispatch();
  const {
    flags: { newCart },
  } = useLaunchDarkly();

  const [createCart, status] = useCreateCartMutation();

  useMutationStatus({ status });

  const triger = useCallback(
    async (input: Exact<{ input: CreateCartInput }>) => {
      await createCart(input);

      if (!newCart) {
        dispatch(clearCart());
        dispatch(getShipments());
      }
    },
    [createCart, dispatch, newCart]
  );

  return [triger, status] as const;
};
