import { targetSupplierIds } from 'constant';
import { AvailabilityLine } from 'models';
import type { OrderItemProductAvailabilityLine, ProductAvailabilityLine } from 'shared/api';

export const createAvailabilityLineFromQuery = (
  line: ProductAvailabilityLine | OrderItemProductAvailabilityLine,
  storeId?: string,
  accountNickname?: string | null,
  supplierId?: string
) => {
  const availabilityLine = new AvailabilityLine({
    ...line,
    address: line.address ?? null,
    group: line.group ?? null,
    id: line.id ?? null,
    qty: { type: 'EQ', value: line.quantity },
    hasTargetSupplier: targetSupplierIds.includes(Number(supplierId)),
  });

  if (availabilityLine.id === Number(storeId)) {
    availabilityLine.setNickname(accountNickname);
  }

  return availabilityLine;
};
