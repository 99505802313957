export const shopTypeOptions = [
  'general repair shop',
  'Tire Shop',
  'quick lube',
  'heavy duty',
  'mobile mechanic',
  'new/used vehicle dealer',
  'fleet repair',
  'parts supplier',
  'Other automotive repair',
];
