export const hearAboutUsOptions = [
  'Shop management system',
  'shop referral',
  'local parts rep',
  'email',
  'demo/webinar',
  'google/search engine',
  'My shop coach',
  'Review site',
  'direct mailer',
  'social media',
  'other',
];
