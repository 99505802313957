import { TrackingStatus } from 'shared/api';
import type { IconName, TypographyProps } from '@partstech/ui';

type OrderStatuses = Record<
  TrackingStatus,
  {
    name: string;
    iconName: IconName;
    color: TypographyProps['color'];
  }
>;

export const ORDER_STATUSES: OrderStatuses = {
  [TrackingStatus.OrderPlaced]: {
    name: 'Order Placed',
    iconName: 'circle',
    color: 'mono17',
  },
  [TrackingStatus.Acknowledged]: {
    name: 'Acknowledged',
    iconName: 'pending',
    color: 'neutral',
  },
  [TrackingStatus.Received]: {
    name: 'Confirmed',
    iconName: 'pending',
    color: 'neutral',
  },
  [TrackingStatus.Shipping]: {
    name: 'Shipping',
    iconName: 'pending',
    color: 'neutral',
  },
  [TrackingStatus.Shipped]: {
    name: 'Shipped',
    iconName: 'pending',
    color: 'neutral',
  },
  [TrackingStatus.Completed]: {
    name: 'Delivered',
    iconName: 'check_circle',
    color: 'positive',
  },
  [TrackingStatus.Cancelled]: {
    name: 'Cancelled',
    iconName: 'block',
    color: 'negative',
  },
  [TrackingStatus.Returned]: {
    name: 'Returned',
    iconName: 'block',
    color: 'negative',
  },
};

export const deliveredStatuses: TrackingStatus[] = [
  TrackingStatus.Completed,
  TrackingStatus.Cancelled,
  TrackingStatus.Returned,
];
