import type { ThemeColorName, IconName } from '@partstech/ui';
import type { AvailabilityLine as AvailabilityLineType, AvailabilityLineVariant } from 'types/product';

export class AvailabilityLine {
  address: string | null;

  group: string | null;

  id: number | null;

  name: string;

  nickname: string | null = null;

  qty: AvailabilityLineType['qty'];

  type: AvailabilityLineType['type'];

  hasTargetSupplier?: boolean;

  constructor(availabilityLine: AvailabilityLineType) {
    this.address = availabilityLine.address;
    this.group = availabilityLine.group;
    this.id = availabilityLine.id;
    this.name = availabilityLine.name;
    this.qty = availabilityLine.qty;
    this.type = availabilityLine.type;
    this.hasTargetSupplier = availabilityLine.hasTargetSupplier;
  }

  get isMaxQuantity() {
    return this.qty.value > 99;
  }

  get formattedQuantity() {
    if (this.qty.type === 'GT') {
      return `${this.qty.value}+`;
    }

    if (this.qty.type === 'EQ') {
      return `${this.qty.value}`;
    }

    return '';
  }

  get formattedMaxQuantity() {
    if (this.isMaxQuantity) {
      return `99+`;
    }

    return this.formattedQuantity;
  }

  isNetwork() {
    return this.type === 'NETWORK';
  }

  isMain() {
    return this.type === 'MAIN';
  }

  isMainInStock() {
    return this.isMain() && this.qty.value > 0;
  }

  isNetworkInStock() {
    return this.isNetwork() && this.qty.value > 0;
  }

  get quantityColor(): ThemeColorName {
    if (this.qty.value === 0) {
      return 'negative';
    }

    if (this.isNetwork()) {
      return 'neutral';
    }

    return 'positive';
  }

  get displayName(): string {
    return this.nickname ?? this.name;
  }

  isEqual(item?: AvailabilityLine): boolean {
    return Boolean(item && ((item.id !== null && item.id === this.id) || item.name === this.name));
  }

  getIcon(): IconName | undefined {
    const variant = this.getVariant();

    if (variant === 'featured' && this.group === 'Home Store') {
      return 'home';
    }

    if (variant === 'default') {
      return 'storefront';
    }

    return undefined;
  }

  getTitle() {
    if (this.isMain()) {
      return this.getMainAvailabilityTitle();
    }

    if (this.isNetwork()) {
      return this.getNetworkAvailabilityTitle();
    }

    return null;
  }

  getMainAvailabilityTitle(): string {
    const variant = this.getVariant();

    if (variant === 'featured' || variant === 'partialMain') {
      if (this.group === 'Home Store') {
        return `In stock (${this.formattedMaxQuantity})`;
      }

      return `${this.group ?? 'In Stock'} (${this.formattedMaxQuantity})`;
    }

    return `In Stock (${this.formattedMaxQuantity})`;
  }

  getNetworkAvailabilityTitle(): string {
    if (this.getVariant() === 'featured') {
      return `${this.group ?? 'Available'} (${this.formattedMaxQuantity})`;
    }

    return `Available (${this.formattedMaxQuantity})`;
  }

  // TODO: Refactor and move this method - DEV-3514
  getVariant(): AvailabilityLineVariant {
    if (this.hasTargetSupplier && this.group === 'Within 2 hours') {
      return 'partialMain';
    }

    if (this.hasTargetSupplier) {
      return 'featured';
    }

    return 'default';
  }

  setNickname(nickname?: string | null) {
    this.nickname = nickname ?? null;
  }
}
