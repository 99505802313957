import { useDeepCompareEffect } from '@partstech/ui/hooks';
import { entries, isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useSmsValidatedDate } from 'hooks/app';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { FSSetUserVars } from '../FSSetUserVars';

type Props = {
  skip: boolean;
};

export const useSendShopData = ({ skip = false }: Props) => {
  const { shopMemberships, shop } = useGetOnBoard();
  const { smsName, punchoutSessionHash: punchoutSessionId, isLoaded: isPunchoutSettingsLoaded } = usePunchoutInfo();
  const { smsValidatedDate, isLoaded: isSmsValidatedDateLoaded } = useSmsValidatedDate();

  const { flags } = useLaunchDarkly();
  const arrayOfFlags = useMemo(() => entries(flags).map(([key, value]) => `${key}=${JSON.stringify(value)}`), [flags]);

  useDeepCompareEffect(() => {
    if (!skip && shopMemberships.length > 0 && isPunchoutSettingsLoaded && isSmsValidatedDateLoaded) {
      FSSetUserVars({
        shopName: shopMemberships?.map((shopMembership) => shopMembership.shop?.name).filter(isNotNull),
        usersCount: shop?.attributes?.usersCount ?? 0,
        numberOrders: shop?.orderingStatistic?.totalPaidOrders ?? 0,
        shopManagementSystemName: smsName ?? '',
        punchoutSessionId,
        smsValidatedDate,
        ldflags: arrayOfFlags,
      });
    }
  }, [
    shopMemberships,
    isPunchoutSettingsLoaded,
    isSmsValidatedDateLoaded,
    smsName,
    punchoutSessionId,
    smsValidatedDate,
    arrayOfFlags,
    shop?.orderingStatistic?.totalPaidOrders,
    shop?.attributes?.usersCount,
    skip,
  ]);
};
