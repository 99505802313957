import { makeContext } from '@partstech/ui/utils';
import type { SignUpStep } from './useSignUpSteps';
import type { ShopFormData } from 'features/shop';
import type { Dispatch, SetStateAction } from 'react';

type CreateShopContext = {
  isDuplicatedShopStep: boolean;
  setIsDuplicatedShopStep: (payload: boolean) => void;
  selectedShop: null | ShopFormData;
  setSelectedShop: (input: null | ShopFormData) => void;
  isMitchell1SignUp: boolean;
  activeStep: SignUpStep;
  nextStep: SignUpStep | null;
  previousStep: SignUpStep | null;
  shopType?: string;
  setShopType: (value: string) => void;
  navigateToNextStep: () => void;
  navigateToPreviousStep: () => void;
  /** @deprecated Remove with old signup */
  isHiddenSignUpStepper: boolean;
  /** @deprecated Remove with old signup */
  setIsHiddenSignUpStepper: (isHidden?: boolean) => void;
  /** @deprecated Remove with old signup */
  isHiddenSignUpHeader: boolean;
  /** @deprecated Remove with old signup */
  setIsHiddenSignUpHeader: Dispatch<SetStateAction<boolean>>;
};

export const [useCreateShopContext, CreateShopContextProvider] = makeContext<CreateShopContext>();
