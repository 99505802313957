import { Box } from '@partstech/ui';
import { FormPasswordField, FormPhoneField, FormSelectBox, FormTextField } from '@partstech/ui/forms';
import { AddressForm } from 'entities/address';
import { shopTypeOptions as shopTypes } from 'entities/signUp';
import { GoogleMapsProvider } from 'integrations/google';
import { of, required } from 'shared/lib/form';
import type { CreateShopAndUserWithSmsConnectInput } from '../../../api/createShopAndUserWithSmsConnect';
import type { Option } from '@partstech/ui';

const shopTypeOptions: Option[] = shopTypes.map((shopType) => ({ value: shopType, text: shopType }));

type Props = {
  useSingleColumn?: boolean;
};

export const DetailedForm = ({ useSingleColumn }: Props) => (
  <>
    <Box display="flex" flexWrap="wrap" gap={2} mb={2}>
      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormTextField name="firstName" label="First name" hideRequiredMark rules={{ required }} />
      </Box>

      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormTextField name="lastName" label="Last name" hideRequiredMark rules={{ required }} />
      </Box>

      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormTextField
          name="email"
          label="Email"
          hideRequiredMark
          rules={{ required }}
          id="email"
          normalize={(value) => value.trim()}
        />
      </Box>

      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormPasswordField
          name="password"
          label="Create password"
          hideRequiredMark
          withComplexValidation
          rules={{ required }}
          id="password"
        />
      </Box>

      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormTextField name="shopName" label="Shop name" hideRequiredMark rules={{ required }} />
      </Box>

      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormSelectBox name="shopType" label="Shop type" options={shopTypeOptions} />
      </Box>
    </Box>

    <GoogleMapsProvider>
      <AddressForm
        path={of<CreateShopAndUserWithSmsConnectInput, 'address'>('address')}
        singleAddressLine={!useSingleColumn}
      />
    </GoogleMapsProvider>

    <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormPhoneField name="shopPhoneNumber" label="Shop phone number" hideRequiredMark rules={{ required }} />
      </Box>

      <Box flex={`1 1 ${useSingleColumn ? '100%' : 'calc(50% - 4px)'}`}>
        <FormTextField name="website" label="Website" />
      </Box>
    </Box>
  </>
);
