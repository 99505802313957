import { entries, isEmpty, isNotNull } from '@partstech/ui/utils';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { TireSize } from 'models';
import { batchGetLabor, updateActiveCartItem } from 'shared/api/rest/gen/shop';
import { getShipments } from 'store/actions/cart';
import { selectProductById } from 'store/entities/product';
import {
  consumeAddToCart,
  consumeAddToCartMultiple,
  consumeRemoveFromCart,
  selectPartStatuses,
} from 'store/features/cart';
import { logout } from 'store/features/user/account';
import { createAppAsyncThunk, selectRootState } from 'store/utils';
import { createPart, getTireSize, getTotalMitchell1LaborHours, selectId } from 'utils';
import {
  clearCart,
  removePaidShipmentsAndParts,
  removeShipmentAndPartsById,
  removeShipmentById,
  selectActualShipmentsIds,
} from './shipment';
import type { EntityId, PayloadAction } from '@reduxjs/toolkit';
import type { CreateTemplateItem } from 'shared/api';
import type { BatchGetLaborResponse, SubmittedLabor } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';
import type { ShipmentType } from 'types/cart';
import type { FullShipmentPart, Labor, Mitchell1Labor, Mitchell1LaborLine, ShipmentPartEntity } from 'types/shipment';

export const getMitchell1Labor = createAppAsyncThunk<
  BatchGetLaborResponse,
  { id: ShipmentPartEntity['id']; partTypeId: number; vehicleId: number; mitchell1VehicleId?: string | null }[],
  { rejectValue: string }
>('entities/shipmentPart/getMitchell1Labor', async (procedureDetails, { rejectWithValue }) => {
  try {
    return await batchGetLabor({
      procedureDetails,
    });
  } catch (e) {
    return rejectWithValue(e.error().message);
  }
});

export const updateQuantity = createAppAsyncThunk<
  void,
  { id: ShipmentPartEntity['id']; quantity: ShipmentPartEntity['quantity'] },
  { rejectValue: string }
>(
  'entities/shipment/updateQuantity',
  async ({ id, quantity }, { rejectWithValue }) => {
    try {
      return await updateActiveCartItem(id, { quantity });
    } catch (e) {
      return rejectWithValue(e.error().message);
    }
  },
  {
    condition: (item, { getState }) => {
      const state = getState();

      const part = state.entities.shipmentPart.entities[item.id];
      const errorQuantity = /Only\s+(.*)+\sleft\sin\sstock/.exec(part?.error || '');
      const maxQuantity = errorQuantity && Number(errorQuantity[1]);

      return !((maxQuantity && item.quantity > maxQuantity) || item.quantity === part?.quantity);
    },
    dispatchConditionRejection: true,
  }
);

const selectState = (state: RootState) => state.entities.shipmentPart;

const shipmentPartsAdapter = createEntityAdapter<ShipmentPartEntity, EntityId>({ selectId: (entity) => entity.id });

const shipmentPartsSlice = createSlice({
  name: 'shipmentPart',
  initialState: shipmentPartsAdapter.getInitialState(),
  reducers: {
    changeMotorLabors: (
      state,
      action: PayloadAction<{
        [partId in string]: Labor;
      }>
    ) => {
      shipmentPartsAdapter.updateMany(
        state,
        entries(action.payload).map(([id, labor]) => ({
          id: Number(id),
          changes: { labor },
        }))
      );
    },
    changeMitchell1Labors: (
      state,
      action: PayloadAction<{
        [partId in string]: Mitchell1Labor;
      }>
    ) => {
      shipmentPartsAdapter.updateMany(
        state,
        entries(action.payload).map(([id, mitchell1Labor]) => ({
          id: Number(id),
          changes: { mitchell1Labor },
        }))
      );
    },
    changeQuantity: (state, action: PayloadAction<{ id: string; quantity: number }>) => {
      shipmentPartsAdapter.updateOne(state, {
        id: Number(action.payload.id),
        changes: { quantity: action.payload.quantity },
      });
    },
    removeParts: (state, action: PayloadAction<{ ids: string[] }>) => {
      shipmentPartsAdapter.removeMany(state, action.payload.ids);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShipments.fulfilled, (state, action) => {
      const entities = action.payload.reduce(
        (memo: ShipmentPartEntity[], { parts }) => [
          ...memo,
          ...parts.map((part) => {
            const { product, vehicleName, customNotes, labor, ...entity } = part;

            return {
              ...entity,
              quantity: action.meta.arg?.saveError ? (state.entities[entity.id]?.quantity ?? 0) : entity.quantity,
              productId: selectId(product),
              selectedStore: product.quote?.availability?.lines?.[0] || null,
              error: action.meta.arg?.saveError ? state.entities[part.id]?.error || entity.error : entity.error,
              vehicleId: product.vehicleId,
              labor: Object.values(labor).reduce(
                (acc, laborLine) => ({
                  ...acc,
                  [laborLine.id]: {
                    ...laborLine,
                    customDuration: state.entities[part.id]?.labor?.[laborLine.id]?.customDuration,
                    checked: state.entities[part.id]?.labor?.[laborLine.id]?.checked,
                  },
                }),
                {}
              ),
            };
          }),
        ],
        []
      );

      shipmentPartsAdapter.upsertMany(state, entities);
    });

    builder.addCase(updateQuantity.fulfilled, (state, action) => {
      shipmentPartsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: {
          error: null,
          quantity: action.meta.arg.quantity,
        },
      });
    });

    builder.addCase(updateQuantity.rejected, (state, action) => {
      shipmentPartsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: {
          error: action.payload ?? state.entities[action.meta.arg.id]?.error,
          quantity: action.meta.arg.quantity,
        },
      });
    });

    builder.addCase(removeShipmentAndPartsById.fulfilled, (state, action) => {
      const partsIds = state.ids.filter((id) => state.entities[id]?.shipmentId === action.meta.arg.id);
      shipmentPartsAdapter.removeMany(state, partsIds);
    });

    builder.addCase(consumeRemoveFromCart.fulfilled, (state, action) => {
      shipmentPartsAdapter.removeOne(state, action.meta.arg.id);
    });

    builder.addCase(clearCart, (state) => {
      shipmentPartsAdapter.removeMany(state, state.ids);
    });

    builder.addCase(removePaidShipmentsAndParts, (state, action) => {
      const entities = action.payload.reduce((memo: EntityId[], shipmentId) => {
        const partsIds = state.ids.reduce((memoPartsIds: EntityId[], partId) => {
          if (state.entities[partId]?.shipmentId === shipmentId) {
            return [...memoPartsIds, partId];
          }
          return memoPartsIds;
        }, []);
        return [...memo, ...partsIds];
      }, []);

      shipmentPartsAdapter.removeMany(state, entities);
    });

    builder.addCase(removeShipmentById, (state, action) => {
      const entities = state.ids.reduce((memoPartsIds: EntityId[], partId) => {
        if (state.entities[partId]?.shipmentId === action.payload) {
          return [...memoPartsIds, partId];
        }
        return memoPartsIds;
      }, []);

      shipmentPartsAdapter.removeMany(state, entities);
    });

    builder.addCase(consumeAddToCart.fulfilled, (state, action) => {
      const { orderItemId, orderId } = action.payload;
      const { quantity, product, origin } = action.meta.arg;

      if (orderItemId && orderId) {
        const entity = state.entities[orderItemId];

        if (entity) {
          const newQuantity = entity.quantity + quantity;

          shipmentPartsAdapter.updateOne(state, {
            id: entity.id,
            changes: { quantity: newQuantity > 999 ? 999 : newQuantity },
          });
        } else {
          shipmentPartsAdapter.upsertOne(
            state,
            createPart(orderItemId, product, quantity, orderId, origin || 'UNDEFINED')
          );
        }
      }
    });

    builder.addCase(consumeAddToCartMultiple.fulfilled, (state, action) => {
      action.payload.forEach(({ orderItemId, orderId, status }, index) => {
        if (orderItemId && orderId && status === 200) {
          const arg = action.meta.arg[index];
          if (!arg) {
            return;
          }

          const { quantity, product, origin } = arg;
          const entity = state.entities[orderItemId];

          if (entity) {
            const newQuantity = entity.quantity + quantity;

            shipmentPartsAdapter.updateOne(state, {
              id: entity.id,
              changes: { quantity: newQuantity > 999 ? 999 : newQuantity },
            });
          } else {
            shipmentPartsAdapter.upsertOne(
              state,
              createPart(orderItemId, product, quantity, orderId, origin || 'UNDEFINED')
            );
          }
        }
      });
    });

    builder.addCase(getMitchell1Labor.pending, (state, action) => {
      shipmentPartsAdapter.updateMany(
        state,
        action.meta.arg.map(({ id }) => ({
          id,
          changes: { isLaborLoading: true },
        }))
      );
    });

    builder.addCase(getMitchell1Labor.fulfilled, (state, action) => {
      action.meta.arg.forEach(({ id, partTypeId, vehicleId }) => {
        const entity = state.entities[id];
        const response = action.payload.procedureDetails.find(
          (item) => item.partTypeId === partTypeId && item.vehicleId === vehicleId
        );

        if (!response || !entity) {
          return;
        }

        entity.isLaborLoading = false;

        entity.mitchell1Labor = {
          availableMitchell1Vehicles: response.availableMitchell1Vehicles,
          error: response.error,
          partTypeId: response.partTypeId,
          vehicleId: response.vehicleId,
          mitchell1VehicleId: entity.mitchell1Labor?.mitchell1VehicleId || response.mitchell1VehicleId,
          operationProcedures: entity.mitchell1Labor?.operationProcedures || {},
        };

        if (response.mitchell1VehicleId) {
          entity.mitchell1Labor.operationProcedures[response.mitchell1VehicleId] = response.operationProcedures;
        }
      });
    });

    builder.addCase(logout.fulfilled, (state) => {
      shipmentPartsAdapter.removeAll(state);
    });
  },
});

export const shipmentPart = shipmentPartsSlice.reducer;

export const { changeMotorLabors, changeMitchell1Labors, changeQuantity, removeParts } = shipmentPartsSlice.actions;

const { selectById, selectIds } = shipmentPartsAdapter.getSelectors(selectState);

export const selectPartById = createSelector(
  [(state: RootState, id: EntityId) => selectById(state, id), selectRootState],
  (entity, state) => {
    if (!entity) {
      return null;
    }

    return { ...entity, product: selectProductById(state, entity.productId) };
  }
);

const selectAllShipmentsParts = createSelector([selectIds, selectRootState], (ids, state) =>
  ids.map((id) => selectPartById(state, id)).filter(isNotNull)
);

const selectRemovedParts = createSelector(
  [(state: RootState) => selectPartStatuses(state), selectAllShipmentsParts],
  (partStatuses, entities) => entities.filter((part) => partStatuses[part.id]?.type === 'removed')
);

const selectAddedParts = createSelector(
  [(state: RootState) => selectPartStatuses(state), selectAllShipmentsParts],
  (partStatuses, entities) => entities.filter((part) => partStatuses[part.id]?.type === 'added')
);

export const selectLastAddedPart = createSelector(selectAddedParts, (parts) => {
  const totalAddedParts = parts.length;

  if (totalAddedParts === 0) {
    return null;
  }

  return parts[totalAddedParts - 1] ?? null;
});

export const selectHasRemovingParts = createSelector([selectRemovedParts], (entities) => entities.length > 0);

export const selectUnRemoveParts = createSelector(
  [(state: RootState) => selectPartStatuses(state), selectAllShipmentsParts],
  (partStatuses, entities) => entities.filter((part) => partStatuses[part.id]?.type !== 'removed')
);

export const selectShipmentPartsMap = createSelector([selectIds, selectRootState], (ids, state) =>
  ids.reduce<Record<string, FullShipmentPart | null>>(
    (acc, id) => ({
      ...acc,
      [id]: selectPartById(state, id),
    }),
    {}
  )
);

export const selectShipmentPartTypeIds = createSelector([selectAllShipmentsParts], (entities) =>
  entities.map((entity) => entity.product?.partTypeId || null).filter(isNotNull)
);

export const selectUnPaidUnRemoveParts = createSelector(
  [selectActualShipmentsIds, selectUnRemoveParts],
  (ids, entities) => entities.filter((part) => ids.includes(part.shipmentId))
);

export const selectPartTypeIdsInShipmentParts = createSelector([selectUnPaidUnRemoveParts], (parts) =>
  parts.map((part) => part.product?.partTypeId).filter(isNotNull)
);

export const selectUnRemovePartsByShipmentId = createSelector(
  [selectUnRemoveParts, (_: unknown, id: ShipmentType['id']) => id],
  (entities, id) => entities.filter(({ shipmentId }) => shipmentId === id)
);

export const selectPartCount = createSelector([selectUnPaidUnRemoveParts], (state) =>
  state.reduce((memo, part) => memo + part.quantity, 0)
);

export const selectInvalidParts = createSelector([selectUnRemoveParts], (entity) =>
  entity
    .filter((part) => Boolean(part.error))
    .map((part) => ({
      ...part,
      status: part.product?.quote?.status,
      partName: part.product?.title,
      partNumber: part.product?.partNumber,
    }))
);

export const selectHasAvailableLabors = createSelector(
  [selectUnRemoveParts, (_: unknown, isMitchell1Labor: boolean) => isMitchell1Labor],
  (entities, isMitchell1Labor) =>
    entities.some((entity) => (isMitchell1Labor ? entity.vehicleId : !isEmpty(entity.labor)))
);

export const selectCheckedLabors = createSelector([selectUnPaidUnRemoveParts], (entities) => {
  const labors: SubmittedLabor[] = [];

  entities.forEach(({ id: partId, labor }) => {
    if (!isEmpty(labor)) {
      Object.values(labor).forEach((laborLine) => {
        if (laborLine.checked) {
          labors.push({
            applicationId: laborLine.id,
            duration: laborLine.customDuration || laborLine.duration,
            orderItemId: partId,
          });
        }
      });
    }
  });

  return labors;
});

export const selectCheckedMotorLaborLinesByPartId = createSelector(selectPartById, (entity) => {
  if (!entity || isEmpty(entity.labor)) {
    return [];
  }

  return Object.values(entity.labor).filter((laborLine) => laborLine.checked);
});

export const selectCheckedMitchell1LaborLinesByPartId = createSelector(selectPartById, (entity) => {
  if (!entity || !entity.mitchell1Labor?.mitchell1VehicleId) {
    return [];
  }

  const lines: Mitchell1LaborLine[] = [];

  entity.mitchell1Labor?.operationProcedures[entity.mitchell1Labor.mitchell1VehicleId]?.forEach(
    (operation, operationIndex) => {
      operation.procedure?.applicationGroups.forEach((group, groupIndex) => {
        group.items.forEach(
          ({ checked, customDuration, standardTime, additionalApplication, vehicleApplication }, itemIndex) => {
            if (checked) {
              lines.push({
                id: `${operationIndex}${groupIndex}${itemIndex}`,
                duration: standardTime,
                name: `${operation.procedure?.component} - ${operation.procedure?.operation}, ${
                  additionalApplication || vehicleApplication || 'All applicable models'
                }`,
                customDuration,
              });
            }
          }
        );

        group.combinationProcedures.forEach((combination, combinationIndex) => {
          combination.items.forEach(
            ({ checked, customDuration, standardTime, additionalApplication, vehicleApplication }, itemIndex) => {
              if (checked) {
                lines.push({
                  id: `${operationIndex}${groupIndex}${combinationIndex}${itemIndex}`,
                  duration: standardTime,
                  name: `${combination.component} - ${combination.operation}, ${
                    additionalApplication || vehicleApplication || 'All applicable models'
                  }`,
                  customDuration,
                });
              }
            }
          );
        });
      });
    }
  );

  return lines;
});

export const selectHasCheckedMitchell1Labors = createSelector([selectUnPaidUnRemoveParts], (entities) =>
  entities.some((entity) => {
    if (!entity.mitchell1Labor?.mitchell1VehicleId) {
      return false;
    }

    return entity.mitchell1Labor.operationProcedures[entity.mitchell1Labor.mitchell1VehicleId]?.some((operation) =>
      operation.procedure?.applicationGroups.some(
        (group) =>
          group.items.some((item) => item.checked) ||
          group.combinationProcedures.some((combination) => combination.items.some((item) => item.checked))
      )
    );
  })
);

export const selectHasCheckedMotorLabors = createSelector([selectCheckedLabors], (labors) => labors.length > 0);

export const selectTotalMotorLaborHours = createSelector([selectUnPaidUnRemoveParts], (entities) => {
  const totalHours = entities.reduce((total, { labor }) => {
    if (!isEmpty(labor)) {
      return (
        total +
        Object.values(labor).reduce(
          (acc, laborLine) => (laborLine.checked ? acc + (laborLine.customDuration || laborLine.duration) : acc),
          0
        )
      );
    }
    return total;
  }, 0);

  return Number(totalHours.toFixed(10));
});

export const selectTotalMitchell1LaborHours = createSelector([selectUnPaidUnRemoveParts], (entities) => {
  const labors = entities.map((entity) => entity.mitchell1Labor).filter(isNotNull);
  return getTotalMitchell1LaborHours(labors);
});

export const selectHasPartsErrors = createSelector(
  [selectUnRemoveParts],
  (entities) => entities.filter((part) => Boolean(part.error)).length > 0
);

export const selectItemsForTemplate = createSelector([selectUnPaidUnRemoveParts], (entities) =>
  entities
    .map((entity) => {
      const { product, urlParams, quantity } = entity;

      if (!product || !urlParams.credentialId) {
        return null;
      }

      const item: CreateTemplateItem = {
        accountId: urlParams.credentialId,
        displayPartNumber: product.partNumber,
        image: product.images.image || null,
        lineCardId: product.lineCardId,
        partName: product.title,
        partNumberId: product.partNumberId,
        partTypeId: product.partTypeId,
        supplierId: product.supplierId || 0,
        tireSize: TireSize.createFromString(getTireSize(product))?.toObject() || undefined,
        vehicleId: product.vehicleId,
        quantity,
      };

      return item;
    }, [])
    .filter(isNotNull)
);
