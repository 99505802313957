/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { SubscriptionFragmentDoc } from './Subscription.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetSubscriptionQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSubscriptionQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        shopSubscription?: {
          __typename?: 'ShopSubscription';
          amount: Types.Scalars['BigDecimal']['input'];
          currentPeriodEnd: Types.Scalars['DateTime']['input'];
          period: Types.SubscriptionPlanPeriod;
          startedAt?: Types.Scalars['DateTime']['input'] | null;
          plans?: Array<{
            __typename?: 'SubscriptionSubscriptionPlan';
            downgradeProductAlias?: Types.SubscriptionProductAlias | null;
            status: Types.SubscriptionSubscriptionPlanStatus;
            account?: { __typename?: 'Account'; id: string } | null;
            plan?: {
              __typename?: 'SubscriptionPlan';
              amount: Types.Scalars['BigDecimal']['input'];
              id: string;
              product?: {
                __typename?: 'SubscriptionProduct';
                alias: Types.SubscriptionProductAlias;
                isPlan: Types.Scalars['Boolean']['input'];
                unsubscribeReasons?: Array<Types.Scalars['String']['input']> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetSubscriptionDocument = `
    query GetSubscription {
  currentUser {
    activeMember {
      shop {
        shopSubscription {
          ...Subscription
        }
      }
    }
  }
}
    ${SubscriptionFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubscription: build.query<GetSubscriptionQuery, GetSubscriptionQueryVariables | void>({
      query: (variables) => ({ document: GetSubscriptionDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSubscriptionQuery, useLazyGetSubscriptionQuery } = injectedRtkApi;
