import { Typography, Button, Box } from '@partstech/ui';
import { HookForm, FormTextField } from '@partstech/ui/forms';
import { useCreateSystemForm } from '../../../../hooks/useCreateSystemForm';

type Props = {
  onSubmit: () => void;
};

export const CreateSystemForm = ({ onSubmit }: Props) => {
  const { form, submitForm, isDisabled, isLoading } = useCreateSystemForm({ onSubmit });

  return (
    <HookForm form={form} onSubmit={submitForm}>
      <Typography variant="subtitle1">My software is not on the list</Typography>

      <Box mt={2} mb={3}>
        <Typography variant="bodyText2">
          We are interested in integrating with all management systems. We’ll reach out to your management system and
          see what we can do.
        </Typography>
      </Box>

      <Typography variant="bodyText2">What is the name of your management system?</Typography>

      <FormTextField
        name="name"
        label="Enter the name"
        adornmentAfter={
          <Button type="submit" disabled={isDisabled} isLoading={isLoading} data-testid="submitRegistrationSystem">
            Submit
          </Button>
        }
      />
    </HookForm>
  );
};
