import { useCallback } from 'react';
import { DEFAULT_AVAILABILITY } from 'constant';
import {
  getSearchUrl as getSearchUrlUtil,
  getSearchUrlBySearchValues as getSearchUrlBySearchValuesUtil,
} from 'utils/url';
import type { SearchParams, SearchUrlBySearchValues } from 'types/search';

export const useGetSearchUrl = () => {
  const getSearchUrl = useCallback(
    (params: SearchParams) =>
      getSearchUrlUtil({ ...params, availability: params.availability ?? DEFAULT_AVAILABILITY }),
    []
  );

  const getSearchUrlBySearchValues = useCallback(
    ({ searchValues, selectedSupplier, dealers, fitOnly, jobId, step, taskType, sort }: SearchUrlBySearchValues) =>
      getSearchUrlBySearchValuesUtil({
        searchValues,
        selectedSupplier,
        dealers,
        fitOnly,
        jobId,
        step,
        taskType,
        sort,
        availability: DEFAULT_AVAILABILITY,
      }),
    []
  );

  return {
    getSearchUrl,
    /** @deprecated use useStartSearch */
    getSearchUrlBySearchValues,
  };
};
