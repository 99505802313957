import { isNotNull } from '@partstech/ui/utils';
import { selectSupplierAccountFromRootStoreById } from 'store/queries/currentUser/supplierAccounts/selectSupplierAccountFromRootStoreById';
import { selectProductById } from '../product';
import type { StockOrder, StockOrderEntity } from './stockOrdersSlice';
import type { RootState } from 'store';

export const denormalizeStockOrder = (rootState: RootState, entity: StockOrder): StockOrderEntity => ({
  ...entity,
  rows: entity.rows.map((row) => ({
    ...row,
    selectedProduct: selectProductById(rootState, row.selectedProductId ?? ''),
    products: row.productIds.map((id) => selectProductById(rootState, id)).filter(isNotNull),
  })),
  supplierAccount: entity.supplierAccountId
    ? selectSupplierAccountFromRootStoreById(rootState, String(entity.supplierAccountId))
    : null,
});
