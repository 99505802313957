import { sortBy } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSubCategoryFromQuery } from 'factories';
import { usePartTypesWithSubCategory } from 'hooks/partType';
import { PartCategory, PartSubCategory } from 'models';
import { isArrayOfInstances } from 'shared/lib/array';
import { useCategories } from '../api';
import { MIN_SEARCH_LENGTH } from '../constants';
import { checkPartTypeDisabled, getAllSubCategoriesFromCategories, getSubCategoriesWithPartTypes } from '../utils';
import { useToggleCategory } from './useToggleCategory';
import type { useGetCategoriesQuery, useGetCategoriesWithPartTypesQuery } from '../api';
import type { PartType } from 'models';

type Props = {
  useQuery?: typeof useGetCategoriesQuery | typeof useGetCategoriesWithPartTypesQuery;
  searchValue?: string;
  defaultValue?: PartSubCategory[];
  onChange?: (subCategories: PartSubCategory[]) => void;
  disabledPartTypeIds?: string[];
};

const combineSubCategoriesById = (subCategories: PartSubCategory[]) =>
  subCategories.reduce<PartSubCategory[]>((prev, option) => {
    const same = prev.findIndex(({ id }) => id === option.id);
    if (same !== -1) {
      prev.splice(
        same,
        1,
        createSubCategoryFromQuery({ id: option.id, name: option.name }, option.categoryId, [
          ...(prev[same]?.partTypes ?? []),
          ...option.partTypes,
        ])
      );
    } else {
      prev.push(option);
    }

    return prev;
  }, []);

export const useCategoryOptions = ({ searchValue, defaultValue, onChange, disabledPartTypeIds = [] }: Props = {}) => {
  const shouldStartSearching = searchValue && searchValue.length >= MIN_SEARCH_LENGTH;

  const {
    categories: rawCategories,
    isSuccess: isCategoriesFetched,
    isFetching: isCategoriesFetching,
    findCategory,
    ...props
  } = useCategories();
  const { partTypes: searchedPartTypes } = usePartTypesWithSubCategory({
    search: shouldStartSearching ? searchValue : undefined,
  });

  const [selectedSubCategories, setSelectedSubCategories] = useState<PartSubCategory[]>([]);

  useEffect(() => {
    setSelectedSubCategories(combineSubCategoriesById(defaultValue ?? []));
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange(combineSubCategoriesById(selectedSubCategories ?? []));
    }
  }, [onChange, selectedSubCategories]);

  const { openedCategory, setOpenedCategory, resetCategory } = useToggleCategory<PartCategory>();
  const {
    openedCategory: openedSubCategory,
    setOpenedCategory: setOpenedSubCategory,
    resetCategory: resetSubCategory,
  } = useToggleCategory<PartSubCategory>();

  const categories = useMemo(() => {
    if (openedCategory || openedSubCategory) {
      return [];
    }

    if (shouldStartSearching) {
      return rawCategories.filter((category) => category.isMatchedToString(searchValue));
    }

    return rawCategories;
  }, [openedCategory, openedSubCategory, rawCategories, searchValue, shouldStartSearching]);

  const rawSubCategories = useMemo(() => getAllSubCategoriesFromCategories(rawCategories), [rawCategories]);

  const subCategories = useMemo(() => {
    if (openedSubCategory) {
      return [];
    }

    if (shouldStartSearching) {
      return rawSubCategories?.filter((subCategory) => subCategory.isMatchedToString(searchValue));
    }

    return openedCategory?.subCategories ?? [];
  }, [openedSubCategory, openedCategory, shouldStartSearching, rawSubCategories, searchValue]);

  const partTypes = useMemo(() => {
    if (openedSubCategory) {
      return sortBy(openedSubCategory.partTypes, 'name');
    }

    if (shouldStartSearching) {
      searchedPartTypes.forEach((partType) =>
        partType.setIsDisabled(checkPartTypeDisabled(partType, disabledPartTypeIds))
      );
      return sortBy(searchedPartTypes, 'name');
    }

    return [];
  }, [disabledPartTypeIds, openedSubCategory, searchedPartTypes, shouldStartSearching]);

  const selectPartType = useCallback(
    (selectedPartTypes: PartType[]) => {
      const subCategoriesWithPartTypes = getSubCategoriesWithPartTypes(rawSubCategories, selectedPartTypes) ?? [];

      setSelectedSubCategories(subCategoriesWithPartTypes);
    },
    [rawSubCategories]
  );

  const selectOption = useCallback(
    (options: PartSubCategory[] | PartType[]) => {
      if (isArrayOfInstances(options, PartSubCategory)) {
        setSelectedSubCategories(combineSubCategoriesById(options));
      } else {
        selectPartType(options);
      }
    },
    [selectPartType]
  );

  const removeOption = useCallback((optionToRemove: PartSubCategory) => {
    setSelectedSubCategories((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
  }, []);

  const openOption = useCallback(
    (option: PartCategory | PartSubCategory) => {
      if (option instanceof PartCategory) {
        setOpenedCategory(option);
        return;
      }

      setOpenedSubCategory(option);
    },
    [setOpenedCategory, setOpenedSubCategory]
  );

  const goBack = useCallback(() => {
    if (openedSubCategory) {
      resetSubCategory();
      return;
    }

    resetCategory();
  }, [openedSubCategory, resetCategory, resetSubCategory]);

  const reset = useCallback(() => {
    resetSubCategory();

    resetCategory();
  }, [resetCategory, resetSubCategory]);

  const isLoaded = useMemo(() => isCategoriesFetched, [isCategoriesFetched]);

  const isFetching = useMemo(() => isCategoriesFetching, [isCategoriesFetching]);

  const findCategoryNameBySubCategory = useCallback(
    (subCategory: PartSubCategory) => {
      if (!subCategory.categoryId || !shouldStartSearching) {
        return '';
      }
      return findCategory(subCategory.categoryId)?.name ?? '';
    },
    [findCategory, shouldStartSearching]
  );

  return {
    ...props,
    categories,
    subCategories,
    selectedSubCategories,
    partTypes,
    selectOption,
    removeOption,
    openOption,
    openedCategory,
    openedSubCategory,
    goBack,
    isLoaded,
    isFetching,
    reset,
    findCategoryNameBySubCategory,
  };
};
