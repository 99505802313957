import type { AddToCartPayload } from 'app/CartProviderOld/types';

export const getDistributedQuantities = (payload: AddToCartPayload): AddToCartPayload[] => {
  const { product, quantity } = payload;

  let remainingQuantity = quantity;

  if (product.supplier?.shouldDistributeQuantities) {
    return (
      product.quote?.availability?.lines?.reduce((payloads: AddToCartPayload[], line) => {
        const availableQuantity = Math.min(line.qty.value, remainingQuantity);

        if (availableQuantity > 0) {
          remainingQuantity -= availableQuantity;
          return [...payloads, { ...payload, quantity: availableQuantity, selectedStore: line }];
        }

        return payloads;
      }, []) ?? [payload]
    );
  }

  return [payload];
};
