import { isNotNull, uniq, uniqueId } from '@partstech/ui/utils';
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { searchTypeahead } from 'shared/api/rest/gen/shop';
import { consumeAddToCartMultiple } from 'store/features/cart';
import { logout } from 'store/features/user/account';
import { selectRootState } from 'store/utils';
import { denormalizeStockOrder } from './denormalizeStockOrder';
import type { EntityId, PayloadAction } from '@reduxjs/toolkit';
import type { AvailabilityLine, SupplierAccount } from 'models';
import type { Product, SearchTypeaheadQuery, SearchTypeaheadResponse } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';
import type { StockOrderTemplate } from 'store/queries/currentUser/stockOrderTemplates/useGetStockOrderTemplate';
import type { StockOrderRow } from 'types/stockOrders';

export type StockOrder = {
  id: string;
  supplierAccountId: number | null;
  rows: StockOrderRow[];
  typeahead: SearchTypeaheadResponse['partNumbers'];
  shouldStartSearch?: boolean;
};

type StockOrdersState = {
  isInitialized: boolean;
  shouldSave?: boolean;
  splittedByLastPurchases: boolean;
  templateId: string | null;
};

export const getTypeahead = createAsyncThunk(
  'entities/stockOrders/getTypeahead',
  async ({ stockOrderId, ...payload }: SearchTypeaheadQuery & { stockOrderId: string }) => searchTypeahead(payload)
);

const stockOrdersAdapter = createEntityAdapter<StockOrder, EntityId>({ selectId: (entity) => entity.id });

const initialState = stockOrdersAdapter.getInitialState<StockOrdersState>({
  isInitialized: false,
  splittedByLastPurchases: false,
  templateId: null,
});

const stockOrdersSlice = createSlice({
  name: 'stockOrders',
  initialState,
  reducers: {
    initStockOrders: (
      state,
      action: PayloadAction<{ stockOrders: StockOrder[]; splittedByLastPurchases: boolean }>
    ) => {
      stockOrdersAdapter.removeAll(state);
      stockOrdersAdapter.setMany(state, action.payload.stockOrders);
      state.isInitialized = true;
      state.splittedByLastPurchases = action.payload.splittedByLastPurchases;
    },
    markAsSaved: (state) => {
      state.shouldSave = false;
    },
    addStockOrderRow: (
      state,
      action: PayloadAction<{ searchQuery: string; stockOrderId: string; brandName?: string }>
    ) => {
      const stockOrder = state.entities[action.payload.stockOrderId];
      stockOrdersAdapter.updateOne(state, {
        id: action.payload.stockOrderId,
        changes: {
          rows: [
            ...(stockOrder?.rows ?? []),
            {
              quantity: 0,
              searchQuery: action.payload.searchQuery,
              productIds: [],
              error: null,
              selectedIndex: null,
              brandName: action.payload.brandName,
            },
          ],
        },
      });

      state.shouldSave = true;
    },
    addStockOrder: (state, action: PayloadAction<string>) => {
      stockOrdersAdapter.setOne(state, {
        id: uniqueId(),
        supplierAccountId: Number(action.payload),
        rows: [],
        typeahead: [],
      });
    },
    removeStockOrder: (state, action: PayloadAction<string>) => {
      stockOrdersAdapter.removeOne(state, action.payload);
      state.shouldSave = true;
    },
    clearTemplate: (state) => {
      state.templateId = null;
    },
    setTemplate: (state, action: PayloadAction<{ template: StockOrderTemplate }>) => {
      const { template } = action.payload;

      if (!template.items) {
        return;
      }

      const accountIds = uniq(template.items.map((item) => item.account?.id)).filter(isNotNull);

      const stockOrders: StockOrder[] = accountIds.map((accountId) => ({
        id: uniqueId(),
        supplierAccountId: Number(accountId),
        typeahead: [],
        shouldStartSearch: true,
        rows:
          template.items
            ?.filter((item) => item.account?.id === accountId)
            .map((item) => ({
              searchQuery: item.displayPartNumber,
              isLoading: false,
              productIds: [],
              lineCardId: String(item.lineCardId),
              partNumberId: item.partNumberId,
              quantity: item.quantity,
              selectedIndex: null,
            })) || [],
      }));

      state.isInitialized = false;
      state.shouldSave = false;
      state.splittedByLastPurchases = false;
      state.templateId = template.id;
      stockOrdersAdapter.removeAll(state);
      stockOrdersAdapter.addMany(state, stockOrders);
    },
    changeSupplier: (state, action: PayloadAction<{ stockOrderId: string; supplierAccountId: string }>) => {
      const { stockOrderId, supplierAccountId } = action.payload;
      const stockOrder = state.entities[stockOrderId];

      if (!stockOrder) {
        return;
      }

      if (stockOrder.supplierAccountId !== Number(supplierAccountId)) {
        stockOrder.supplierAccountId = Number(supplierAccountId);
        state.shouldSave = true;
      }
    },
    resetTypeahead: (state, action: PayloadAction<string>) => {
      stockOrdersAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          typeahead: [],
        },
      });
    },
    changeStore: (
      state,
      action: PayloadAction<{
        stockOrderId: string;
        rowIndex: number;
        selectedStore: AvailabilityLine | null;
      }>
    ) => {
      const { stockOrderId, rowIndex, selectedStore } = action.payload;
      const stockOrder = state.entities[stockOrderId];

      if (!stockOrder) {
        return;
      }

      const row = stockOrder.rows[rowIndex];

      if (row) {
        row.selectedStore = selectedStore;
        row.error = null;
      }
    },
    removeRow: (state, action: PayloadAction<{ stockOrderId: string; rowIndex: number }>) => {
      const { stockOrderId, rowIndex } = action.payload;
      const stockOrder = state.entities[stockOrderId];

      if (!stockOrder) {
        return;
      }

      stockOrder.rows = stockOrder.rows.filter((_, index) => index !== rowIndex);
      state.shouldSave = true;
    },
    changeQuantity: (state, action: PayloadAction<{ stockOrderId: string; rowIndex: number; quantity?: number }>) => {
      const { stockOrderId, rowIndex, quantity } = action.payload;
      const stockOrder = state.entities[stockOrderId];

      if (!stockOrder) {
        return;
      }

      const row = stockOrder.rows[rowIndex];

      if (row) {
        row.quantity = quantity || 1;
        row.error = null;
      }

      state.shouldSave = true;
    },
    changeSelectedProduct: (
      state,
      action: PayloadAction<{
        stockOrderId: string;
        rowIndex: number;
        productId: string;
        canBuy: boolean;
        quantity: number;
        selectedIndex: number;
      }>
    ) => {
      const { stockOrderId, rowIndex, productId, canBuy: isAddToCart, quantity, selectedIndex } = action.payload;
      const stockOrder = state.entities[stockOrderId];

      if (!stockOrder) {
        return;
      }

      const row = stockOrder.rows[rowIndex];
      if (!row) {
        return;
      }

      row.quantity = quantity;
      row.selectedProductId = productId;
      row.selectedIndex = selectedIndex;
      row.error = !isAddToCart;
      row.selectedStore = null;
      row.manufacturerId = null;
      state.shouldSave = true;
    },
    removeStockOrderBySupplierAccountId: (state, action: PayloadAction<string>) => {
      const stockOrderIdsToRemove = state.ids.filter(
        (id) => state.entities[id]?.supplierAccountId === Number(action.payload)
      );

      stockOrdersAdapter.removeMany(state, stockOrderIdsToRemove);
      state.shouldSave = true;
    },
    setError: (
      state,
      action: PayloadAction<{
        stockOrderId: string;
        rowIndex: number;
        error: string;
      }>
    ) => {
      const { stockOrderId, rowIndex, error } = action.payload;
      const row = state.entities[stockOrderId]?.rows[rowIndex];

      if (!row) {
        return;
      }

      row.error = error;
    },
    removeAddedItems: (
      state,
      action: PayloadAction<
        {
          stockOrderId?: string;
          searchResultPosition?: number;
          error: string | null;
        }[]
      >
    ) => {
      state.ids.forEach((id) => {
        const stockOrder = state.entities[id];

        if (!stockOrder) {
          return;
        }

        const updatedRows = stockOrder.rows
          .map((row, rowIndex) => {
            const index = action.payload.findIndex(
              ({ stockOrderId, searchResultPosition }) => stockOrderId === id && rowIndex === searchResultPosition
            );

            if (index === -1) {
              return row;
            }

            const error = action.payload[index]?.error;
            return error ? { ...row, error } : null;
          })
          .filter(isNotNull);

        if (updatedRows.length === 0) {
          stockOrdersAdapter.removeOne(state, id);
        } else {
          stockOrdersAdapter.updateOne(state, { id, changes: { rows: updatedRows } });
        }
      });

      state.shouldSave = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(consumeAddToCartMultiple.fulfilled, (state, action) => {
      state.ids.forEach((id) => {
        const stockOrder = state.entities[id];

        if (!stockOrder) {
          return;
        }

        const updatedRows = stockOrder.rows
          .map((row, rowIndex) => {
            const index = action.meta.arg.findIndex(
              ({ stockOrderId, searchResultPosition }) => stockOrderId === id && rowIndex === searchResultPosition
            );

            if (index === -1) {
              return row;
            }

            const hasError = action.payload[index]?.status !== 200;
            const errorMessage = action.payload[index]?.error?.errors[0]?.message;
            return hasError ? { ...row, error: errorMessage || 'Unexpected error' } : null;
          })
          .filter(isNotNull);

        if (updatedRows.length === 0) {
          stockOrdersAdapter.removeOne(state, id);
        } else {
          stockOrdersAdapter.updateOne(state, { id, changes: { rows: updatedRows } });
        }
      });

      state.shouldSave = true;
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(getTypeahead.fulfilled, (state, action) => {
      stockOrdersAdapter.updateOne(state, {
        id: action.meta.arg.stockOrderId,
        changes: {
          typeahead: action.payload.partNumbers,
        },
      });
    });
  },
});

export const stockOrders = stockOrdersSlice.reducer;

export const {
  initStockOrders,
  markAsSaved,
  addStockOrder,
  addStockOrderRow,
  removeStockOrder,
  changeSupplier,
  changeStore,
  removeRow,
  changeQuantity,
  changeSelectedProduct,
  resetTypeahead,
  setTemplate,
  clearTemplate,
  removeStockOrderBySupplierAccountId,
  setError,
  removeAddedItems,
} = stockOrdersSlice.actions;

const selectState = (state: RootState) => state.entities.stockOrders;

const { selectAll } = stockOrdersAdapter.getSelectors(selectState);

export type StockOrderEntity = Omit<StockOrder, 'rows'> & {
  rows: (StockOrderRow & {
    selectedProduct: Product | null;
    products: Product[];
  })[];
  supplierAccount: SupplierAccount | null;
};

export const selectStockOrders = createSelector([selectRootState, selectAll], (rootState, entities) =>
  entities.map((entity) => denormalizeStockOrder(rootState, entity))
);

export const selectIsStockOrdersInitialized = createSelector([selectState], (state) => state.isInitialized);
export const selectShouldSaveStockOrders = createSelector([selectState], (state) => state.shouldSave);
export const selectIsSplittedByLastPurchases = createSelector([selectState], (state) => state.splittedByLastPurchases);
export const selectStockOrderTemplateId = createSelector([selectState], (state) => state.templateId);
