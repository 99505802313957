import { uniqBy } from '@partstech/ui/utils';
import { captureException } from '@sentry/react';
import { type AttributeUsageSection, MIN_RECENT_PURCHASE_QUANTITY_FOR_SHOWING } from 'constant';
import { findAttributeByName } from '../utils/product/attributes/findAttributeByName';
import {
  getCustomAttributesForSection,
  makeProductAttribute,
} from '../utils/product/attributes/getCustomAttributesForSection';
import { getCustomAttributesByNames } from '../utils/tire/getCustomAttributesByNames';
import type { Brand } from './Brand';
import type { PartNumber } from './PartNumber';
import type { Quote } from './Quote';
import type { Store } from './Store';
import type { Supplier } from './Supplier';
import type { PartImages, RawAttribute, RewardPoints } from 'shared/api/rest/gen/shop';
import type { Rebate } from 'store/entities/product';
import type { File } from 'types/entityTyped';
import type { Bundle, Variation } from 'types/product';

export class Product {
  attributes: RawAttribute[];

  brand: Brand | null = null;

  bundles: Bundle[];

  coreCharge: number | null;

  credentialId: string | null;

  descriptions: { code: string; label: string; value: string[] }[];

  fetCharge: number | null;

  hasVehicleFitment: boolean;

  id: string;

  images: PartImages;

  interchangePart: { brand: string | null; partNumber: string; type: 'INTCH' | 'SUPERSEDE' | 'ALTERNATE' } | null;

  lineCardId: number | null;

  listPrice: number | null;

  mapPrice: number | null;

  notes: string[][];

  notesOem: string[];

  partNumber: PartNumber | null = null;

  partTypeId: string | null;

  partTypeName: string | null;

  price: number | null;

  quantityStep: number;

  quote: Quote | null = null;

  rebates: Rebate[];

  retailPrice: number | null;

  rewardPoints: RewardPoints | null;

  showRetailPrice: boolean | null = null;

  sortHash: string;

  sponsorType: 'NONE' | 'SPONSORED' | 'POTENTIALLY_SPONSORED' = 'NONE';

  sponsoredPurchasesCount: number | null = null;

  stockMessage: Record<string, string> | null = null;

  shippingCharge: boolean;

  store: Store | null = null;

  supplier: Supplier | null = null;

  title: string;

  variations: Variation[];

  vehicleId: number | null;

  constructor(
    product: Pick<
      Product,
      | 'attributes'
      | 'bundles'
      | 'coreCharge'
      | 'credentialId'
      | 'descriptions'
      | 'fetCharge'
      | 'hasVehicleFitment'
      | 'id'
      | 'images'
      | 'interchangePart'
      | 'lineCardId'
      | 'listPrice'
      | 'mapPrice'
      | 'notes'
      | 'notesOem'
      | 'partTypeId'
      | 'partTypeName'
      | 'price'
      | 'quantityStep'
      | 'rebates'
      | 'retailPrice'
      | 'rewardPoints'
      | 'sponsoredPurchasesCount'
      | 'sponsorType'
      | 'stockMessage'
      | 'shippingCharge'
      | 'sortHash'
      | 'title'
      | 'variations'
      | 'vehicleId'
    >
  ) {
    this.attributes = product.attributes;
    this.bundles = product.bundles;
    this.coreCharge = product.coreCharge;
    this.credentialId = product.credentialId;
    this.descriptions = product.descriptions;
    this.fetCharge = product.fetCharge;
    this.hasVehicleFitment = product.hasVehicleFitment;
    this.id = product.id;
    this.images = product.images;
    this.interchangePart = product.interchangePart;
    this.lineCardId = product.lineCardId;
    this.listPrice = product.listPrice;
    this.mapPrice = product.mapPrice;
    this.notes = product.notes;
    this.notesOem = product.notesOem;
    this.partTypeId = product.partTypeId;
    this.partTypeName = product.partTypeName;
    this.price = product.price;
    this.quantityStep = product.quantityStep;
    this.rebates = product.rebates;
    this.retailPrice = product.retailPrice;
    this.rewardPoints = product.rewardPoints;
    this.sortHash = product.sortHash;
    this.sponsorType = product.sponsorType;
    this.sponsoredPurchasesCount = product.sponsoredPurchasesCount ?? null;
    this.stockMessage = product.stockMessage;
    this.shippingCharge = product.shippingCharge ?? false;
    this.sortHash = product.sortHash;
    this.title = product.title;
    this.variations = product.variations;
    this.vehicleId = product.vehicleId;
  }

  get isBundleAvailable(): boolean {
    return this.bundles.length > 0;
  }

  get isStaggeredTire(): boolean {
    return this.isTire && this.hasPosition;
  }

  get isTire(): boolean {
    return this.supplier?.isTire ?? false;
  }

  get hasPosition(): boolean {
    return Boolean(findAttributeByName('Position', this.attributes));
  }

  get points(): number | null {
    return this.rewardPoints?.points ?? null;
  }

  get shouldApplyFetCharge(): boolean {
    return !this.supplier?.isTireDisconnected && this.isTire && Boolean(this.fetCharge === null || this.fetCharge > 0);
  }

  canBuy() {
    return this.quote !== null && this.quote.isAddToCart();
  }

  extractImages(): File[] {
    if (!this.images.other || !this.images.partImage) {
      return [];
    }

    return this.images.other
      .reduce((images, image) => [...images, image], [this.images.partImage])
      .filter(({ isAttachment, isVideo }) => !isVideo && !isAttachment);
  }

  getActualPrice() {
    if (this.showRetailPrice) {
      return this.retailPrice || this.price;
    }

    return this.price;
  }

  getAttributeByName(name: RawAttribute['name']) {
    return this.getAttributesByNames([name])[0] ?? null;
  }

  getAttributesByNames(names: RawAttribute['name'][]) {
    return getCustomAttributesByNames(names, this.attributes.map(makeProductAttribute));
  }

  getAttributesForSection(section: AttributeUsageSection, variationId: string | null = null) {
    const variation = this.getVariationById(variationId);

    return getCustomAttributesForSection([...(variation?.attributes ?? []), ...this.attributes], section);
  }

  getCoreCharge() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.coreCharge;
  }

  getDefaultQuantity(variationId: string | null = null): number {
    if (this.quote && this.quantityStep > 1) {
      return this.quantityStep;
    }

    if (this.isTire) {
      const availableQuantity = this.quote?.getAvailableQuantity();

      if (this.isStaggeredTire) {
        if (availableQuantity && availableQuantity < 2) {
          return availableQuantity;
        }

        return 2;
      }

      if (availableQuantity && availableQuantity < 4) {
        return availableQuantity;
      }

      return 4;
    }

    const attributes = this.getAttributesForSection('product', variationId);
    const quantityPerVehicle = findAttributeByName('Qty', attributes);

    return quantityPerVehicle ? Number(quantityPerVehicle.value) : 1;
  }

  getDescriptionByCode(code: string) {
    return this.descriptions.find((description) => description.code === code)?.value.join(' ');
  }

  getFetCharge() {
    return this.fetCharge;
  }

  getId() {
    try {
      return [this.getPartNumberId().replace(/[^\w]/g, ''), this.credentialId, this.lineCardId, this.supplier?.id].join(
        '-'
      );
    } catch (error) {
      captureException(error, {
        data: {
          partNumberId: this.getPartNumberId(),
          typeOfPartNumberId: typeof this.getPartNumberId(),
          productId: this.id,
        },
      });

      return '';
    }
  }

  getPartNumberId() {
    return this.partNumber?.id ?? '';
  }

  getListPrice() {
    if (this.showRetailPrice || this.isTire) {
      return null;
    }

    return this.listPrice;
  }

  getLoadIndex() {
    return Number(this.getAttributeByName('TiresLoadIndex')?.value);
  }

  getMapPrice() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.mapPrice;
  }

  getNotes(variationId: string | null = null) {
    const variation = this.getVariationById(variationId);

    return variation ? [...this.notes, ...variation.notes] : this.notes;
  }

  getRetailPrice() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.retailPrice || this.price;
  }

  getSpeedRating() {
    return this.getAttributeByName('TiresSpeedRating')?.value;
  }

  getTitle(id: string | null = null) {
    const variation = this.getVariationById(id);
    return variation?.partTitle || this.title;
  }

  static getUniqueBundles(bundles?: Bundle[]) {
    return bundles ? uniqBy(bundles, (bundle) => bundle.name.toLowerCase()) : null;
  }

  getVariationAttributes(section: AttributeUsageSection, variationId: string) {
    const variation = this.getVariationById(variationId);

    return getCustomAttributesForSection(variation?.attributes ?? [], section);
  }

  getVariationById(id: string | null) {
    return this.variations?.find((item) => item.variationId === id) || null;
  }

  getRecentPurchasesCount() {
    if (this.sponsoredPurchasesCount && this.sponsoredPurchasesCount <= MIN_RECENT_PURCHASE_QUANTITY_FOR_SHOWING) {
      return null;
    }

    return this.sponsoredPurchasesCount;
  }

  hasEnoughQuantity(quantity: number): boolean {
    if (!this.quote) {
      return false;
    }
    return this.quote?.getAvailableQuantity() >= quantity;
  }

  isRear() {
    return this.isTire && this.getAttributeByName('Position')?.value === 'Rear';
  }

  isSponsored() {
    return this.sponsorType === 'SPONSORED';
  }

  isPotentiallySponsored() {
    return this.sponsorType === 'POTENTIALLY_SPONSORED';
  }

  shouldCallForAvailability() {
    if (this.canBuy() || !this.store || !this.store.phone) {
      return false;
    }

    return !this.quote?.isCallAvailability() || !this.quote?.isCallSupplier();
  }

  setBrand(brand: Brand) {
    this.brand = brand;
  }

  setPartNumber(partNumber: PartNumber) {
    this.partNumber = partNumber;
  }

  setQuote(quote: Quote | null) {
    this.quote = quote;
  }

  setShowRetailPrice(isShowRetailPrice: boolean) {
    this.showRetailPrice = isShowRetailPrice;
  }

  setStore(store: Store) {
    this.store = store;
  }

  setSupplier(supplier: Supplier) {
    this.supplier = supplier;
  }
}
