import { Routes } from 'constant';
import { generateUrl } from './generateUrl';

enum SignUpPages {
  CreateAccount = 'create-account',
  InvalidToken = 'invalid-token',
  Referral = 'referral',
  Success = 'success',
}

export enum CreateShopPages {
  Type = 'shop-type',
  ShopDetails = 'shop-details',
  ManagementSystem = 'management-system',
  PartsSuppliers = 'parts-suppliers',
  TireSuppliers = 'tire-suppliers',
  Role = 'role',
  HearAboutUs = 'hear-about-us',
  PhoneNumber = 'phone-number',
}

const signUpPages = [
  'create-account',
  'create-shop',
  'details',
  'invalid-token',
  'referral',
  'select-sms',
  'success',
] as const;

type SignUpPagesAlias = (typeof signUpPages)[number];

export type SignUpQueryParams = Partial<{
  code: string;
  selectedSms: string;
  state: string;
  token: string;
}>;

export const getSignUpUrl = (page: SignUpPagesAlias = 'create-account', search?: SignUpQueryParams) =>
  generateUrl(Routes.SIGN_UP, { page }, search);

export const getSignUpUrlNew = (
  page: CreateShopPages | SignUpPages = SignUpPages.CreateAccount,
  search?: SignUpQueryParams
) => generateUrl(Routes.SIGN_UP, { page }, search);
