import type { AddToCartPayload } from '../types';

export const getDistributedQuantities = (payload: AddToCartPayload): AddToCartPayload[] => {
  const { product, quantity } = payload;

  let remainingQuantity = quantity;

  if (product.supplier?.shouldDistributeQuantities) {
    return (
      product.quote?.lines?.reduce<AddToCartPayload[]>((acc: AddToCartPayload[], line) => {
        const availableQuantity = Math.min(line.qty.value, remainingQuantity);

        if (availableQuantity > 0) {
          remainingQuantity -= availableQuantity;
          return [...acc, { ...payload, quantity: availableQuantity, selectedStore: line }];
        }

        return acc;
      }, []) ?? [payload]
    );
  }

  return [payload];
};
