import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchProduct } from 'store/entities/product';
import { queryToUrlParams, selectId } from 'utils';
import { logout } from './user/account';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

type ProductPageState = {
  fetchedIds: string[];
  fetchedWithErrorIds: string[];
  isFailed: boolean;
  fetchingIds: string[];
  productId: string | null;
};

const initialState: ProductPageState = {
  fetchedIds: [],
  fetchedWithErrorIds: [],
  isFailed: false,
  fetchingIds: [],
  productId: null,
};

const productPageSlice = createSlice({
  name: 'productPage',
  initialState,
  reducers: {
    resetProductId: (state) => {
      state.productId = initialState.productId;
    },
    resetProductPage: (state) => {
      state.isFailed = initialState.isFailed;
      state.fetchingIds = initialState.fetchingIds;
    },
    resetFetchedIds: (state) => {
      state.fetchedIds = initialState.fetchedIds;
    },
    setProductId: (state, action: PayloadAction<string>) => {
      state.productId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.pending, (state, action) => {
        const productId = selectId(queryToUrlParams(action.meta.arg));

        state.fetchingIds.push(productId);
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.isFailed = false;

        const productId = selectId(queryToUrlParams(action.meta.arg));

        state.fetchingIds = state.fetchingIds.filter((id) => id !== productId);

        state.fetchedIds.push(productId);
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.isFailed = true;

        const productId = selectId(queryToUrlParams(action.meta.arg));

        state.fetchingIds = state.fetchingIds.filter((id) => id !== productId);

        if (!state.fetchedIds.includes(productId)) {
          state.fetchedIds.push(productId);
        }

        if (!state.fetchedWithErrorIds.includes(productId)) {
          state.fetchedWithErrorIds.push(productId);
        }
      })
      .addCase(logout.fulfilled, () => initialState);
  },
});

export const productPage = productPageSlice.reducer;

export const { resetProductPage, resetProductId, resetFetchedIds, setProductId } = productPageSlice.actions;

const selectState = (state: RootState) => state.features.productPage;

export const selectProductId = createSelector([selectState], (page) => page.productId);

export const selectFetchedIds = createSelector([selectState], (page) => page.fetchedIds);

export const selectFetchedWithErrorIds = createSelector([selectState], (page) => page.fetchedWithErrorIds);

export const selectIsProductFetched = createSelector(
  [selectFetchedIds, (_: unknown, id: string | null) => id],
  (fetchedIds, id) => (id ? fetchedIds.includes(id) : true)
);

export const selectIsProductFetching = createSelector([selectState, (_: unknown, id: string) => id], (state, id) =>
  state.fetchingIds.includes(id)
);

export const selectIsProductsFetching = createSelector([selectState], (state) => state.fetchingIds.length > 0);

export const selectIsProductsFetched = createSelector(
  [selectFetchedIds, (_: unknown, ids: string[]) => ids],
  (fetchedIds, ids) => ids.every((id) => fetchedIds.includes(id))
);

export const selectIsProductFetchFailed = createSelector(
  [selectFetchedWithErrorIds, (_: unknown, id: string | null) => id],
  (failedIds, id) => (id ? failedIds.includes(id) : false)
);

export const selectIsProductsFetchFailed = createSelector(
  [selectFetchedWithErrorIds, (_: unknown, ids: string[]) => ids],
  (failedIds, ids) => ids.some((id) => failedIds.includes(id))
);
