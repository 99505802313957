import { useCallback, useMemo, useState } from 'react';
import { useAddLaborItemToCart } from 'features/labor/api/mutations/addLaborItemToCart';
import { AddingLaborContextProvider } from './AddingLaborContext';
import type { AddingLaborContextValue } from './AddingLaborContext';
import type { LaborApplication } from 'models';
import type { PropsWithChildren } from 'react';

export const AddingLaborProvider = ({ children }: PropsWithChildren) => {
  const [selectedLaborApplications, setSelectedLaborApplications] = useState<LaborApplication[]>([]);

  const [customDurations, setCustomDurations] = useState<Record<string, number>>({});

  const [addLaborItemToCart] = useAddLaborItemToCart();

  const toggleLaborApplication = useCallback((application: LaborApplication) => {
    setSelectedLaborApplications((prev) => {
      const isSelected = prev.some((prevApplication) => prevApplication.id === application.id);

      if (isSelected) {
        return prev.filter((prevApplication) => prevApplication.id !== application.id);
      }
      return [...prev, application];
    });
  }, []);

  const changeCustomDuration = useCallback((laborApplicationId: string, customDuration: number) => {
    setCustomDurations((prev) => ({ ...prev, [laborApplicationId]: customDuration }));
  }, []);

  const clear = useCallback(() => {
    setSelectedLaborApplications([]);
    setCustomDurations({});
  }, []);

  const totalHours = useMemo(
    () =>
      selectedLaborApplications.reduce(
        (acc, application) => acc + (customDurations[application.id] || application.duration),
        0
      ),
    [customDurations, selectedLaborApplications]
  );

  const checkedLaborMap = useMemo(
    () =>
      selectedLaborApplications.reduce<Record<string, boolean>>(
        (acc, application) => ({ ...acc, [application.id]: true }),
        {}
      ),
    [selectedLaborApplications]
  );

  const addToCart = useCallback(
    (vehicleId: string, vin?: string) => {
      selectedLaborApplications.forEach((application) => {
        addLaborItemToCart({
          applicationId: application.applicationId,
          name: application.title,
          hours: customDurations[application.id] || application.duration,
          defaultHours: application.duration,
          skill: application.skill,
          vehicleId,
          vin,
        });
      });

      clear();
    },
    [addLaborItemToCart, clear, customDurations, selectedLaborApplications]
  );

  const contextValue: AddingLaborContextValue = useMemo(
    () => ({
      selectedLaborApplications,
      customDurations,
      totalHours,
      checkedLaborMap,
      toggleLaborApplication,
      changeCustomDuration,
      clear,
      addToCart,
    }),
    [
      selectedLaborApplications,
      customDurations,
      totalHours,
      checkedLaborMap,
      toggleLaborApplication,
      changeCustomDuration,
      clear,
      addToCart,
    ]
  );

  return <AddingLaborContextProvider value={contextValue}>{children}</AddingLaborContextProvider>;
};
