import { captureException } from '@sentry/react';
import type { ProductUrlParams } from 'types/product';

/** @deprecated use getId from src/models/Product.ts */
export const selectId = ({ credentialId, lineCardId, supplierId, partNumberId }: ProductUrlParams) => {
  try {
    return [String(partNumberId ?? '').replace(/[^\w]/g, ''), credentialId, lineCardId, supplierId].join('-');
  } catch (error) {
    captureException(error, {
      data: { partNumberId, typeOfPartNumberId: typeof partNumberId, credentialId, lineCardId, supplierId },
    });

    return '';
  }
};
