import { useCallback } from 'react';
import { Routes } from 'constant';
import { FullStoryEventNames, useTracking } from 'integrations/fullstory/tracking';
import { getProductUrl } from 'utils/url';
import type { CartOrderItem } from 'models';

export const useRemoveFromCartTracking = () => {
  const { sendEvent } = useTracking();

  const trackRemoveFromCart = useCallback(
    (orderItem: CartOrderItem) => {
      sendEvent(FullStoryEventNames.PRODUCT_REMOVED, {
        removed_from: window.location.pathname === Routes.CART ? 'cart' : 'widget',
        brand: orderItem.brandName || '',
        category: orderItem.partTypeName || '',
        name: orderItem.title,
        price: orderItem.price,
        product_id: orderItem.partNumberId,
        quantity: orderItem.quantity,
        sku: orderItem.partNumber,
        supplier: orderItem.supplierId,
        url: getProductUrl({
          partNumberId: orderItem.partNumberId || '',
          supplierId: Number(orderItem.supplierId),
          lineCardId: orderItem.lineCardId,
          credentialId: Number(orderItem.supplierAccountId),
        }),
      });
    },
    [sendEvent]
  );

  return { trackRemoveFromCart };
};
