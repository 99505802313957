import { usePrevious } from '@partstech/ui/hooks';
import { isEqual } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectLastSearch } from 'store/features/search/settings';
import { useSearchParams } from './useSearchParams';
import type { SearchParams } from 'types/search';

const isPrefillAttributes = (
  lastSearch: SearchParams | null,
  valuableParams: SearchParams,
  prevValuableParams?: SearchParams
) => {
  if (!lastSearch && prevValuableParams) {
    return false;
  }

  if (prevValuableParams?.fit && !isEqual(prevValuableParams.fit, valuableParams.fit)) {
    return false;
  }

  if (isEqual(lastSearch?.attributes, valuableParams.attributes)) {
    return false;
  }

  if (Boolean(prevValuableParams?.attributes) && !isEqual(prevValuableParams?.attributes, valuableParams.attributes)) {
    return false;
  }

  return Boolean(valuableParams?.vehicle) && Boolean(valuableParams?.attributes);
};

export const useIsSameSearch = () => {
  const { valuableParams } = useSearchParams();

  const lastSearch = useAppSelector(selectLastSearch);

  const previousValuableParams = usePrevious(valuableParams);

  return useMemo(
    () =>
      isPrefillAttributes(lastSearch, valuableParams, previousValuableParams) || isEqual(lastSearch, valuableParams),
    [lastSearch, valuableParams, previousValuableParams]
  );
};
