import React, { useCallback, useEffect, useState } from 'react';
import { Options } from '../../DefaultFilter/Options';
import type { OptionsProps } from '../../DefaultFilter/Options/types';

export const TireAvailabilityOptions = ({ onChange, ...props }: OptionsProps) => {
  const { options, checkedOptions } = props;

  const [disabledOptions, setDisabledOptions] = useState<string[]>([]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const optionIndex = options.findIndex((option) => option.value === e.target.value);
      const prevOptions = options.slice(0, optionIndex).map((option) => option.value);

      const batchOnChange = (values: string[]) => {
        values.forEach((value) => {
          onChange({ ...e, target: { ...e.target, value } });
        });
      };

      if (e.target.checked) {
        batchOnChange(prevOptions.filter((value) => !checkedOptions.includes(value)));
      } else {
        batchOnChange(prevOptions);
      }

      onChange(e);
    },
    [checkedOptions, onChange, options]
  );

  useEffect(() => {
    const prevOptions = checkedOptions.slice(0, -1);

    setDisabledOptions(prevOptions);
  }, [checkedOptions]);

  return <Options {...props} disabledOptions={disabledOptions} onChange={handleChange} />;
};
