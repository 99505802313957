import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { getFullStorySessionIdAsync } from 'integrations/fullstory/getFullStorySessionId';
import { GraphQLError } from './GraphQLError';
import type { ResponseError } from './GraphQLError';

const getApiUrl = (path: string) => (process.env.NODE_ENV === 'test' ? `https://app.beta.partstech.com${path}` : path);

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    url: getApiUrl('/graphql'),
    customErrors: (error): ResponseError<{}> =>
      new GraphQLError({
        errors: (error.response.errors ?? []) as unknown as ResponseError<{}>['errors'],
        status: error.response.status,
      }).toSerializable(),
    prepareHeaders: async (headers) => {
      const fsid = await getFullStorySessionIdAsync();
      headers.set('X-FSID', fsid);
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    'ActiveCart',
    'AppConfig',
    'AutomaticPreferredBrand',
    'CurrentUser',
    'CustomPreferredBrand',
    'Invitation',
    'Job',
    'LaborRate',
    'Matrix',
    'Member',
    'PaymentCard',
    'PricePackage',
    'Product',
    'RecentSearch',
    'RecentVehicle',
    'Report',
    'SavedCart',
    'Shipment',
    'ShopAddress',
    'StockOrderTemplate',
    'SubscriptionPayment',
    'SupplierAccount',
    'SystemAlert',
    'TaxRate',
    'Vehicle',
    'VehicleById',
    'VehicleByPlateVin',
    'BoschAccount',
  ],
  keepUnusedDataFor: 3600 * 6, // 6 hours in seconds
});

export const shopApi = createApi({
  reducerPath: 'shopApi',
  baseQuery: fetchBaseQuery({ baseUrl: getApiUrl('/api') }),
  endpoints: () => ({}),
});

export const gUploadApi = createApi({
  reducerPath: 'gUploadApi',
  baseQuery: fetchBaseQuery({ baseUrl: getApiUrl('/g/upload') }),
  endpoints: () => ({}),
});

export const gExportApi = createApi({
  reducerPath: 'gExportApi',
  baseQuery: fetchBaseQuery({ baseUrl: getApiUrl('/g/export') }),
  endpoints: () => ({}),
  tagTypes: ['Report'],
});
