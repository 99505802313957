import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './DeleteLaborItemFromCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteLaborItemFromCart: {
      onQueryStarted: async (input, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            const { activeCart } = draft;

            if (!activeCart) {
              return;
            }

            if (
              data.deleteActiveCartLaborItem &&
              'success' in data.deleteActiveCartLaborItem &&
              data.deleteActiveCartLaborItem.success
            ) {
              Object.assign(activeCart, {
                laborItems: activeCart.laborItems?.filter((item) => item.id !== input.id),
              });

              activeCart.orders?.forEach((order) => {
                order.items?.forEach((orderItem) => {
                  Object.assign(orderItem, {
                    laborItems: orderItem.laborItems?.filter((item) => item.id !== input.id),
                  });
                });
              });
            }
          })
        );
      },
    },
  },
});

const { useDeleteLaborItemFromCartMutation } = api;

export const useDeleteLaborItemFromCart = () => {
  const [mutate, status] = useDeleteLaborItemFromCartMutation();

  useMutationStatus({ status });

  return [mutate, status] as const;
};
