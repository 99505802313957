import { useMemo } from 'react';
import { DynamicAttributeConfigurator } from 'features/dynamicAttribute';
import { UniversalPartType } from 'models';
import { OptionListGroups } from '../../OptionListGroups';
import { SearchEntryPoints } from '../../SearchEntryPoints';
import type { SearchEntryPointInterface } from '../../../models';
import type { SearchTabs } from '../../../types';
import type { Vehicle } from 'models';

type Props = {
  inputValue: string;
  isVisible: boolean;
  onRecentSearchSelect: () => void;
  onSelect: (shouldShowTypeahead: boolean) => (entryPoint: SearchEntryPointInterface) => void;
  onSelectVehicleClick: () => void;
  onSubmit: (searchEntry: SearchEntryPointInterface) => void;
  onTabChange: (type: SearchTabs) => void;
  options: SearchEntryPointInterface[];
  tab: SearchTabs;
  value: SearchEntryPointInterface | undefined | null;
  vehicle: Vehicle | null;
  withDynamicAttributes?: boolean;
};

export const SearchFormContent = ({
  inputValue,
  isVisible,
  onRecentSearchSelect,
  onSelect,
  onSelectVehicleClick,
  onSubmit,
  onTabChange,
  options,
  tab,
  value,
  vehicle,
  withDynamicAttributes,
}: Props) => {
  const shouldShowDynamicAttributeConfigurator = useMemo(
    () => inputValue === value?.populateInput() && value instanceof UniversalPartType && withDynamicAttributes,
    [inputValue, value, withDynamicAttributes]
  );

  if (shouldShowDynamicAttributeConfigurator && value instanceof UniversalPartType) {
    return (
      <DynamicAttributeConfigurator
        partType={value}
        onSelectVehicleClick={onSelectVehicleClick}
        isSelectVehicleShow={!vehicle}
        onChange={onSubmit}
      />
    );
  }

  if (isVisible && inputValue.length > 0) {
    return (
      <OptionListGroups
        data-testid="typeaheadResults"
        highlightText={inputValue}
        options={options}
        onSelect={onSelect(true)}
        onChange={onSubmit}
      />
    );
  }

  return (
    <SearchEntryPoints
      onChange={onSubmit}
      onSelect={onSelect(false)}
      vehicle={vehicle}
      tab={tab}
      onRecentSearchSelect={onRecentSearchSelect}
      onSelectVehicleClick={onSelectVehicleClick}
      onTabChange={onTabChange}
    />
  );
};
