import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useState } from 'react';
import { useCartOld } from 'app/CartProviderOld/CartContext';
import { createAvailabilityLineFromData, createProductFromData } from 'factories';
import { useAddToCart as useAddToCartNew } from 'features/cart';
import { useSearchSequence } from 'features/searchSequence';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { CartOrderItemOrigin, ErrorCode } from 'shared/api';
import { useAppDispatch } from 'store';
import { setProductError } from 'store/features/search/productStatuses';
import { getAvailableQuantityInStock, getDistributedQuantities, selectId } from 'utils';
import type { AddToCartPayload } from 'app/CartProviderOld/types';
import type { SupplierAccount } from 'models';
import type { Error } from 'shared/api/rest/gen/shop';

const useAddToCartOld = () => {
  const dispatch = useAppDispatch();

  const { addToCart, addToCartMultiple } = useCartOld();
  const { launchNext: launchNextSearch } = useSearchSequence();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPartialSuccess, setIsPartialSuccess] = useState(false);
  const [errors, setErrors] = useState<Error[] | null>(null);

  const setGeneralError = useCallback(() => {
    setErrors([
      {
        errors: [
          {
            field: null,
            code: ErrorCode.GENERAL_ERROR,
            message: 'We are having issues getting information from the suppliers, please try again later',
          },
        ],
      },
    ]);
  }, []);

  const setAvailabilityError = useCallback((message: string) => {
    setErrors([
      {
        errors: [
          {
            field: null,
            code: ErrorCode.AVAILABILITY_ERROR,
            message,
          },
        ],
      },
    ]);
  }, []);

  const processAddToCart = useCallback(
    (payload: AddToCartPayload) => {
      const { quantity, product } = payload;
      const productId = selectId(product);

      dispatch(setProductError({ productId, error: null }));

      setErrors(null);
      setIsLoading(true);
      setIsSuccess(false);
      setIsPartialSuccess(false);

      const quantityInStock = getAvailableQuantityInStock(product);

      if (product.supplier?.shouldDistributeQuantities && quantity > quantityInStock) {
        const errorMessage = `Only ${quantityInStock} left in stock`;

        dispatch(setProductError({ productId, error: errorMessage }));
        setAvailabilityError(errorMessage);
        setIsLoading(false);

        return;
      }

      if (quantity !== product.quantityStep && product.quantityStep > 1 && quantity % product.quantityStep > 0) {
        const errorMessage = `Sold in multiples of ${product.quantityStep}. Please adjust order quantity.`;

        dispatch(
          setProductError({
            productId,
            error: errorMessage,
          })
        );
        setAvailabilityError(errorMessage);
        setIsLoading(false);

        return;
      }

      const payloads = getDistributedQuantities(payload);

      if (payloads.length > 1) {
        try {
          addToCartMultiple(payloads, (responses) => {
            setIsLoading(false);

            const isAllSuccess = Array.isArray(responses) && responses.every((response) => response.status === 200);
            const isAllFailed = Array.isArray(responses) && responses.every((response) => response.status !== 200);
            setIsSuccess(isAllSuccess);

            if (Array.isArray(responses) && !isAllSuccess) {
              const responseErrors = responses
                .filter((response) => response.status !== 200)
                .map((response) => response.error)
                .filter(isNotNull);

              setErrors(responseErrors);

              if (!isAllFailed) {
                setIsPartialSuccess(true);
              }
            }

            if (isAllSuccess) {
              launchNextSearch();
            }

            if (!Array.isArray(responses)) {
              setGeneralError();
            }
          });
        } catch (e) {
          setGeneralError();
          setIsLoading(false);
        }

        return;
      }

      const singlePayload = payloads[0];
      if (!singlePayload) {
        return;
      }

      addToCart(singlePayload, (response) => {
        setIsLoading(false);

        if (response && 'errors' in response) {
          setErrors([response as Error]);
        }

        if (response && 'orderItemId' in response) {
          setIsSuccess(true);
          launchNextSearch();
        }
      });
    },
    [dispatch, addToCart, addToCartMultiple, launchNextSearch, setAvailabilityError, setGeneralError]
  );

  return { addToCart: processAddToCart, errors, isLoading, isPartialSuccess, isSuccess };
};

const originMap: Record<NonNullable<AddToCartPayload['origin']>, CartOrderItemOrigin> = {
  API: CartOrderItemOrigin.Api,
  SEARCH: CartOrderItemOrigin.Search,
  STOCK_ORDER: CartOrderItemOrigin.StockOrder,
  UNDEFINED: CartOrderItemOrigin.Search,
};

export const useAddToCart = () => {
  const {
    flags: { newCart },
  } = useLaunchDarkly();
  const { addToCart, error, isLoading, isSuccess } = useAddToCartNew();
  const {
    addToCart: addToCartOld,
    errors: errorsOld,
    isLoading: isLoadingOld,
    isSuccess: isSuccessOld,
    isPartialSuccess,
  } = useAddToCartOld();

  const processAddToCart = useCallback(
    (payload: AddToCartPayload & { supplierAccount?: SupplierAccount | null }) => {
      const { supplierAccount, product, selectedStore, origin, ...rest } = payload;

      if (newCart) {
        addToCart({
          product: createProductFromData({
            productData: product,
            supplier: supplierAccount?.supplier,
            store: supplierAccount?.store,
          }),
          selectedStore: selectedStore
            ? createAvailabilityLineFromData(selectedStore, supplierAccount?.store?.id, supplierAccount?.nickname)
            : null,
          origin: origin ? originMap[origin] : CartOrderItemOrigin.Search,
          ...rest,
        });
      } else {
        addToCartOld({ product, selectedStore, origin, ...rest });
      }
    },
    [addToCart, addToCartOld, newCart]
  );

  return {
    addToCart: processAddToCart,
    errorMessage: newCart ? error?.errorMessage : errorsOld?.[0]?.errors[0]?.message,
    isLoading: newCart ? isLoading : isLoadingOld,
    isSuccess: newCart ? isSuccess : isSuccessOld,
    isPartialSuccess,
  };
};
