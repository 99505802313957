import { useCallback } from 'react';
import { Option } from './Option';
import type { PartType } from 'models';

type Props = {
  highlightedText?: string;
  onChange?: (option: PartType[]) => void;
  options: PartType[];
  value: PartType[];
  disabledText?: string;
};
const toggleOption = <
  T extends {
    id: string | number;
  },
>(
  isSelected: (option: T) => boolean,
  option: T,
  value: T[]
) => (isSelected(option) ? value.filter((selectedOption) => option.id !== selectedOption.id) : [...value, option]);

export const PartTypes = ({ highlightedText, onChange, options, value, disabledText }: Props) => {
  const isSelected = useCallback(
    (option: PartType) => value.some((selectedOption) => selectedOption.id === option.id),
    [value]
  );

  const selectOption = useCallback(
    (option: PartType) => {
      if (!onChange) {
        return;
      }

      onChange(toggleOption(isSelected, option, value));
    },
    [isSelected, onChange, value]
  );

  return (
    <>
      {options.map((option, index) => (
        <Option<PartType>
          highlightedText={highlightedText}
          key={`${option.id}-${index}`}
          isSelected={isSelected(option)}
          onSelect={selectOption}
          option={option}
          disabledText={disabledText}
        />
      ))}
    </>
  );
};
