import { css } from '@emotion/react';
import { Box, Card, ImageFallback, Status, TextCut, Typography } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { PartModal } from './PartModal';
import { PartPopover } from './PartPopover';
import type { CartOrderItem as CartOrderItemModel } from 'models';

const styles = {
  image: css`
    object-fit: contain;
  `,
  card: css`
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  `,
};

type Props = {
  orderItem: CartOrderItemModel;
  showRetailPrice: boolean;
  disabled: boolean;
  onChangeQuantity: (orderItem: CartOrderItemModel, quantity: number) => void;
  onRemove: (orderItem: CartOrderItemModel) => void;
};

export const CartOrderItem = ({ orderItem, showRetailPrice, disabled, onChangeQuantity, onRemove }: Props) => {
  const { isToggle: isPopoverOpen, turnOn: showPopover, turnOff: hidePopover } = useToggle(false);
  const { isToggle: isModalOpen, turnOn: showModal, turnOff: hideModal } = useToggle(false);

  const handleRemove = useCallback(() => {
    onRemove(orderItem);
  }, [onRemove, orderItem]);

  const handleChangeQuantity = useCallback(
    (quantity: number) => {
      onChangeQuantity(orderItem, quantity);
    },
    [onChangeQuantity, orderItem]
  );

  return (
    <Box width={31}>
      <Card
        position="relative"
        minHeight={31}
        p={2}
        pt={4}
        borderColor={orderItem.error ? 'negative' : 'mono17'}
        onMouseEnter={showPopover}
        onMouseLeave={hidePopover}
        onClick={showModal}
        css={styles.card}
        data-testid="cartOrderItem"
      >
        {isPopoverOpen && (
          <PartPopover
            orderItem={orderItem}
            showRetailPrice={showRetailPrice}
            disabled={disabled}
            onChangeQuantity={handleChangeQuantity}
            onRemove={handleRemove}
          />
        )}

        {orderItem.brandLogo && (
          <Box position="absolute" left={1} top={1}>
            <ImageFallback src={orderItem.brandLogo} width={48} height={28} css={styles.image} />
          </Box>
        )}

        <ImageFallback src={orderItem.image} width={108} height={72} css={styles.image} />

        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <TextCut>
            <Typography variant="caption" component="div">
              {orderItem.partNumber}
            </Typography>
          </TextCut>

          <Typography variant="subtitle2" component="div" bold>
            x{orderItem.quantity}
          </Typography>
        </Box>
      </Card>

      {orderItem.error && (
        <Box mt={1}>
          <Status variant="light" color="negative">
            {orderItem.error}
          </Status>
        </Box>
      )}

      {isModalOpen && (
        <PartModal
          orderItem={orderItem}
          showRetailPrice={showRetailPrice}
          disabled={disabled}
          onChangeQuantity={handleChangeQuantity}
          onRemove={handleRemove}
          onClose={hideModal}
        />
      )}
    </Box>
  );
};
