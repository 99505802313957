import { css } from '@emotion/react';
import { MenuItem, MenuAction, Menu, Box } from '@partstech/ui';
import { useCallback } from 'react';
import { Loader } from './Loader';
import type { Place } from '../../../types';
import type { Theme } from '@emotion/react';

const styles = {
  menuAction: (theme: Theme) => css`
    position: sticky;
    background: ${theme.color.white};
    bottom: 0;
  `,
};

type Props = {
  options: Place[];
  onSelect: (values: Place) => void;
  onSelectNotFound: () => void;
  isLoading?: boolean;
};

export const PlacesMenu = ({ options, onSelect, onSelectNotFound, isLoading }: Props) => {
  const handleSelect = useCallback(
    (place: Place) => () => {
      onSelect(place);
    },
    [onSelect]
  );

  return (
    <Menu fullWidth>
      {isLoading ? (
        <Loader />
      ) : (
        <Box position="relative" pt={3}>
          {options.map((option) => (
            <MenuItem
              key={option.id}
              secondaryText={option.address}
              justifyContent="flex-start"
              onClick={handleSelect(option)}
            >
              {option.name}
            </MenuItem>
          ))}

          <MenuAction
            typographyStyle="subtitle2"
            color="primary"
            justifyContent="center"
            onClick={onSelectNotFound}
            hasDivider
            css={styles.menuAction}
          >
            I don't see my shop
          </MenuAction>
        </Box>
      )}
    </Menu>
  );
};
