import { css } from '@emotion/react';
import type { Theme } from '@partstech/ui';

export const styles = {
  logo: (theme: Theme) => css`
    span {
      opacity: 6%;
      width: ${theme.sizing(40)};
      max-height: ${theme.sizing(30)};
      text-align: center;
    }
  `,
  filtersEmptyButton: (theme: Theme) => css`
    margin: ${theme.sizing(0, 1)};
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  `,
};
