/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { FullPartTypeFragmentDoc } from '../partTypes/FullPartType.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type TypeaheadPartTypeGroupFragment = {
  __typename?: 'PartTypeGroup';
  id: string;
  name: Types.Scalars['String']['input'];
  aliases?: Array<Types.Scalars['String']['input']> | null;
  partTypes?: Array<{
    __typename?: 'PartType';
    id: string;
    aliases?: Array<Types.Scalars['String']['input']> | null;
    application: Types.PartTypeApplication;
    name: Types.Scalars['String']['input'];
  }> | null;
};

export type TypeaheadPartTypeFragment = {
  __typename?: 'PartType';
  id: string;
  name: Types.Scalars['String']['input'];
  application: Types.PartTypeApplication;
  aliases?: Array<Types.Scalars['String']['input']> | null;
  attributes?: Array<{
    __typename?: 'PartTypeAttribute';
    name: Types.Scalars['String']['input'];
    required: Types.Scalars['Boolean']['input'];
    values?: Array<Types.Scalars['String']['input']> | null;
  }> | null;
  category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
  linkedPartTypes?: Array<{ __typename?: 'PartType'; application: Types.PartTypeApplication }> | null;
  subCategory?: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
};

export type TypeaheadGroupedPartNumberFragment = {
  __typename?: 'GroupedPartNumber';
  id: string;
  partNumber: Types.Scalars['String']['input'];
  brandName: Types.Scalars['String']['input'];
};

export type GetTypeaheadQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
}>;

export type GetTypeaheadQuery = {
  __typename?: 'Query';
  isPartNumber?: Types.Scalars['Boolean']['input'] | null;
  typeahead?: Array<{
    __typename?: 'SearchResultItem';
    suggested: Types.Scalars['Boolean']['input'];
    item:
      | {
          __typename?: 'GroupedPartNumber';
          id: string;
          partNumber: Types.Scalars['String']['input'];
          brandName: Types.Scalars['String']['input'];
        }
      | {
          __typename?: 'PartType';
          id: string;
          name: Types.Scalars['String']['input'];
          application: Types.PartTypeApplication;
          aliases?: Array<Types.Scalars['String']['input']> | null;
          attributes?: Array<{
            __typename?: 'PartTypeAttribute';
            name: Types.Scalars['String']['input'];
            required: Types.Scalars['Boolean']['input'];
            values?: Array<Types.Scalars['String']['input']> | null;
          }> | null;
          category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
          linkedPartTypes?: Array<{ __typename?: 'PartType'; application: Types.PartTypeApplication }> | null;
          subCategory?: { __typename?: 'PartSubCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
        }
      | {
          __typename?: 'PartTypeGroup';
          id: string;
          name: Types.Scalars['String']['input'];
          aliases?: Array<Types.Scalars['String']['input']> | null;
          partTypes?: Array<{
            __typename?: 'PartType';
            id: string;
            aliases?: Array<Types.Scalars['String']['input']> | null;
            application: Types.PartTypeApplication;
            name: Types.Scalars['String']['input'];
          }> | null;
        };
  }> | null;
};

export const TypeaheadPartTypeGroupFragmentDoc = `
    fragment TypeaheadPartTypeGroup on PartTypeGroup {
  id
  name
  aliases
  partTypes {
    id
    aliases
    application
    name
  }
}
    `;
export const TypeaheadPartTypeFragmentDoc = `
    fragment TypeaheadPartType on PartType {
  ...FullPartType
}
    ${FullPartTypeFragmentDoc}`;
export const TypeaheadGroupedPartNumberFragmentDoc = `
    fragment TypeaheadGroupedPartNumber on GroupedPartNumber {
  id
  partNumber: number
  brandName: companyName
}
    `;
export const GetTypeaheadDocument = `
    query GetTypeahead($search: String!) {
  typeahead(search: $search) {
    item {
      ...TypeaheadPartTypeGroup
      ...TypeaheadPartType
      ...TypeaheadGroupedPartNumber
    }
    suggested
  }
  isPartNumber(search: $search)
}
    ${TypeaheadPartTypeGroupFragmentDoc}
${TypeaheadPartTypeFragmentDoc}
${TypeaheadGroupedPartNumberFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetTypeahead: build.query<GetTypeaheadQuery, GetTypeaheadQueryVariables>({
      query: (variables) => ({ document: GetTypeaheadDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTypeaheadQuery, useLazyGetTypeaheadQuery } = injectedRtkApi;
