import { ProtectedRoute } from 'app/AppRouter/ProtectedRoute';
import { SignUpLayout } from 'components/Layout/SignUpLayout';
import { CreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext';
import { CreateShopLayoutOld } from 'components/Layout/SignUpLayout/CreateShopLayoutOld';
import { Layout } from 'components/Layout/SignUpLayout/Layout';
import { PagePermission } from 'constant';
import { CreateShopPages } from 'utils/url';
import { HearAboutUs } from './HearAboutUs';
import { ManagementSystem } from './ManagementSystem';
import { PartsSuppliers } from './PartsSuppliers';
import { PhoneNumber } from './PhoneNumber';
import { PrimaryRole } from './PrimaryRole';
import { ShopDetails } from './ShopDetails';
import { ShopType } from './ShopType';
import { TireSuppliers } from './TireSuppliers';
import type { RouteObject } from 'react-router-dom';

const createAccountPage = {
  path: 'create-account',
  lazy: () => import('./CreateAccount').then((module) => ({ Component: module.CreateAccount })),
};

const referralPage = {
  path: 'referral',
  lazy: () => import('./ReferralSignUp').then((module) => ({ Component: module.ReferralSignUp })),
};

const addShopPage = {
  path: 'create-shop',
  lazy: () => import('./AddShop').then((module) => ({ Component: module.AddShop })),
};

const selectSystemPage = {
  path: 'select-sms',
  lazy: () => import('./SelectSystem').then((module) => ({ Component: module.SelectSystem })),
};

const detailsPage = {
  path: 'details',
  lazy: () => import('./Details').then((module) => ({ Component: module.Details })),
};

const successPage = {
  path: 'success',
  lazy: () => import('./Success').then((module) => ({ Component: module.Success })),
};

const shopTypePage = {
  path: CreateShopPages.Type,
  element: <ShopType />,
};

const shopDetailsPage = {
  path: CreateShopPages.ShopDetails,
  element: <ShopDetails />,
};

const managementSystemPage = {
  path: CreateShopPages.ManagementSystem,
  element: <ManagementSystem />,
};

const partSupplierPage = {
  path: CreateShopPages.PartsSuppliers,
  element: <PartsSuppliers />,
};

const tireSupplierPage = {
  path: CreateShopPages.TireSuppliers,
  element: <TireSuppliers />,
};

const rolePage = {
  path: CreateShopPages.Role,
  element: <PrimaryRole />,
};

const hearAboutUsPage = {
  path: CreateShopPages.HearAboutUs,
  element: <HearAboutUs />,
};

const phoneNumberPage = {
  path: CreateShopPages.PhoneNumber,
  element: <PhoneNumber />,
};

export const signUpPages: RouteObject = {
  path: '/sign-up',
  children: [
    {
      element: <ProtectedRoute permission={PagePermission.UNAUTHORIZED} />,
      children: [
        {
          element: <SignUpLayout />,
          children: [createAccountPage, referralPage],
        },
        successPage,
      ],
    },

    {
      element: (
        <ProtectedRoute permission={PagePermission.AUTHORIZED_NO_SHOP}>
          <CreateShopContext>
            <CreateShopLayoutOld />
          </CreateShopContext>
        </ProtectedRoute>
      ),
      children: [addShopPage, selectSystemPage, detailsPage],
    },

    {
      element: (
        <ProtectedRoute permission={PagePermission.AUTHORIZED_NO_SHOP}>
          <CreateShopContext>
            <Layout />
          </CreateShopContext>
        </ProtectedRoute>
      ),
      children: [
        shopTypePage,
        shopDetailsPage,
        managementSystemPage,
        partSupplierPage,
        tireSupplierPage,
        rolePage,
        hearAboutUsPage,
        phoneNumberPage,
      ],
    },
  ],
};
