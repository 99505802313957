import { entries } from '@partstech/ui/utils';
import { createAvailabilityLineFromData } from 'factories/createAvailabilityLineFromData';
import { DealerQuote } from '../models/Quote/DealerQuote';
import { Quote } from '../models/Quote/Quote';
import type { Quote as QuoteType } from 'types/product';

export const createQuoteFromData = (
  quote: QuoteType,
  stockMessage: Record<string, string> | null,
  isDealer?: boolean,
  storeId?: string,
  accountNickname?: string | null,
  supplierId?: string
) => {
  if (!quote) {
    return null;
  }

  const lines =
    quote.availability?.lines?.map((line) =>
      createAvailabilityLineFromData(line, storeId, accountNickname, supplierId)
    ) ?? [];

  const message = stockMessage && entries(stockMessage).map(([key, value]) => ({ key, value }));

  if (isDealer) {
    return new DealerQuote({ action: quote.action, status: quote.status, lines, stockMessage: message });
  }

  return new Quote({ action: quote.action, status: quote.status, lines, stockMessage: message });
};
