import { Tab, Tabs } from '@partstech/ui';
import { useCallback } from 'react';
import { usePermissions } from 'store/queries/currentUser/usePermissions';
import { useMyShopTabs } from './useMyShopTabs';
import type { TabProps } from '@partstech/ui';
import type { MyShopPageAlias } from 'utils/url';

export const MyShopTabs = () => {
  const { activeTab, tabs, changeTab, isChangeLocked } = useMyShopTabs();

  const {
    member: { retailPricingAllowed: retailPricingMemberAllowed },
  } = usePermissions();

  const getIconStyles = useCallback(
    (alias: string): Partial<TabProps<MyShopPageAlias>> => {
      if (alias === 'retail-pricing' && !retailPricingMemberAllowed) {
        return { icon: 'auto_awesome', iconColor: 'secondary' };
      }

      return {};
    },
    [retailPricingMemberAllowed]
  );

  return (
    <Tabs activeTab={activeTab} onChange={changeTab} isChangeLocked={isChangeLocked} data-testid="myShopTabsContainer">
      {tabs.map((tab) => (
        <Tab id={tab.alias} key={tab.alias} data-testid={`${tab.alias}Tab`} {...getIconStyles(tab.alias)}>
          {tab.title}
        </Tab>
      ))}
    </Tabs>
  );
};
