/** @knipignore - global error enum for rest api */
export enum ErrorCode {
  USERNAME_NOT_EXIST = 0,
  GENERAL_ERROR = 1,
  CANCEL = 2,
  FAILED_CHECK_AVAILABILITY = 1001,
  VALIDATION_FIELD_ERROR = 1003,
  REQUESTED_OBJECT_NOT_FOUND = 1004,
  ACCESS_DENIED = 1005,
  AVAILABILITY_ERROR = 1007,
  UNEXPECTED_RESPONSE_FROM_REMOTE_SERVER = 4001,
  BAD_CREDENTIALS = 6000,
  ACCOUNT_DISABLED = 6001,
  ACCOUNT_UNVERIFIED = 6002,
  GOOGLE_INVALID_TOKEN = 6007,
  SHOP_ALREADY_CREATED = 7000,
}

export type ErrorLine = {
  field?: string;
  message: string;
  code: ErrorCode;
};

export interface IResponseError {
  errors: ErrorLine[];
  code: number;
}

export class ResponseError extends Error implements IResponseError {
  code: number;

  errors: ErrorLine[];

  constructor(code: number = 500, errors: ErrorLine[] = []) {
    const message = errors.length > 0 ? errors[0]?.message : 'Something went wrong';
    super(message);

    this.code = code;
    this.errors = errors;
  }

  error = (): ErrorLine => this.errors[0] ?? { message: 'Something went wrong', code: ErrorCode.GENERAL_ERROR };
}
