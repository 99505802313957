import { useCallback, useEffect, useState } from 'react';
import { useGoogleMaps } from '../components/GoogleMapsProvider/GoogleMapsContext';
import { createPlacesService } from '../utils';
import type { Place } from '../types';

const coordsUSA = { lat: 40.73061, lng: -73.935242 };

type Args = {
  value: string;
  element: HTMLElement | null;
};

type Params = Partial<{ skip: boolean }>;

export const useGetPlaces = ({ value, element }: Args, { skip = false }: Params = {}) => {
  const { isLoaded } = useGoogleMaps();

  const [options, setOptions] = useState<Place[]>([]);
  const [status, setStatus] = useState({ isLoading: false, isSuccess: false, isError: false, error: '' });

  const generateError = useCallback((errorMessage: string) => {
    setStatus({ isLoading: false, isSuccess: false, isError: true, error: errorMessage });

    return new Promise<Place[]>((_, reject) => {
      reject(new Error(errorMessage));
    });
  }, []);

  const getPlaces = useCallback(
    (query: string) => {
      if (!isLoaded) {
        return generateError('google services have not been loaded');
      }

      if (!element) {
        return generateError('element must not be null');
      }

      setStatus({ isLoading: true, isSuccess: false, isError: false, error: '' });

      const placesService = createPlacesService(element);

      if (!placesService?.textSearch) {
        return generateError('textSearch is not defined');
      }

      return new Promise<Place[]>((resolve, reject) => {
        const { PlacesServiceStatus } = google.maps.places;

        placesService?.textSearch({ query, location: coordsUSA }, (response, responseStatus) => {
          if (
            response &&
            (responseStatus === PlacesServiceStatus.OK || responseStatus === PlacesServiceStatus.ZERO_RESULTS)
          ) {
            const newData =
              response?.reduce<Place[]>((places, place, index) => {
                const isUsAddress = place.formatted_address?.endsWith('United States');
                const isCaAddress = place.formatted_address?.endsWith('Canada');
                const isMxAddress = place.formatted_address?.endsWith('Mexico');

                if (!isUsAddress && !isCaAddress && !isMxAddress) {
                  return places;
                }

                return [
                  ...places,
                  {
                    id: place.place_id ?? `${index + 1}`,
                    name: place.name ?? '',
                    address: place?.formatted_address ?? '',
                    phone: place.formatted_phone_number ?? '',
                  },
                ];
              }, []) ?? [];

            setOptions(newData);
            setStatus({ isLoading: false, isSuccess: true, isError: false, error: '' });

            resolve(newData);
            return;
          }

          const errorMessage = 'Something went wrong';

          setStatus({ isLoading: false, isSuccess: false, isError: true, error: errorMessage });

          reject(new Error(errorMessage));
        });
      });
    },
    [element, generateError, isLoaded]
  );

  useEffect(() => {
    if (skip) {
      return;
    }

    getPlaces(value);
  }, [getPlaces, value, skip]);

  return { options, refetch: getPlaces, ...status };
};
