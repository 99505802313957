import { useMemo } from 'react';
import { Country } from 'shared/api';
import { createAccountAddressFromData } from '../../lib/createAccountAddressFromData';
import { createShopAddressFromData } from '../../lib/createShopAddressFromData';
import { api as generatedApi } from './GetAddresses.generated';
import type { GetAddressesQuery } from './GetAddresses.generated';
import type { AccountAddress } from '../../model/AccountAddress';
import type { ShopAddress } from '../../model/ShopAddress';

const emptyAccountAddresses: AccountAddress[] = [];
const emptyAddresses: ShopAddress[] = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['ShopAddress'],
  endpoints: {
    GetAddresses: {
      providesTags: (response: GetAddressesQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;

        if (!shop) {
          return [];
        }

        const addressTags = shop?.addresses?.map((address) => ({ type: 'ShopAddress' as const, id: address.id })) ?? [];

        return ['ShopAddress', ...addressTags];
      },
    },
  },
});

const { useGetAddressesQuery, endpoints } = api;

type Args = {
  skip?: boolean;
};

const useGetAddresses = ({ skip = false }: Args = {}) => {
  const { data, currentData, ...props } = useGetAddressesQuery(undefined, { skip });

  const shop = data?.currentUser?.activeMember?.shop;

  const accountAddresses = useMemo(
    () => shop?.accountAddresses?.map(createAccountAddressFromData) ?? emptyAccountAddresses,
    [shop?.accountAddresses]
  );

  const addresses = useMemo(() => shop?.addresses?.map(createShopAddressFromData) ?? emptyAddresses, [shop?.addresses]);

  const shippingAddresses = useMemo(() => addresses.filter((address) => address.isShippingAddress), [addresses]);

  const billingAddress = useMemo(() => addresses.find((address) => address.isBillingAddress), [addresses]);

  const defaultAddress = useMemo(() => shippingAddresses.find((address) => address.isDefault), [shippingAddresses]);

  const isBillingAddressInUS = billingAddress?.country === Country.Us;

  return {
    accountAddresses,
    shippingAddresses,
    billingAddress,
    defaultAddress,
    isBillingAddressInUS,
    ...props,
  };
};

export { useGetAddresses, endpoints };
