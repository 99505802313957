import { FullStory } from '@fullstory/browser';
import { isDevEnvironment } from 'app/config';
import type { FSUserVars } from './FSSetUserVars';

type FSIdentifyArguments = FSUserVars & {
  id: string;
};

export const FSIdentify = ({ id, ...rest }: FSIdentifyArguments) => {
  if (isDevEnvironment()) {
    return;
  }

  FullStory.identify(id.toString(), { id, ...rest });
};
