import { useCallback } from 'react';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { getShipments } from 'store/actions/cart';
import { clearCart } from 'store/entities/shipment';
import { api as generatedApi } from './ActivateCart.generated';
import type { ActivateCartInput, Exact } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ActivateCart: {
      invalidatesTags: ['ActiveCart'],
    },
  },
});

const { useActivateCartMutation } = api;

export const useActivateCart = () => {
  const dispatch = useAppDispatch();
  const {
    flags: { newCart },
  } = useLaunchDarkly();

  const [activateCart, status] = useActivateCartMutation();

  useMutationStatus({ status });

  const triger = useCallback(
    async (input: Exact<{ input: ActivateCartInput }>) => {
      await activateCart(input);

      if (!newCart) {
        dispatch(clearCart());
        dispatch(getShipments());
      }
    },
    [activateCart, dispatch, newCart]
  );

  return [triger, status] as const;
};
