import { targetSupplierIds } from 'constant';
import { AvailabilityLine } from 'models';
import type { AvailabilityLine as AvailabilityLineType } from 'types/product';

export const createAvailabilityLineFromData = (
  line: AvailabilityLineType,
  storeId?: string,
  accountNickname?: string | null,
  supplierId?: string
) => {
  const availabilityLine = new AvailabilityLine({
    ...line,
    hasTargetSupplier: targetSupplierIds.includes(Number(supplierId)),
  });

  if (availabilityLine.id === Number(storeId)) {
    availabilityLine.setNickname(accountNickname);
  }

  return availabilityLine;
};
