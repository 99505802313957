/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CartLaborItemFragmentDoc } from '../../../../../shared/api/graphql/fragment/CartLaborItem.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type AddLaborItemToCartMutationVariables = Types.Exact<{
  hours: Types.Scalars['BigDecimal']['input'];
  defaultHours?: Types.InputMaybe<Types.Scalars['BigDecimal']['input']>;
  applicationId: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  orderItemId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  skill: Types.Scalars['String']['input'];
  vehicleId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vin?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AddLaborItemToCartMutation = {
  __typename?: 'Mutation';
  addLaborItemToActiveCart?:
    | { __typename?: 'AddLaborItemToActiveCartErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | {
        __typename?: 'AddLaborItemToActiveCartSuccessPayload';
        cartLaborItem: {
          __typename?: 'CartLaborItem';
          id: string;
          hours: Types.Scalars['Float']['input'];
          defaultHours: Types.Scalars['Float']['input'];
          vin?: Types.Scalars['String']['input'] | null;
          orderItemId?: string | null;
          name: Types.Scalars['String']['input'];
          notes?: Types.Scalars['String']['input'] | null;
          skill: Types.Scalars['String']['input'];
          identifiers?:
            | { __typename?: 'Mitchell1Identifiers' }
            | { __typename?: 'MotorIdentifiers'; id: string }
            | null;
          vehicle?: { __typename?: 'Vehicle'; id: string } | null;
        };
      }
    | null;
};

export const AddLaborItemToCartDocument = `
    mutation AddLaborItemToCart($hours: BigDecimal!, $defaultHours: BigDecimal, $applicationId: ID!, $name: String!, $orderItemId: ID, $skill: String!, $vehicleId: ID, $vin: String) {
  addLaborItemToActiveCart(
    input: {hours: $hours, defaultHours: $defaultHours, motorIdentifiers: {applicationId: $applicationId}, name: $name, orderItemId: $orderItemId, vehicleId: $vehicleId, skill: $skill, vin: $vin}
  ) {
    ... on AddLaborItemToActiveCartErrorPayload {
      errorMessage
    }
    ... on AddLaborItemToActiveCartSuccessPayload {
      cartLaborItem {
        ...CartLaborItem
      }
    }
  }
}
    ${CartLaborItemFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddLaborItemToCart: build.mutation<AddLaborItemToCartMutation, AddLaborItemToCartMutationVariables>({
      query: (variables) => ({ document: AddLaborItemToCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddLaborItemToCartMutation } = injectedRtkApi;
