import { endpoints as addressesEndpoints } from 'entities/address';
import { createSupplierAccountFromData, createSupplierFromQueryData, createStoreFromQueryData } from 'factories';
import { ShopAddressType } from 'shared/api';
import { endpoints } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { endpoints as currentUserEndpoints } from 'store/queries/currentUser/useGetCurrentUser';
import type { RootState } from 'store';

/*
 * @deprecated Used only in redux slices
 */
export const selectSupplierAccountFromRootStoreById = (rootState: RootState, accountId: string | null) => {
  if (!accountId) {
    return null;
  }

  const accountsQuery = endpoints.GetSupplierAccounts.select({})(rootState).data;
  const account = accountsQuery?.currentUser?.activeMember?.shop?.accounts?.find((entity) => entity.id === accountId);

  if (!account) {
    return null;
  }

  const addressesQuery = addressesEndpoints.GetAddresses.select()(rootState).data;
  const coordinates =
    addressesQuery?.currentUser?.activeMember?.shop?.addresses?.find(
      (entity) => entity.type === ShopAddressType.Shipping && entity.isDefault
    )?.coordinates ?? null;

  const currentUserQuery = currentUserEndpoints.GetCurrentUser.select()(rootState).data;
  const pickupRadius = currentUserQuery?.currentUser?.activeMember?.shop?.pickupRadius;

  const supplierEntity = account.supplier ? createSupplierFromQueryData(account.supplier) : null;

  const storeEntity = account.store
    ? createStoreFromQueryData(account.store, supplierEntity, coordinates, pickupRadius)
    : null;

  return createSupplierAccountFromData(account, supplierEntity, storeEntity);
};
