import { usePosition, usePrevious } from '@partstech/ui/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useCartOld } from 'app/CartProviderOld/CartContext';
import { useAppSelector } from 'store';
import { selectPartStatuses } from 'store/features/cart';

export const useSavedQuotesIcon = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null);

  const partStatuses = useAppSelector(selectPartStatuses, shallowEqual);

  const partStatusesValues = useMemo(() => Object.values(partStatuses), [partStatuses]);

  const { isLoading } = useCartOld();

  const [showAnimation, setShowAnimation] = useState(false);

  const partsCount = partStatusesValues.length;
  const isCartEmpty = partsCount === 0;
  const isFirstPartAdded = partStatusesValues[0]?.type === 'added';

  const isCartEmptyPrev = usePrevious(isCartEmpty);

  const isFirstAdding = useMemo(
    () => isCartEmptyPrev && partsCount === 1 && isFirstPartAdded,
    [isCartEmptyPrev, isFirstPartAdded, partsCount]
  );

  const { position } = usePosition({ contentRef, wrapRef, preferPlacement: 'top-start', offset: -7 });

  useEffect(() => {
    if (isFirstAdding) {
      setShowAnimation(true);

      timerRef.current = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
    }
  }, [isCartEmptyPrev, isFirstAdding]);

  useEffect(() => {
    if (!showAnimation && timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [showAnimation]);

  return { wrapRef, contentRef, position, showAnimation, isLoading: isLoading && !isCartEmpty && !isFirstAdding };
};
