import { makeContext } from '@partstech/ui/utils';
import type { LaborApplication } from 'models';

export type AddingLaborContextValue = {
  selectedLaborApplications: LaborApplication[];
  customDurations: Record<string, number>;
  checkedLaborMap: Record<string, boolean>;
  totalHours: number;
  toggleLaborApplication: (application: LaborApplication) => void;
  changeCustomDuration: (laborApplicationId: string, customDuration: number) => void;
  clear: () => void;
  addToCart: (vehicleId: string, vin?: string) => void;
};

export const [useAddingLabor, AddingLaborContextProvider] = makeContext<AddingLaborContextValue>();
