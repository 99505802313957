import { useForm } from '@partstech/ui/forms';
import { useCallback, useEffect } from 'react';
import { useSendReasonsMutation, useRemoveAddOn } from '../api';
import { useDowngradePlan } from './useDowngradePlan';

type FormData = {
  feedback: string;
  terms: boolean;
};

type Args = {
  currentPlanId?: string;
  id: string | null;
  isPlan: boolean;
  selectedReasons: string[];
  onSuccess?: () => void;
};

export const useDowngradeForm = ({ currentPlanId, id, isPlan, selectedReasons, onSuccess }: Args) => {
  const [downgradePlan, downgradePlanStatus] = useDowngradePlan(id);
  const [removeAddOn, removeAddOnStatus] = useRemoveAddOn(id);
  const [sendReasons] = useSendReasonsMutation();

  const form = useForm<FormData>({ defaultValues: { feedback: '', terms: false } });
  const { formState, watch } = form;
  const { isSubmitting } = formState;

  const terms = watch('terms');
  const feedback = watch('feedback');

  const isSubmitDisabled = isSubmitting || !selectedReasons.length || !terms;
  const status = isPlan ? downgradePlanStatus : removeAddOnStatus;

  const submitForm = useCallback(async () => {
    if (isPlan) {
      await downgradePlan();
      return;
    }

    await removeAddOn();
  }, [isPlan, downgradePlan, removeAddOn]);

  useEffect(() => {
    if (status.isSuccess && onSuccess) {
      onSuccess();
    }
  }, [status.isSuccess, onSuccess]);

  useEffect(() => {
    if (status.isSuccess) {
      const planId = isPlan ? currentPlanId : id;

      if (planId) {
        sendReasons({ input: { planId, reasons: selectedReasons, otherReasonText: feedback } });
      }
    }
  }, [currentPlanId, feedback, id, isPlan, selectedReasons, status.isSuccess, sendReasons]);

  return {
    form,
    isSubmitting,
    isSubmitDisabled,
    submitForm,
  };
};
