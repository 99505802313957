import { useCallback, useState } from 'react';
import { useSearchSequence } from 'features/searchSequence';
import { AddItemError } from 'shared/api';
import { useCart } from '../contexts/CartProvider/CartContext';
import { getDistributedQuantities } from '../utils/getDistributedQuantities';
import { useAddedToCartSnackbar } from './useAddedToCartSnackbar';
import { usePartAddedTracking } from './usePartAddedTracking';
import type { AddToCartPayload } from '../types';
import type { AddItemToActiveCartErrorPayload } from 'shared/api';

export const useAddToCart = () => {
  const { addToCart, addToCartMultiple } = useCart();
  const { launchNext: launchNextSearch } = useSearchSequence();
  const { showAddedToCartSnackbar } = useAddedToCartSnackbar();
  const { trackEvent } = usePartAddedTracking();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<AddItemToActiveCartErrorPayload | null>(null);

  const processAddToCart = useCallback(
    (payload: AddToCartPayload) => {
      const { quantity, product } = payload;

      setError(null);
      setIsLoading(true);
      setIsSuccess(false);

      const quantityInStock = product.quote?.getAvailableQuantity() || 0;

      if (product.supplier?.shouldDistributeQuantities && quantity > quantityInStock) {
        const errorMessage = `Only ${quantityInStock} left in stock`;
        setError({ error: AddItemError.Quantity, errorMessage });
        trackEvent(payload, errorMessage);
        setIsLoading(false);
        return;
      }

      if (quantity !== product.quantityStep && product.quantityStep > 1 && quantity % product.quantityStep > 0) {
        const errorMessage = `Sold in multiples of ${product.quantityStep}. Please adjust order quantity.`;
        setError({ error: AddItemError.Quantity, errorMessage });
        trackEvent(payload, errorMessage);
        setIsLoading(false);
        return;
      }

      const payloads = getDistributedQuantities(payload);

      if (payloads.length === 1 && payloads[0]) {
        addToCart(payloads[0], (response) => {
          setIsLoading(false);

          if (response && 'error' in response) {
            setError(response);
            trackEvent(payload, response.errorMessage);
          }

          if (response && 'orderItemId' in response) {
            setIsSuccess(true);
            showAddedToCartSnackbar();
            trackEvent(payload);
            launchNextSearch();
          }
        });
      } else {
        addToCartMultiple(payloads, (responses) => {
          setIsLoading(false);

          const isAllSuccess = responses.every((response) => 'orderItemId' in response.result);

          if (isAllSuccess) {
            setIsSuccess(isAllSuccess);
            showAddedToCartSnackbar();
            launchNextSearch();
            trackEvent(payload);
            return;
          }

          responses.forEach((response) => {
            if ('error' in response.result) {
              setError(response.result);
              trackEvent(payload, response.result.errorMessage);
            }
          });
        });
      }
    },
    [addToCart, addToCartMultiple, launchNextSearch, showAddedToCartSnackbar, trackEvent]
  );

  return { addToCart: processAddToCart, error, isLoading, isSuccess };
};
