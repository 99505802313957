/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSearchEntryPointsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSearchEntryPointsQuery = {
  __typename?: 'Query';
  popularPartTypes?: Array<{
    __typename?: 'PartType';
    id: string;
    name: Types.Scalars['String']['input'];
    application: Types.PartTypeApplication;
    aliases?: Array<Types.Scalars['String']['input']> | null;
    attributes?: Array<{
      __typename?: 'PartTypeAttribute';
      name: Types.Scalars['String']['input'];
      required: Types.Scalars['Boolean']['input'];
      values?: Array<Types.Scalars['String']['input']> | null;
    }> | null;
  }> | null;
  categories?: Array<{
    __typename?: 'PartCategory';
    id: string;
    name: Types.Scalars['String']['input'];
    subCategories?: Array<{
      __typename?: 'PartSubCategory';
      id: string;
      name: Types.Scalars['String']['input'];
    }> | null;
  }> | null;
  tireSizes?: Array<{
    __typename?: 'TireSize';
    height: Types.Scalars['Float']['input'];
    width: Types.Scalars['Float']['input'];
    rim: Types.Scalars['Float']['input'];
  }> | null;
};

export const GetSearchEntryPointsDocument = `
    query GetSearchEntryPoints {
  popularPartTypes {
    id
    name
    application
    aliases
    attributes(requiredOnly: true) {
      name
      required
      values
    }
  }
  categories {
    id
    name
    subCategories {
      id
      name
    }
  }
  tireSizes {
    height
    width
    rim
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSearchEntryPoints: build.query<GetSearchEntryPointsQuery, GetSearchEntryPointsQueryVariables | void>({
      query: (variables) => ({ document: GetSearchEntryPointsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSearchEntryPointsQuery, useLazyGetSearchEntryPointsQuery } = injectedRtkApi;
