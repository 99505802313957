import { useMedia } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useSelectedSmsFromQuery } from 'hooks/shopManagementSystems';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import { getSignUpUrl } from 'utils/url';
import { useCreateShopContext } from '../../CreateShopContext/CreateShopContext';

enum NameStep {
  ADD_SHOP_DESKTOP = 'Add shop',
  ADD_SHOP_MOBILE = `What's the name of your business?`,
  SELECT_SMS = 'Select system',
  DETAILS_DESKTOP = 'Details',
  DETAILS_MOBILE = 'Additional details',
}

export const useCreateShopStepper = () => {
  const { isMobile } = useMedia();

  const navigate = useAppNavigate();
  const { pathname } = useAppLocation();

  const { isDuplicatedShopStep, setIsDuplicatedShopStep, isHiddenSignUpStepper } = useCreateShopContext();

  const { managementSystem, shop } = useGetOnBoard();
  const { selectedSms } = useSelectedSmsFromQuery();

  const steps = useMemo(
    () =>
      [
        {
          url: getSignUpUrl('create-shop', { selectedSms }),
          isCompleted: true,
          name: isMobile ? NameStep.ADD_SHOP_MOBILE : NameStep.ADD_SHOP_DESKTOP,
          isSkipped: false,
        },
        {
          url: getSignUpUrl('select-sms', { selectedSms }),
          isCompleted: Boolean(shop),
          name: NameStep.SELECT_SMS,
          isSkipped: Boolean(selectedSms),
        },
        {
          url: getSignUpUrl('details', { selectedSms }),
          isCompleted: Boolean(managementSystem),
          name: isMobile ? NameStep.DETAILS_MOBILE : NameStep.DETAILS_DESKTOP,
        },
      ].filter((step) => !step.isSkipped),
    [isMobile, managementSystem, selectedSms, shop]
  );

  const currentStep = useMemo(() => steps.findIndex((step) => step.url.startsWith(pathname)), [steps, pathname]);

  const previousStep = isDuplicatedShopStep ? 0 : currentStep - 1;

  const stepNames = useMemo(
    () =>
      steps.map((step, index) => ({
        isCompleted: step.isCompleted,
        label: isMobile ? step.name : step.name.toUpperCase(),
        optional: currentStep !== index,
      })),
    [steps, isMobile, currentStep]
  );

  const changeStep = useCallback(
    (stepNumber: number) => () => {
      const selectedStep = steps[stepNumber - 1];

      if (selectedStep?.url && selectedStep.isCompleted) {
        navigate(selectedStep.url);
      }
    },
    [navigate, steps]
  );

  const navigateToPreviousStep = useCallback(() => {
    if (isDuplicatedShopStep) {
      setIsDuplicatedShopStep(false);
    }

    navigate(steps[previousStep]?.url ?? '');
  }, [isDuplicatedShopStep, navigate, steps, previousStep, setIsDuplicatedShopStep]);

  const showPreviousButton = !isHiddenSignUpStepper && (currentStep > 0 || isDuplicatedShopStep);

  return { steps, stepNames, currentStep, previousStep, changeStep, navigateToPreviousStep, showPreviousButton };
};
