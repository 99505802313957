/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSignUpGoogleAuthUriQueryVariables = Types.Exact<{
  selectedSms?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetSignUpGoogleAuthUriQuery = {
  __typename?: 'Query';
  signUpGoogleAuthUri?: Types.Scalars['String']['input'] | null;
};

export const GetSignUpGoogleAuthUriDocument = `
    query GetSignUpGoogleAuthUri($selectedSms: String) {
  signUpGoogleAuthUri(selectedSms: $selectedSms)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSignUpGoogleAuthUri: build.query<GetSignUpGoogleAuthUriQuery, GetSignUpGoogleAuthUriQueryVariables | void>({
      query: (variables) => ({ document: GetSignUpGoogleAuthUriDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSignUpGoogleAuthUriQuery, useLazyGetSignUpGoogleAuthUriQuery } = injectedRtkApi;
