import { useCallback, useMemo } from 'react';
import { SubscriptionPlanPeriod } from 'shared/api';
import { useGetSubscriptionProducts } from '../api';
import { useSubscription } from './useSubscription';
import type { ProductAlias } from '../constants';

export const useSubscriptionProducts = (isYearly: boolean) => {
  const {
    getProductByAlias: getSubscriptionProductByAlias,
    isLoading: isSubscriptionLoading,
    subscription,
  } = useSubscription();

  const { isLoading: isProductsLoading, products: productsList } = useGetSubscriptionProducts(isSubscriptionLoading);

  const products = useMemo(
    () =>
      productsList.map((product) => {
        const { name, plans } = product;

        const alias = product.alias as unknown as ProductAlias;
        const subscriptionProduct = getSubscriptionProductByAlias(alias);

        const monthlyPlan = plans?.find((plan) => plan.period === SubscriptionPlanPeriod.Month);
        const yearlyPlan = plans?.find((plan) => plan.period === SubscriptionPlanPeriod.Year);

        const priceMonthly = monthlyPlan?.amount ?? 0;
        const priceYearly = yearlyPlan?.amount ?? 0;

        return {
          alias,
          id: (isYearly ? yearlyPlan?.id : monthlyPlan?.id) ?? '',
          isActive: Boolean(subscriptionProduct?.isActive),
          isExpires: Boolean(subscriptionProduct?.isExpires),
          isYearly,
          name,
          price: isYearly ? priceYearly : priceMonthly,
          priceMonthly,
          priceYearly,
        };
      }),
    [getSubscriptionProductByAlias, isYearly, productsList]
  );

  const getProductByAlias = useCallback(
    (alias: ProductAlias) => products.find((product) => product.alias === alias),
    [products]
  );

  return {
    isLoading: isProductsLoading || isSubscriptionLoading,
    products,
    subscription,
    getProductByAlias,
  };
};
