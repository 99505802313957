import { Box, Scrollbar, Typography } from '@partstech/ui';
import { memo, useCallback } from 'react';
import synchronizeIcon from 'images/icons/synchronize.svg';
import { styles } from '../FileViewerModal.styles';
import { ImageCell } from '../ImageCell';
import type { File } from 'types/entityTyped';

type Props = {
  files: File[];
  hasImages360Groups: boolean;
  currentIndex: number;
  isVertical: boolean;
  onClick: (index: number) => void;
};

export const FilesCarousel = memo(({ files, hasImages360Groups, currentIndex, isVertical, onClick }: Props) => {
  const handleClick = useCallback(
    (index: number) => () => {
      if (index === currentIndex) {
        return;
      }

      onClick(index);
    },
    [currentIndex, onClick]
  );

  return (
    <Scrollbar css={styles.scrollbar} data-testid="filesCarousel">
      <Box display="inline-flex" flexDirection={isVertical ? 'column' : 'row'} gap={2}>
        {files.map((file, index) => {
          const isSelected = index === currentIndex;

          return (
            <ImageCell
              key={`${file.preview}_${index}`}
              file={file}
              index={index}
              isSelected={isSelected}
              onClick={handleClick(index)}
            />
          );
        })}

        {hasImages360Groups && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            data-testid="view360"
            width={17}
            height={16}
            p={currentIndex === files.length ? 0.75 : 1}
            className={currentIndex === files.length ? 'active' : undefined}
            css={styles.carouselButton(currentIndex === files.length)}
            onClick={handleClick(files.length)}
          >
            <img src={synchronizeIcon} alt="sync_icon" />
            <Typography variant="caption" color="primary">
              View 360
            </Typography>
          </Box>
        )}
      </Box>
    </Scrollbar>
  );
});
