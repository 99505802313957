import { BaseStatus } from 'components/Product/StoreAvailability/Statuses/BaseStatus';
import type { AvailabilityStatusProps } from 'components/Product/StoreAvailability/AvailabilityDropdown';

type Props = AvailabilityStatusProps;

export const InStockStatus = ({ onClick, store, trailingIcon }: Props) => (
  <BaseStatus
    color="positive"
    leadingIcon={store?.getIcon()}
    onClick={onClick}
    secondaryText={`${'Home Store'}: ${store?.displayName}`}
    text={store?.getTitle() ?? ''}
    trailingIcon={trailingIcon}
  />
);
