import { useSnackbar, useMedia } from '@partstech/ui';
import { useDebouncedCallback, usePrevious, useScrollListener } from '@partstech/ui/hooks';
import { isEmpty } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { RemoveFromCartSnackbar } from 'components/CartBar/RemoveFromCartSnackbar';
import { Routes } from 'constant';
import { useAddedToCartSnackbar, useCartErrors } from 'hooks/cart';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useAppDispatch, useAppSelector } from 'store';
import { getShipments } from 'store/actions/cart';
import { removePaidShipmentsAndParts, selectAreShipmentsLoading, selectPaidShipmentIds } from 'store/entities/shipment';
import { selectHasRemovingParts } from 'store/entities/shipmentPart';
import {
  selectIsCartBarLoading,
  consumeAddToCart,
  consumeAddToCartMultiple,
  consumeRemoveFromCart,
  selectShipmentsToUpdates,
  clearShipmentsToUpdate,
  setPartStatus,
  selectIsFirstLoadingCart,
  selectIsCartSubmitting,
} from 'store/features/cart';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { CartContextProvider } from './CartContext';
import { usePartAddedTracking } from './usePartAddedTracking';
import { useRemoveFromCartTracking } from './useRemoveFromCartTracking';
import type { CartContextValue } from './CartContext';
import type { CartProviderProps, Highlight, AddToCartPayload, QueueItem } from './types';
import type { LaborRate, ResponseError } from 'shared/api';
import type { AddItemResponse } from 'shared/api/rest/gen/shop';
import type { GetShipmentsArgs } from 'store/actions/cart';
import type { FullShipmentPart } from 'types/shipment';

export const CartProviderOld = ({ children }: CartProviderProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useMedia();
  const { addSnackbar } = useSnackbar();
  const isCartPage = Boolean(useMatch(Routes.CART));
  const navigate = useAppNavigate();
  const { isScrolling, onScroll } = useScrollListener();
  const { trackRemoveFromCart } = useRemoveFromCartTracking();
  const {
    flags: { newCart },
  } = useLaunchDarkly();

  const cartErrors = useCartErrors();

  usePartAddedTracking();

  useAddedToCartSnackbar();

  const { shop, hasCurrentUser } = useGetCurrentUser();
  const { isLoading: isPunchoutLoading } = usePunchoutInfo();

  const isCartLoading = useAppSelector(selectIsCartBarLoading);
  const isSubmitting = useAppSelector(selectIsCartSubmitting);
  const areShipmentsLoading = useAppSelector(selectAreShipmentsLoading);
  const shipmentsToUpdate = useAppSelector(selectShipmentsToUpdates, shallowEqual);
  const shipmentPaidIds = useAppSelector(selectPaidShipmentIds, shallowEqual);
  const isFirstLoadingCart = useAppSelector(selectIsFirstLoadingCart, shallowEqual);

  const [queue, setQueue] = useState<QueueItem[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [isCompletedOrder, setIsCompletedOrder] = useState(false);
  const [laborRate, setLaborRate] = useState<LaborRate | null>(null);

  const isAddingBlocked = useAppSelector(selectHasRemovingParts);
  const isRemovingBlocked = useMemo(() => queue[0]?.type === 'add', [queue]);

  const previousPickupRadius = usePrevious(shop?.pickupRadius);

  const clearHighlights = useDebouncedCallback(() => setHighlights([]), 600);

  const addHighlight = useCallback(
    (highlight: Highlight[]) => {
      setHighlights(highlight);

      clearHighlights();
    },
    [clearHighlights]
  );

  const getHighlight = useCallback(
    (partId: number) => highlights.find((highlight) => highlight.partId === partId)?.type,
    [highlights]
  );

  const proceedQueue = useCallback(
    async (item: QueueItem) => {
      setIsProcessing(true);

      if (item.type === 'add') {
        const action = await dispatch(consumeAddToCart(item.payload));
        if (item.onAddSuccess) {
          item.onAddSuccess(action.payload);
        }
      }

      if (item.type === 'addMultiple') {
        const action = await dispatch(consumeAddToCartMultiple(item.payload));
        if (item.onFinally) {
          item.onFinally(action.payload);
        }
      }

      if (item.type === 'remove') {
        await dispatch(consumeRemoveFromCart(item.payload));
        trackRemoveFromCart(item.payload);
      }

      setQueue((prevQueue) => prevQueue.slice(1));
      setIsProcessing(false);
    },
    [dispatch, trackRemoveFromCart]
  );

  const updateShipments = useCallback(
    (arg?: GetShipmentsArgs) => {
      if (newCart !== false) {
        return;
      }

      dispatch(getShipments(arg));
    },
    [dispatch, newCart]
  );

  useEffect(() => {
    if (!isProcessing && queue.length > 0 && queue[0]) {
      proceedQueue(queue[0]);
    }
  }, [isProcessing, proceedQueue, queue]);

  useEffect(() => {
    if (queue.length === 0 && shipmentsToUpdate.length > 0 && !isAddingBlocked) {
      shipmentsToUpdate.forEach((shipmentId) => updateShipments({ id: shipmentId }));
      dispatch(clearShipmentsToUpdate());
    }
  }, [dispatch, isAddingBlocked, queue.length, shipmentsToUpdate, updateShipments]);

  useEffect(() => {
    if (isFirstLoadingCart || shop?.pickupRadius === previousPickupRadius) {
      return;
    }

    updateShipments();
  }, [updateShipments, isFirstLoadingCart, previousPickupRadius, shop?.pickupRadius]);

  useEffect(() => {
    if (hasCurrentUser && !isEmpty(shop) && isFirstLoadingCart) {
      updateShipments();
    }
  }, [updateShipments, shop, hasCurrentUser, isFirstLoadingCart]);

  const removeFromCart = useCallback(
    (part: FullShipmentPart) => {
      if (!isRemovingBlocked) {
        dispatch(setPartStatus({ partId: part.id, status: { type: 'removed' } }));

        addSnackbar((removeSnackbar) => ({
          as: (
            <RemoveFromCartSnackbar
              partId={part.id}
              onClick={() => {
                dispatch(setPartStatus({ partId: part.id, status: { type: 'ready' } }));
                removeSnackbar();
              }}
            />
          ),
          maxCount: null,
          onTimeout: () => setQueue((prev) => [...prev, { type: 'remove', payload: part }]),
          position: { vertical: 'bottom', horizontal: 'middle', offsetY: isMobile ? 0 : 4 },
          duration: 4,
        }));
      }
    },
    [addSnackbar, dispatch, isMobile, isRemovingBlocked]
  );

  useEffect(() => {
    if (shipmentPaidIds.length > 0) {
      if (isCartPage && !isCompletedOrder) {
        setIsCompletedOrder(true);
      }

      if (!isCartPage && isCompletedOrder) {
        dispatch(removePaidShipmentsAndParts(shipmentPaidIds));
        setIsCompletedOrder(false);
      }

      if (!isCartPage && !isCompletedOrder) {
        navigate(Routes.CART);
      }
    }
  }, [dispatch, isCartPage, isCompletedOrder, navigate, shipmentPaidIds]);

  const addToCart = useCallback(
    (item: AddToCartPayload, onAddSuccess?: (response?: AddItemResponse | ResponseError) => void) => {
      if (!isAddingBlocked) {
        setQueue((prev) => [...prev, { type: 'add', payload: item, onAddSuccess }]);
      }
    },
    [isAddingBlocked]
  );

  const addToCartMultiple = useCallback(
    (items: AddToCartPayload[], onFinally?: (responses?: AddItemResponse[] | ResponseError) => void) => {
      if (!isAddingBlocked) {
        setQueue((prev) => [...prev, { type: 'addMultiple', payload: items, onFinally }]);
      }
    },
    [isAddingBlocked]
  );

  const checkIsPartAdding = useCallback(
    (id?: string) =>
      queue.some(
        (item) =>
          (item.type === 'add' && item.payload.product.id === id) ||
          (item.type === 'addMultiple' && item.payload.some((part) => part.product.id === id))
      ),
    [queue]
  );

  const contextValue: CartContextValue = useMemo(
    () => ({
      updateShipments,
      addToCart,
      addToCartMultiple,
      removeFromCart,
      checkIsPartAdding,
      addHighlight,
      getHighlight,
      laborRate,
      setLaborRate,
      isScrolling,
      onScroll,
      cartErrors,
      isSubmitting,
      isLoading:
        isCartLoading ||
        isPunchoutLoading ||
        areShipmentsLoading ||
        queue.length > 0 ||
        shipmentsToUpdate.length > 0 ||
        isAddingBlocked,
    }),
    [
      updateShipments,
      addHighlight,
      addToCart,
      addToCartMultiple,
      areShipmentsLoading,
      checkIsPartAdding,
      getHighlight,
      isAddingBlocked,
      isCartLoading,
      isPunchoutLoading,
      isScrolling,
      laborRate,
      onScroll,
      queue.length,
      removeFromCart,
      shipmentsToUpdate.length,
      cartErrors,
      isSubmitting,
    ]
  );

  return <CartContextProvider value={contextValue}>{children}</CartContextProvider>;
};
