import { useEffect, useState } from 'react';
import { FSIdentify } from 'integrations/fullstory/FSIdentify';
import { useFeatureFlag } from 'store/queries/currentUser/useFeatureFlag';
import { useGetOnBoard } from 'store/queries/currentUser/useGetOnBoard';
import type { FSUserVars } from 'integrations/fullstory';

const getAccountType = (isSupplier: boolean): FSUserVars['accountType'] => (isSupplier ? 'supplier' : 'DIFM');

export const useIdentifyFullstory = () => {
  const { fullName, email, username, accountId, isSupplier, hasCurrentUser, isSuccess, managementSystem, isDemo } =
    useGetOnBoard();

  const [isIdentified, setIsIdentified] = useState(false);

  const { availableFeatureFlags: featureFlags } = useFeatureFlag();

  useEffect(() => {
    if (accountId && isSuccess) {
      const userVars = {
        id: accountId,
        accountType: getAccountType(isSupplier),
        displayName: fullName,
        email: email ?? undefined,
        userName: username ?? undefined,
        shopManagementSystemName: managementSystem?.name ?? '',
        smsValidatedDate: null,
        featureFlag: featureFlags,
        isDemo,
      };

      FSIdentify(userVars);
      setIsIdentified(true);
    }
  }, [accountId, email, featureFlags, fullName, isDemo, isSuccess, isSupplier, managementSystem?.name, username]);

  useEffect(() => {
    if (!hasCurrentUser && isIdentified) {
      setIsIdentified(false);
    }
  }, [hasCurrentUser, isIdentified]);

  return isIdentified;
};
