import { useCallback } from 'react';
import { useMutationStatus } from 'shared/api';
import { useCreateOrUpdateOnboardStepMutation } from '../api/mutations/onboardStep';
import type { CreateOrUpdateOnboardStepInput, MutationStatusArgs } from 'shared/api';

export const useCreateOnboardStep = (args: Omit<MutationStatusArgs, 'status'> = {}) => {
  const [createStep, status] = useCreateOrUpdateOnboardStepMutation();

  const createOnboardStep = useCallback((input: CreateOrUpdateOnboardStepInput) => createStep({ input }), [createStep]);

  useMutationStatus({ status, ...args });

  return [createOnboardStep, status] as const;
};
