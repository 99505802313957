import { css } from '@emotion/react';
import { Box, Button, ImageFallback, Link, Typography } from '@partstech/ui';
import { isNumber } from '@partstech/ui/utils';
import { shallowEqual } from 'react-redux';
import { Routes } from 'constant';
import { currency } from 'shared/lib/string';
import { useAppSelector } from 'store';
import { selectPartById } from 'store/entities/shipmentPart';
import type { Theme } from '@partstech/ui';

const styles = {
  root: (theme: Theme) => css`
    background: rgba(0, 0, 0, 0.8);
    border-radius: ${theme.sizing(1)};

    ${theme.breakpoints.down('md')} {
      border-radius: 0;
    }
  `,
  image: css`
    object-fit: contain;
  `,
  button: (theme: Theme) => css`
    background-color: ${theme.color.secondary};

    &:hover {
      background-color: ${theme.color.secondaryLight};
    }
  `,
};

type Props = {
  partId: number;
  onRemove?: () => void;
};

export const AddedToCartSnackbar = ({ partId, onRemove }: Props) => {
  const part = useAppSelector((state) => selectPartById(state, partId), shallowEqual);

  if (!part) {
    return null;
  }

  return (
    <Box color="white" width={{ sm: '100vw', md: 'auto' }} p={4} css={styles.root}>
      <Box display="flex" justifyContent="space-between">
        <ImageFallback width={64} height={64} src={part.product?.images?.partImage?.preview ?? ''} css={styles.image} />

        <Box px={4} width="100%">
          <Typography color="white">
            <Typography component="span" color="inherit" bold>
              {part.product?.title}{' '}
            </Typography>
            added to your shopping cart
          </Typography>
        </Box>

        <Button leadingIcon="close" variant="light" iconColor="white" size="dense" onClick={onRemove} />
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Button as={<Link to={Routes.CART} />} size="dense" onClick={onRemove} css={styles.button}>
          Proceed to checkout
        </Button>

        <div>
          <Typography color="white">{part.quantity} x </Typography>

          <Typography color="white" variant="h3">
            {isNumber(part.product?.price) && currency(part.product?.price || 0)}
          </Typography>
        </div>
      </Box>
    </Box>
  );
};
