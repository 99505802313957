import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useSignupQueryParams } from 'hooks/signup';
import { CreateShopPages, getSignUpUrlNew } from 'utils/url';

export type SignUpStep = {
  id: CreateShopPages;
  name: string;
  url: string;
  isActive: boolean;
};

type Args = {
  isMitchell1SignUp: boolean;
};

export const useSignUpSteps = ({ isMitchell1SignUp }: Args) => {
  const { pathname } = useAppLocation();

  const searchParams = useSignupQueryParams();

  const signUpSteps = useMemo<SignUpStep[]>(
    () =>
      [
        {
          id: CreateShopPages.Type,
          name: 'Shop type',
          url: getSignUpUrlNew(CreateShopPages.Type, searchParams),
          isActive: true,
        },
        {
          id: CreateShopPages.ShopDetails,
          name: 'Shop details',
          url: getSignUpUrlNew(CreateShopPages.ShopDetails, searchParams),
          isActive: true,
        },
        {
          id: CreateShopPages.ManagementSystem,
          name: 'Management system',
          url: getSignUpUrlNew(CreateShopPages.ManagementSystem, searchParams),
          isActive: !isMitchell1SignUp,
        },
        {
          id: CreateShopPages.PartsSuppliers,
          name: 'Parts suppliers',
          url: getSignUpUrlNew(CreateShopPages.PartsSuppliers, searchParams),
          isActive: true,
        },
        {
          id: CreateShopPages.TireSuppliers,
          name: 'Tire suppliers',
          url: getSignUpUrlNew(CreateShopPages.TireSuppliers, searchParams),
          isActive: true,
        },
        {
          id: CreateShopPages.Role,
          name: 'Roles',
          url: getSignUpUrlNew(CreateShopPages.Role, searchParams),
          isActive: true,
        },
        {
          id: CreateShopPages.HearAboutUs,
          name: 'Hear about us',
          url: getSignUpUrlNew(CreateShopPages.HearAboutUs, searchParams),
          isActive: true,
        },
        {
          id: CreateShopPages.PhoneNumber,
          name: 'Phone number',
          url: getSignUpUrlNew(CreateShopPages.PhoneNumber, searchParams),
          isActive: true,
        },
      ].filter((step) => step.isActive),
    [isMitchell1SignUp, searchParams]
  );

  const activeStepIndex = useMemo(
    () => signUpSteps.findIndex((step) => matchPath(step.url.split('?')[0]!, pathname)),
    [pathname, signUpSteps]
  );

  const activeStep = useMemo(() => signUpSteps[activeStepIndex] ?? signUpSteps[0]!, [activeStepIndex, signUpSteps]);

  const nextStep = useMemo(() => signUpSteps[activeStepIndex + 1] ?? null, [activeStepIndex, signUpSteps]);

  const previousStep = useMemo(() => signUpSteps[activeStepIndex - 1] ?? null, [activeStepIndex, signUpSteps]);

  return { signUpSteps, activeStep, nextStep, previousStep };
};
