import { useSnackbar, useMedia } from '@partstech/ui';
import { useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { AddedToCartSnackbar } from 'components/CartBar/AddedToCartSnackbar';
import { Routes } from 'constant';
import { useAppSelector } from 'store';
import { selectLastAddedPart } from 'store/entities/shipmentPart';
import { useSearchSequenceStorage } from '../searchSequence/useSearchSequenceStorage';

export const useAddedToCartSnackbar = () => {
  const navigate = useAppNavigate();

  const { addSnackbar } = useSnackbar();

  const { isMobile, isWide } = useMedia();

  const { totalTask } = useSearchSequenceStorage();

  const lastAddedPart = useAppSelector(selectLastAddedPart, shallowEqual);

  const lastAddedPartId = lastAddedPart?.id;

  const shouldAddSearchTaskSnackbar = isMobile && totalTask > 0;

  const handleOpenCart = useCallback(() => {
    navigate(Routes.CART);
  }, [navigate]);

  useEffect(() => {
    if (lastAddedPartId && !isWide && !shouldAddSearchTaskSnackbar) {
      addSnackbar((removeSnackbar) => ({
        label: 'added',
        as: <AddedToCartSnackbar partId={lastAddedPartId} onRemove={removeSnackbar} />,
        position: {
          horizontal: 'middle',
          vertical: 'bottom',
          offsetY: isMobile ? 0 : 4,
        },
      }));
    }
  }, [addSnackbar, isMobile, isWide, lastAddedPartId, shouldAddSearchTaskSnackbar]);

  useEffect(() => {
    if (lastAddedPartId && shouldAddSearchTaskSnackbar) {
      addSnackbar(() => ({
        label: 'Item was added to cart',
        button: 'View',
        position: { horizontal: 'middle', vertical: 'bottom', offsetY: 18 },
        buttonColor: 'primaryLight',
        dense: true,
        onClick: handleOpenCart,
        fullWidth: true,
        maxCount: 1,
      }));
    }
  }, [addSnackbar, shouldAddSearchTaskSnackbar, lastAddedPartId, handleOpenCart]);
};
