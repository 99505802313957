/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetSubCategoryQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['ID']['input'];
  subCategoryId: Types.Scalars['ID']['input'];
}>;

export type GetSubCategoryQuery = {
  __typename?: 'Query';
  category?: {
    __typename?: 'PartCategory';
    subCategory?: {
      __typename?: 'PartSubCategory';
      id: string;
      name: Types.Scalars['String']['input'];
      partTypes?: Array<{
        __typename?: 'PartType';
        id: string;
        name: Types.Scalars['String']['input'];
        aliases?: Array<Types.Scalars['String']['input']> | null;
        application: Types.PartTypeApplication;
        attributes?: Array<{
          __typename?: 'PartTypeAttribute';
          name: Types.Scalars['String']['input'];
          required: Types.Scalars['Boolean']['input'];
          values?: Array<Types.Scalars['String']['input']> | null;
        }> | null;
      }> | null;
    } | null;
  } | null;
};

export const GetSubCategoryDocument = `
    query GetSubCategory($categoryId: ID!, $subCategoryId: ID!) {
  category(categoryID: $categoryId) {
    subCategory(subCategoryID: $subCategoryId) {
      id
      name
      partTypes {
        id
        name
        aliases
        application
        attributes {
          name
          required
          values
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubCategory: build.query<GetSubCategoryQuery, GetSubCategoryQueryVariables>({
      query: (variables) => ({ document: GetSubCategoryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSubCategoryQuery, useLazyGetSubCategoryQuery } = injectedRtkApi;
