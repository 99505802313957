import { useMemo } from 'react';
import { useFullPartTypes } from 'hooks/partType';
import { useCategories } from '../api';

type Props = {
  partTypeIds: number[];
};

export const useCategoryNamesMapByPartTypeIds = ({ partTypeIds }: Props) => {
  const { partTypes } = useFullPartTypes({ ids: partTypeIds.map(String) });
  const { categories } = useCategories({ skip: partTypeIds.length === 0 });

  const categoryNamesMap = useMemo(
    () =>
      partTypes.reduce<Record<string, string>>((acc, partType) => {
        const category = categories.find((item) => partType?.category?.id && item.id === String(partType.category?.id));

        if (category) {
          return {
            ...acc,
            [String(partType.id)]: category?.name || '',
          };
        }

        return acc;
      }, {}),
    [categories, partTypes]
  );

  return categoryNamesMap;
};
