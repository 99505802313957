import { Box, Scrollbar, useMedia } from '@partstech/ui';
import { chunk } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useColumnKeyboardSelection } from 'shared/lib/keyboard';
import { OptionList } from '../OptionList';
import type { OptionsListProps } from '../OptionList';
import type { ScrollBarProps } from '@partstech/ui';
import type { SearchEntryPointInterface } from 'features/searchForm';

type Props<T extends SearchEntryPointInterface | string> = Omit<ScrollBarProps, 'onSelect' | 'onChange'> &
  OptionsListProps<T> & {
    isKeyboardDisabled?: boolean;
    maxColumns?: number;
    minCountInColumn?: number;
  };

// TODO Extract to reusable component DEV-3507
export const OptionsByColumns = <T extends SearchEntryPointInterface | string>({
  minCountInColumn = 13,
  maxColumns,
  onChange,
  onSelect,
  options,
  adornmentBefore,
  adornmentAfter,
  isKeyboardDisabled,
  ...props
}: Props<T>) => {
  const { isMobile } = useMedia();

  const columnsCount = useMemo(() => {
    if (options.length > 40) {
      return isMobile ? (maxColumns ?? 4) : (maxColumns ?? 8);
    }

    if (options.length > 20) {
      return maxColumns ?? 4;
    }

    if (options.length > 10) {
      return 2;
    }

    return 1;
  }, [isMobile, maxColumns, options.length]);

  const perColumn = Math.ceil(options.length / columnsCount);

  const columns = useMemo(
    () => chunk(options, perColumn < minCountInColumn ? minCountInColumn : perColumn),
    [minCountInColumn, options, perColumn]
  );

  const columnMaxWidth = `${100 / columnsCount}%`;

  const { isActiveByColumnIndex } = useColumnKeyboardSelection({
    columns,
    onEnterKeyDown: onChange,
    onArrowKeyDown: onSelect,
    isKeyboardDisabled,
  });

  return (
    <Scrollbar {...props}>
      <Box display="flex" flexDirection="row" width="100%">
        {columns.map((optionsInColumn, columnIndex) => (
          <OptionList
            onChange={onChange}
            options={optionsInColumn}
            key={columnIndex}
            adornmentBefore={adornmentBefore}
            adornmentAfter={adornmentAfter}
            flex="1"
            maxWidth={columnMaxWidth}
            isActive={isActiveByColumnIndex(columnIndex)}
          />
        ))}
      </Box>
    </Scrollbar>
  );
};
