import { useEffect } from 'react';
import { useAfterMarketSupplierAccounts, useDealerSupplierAccounts, useSupplierAccounts } from 'hooks/supplierAccounts';
import { FSSetUserVars } from '../FSSetUserVars';

type Props = {
  skip?: boolean;
};

export const useSendSupplierAccountStats = ({ skip = false }: Props) => {
  const { accounts: tireAccounts, isSuccess: isLoadedSupplierAccounts } = useSupplierAccounts(
    { type: 'tires' },
    { skip }
  );
  const { afterMarketAccounts } = useAfterMarketSupplierAccounts({ skip });
  const { dealerAccounts } = useDealerSupplierAccounts({ skip });

  useEffect(() => {
    if (!skip && isLoadedSupplierAccounts) {
      FSSetUserVars({
        partSupplierCount: afterMarketAccounts.length,
        dealersSupplierCount: dealerAccounts.length,
        tireSuppliersCount: tireAccounts.length,
      });
    }
  }, [afterMarketAccounts.length, dealerAccounts.length, isLoadedSupplierAccounts, skip, tireAccounts.length]);
};
