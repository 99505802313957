/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { VehicleFragmentDoc } from '../../vehicle/VehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetRecentOrdersQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  fromDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  manufactureIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.TrackingStatus>;
  supplierIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;

export type GetRecentOrdersQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        recentOrders?: {
          __typename?: 'RecentOrderConnection';
          edges?: Array<{
            __typename?: 'RecentOrderEdge';
            node?: {
              __typename?: 'Order';
              canMarkAsDelivered: Types.Scalars['Boolean']['input'];
              confirmationNumbers: Array<Types.Scalars['String']['input']>;
              customNotes?: Types.Scalars['String']['input'] | null;
              customerNumber: Types.Scalars['String']['input'];
              datePaid?: Types.Scalars['DateTime']['input'] | null;
              discountTotal: Types.Scalars['BigDecimal']['input'];
              fetChargeTotal: Types.Scalars['BigDecimal']['input'];
              grandTotal: Types.Scalars['BigDecimal']['input'];
              hash: Types.Scalars['String']['input'];
              id: string;
              lastTrackingDate?: Types.Scalars['DateTime']['input'] | null;
              purchaseOrderNumber: Types.Scalars['String']['input'];
              shippingTotal: Types.Scalars['BigDecimal']['input'];
              taxTotal: Types.Scalars['BigDecimal']['input'];
              trackingStatus: Types.TrackingStatus;
              credential?: { __typename?: 'Account'; id: string } | null;
              lines?: Array<{
                __typename?: 'OrderItem';
                rewardPoints?: number | null;
                id: string;
                quantity: number;
                vin?: Types.Scalars['String']['input'] | null;
                vehicle?: {
                  __typename?: 'Vehicle';
                  id: string;
                  type: Types.VehicleType;
                  regions?: Array<Types.VehicleRegion> | null;
                  year: number;
                  make: {
                    __typename?: 'VehicleMake';
                    id: string;
                    name: Types.Scalars['String']['input'];
                    priority: number;
                  };
                  model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
                  subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
                  engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
                } | null;
              }> | null;
              supplier?: {
                __typename?: 'Supplier';
                id: string;
                name: Types.Scalars['String']['input'];
                coreChargePolicy?: Types.Scalars['String']['input'] | null;
                coreReturns: Types.Scalars['Boolean']['input'];
                logo: Types.Scalars['String']['input'];
                returnAuthSetting: Types.ReturnAuthSetting;
              } | null;
              user?: {
                __typename?: 'User';
                id: string;
                firstName: Types.Scalars['String']['input'];
                lastName: Types.Scalars['String']['input'];
              } | null;
            } | null;
          } | null> | null;
          pageInfo: {
            __typename?: 'PageInfo';
            endCursor?: Types.Scalars['String']['input'] | null;
            hasNextPage: Types.Scalars['Boolean']['input'];
            hasPreviousPage: Types.Scalars['Boolean']['input'];
          };
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetRecentOrdersDocument = `
    query GetRecentOrders($after: String, $first: Int, $fromDate: DateTime, $manufactureIds: [ID!], $search: String, $status: TrackingStatus, $supplierIds: [ID!], $toDate: DateTime) {
  currentUser {
    activeMember {
      shop {
        recentOrders(
          after: $after
          first: $first
          fromDate: $fromDate
          manufactureIds: $manufactureIds
          search: $search
          status: $status
          supplierIds: $supplierIds
          toDate: $toDate
        ) {
          edges {
            node {
              canMarkAsDelivered
              confirmationNumbers
              credential {
                id
              }
              customNotes
              customerNumber
              datePaid
              discountTotal
              fetChargeTotal
              grandTotal
              hash
              id
              lastTrackingDate
              lines {
                rewardPoints
                id
                quantity
                vin
                vehicle {
                  ...Vehicle
                }
              }
              purchaseOrderNumber
              shippingTotal
              supplier {
                id
                name
                coreChargePolicy
                coreReturns
                logo
                returnAuthSetting
              }
              taxTotal
              trackingStatus
              user {
                id
                firstName
                lastName
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
}
    ${VehicleFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetRecentOrders: build.query<GetRecentOrdersQuery, GetRecentOrdersQueryVariables | void>({
      query: (variables) => ({ document: GetRecentOrdersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRecentOrdersQuery, useLazyGetRecentOrdersQuery } = injectedRtkApi;
